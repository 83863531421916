import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { useStyles } from '../useStyles';
import { getPaperImgDimensions } from 'common/utils/documents';
import { DocCardComposition } from '../../../User/User.enum';
import { BaseError } from 'common/models/Error.interface';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  src: string;
  fallback?: string;
  cardComposition?: DocCardComposition;
  isIcon?: boolean;
  error?: BaseError | null;
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  alt?: string;
}

export const AdaptiveImage: FC<Props> = ({
  src,
  fallback,
  alt,
  className,
  cardComposition,
  isIcon,
  isLoading,
  error,
  children,
}) => {
  const classes = useStyles();

  const [link, setLink] = useState(src);

  useEffect(() => {
    setLink(src);
  }, [src]);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const [imgStyles, setImgStyles] = useState<React.CSSProperties>({});

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperStyles, setWrapperStyles] = useState<React.CSSProperties>({});

  const setStyles = () => {
    if (
      !error &&
      imgRef?.current?.naturalHeight &&
      imgRef?.current?.naturalWidth &&
      wrapperRef.current?.getBoundingClientRect
    ) {
      const { naturalHeight, naturalWidth } = imgRef.current;

      const styles = getPaperImgDimensions(
        wrapperRef.current.getBoundingClientRect(),
        { height: naturalHeight, width: naturalWidth },
        isIcon,
        cardComposition
      );

      setWrapperStyles(styles.wrapperStyles);
      setImgStyles(styles.imgStyles);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setStyles);
    return () => {
      window.removeEventListener('resize', setStyles);
    };
  }, [imgRef, wrapperRef, isIcon, setStyles]);

  const handleError = useCallback(() => {
    if (fallback && link !== fallback) {
      setLink(fallback);
    }
  }, [link, fallback]);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height="100%"
        classes={{ rectangular: classes.skeleton }}
      />
    );
  }

  const isFallback = fallback === link;

  return (
    <div className={classes.imgWrapper} ref={wrapperRef} style={wrapperStyles}>
      <img
        ref={imgRef}
        src={link}
        alt={alt ?? 'document img'}
        style={imgStyles}
        onLoad={setStyles}
        className={clsx(classes.img, className)}
        onError={handleError}
      />
      {!isFallback ? children : null}
    </div>
  );
};
