import { useCallback, useState } from 'react';

export const useSearchPopper = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleOpen = useCallback((newSearchQuery: string) => {
    setSearchQuery(newSearchQuery);
    setDialogOpen(true);
  }, []);

  return {
    dialogOpen,
    searchQuery,
    onOpen: handleOpen,
    onClose: handleClose,
  };
};
