import posthog from 'posthog-js';

import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { TrackEventName } from '../components/TrackedActions/withTrackedAction.interface';
import { captureException } from '@sentry/react';

export interface PosthogPeopleSetPayload {
  firstName: string;
  lastName: string;
  email: string;
  recommendations: boolean;
  feedbackButtons: boolean;
  onboarded: boolean;
  useOpenAI: boolean;
  affiliation?: string;
  promoCode?: string;
  creator?: string;
  expireDate?: string;
  cardStyle?: 'default' | 'compact' | 'compressed';
  indexCluster?: string;
}

export const posthogCapture: typeof posthog.capture = (...args) => {
  try {
    return posthog.capture(...args);
  } catch (error) {
    captureException(error);
    console.error(error);
  }
};

export const posthogPeopleSet = (payload: PosthogPeopleSetPayload) => {
  posthog.people.set(payload);
};

export const posthogAnnotations = {
  create: (tenant: string, highlightType: string) =>
    posthogCapture('Annotation create', { highlightType, tenant }),
  update: (tenant: string, highlightType: string) =>
    posthogCapture('Annotation update', { highlightType, tenant }),
};

export const posthogPDFReader = {
  open: (tenant: string, docId: string) =>
    posthogCapture('PDF Reader open', { docId, tenant }),
  print: (tenant: string, docId: string, fileName?: string) =>
    posthogCapture('PDF Reader print', { docId, fileName, tenant }),
  download: (tenant: string, docId: string, fileName?: string) =>
    posthogCapture('PDF Reader download', { docId, fileName, tenant }),
  chat: (tenant: string, isImageInNoteOrHighlight: boolean, isNote?: boolean) =>
    posthogCapture('PDF Reader chat', {
      tenant,
      isNote,
      isImageInNoteOrHighlight,
    }),
};

export const posthogSearch = {
  searchDocs: ({
    page,
    retrievalUnit,
    retrievalMethod,
    withCode,
    locationCountries,
    organizations,
    visibility,
    docTypes,
    sort,
    date,
    docSources,
    searchEngine,
    tenant,
    tagIds,
  }: Partial<SearchPayload>) =>
    posthogCapture('Search docs', {
      page,
      retrievalUnit,
      retrievalMethod,
      locationCountries,
      organizations,
      visibility,
      docTypes,
      docSources,
      searchEngine,
      code: withCode,
      docSort: sort?.toString(),
      dateRange: date?.toString(),
      tenant,
      tagIds,
    }),
};

export const posthogTag = {
  sortSelect: (
    tenant: string,
    actionContext: string,
    sortingSelection: string
  ) =>
    posthogCapture('Changed tag sorting selection', {
      actionContext,
      sortingSelection,
      tenant,
    }),
  shareMenuItem: (tenant: string, title: string, actionContext: string) => {
    posthogCapture(`Clicked on ${title} button`, {
      actionContext,
      tenant,
    });
  },
};

export const posthogAnalyticsWidget = {
  chartSelect: (
    tenant: string,
    actionContext: string,
    chartSelection: string
  ) =>
    posthogCapture('Changed the analytics widget chart', {
      actionContext,
      chartSelection,
      tenant,
    }),
};

export const posthogTagSuggestions = {
  open: (tenant: string, actionContext: string, tagId: number) =>
    posthogCapture(TrackEventName.TagClickFindSimilarButton, {
      actionContext,
      tagId,
      tenant,
    }),
};

export const posthogChangeQAQuestion = {
  open: (tenant: string, answerMode: string) =>
    posthogCapture(`Request answer on QA - ${answerMode} answer mode`, {
      tenant,
    }),
};
