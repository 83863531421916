import React, { FC, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './useStyles';
import { DocImageProps } from './DocImageProps';
import { getInitialsFromName } from 'common/utils/stringsHelpers';
import { getPaperImgBySource } from 'common/utils/documents';
import { DocMediaImage } from './DocMediaImage';
import { DocMediaLogo } from './DocMediaLogo';
import { AdaptiveImage } from './components/AdaptiveImage';

export const DocImage: FC<DocImageProps> = ({
  data: { source, image, documentId, logo, authors },
  cardComposition,
}) => {
  const classes = useStyles();

  const initials = useMemo(
    () => getInitialsFromName(authors?.[0]?.fullName ?? ''),
    [authors]
  );

  const fallback = useMemo(() => getPaperImgBySource(source), [source]);

  const getContent = useCallback(() => {
    if (image) {
      return (
        <DocMediaImage
          image={image}
          documentId={documentId}
          fallback={fallback}
          cardComposition={cardComposition}
        />
      );
    }

    if (logo) {
      return (
        <DocMediaLogo
          logo={logo}
          documentId={documentId}
          fallback={fallback}
          cardComposition={cardComposition}
          initials={initials}
        />
      );
    }

    return (
      <AdaptiveImage
        src={fallback}
        fallback={fallback}
        cardComposition={cardComposition}
      />
    );
  }, [image, logo, documentId, initials, fallback, cardComposition]);

  return (
    <Grid item xs={3} md={2} className={classes.grid}>
      <div className={classes.wrapper} aria-label="paper image">
        {getContent()}
      </div>
    </Grid>
  );
};

DocImage.displayName = 'DocImage';
