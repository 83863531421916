import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { AppDispatch } from 'app/state/store';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { addTagToDocument } from 'containers/SavedDocuments/savedDocuments.slice';
import { DocumentDragItem } from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export interface UseDocumentDragTaggingReturn {
  handleDocumentDrop: (
    item: DocumentDragItem,
    tag: TagDetails
  ) => Promise<void>;
}

export const useDocumentDragTagging = (): UseDocumentDragTaggingReturn => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const parsedHostname = useParsedHostname();

  const handleDocumentDrop = async (
    item: DocumentDragItem,
    tag: TagDetails
  ) => {
    await dispatch(
      addTagToDocument({
        docId: item.id,
        docType: item.docType,
        tagId: tag.id,
        tagType: tag.type,
        userOrder: 'first',
        tenant: parsedHostname.tenant,
      })
    );

    enqueueSnackbar(
      t('tagDocument.addSingle.successMessage', { tagName: tag.name })
    );
  };

  return {
    handleDocumentDrop,
  };
};
