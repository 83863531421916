import React from 'react';
import { Container, ContainerProps, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

type PageContainerPros = {} & ContainerProps;

export const PageContainer = ({ children, ...rest }: PageContainerPros) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.root} {...rest}>
      {children}
    </Container>
  );
};
