import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

interface Props extends TooltipProps {
  Component?: typeof Tooltip;
}

export const ControlledTooltip: FC<Props> = ({
  Component = Tooltip,
  children,
  open,
  ...props
}) => {
  return (
    <Component
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      {...props}
    >
      <span>{children}</span>
    </Component>
  );
};
