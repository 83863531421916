import React from 'react';
import { List, Popover, PopoverProps } from '@mui/material';
import { ResultType } from '@zarn/vendor/dist/query-logging';

import { RetrievalUnitAuthor } from '../RetrievalUnitData.interface';
import { ShareEnum } from 'common/enums';
import { DocAction } from '../DocActions/DocActions.interface';
import DocActionItem from '../DocActions/DocActionItem/DocActionItem';
import { useDocShareActions } from '../hooks/useDocShareActions';
import ShareLinkDialog from 'common/components/ShareDocuments/ShareLinkDialog/ShareLinkDialog';
import ShareViaEmailDialog from 'common/components/ShareDocuments/ShareViaEmailDialog/ShareViaEmailDialog';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export type DocSharePopoverProps = {
  docId: string;
  docTitle: string;
  docAuthors?: RetrievalUnitAuthor[];
  searchId?: string;
  resultType?: ResultType;
  open: boolean;
  shareUri?: string;
} & Partial<PopoverProps>;

const DocSharePopover = ({
  docId,
  docTitle,
  docAuthors,
  searchId,
  resultType,
  shareUri,
  ...rest
}: DocSharePopoverProps) => {
  const {
    docShareActions,
    shareDialog,
    notesLoading,
    setShareDialog,
    getShareLink,
    onShare,
    onNotesInclude,
  } = useDocShareActions({
    searchId,
    resultType,
    id: docId,
    title: docTitle,
    authorList: docAuthors,
    shareUri,
  });

  return (
    <>
      <Popover
        aria-label="document share popover"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...rest}
      >
        <List data-testid="docShareActions">
          {docShareActions.map((action: DocAction) => (
            <DocActionItem
              key={action.title}
              {...action}
              eventName={`${TrackEventName.MenuItemClicked} ${action.title}`}
            />
          ))}
        </List>
      </Popover>

      <ShareLinkDialog
        open={shareDialog === ShareEnum.Link}
        getShareLink={getShareLink}
        setOpen={setShareDialog}
        onShare={onShare}
      />

      <ShareViaEmailDialog
        open={shareDialog === ShareEnum.Email}
        onShare={onShare}
        getShareLink={getShareLink}
        notesLoading={notesLoading}
        onNotesInclude={onNotesInclude}
        setOpen={setShareDialog}
      />
    </>
  );
};

export default DocSharePopover;
