import React, { FC, useEffect, useRef } from 'react';
import { TextFieldProps } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { TextField } from '../TextField';

type Props = {
  name: string;
  testId?: string;
} & TextFieldProps;

export const FormTextField: FC<Props> = ({
  name,
  label,
  helperText,
  type,
  testId,
  autoFocus,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && !disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus, disabled]);

  return (
    <Field name={name} type={type}>
      {({ field, meta, form }: FieldProps) => (
        <TextField
          {...field}
          {...rest}
          autoFocus={autoFocus}
          disabled={disabled}
          inputRef={inputRef}
          fullWidth
          type={type}
          label={label}
          inputProps={{
            role: 'textbox',
            'data-testid': testId ?? 'mui-input',
          }}
          error={!!meta.error && form.submitCount > 0}
          FormHelperTextProps={{ 'aria-label': `${label} helper text` }}
          helperText={
            (form.submitCount > 0 && meta.error && t(meta.error)) || helperText
          }
        />
      )}
    </Field>
  );
};
