import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';

interface ErrorPageContentProps {
  title: string;
  description: React.ReactNode;
}

export const ErrorPageContent: FC<ErrorPageContentProps> = ({
  title,
  description,
}) => {
  const { t } = useTranslation(['pages', 'common']);

  return (
    <Box
      my={5}
      mx={3}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h1">{title}</Typography>

      <Typography>{description}</Typography>

      <Box mt={3}>
        <Button color="primary" startIcon={<HomeIcon />} component="a" href="/">
          {t('common:navBar.backToHome')}
        </Button>
      </Box>
    </Box>
  );
};
