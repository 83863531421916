import React, { useCallback } from 'react';
import { CircularProgress, TextFieldProps } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { TextField } from 'common/components/TextField';

export type UseTagsAutocompleteRenderInputProps = {
  loading?: boolean;
} & TextFieldProps;

export const useTagsAutocompleteRenderInput = ({
  loading,
  ...textFieldProps
}: UseTagsAutocompleteRenderInputProps) => {
  const { t } = useTranslation('tags');

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        data-testid="tagsAutocompleteInput"
        disabled={loading}
        variant="outlined"
        placeholder={t('tagsAutocomplete.placeholder')}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}

              {params.InputProps.endAdornment}
            </>
          ),
        }}
        {...textFieldProps}
      />
    ),
    [loading, ...Object.values(textFieldProps), t]
  );

  return renderInput;
};
