import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  resizeHandle: {
    position: 'absolute',
    height: '100%',
    width: 1,
    right: 0,
    top: 0,
    background: theme.palette.grey[100],
    cursor: 'col-resize',
    zIndex: 1,
    '&:hover, &:active': {
      backgroundColor: theme.palette.secondary.main,
      width: 4,
    },
  },
  positionLeft: {
    right: 'auto',
    left: 0,
  },
}));

type Props = {
  positionLeft?: boolean;
  onResizeStart: React.MouseEventHandler<HTMLButtonElement>;
};

export const DrawerResize = ({ positionLeft, onResizeStart }: Props) => {
  const classes = useStyles();

  return (
    <button
      type="button"
      aria-label="resize drawer"
      onMouseDown={onResizeStart}
      aria-hidden="true"
      className={clsx(
        classes.resizeHandle,
        positionLeft && classes.positionLeft
      )}
    />
  );
};
