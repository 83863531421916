import React from 'react';
import { Box, BoxProps } from '@mui/material';

type TabContentProps<T extends string> = {
  activeTab: T;
  tabId: T;
  children?: React.ReactNode;
} & BoxProps;

const TabContent = <T extends string>({
  children,
  activeTab,
  tabId,
  ...boxProps
}: TabContentProps<T>) => {
  return (
    <Box id={tabId} role="tabpanel" hidden={activeTab !== tabId} {...boxProps}>
      {activeTab === tabId && children}
    </Box>
  );
};

export default TabContent;
