import { BORDER_RADIUS } from '../../../app/theme';
import { alpha, Theme } from '@mui/material';

export const style = ({ theme }: { theme: Theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: BORDER_RADIUS,
    borderWidth: 1,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.divider,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 8px 2px`,
    },
  },
});
