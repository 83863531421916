import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = ButtonProps & NavLinkProps;

const TrackedNavLinkButton = ({
  onClick,
  onAction,
  to,
  children,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button to={to} component={NavLink} {...rest} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedNavLinkButton
);
