import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { DocCardComposition } from 'containers/User/User.enum';

type RetrievalUnitSkeletonProps = {
  cardComposition?: DocCardComposition;
};

export const RetrievalUnitSkeleton = ({
  cardComposition,
}: RetrievalUnitSkeletonProps) => {
  const actionSkeletons = [1, 2, 3, 4];

  return (
    <Box mb={2}>
      <Card aria-label="retrieval unit skeleton">
        <Grid container spacing={0}>
          <Grid item xs={3} md={2}>
            <CardContent style={{ paddingRight: 0 }}>
              <Skeleton height={100} variant="rectangular" />
            </CardContent>
          </Grid>

          <Grid item xs={9} md={10}>
            <CardHeader
              title={<Skeleton height={30} width={200} />}
              subheader={<Skeleton height={15} />}
            />

            <CardContent>
              <Skeleton height={15} />
              <Skeleton height={15} />
              <Skeleton height={15} width="70%" />
            </CardContent>
          </Grid>
        </Grid>
        {(cardComposition === DocCardComposition.Default ||
          cardComposition === undefined) && (
          <>
            <Divider />
            <CardActions>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Skeleton height={20} width={40} />
                </Grid>

                <Grid
                  container
                  item
                  xs={6}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  {actionSkeletons.map((action) => (
                    <Grid item key={action}>
                      <Skeleton variant="circular" height={24} width={24} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </CardActions>
          </>
        )}
      </Card>
    </Box>
  );
};
