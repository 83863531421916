import {
  RetrievalUnitEnum,
  RetrievalMethodEnum,
  SearchSortField,
  SearchSortMethodEnum,
  SearchTypeEnum,
  SearchEngineEnum,
} from 'common/enums';
import {
  SearchPayload,
  SearchPayloadDocType,
} from 'containers/Search/SearchPayload.interface';
import { SearchDateFilterValueEnum } from '../SearchFilters/SearchDateFilter/SearchDateFilterValue.enum';
import { TENANT } from 'common/constants';

export const DEFAULT_RETRIEVAL_UNIT = RetrievalUnitEnum.Document;

export const DEFAULT_RETRIEVAL_METHOD = RetrievalMethodEnum.Knn;

export const DEFAULT_DOC_TYPES: SearchPayloadDocType[] = ['document', 'note'];

export const DEFAULT_MIN_YEAR = 2010;

export const DEFAULT_SORT_METHOD = SearchSortMethodEnum.Date;

export const DEFAULT_SEARCH_PAYLOAD: SearchPayload = {
  queryString: '',
  retrievalUnit: DEFAULT_RETRIEVAL_UNIT,
  retrievalMethod: DEFAULT_RETRIEVAL_METHOD,
  page: 1,
  tenant: TENANT,
};

export const DEFAULT_SEARCH_SORT_METHODS = {
  [SearchTypeEnum.Query]: SearchSortMethodEnum.Relevance,
  [SearchTypeEnum.Default]: SearchSortMethodEnum.Date,
  [SearchTypeEnum.Term]: SearchSortMethodEnum.Citations,
};

export const THIRD_PARTY_ENGINES = [SearchEngineEnum.GoogleScholar];

export const SEARCH_SORT_METHOD_TO_FIELD = {
  [SearchSortMethodEnum.Date]: SearchSortField.Date,
  [SearchSortMethodEnum.Citations]: SearchSortField.Citations,
  [SearchSortMethodEnum.Popularity]: SearchSortField.Popularity,
  [SearchSortMethodEnum.Authority]: SearchSortField.Authority,
  [SearchSortMethodEnum.Code]: SearchSortField.Code,
};

export const DATE_FILTER_WITH_CITATIONS_FOR_TERM = [
  SearchDateFilterValueEnum.Custom,
  SearchDateFilterValueEnum.LastYear,
  SearchDateFilterValueEnum.AnyTime,
];
