import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import { AddPrivateDocsMethodEnum } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocsMethod.enum';
import { AddPrivateDocsOption } from 'containers/PrivateDocs/AddPrivateDocs/AddPrivateDocs.interface';

export const useAddPrivateDocsOptions = (): AddPrivateDocsOption[] => {
  const { t } = useTranslation('privateDocs');

  return useMemo<AddPrivateDocsOption[]>(
    () => [
      {
        primaryText: t('options.singleDoc.primaryText'),
        method: AddPrivateDocsMethodEnum.SinglePaper,
        icon: NoteAddIcon,
      },
      {
        primaryText: t('options.batchUpload.primaryText'),
        method: AddPrivateDocsMethodEnum.BatchUpload,
        icon: ImportContactsIcon,
      },
    ],
    [t]
  );
};
