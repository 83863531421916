import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';

import { AppDispatch } from 'app/state/store';
import { userDocumentCreate } from 'api/privateDocsApi';
import { AddPrivateDocsFormValues } from './AddPrivateDocsFormValues.interface';
import { HitType } from 'common/enums';
import { deserializeAxiosError } from 'common/utils/error';
import {
  saveDocumentToFavourites,
  updateDocumentTags,
} from 'containers/SavedDocuments/savedDocuments.slice';
import { selectFavouriteTag } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { TagDetailsBase } from 'api/tagsApi';
import { useTagsPageParams } from 'pages/TagsPage/hooks/useTagsPageParams';
import { useOnAddTaggedDoc } from 'containers/TaggedDocs/hooks/useOnAddTaggedDoc';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { getDocStatus } from 'common/utils/documents';
import { UserDocumentStatus } from '@zarn/vendor/dist/user-documents';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { selectUser } from '../../../User/user.slice';
import { FileWithPath } from 'react-dropzone';
import { Nullable } from '../../../../common/utils/assert';
import { captureException } from '@sentry/react';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

interface UseAddPrivateDocsFormReturn {
  initialValues: AddPrivateDocsFormValues;
  onSubmit: (
    values: AddPrivateDocsFormValues,
    { setSubmitting }: FormikHelpers<AddPrivateDocsFormValues>
  ) => Promise<void>;
  loadingsStates: Array<Nullable<'loading' | 'success' | 'error'>>;
}

export const useAddPrivateDocsForm = (
  onFormSubmit: () => void
): UseAddPrivateDocsFormReturn => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const dispatch = useDispatch<AppDispatch>();
  const onAddTaggedDoc = useOnAddTaggedDoc();
  const { tenant } = useParsedHostname();
  const favouriteTag = useSelector(selectFavouriteTag);
  const routeMatch = useTagsPageParams();
  const currentTagPageId = routeMatch?.params?.tagId;
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectUser);
  const navigation = useNavigationWithState();

  const [loadingsStates, setLoadingsStates] = useState<
    Array<Nullable<'loading' | 'success' | 'error'>>
  >([]);

  const initialValues = useMemo(
    (): AddPrivateDocsFormValues => ({
      uri: '',
      title: '',
      file: [],
      abstractText: '',
      year: undefined,
      source: '',
      authors: '',
      selectedTags: [],
      addToFavorites: true,
      shareWithOrg: false,
    }),
    []
  );

  const handleUpdateTaggedDocs = useCallback(
    async (newPrivateDoc: RetrievalUnitData, tags: TagDetailsBase[]) => {
      const tag = tags.find(({ id }) => String(id) === currentTagPageId);

      if (!tag) return;

      onAddTaggedDoc(tag.id, {
        ...newPrivateDoc,
        status:
          newPrivateDoc.status ??
          getDocStatus({ status: UserDocumentStatus.Pending }) ??
          undefined,
      });
    },
    [onAddTaggedDoc, currentTagPageId]
  );

  const handleAddPrivateDocToTags = useCallback(
    async (
      values: AddPrivateDocsFormValues,
      newPrivateDoc: RetrievalUnitData
    ) => {
      if (!values.selectedTags.length) {
        return;
      }

      await dispatch(
        updateDocumentTags({
          organizeDocId: newPrivateDoc.organizeDocId,
          docType: HitType.PrivateDocument,
          selectedTags: values.selectedTags,
          tenant,
        })
      );

      await handleUpdateTaggedDocs(newPrivateDoc, values.selectedTags);
    },
    [dispatch, tenant, handleUpdateTaggedDocs]
  );

  const handleAddPrivateDocToFavourites = useCallback(
    async (
      values: AddPrivateDocsFormValues,
      newPrivateDoc: RetrievalUnitData
    ) => {
      if (!values.addToFavorites || !favouriteTag) {
        return;
      }

      await dispatch(
        saveDocumentToFavourites({
          organizeDocId: newPrivateDoc.organizeDocId,
          docType: HitType.PrivateDocument,
          tenant,
        })
      );

      await handleUpdateTaggedDocs(newPrivateDoc, [favouriteTag]);
    },
    [favouriteTag, dispatch, tenant, handleUpdateTaggedDocs]
  );

  const createDocument = useCallback(
    (values: AddPrivateDocsFormValues) =>
      async (fileToUpload: Nullable<FileWithPath>, index: number) => {
        try {
          const { data } = await userDocumentCreate(
            values,
            fileToUpload,
            tenant,
            user?.useOpenAI
          );
          await handleAddPrivateDocToTags(values, data);
          await handleAddPrivateDocToFavourites(values, data);
          setLoadingsStates((prev) => {
            const newState = [...prev];
            newState[index] = 'success';
            return newState;
          });
        } catch (error) {
          captureException(error);
          setLoadingsStates((prev) => {
            const newState = [...prev];
            newState[index] = 'error';
            return newState;
          });
          throw error;
        }
      },
    [
      handleAddPrivateDocToFavourites,
      handleAddPrivateDocToTags,
      tenant,
      user?.useOpenAI,
    ]
  );

  const createSingleDocument = useCallback(
    async (values: AddPrivateDocsFormValues) => {
      setLoadingsStates(['loading']);
      await createDocument(values)(values.file[0] ?? null, 0);
      enqueueSnackbar(t('privateDocs:addPrivateDocForm.successMessage'));
    },
    [createDocument, enqueueSnackbar, t]
  );

  const createMultiDocuments = useCallback(
    async (values: AddPrivateDocsFormValues) => {
      setLoadingsStates(values.file.map(() => 'loading'));
      const allPromises = await Promise.allSettled(
        values.file.map(createDocument(values))
      );
      const successPromises = allPromises.filter(
        ({ status }) => status === 'fulfilled'
      );
      if (successPromises.length === allPromises.length) {
        enqueueSnackbar(
          t('privateDocs:addPrivateDocForm.successAllFilesMessage', {
            all: allPromises.length,
            variant: 'success',
          })
        );
      } else if (successPromises.length) {
        enqueueSnackbar(
          t('privateDocs:addPrivateDocForm.successSomeFilesMessage', {
            success: successPromises.length,
            all: allPromises.length,
            variant: 'warning',
          })
        );
      } else {
        enqueueSnackbar(t('privateDocs:addPrivateDocForm.failedFilesMessage'), {
          variant: 'warning',
        });
      }
    },
    [createDocument, enqueueSnackbar, t]
  );

  const handleSubmit = useCallback(
    async (
      values: AddPrivateDocsFormValues,
      { setSubmitting }: FormikHelpers<AddPrivateDocsFormValues>
    ) => {
      setSubmitting(true);

      try {
        if (values.file.length > 1) {
          await createMultiDocuments(values);
        } else {
          await createSingleDocument(values);
        }

        onFormSubmit();

        navigation.push(`/my-documents`, {
          new_doc_added: new Date().toString(),
        });
      } catch (error) {
        captureException(error);
        const err = deserializeAxiosError(error);

        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }

      setSubmitting(false);
    },
    [
      enqueueSnackbar,
      onFormSubmit,
      navigation,
      createMultiDocuments,
      createSingleDocument,
    ]
  );

  return {
    initialValues,
    onSubmit: handleSubmit,
    loadingsStates,
  };
};
