import { Form, FormikProps } from 'formik';
import { AddPrivateDocsFormValues } from './AddPrivateDocsFormValues.interface';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { PrivateDocForm } from '../../PrivateDocForm/PrivateDocForm';
import { FormSectionDivider } from 'common/components/FormSection/FormSectionDivider';
import TagsAutocomplete from '../../../Tagging/TagsAutocomplete';
import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'common/utils/assert';
import { isSharedTag } from '../../privateDocs.utils';
import { ControlledTooltip } from 'common/components/Tooltips/ControlledTooltip';
import { WarningTooltip } from 'common/components/Tooltips/WarningTooltip';

interface Props extends FormikProps<AddPrivateDocsFormValues> {
  loadingsStates: Array<Nullable<'loading' | 'success' | 'error'>>;
}

export const AddPrivateDocForm: FC<Props> = ({
  loadingsStates,
  ...helpers
}) => {
  const { t } = useTranslation(['common', 'privateDocs']);

  const sharedTags = helpers.values.selectedTags
    .filter(isSharedTag)
    .map(({ name }) => name);

  const isShareTooltip = !helpers.values.shareWithOrg && !!sharedTags.length;

  return (
    <Form>
      <DialogContent>
        <DialogContentText>
          {t('privateDocs:addDialog.explanation')}
        </DialogContentText>

        <PrivateDocForm loadingsStates={loadingsStates} {...helpers} />

        <FormSectionDivider />

        <TagsAutocomplete
          name="selectedTags"
          suggestedTags={[]}
          margin="normal"
          helperText={t('tags:tagsAutocomplete.helperText')}
          label={t('tags:tagsAutocomplete.label')}
        />
        <FormGroup>
          <FormControlLabel
            labelPlacement="start"
            label={t('privateDocs:form.addToFavorites.label')}
            control={<FormSwitchControl name="addToFavorites" />}
          />
          <FormControlLabel
            labelPlacement="start"
            label={t('privateDocs:form.sharing.label')}
            control={
              <ControlledTooltip
                Component={WarningTooltip}
                title={t('privateDocs:addPrivateDocForm.errorTags', {
                  tags: sharedTags.join(', '),
                })}
                placement="left"
                arrow
                open={isShareTooltip}
              >
                <FormSwitchControl name="shareWithOrg" />
              </ControlledTooltip>
            }
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button type="reset" disabled={helpers.isSubmitting}>
              {t('common:buttons.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <SubmitButton
              disabled={helpers.isSubmitting}
              isSubmitting={helpers.isSubmitting}
              text={t('privateDocs:form.submitButton.text')}
              eventName={TrackEventName.PrivateDocumentImported}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Form>
  );
};
