import React from 'react';
import { Skeleton } from '@mui/material';

export const TagsListSkeleton = () => {
  return (
    <div data-testid="tagsListLoadingSkeleton">
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
    </div>
  );
};
