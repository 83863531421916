import { createContext } from 'react';
import { ConfirmDialogContextProps } from './ConfirmDialog.types';

const INIT_VALUE: ConfirmDialogContextProps = {
  dialog: null,
  setDialog: () => void 0,
};

export const ConfirmDialogContext =
  createContext<ConfirmDialogContextProps>(INIT_VALUE);
