import { PaginatedResults } from 'api/models/PaginatedResults';
import { AxiosResponse } from 'axios';

import {
  RecommendationsApi,
  PaginatedResponseRecommendationItem,
  Configuration,
  RecommendationTasksApi,
} from '@zarn/vendor/dist/recommendations';
import { SERVICE_URL, BASE_HEADERS } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  ListRecommendationsPayload,
  RecTask,
  RecommendedItem,
} from './RecommendationsApi.types';
import {
  deserializeRecommendation,
  deserializeRecommendationTaskItem,
} from './recommendationsApi.utils';

export const DEFAULT_PAGE_SIZE = 10;

export const recommendationsApi = new RecommendationsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const recommendationsTasksApi = new RecommendationTasksApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const getRecommendations = (
  page: number,
  findRecInContext?: string | undefined,
  tagId?: string
): Promise<AxiosResponse<PaginatedResponseRecommendationItem>> => {
  return recommendationsApi.getRecommendations({
    requesterUuid: '',
    userRoles: '',
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    findRecInContext,
    tagId,
  });
};

export const listRecommendations = async ({
  tagId,
  pageSize = DEFAULT_PAGE_SIZE,
  ...payload
}: ListRecommendationsPayload): Promise<
  AxiosResponse<PaginatedResults<RecommendedItem>>
> => {
  const { data, ...rest } = await recommendationsApi.getRecommendations({
    ...payload,
    requesterUuid: '',
    userRoles: '',
    pageSize,
    tagId: tagId !== undefined ? String(tagId) : undefined,
  });

  const page = data.previous?.page ? data.previous?.page + 1 : payload.page;

  return {
    ...rest,
    data: new PaginatedResults(
      data.count,
      data.results.map(deserializeRecommendation),
      data.next?.page,
      data.previous?.page,
      page,
      pageSize
    ),
  };
};

export const getRecommendationsTask = async (): Promise<
  AxiosResponse<RecTask>
> => {
  const { data, ...rest } = await recommendationsTasksApi.getTask({
    requesterUuid: '',
    userRoles: '',
  });

  return {
    ...rest,
    data: deserializeRecommendationTaskItem(data),
  };
};

export const addRecommendationTask = async (): Promise<
  AxiosResponse<RecTask>
> => {
  const { data, ...rest } = await recommendationsTasksApi.addTask({
    requesterUuid: '',
    userRoles: '',
  });

  return {
    ...rest,
    data: deserializeRecommendationTaskItem(data),
  };
};
