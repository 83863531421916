import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from 'containers/User/user.slice';
import { useAssertTenantSettings } from './useAssertTenantSettings';

export interface UseSearchPayloadReturnValue {
  searchPayload: SearchPayload;
  searchQuery: string;
  indexCluster?: string | null;
}

export function useSearchPayload(): UseSearchPayloadReturnValue {
  const { search } = useLocation();
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);
  const { searchDynamicsFilters } = useAssertTenantSettings();

  const searchParams = new URLSearchParams(search);
  searchParams.delete('tab');
  searchParams.delete('noteId');
  searchParams.delete('docId');
  const searchWithoutTabAndNoteId = searchParams.toString();

  const searchPayload = useMemo<UseSearchPayloadReturnValue>(() => {
    const payload = new SearchPayloadFromURL(
      searchWithoutTabAndNoteId,
      parsedHostname,
      undefined,
      indexCluster,
      searchDynamicsFilters
    );

    return {
      searchPayload: payload.getSearchPayload(),
      searchQuery: searchWithoutTabAndNoteId,
    };
  }, [
    searchWithoutTabAndNoteId,
    parsedHostname,
    indexCluster,
    searchDynamicsFilters,
  ]);

  return searchPayload;
}
