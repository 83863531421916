import React from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

const DEFAULT_DEBOUNCE = 300;

type Props = {
  debounceMs?: number;
};

const FormAutoSave = ({ debounceMs = DEFAULT_DEBOUNCE }: Props) => {
  const formikContext = useFormikContext();

  const debouncedSubmit = React.useCallback(
    debounce(() => formikContext.submitForm(), debounceMs),
    [debounceMs, formikContext.submitForm]
  );

  React.useEffect(() => {
    const isInitial = formikContext.initialValues === formikContext.values;
    if (!isInitial) {
      debouncedSubmit();
    }
  }, [formikContext.values]);

  return null;
};

FormAutoSave.displayName = 'FormAutoSave';

export default FormAutoSave;
