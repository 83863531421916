import { serializeIds } from 'common/utils/apiHelpers';
import { Configuration, DocumentsApi } from '@zarn/vendor/dist/saved-results';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';
import {
  CreateFavoriteDocumentPayload,
  DocumentUserData,
  ListDocumentUserDataPayload,
  ListFavoriteDocsPayload,
  ListTaggedDocsPayload,
  TagDocumentPayload,
  TaggedDocumentResponse,
  UntagDocumentPayload,
} from './DocumentsApi.types';
import {
  deserializeDocumentUserData,
  deserializeTaggedDocumentResponse,
  toTagTypeParam,
} from './documentsApi.utils';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { AxiosResponse } from 'axios';
import { serializeTagOrder } from 'api/tagsApi';

export const documentsApi = new DocumentsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const listTaggedDocs = async ({
  tagType,
  tagId,
  page = 1,
  pageSize = 10,
  docIds,
  tenant,
}: ListTaggedDocsPayload): Promise<
  AxiosResponse<PaginatedResults<TaggedDocumentResponse>>
> => {
  const { data, ...rest } = await documentsApi.taggedDocumentsFilter({
    requesterUuid: '',
    userRoles: '',
    tagType: toTagTypeParam(tagType),
    id: tagId,
    page,
    pageSize,
    documentIds: docIds,
    tenant,
  });

  return {
    ...rest,
    data: new PaginatedResults<TaggedDocumentResponse>(
      data.count,
      data.results.map(deserializeTaggedDocumentResponse),
      data.next?.page,
      data.previous?.page,
      page,
      pageSize
    ),
  };
};

export const listFavoriteDocs = async ({
  page = 1,
  pageSize = 10,
  tenant,
  ...restPayload
}: ListFavoriteDocsPayload): Promise<
  AxiosResponse<PaginatedResults<TaggedDocumentResponse>>
> => {
  const { data, ...rest } = await documentsApi.favoriteTagDocumentsFilter({
    requesterUuid: '',
    userRoles: '',
    page,
    pageSize,
    tenant,
    ...restPayload,
  });

  return {
    ...rest,
    data: new PaginatedResults<TaggedDocumentResponse>(
      data.count,
      data.results.map(deserializeTaggedDocumentResponse),
      data.next?.page,
      data.previous?.page,
      page,
      pageSize
    ),
  };
};

export const listDocumentsUserData = async ({
  docIds,
  tenant,
  ...payload
}: ListDocumentUserDataPayload): Promise<
  AxiosResponse<PaginatedResults<DocumentUserData>>
> => {
  const { data, ...rest } = await documentsApi.docsFilter({
    requesterUuid: '',
    userRoles: '',
    tenant,
    ...payload,
    docGuids: docIds ? docIds && serializeIds(docIds) : undefined,
  });

  return {
    ...rest,
    data: new PaginatedResults<DocumentUserData>(
      data.count,
      data.results.map(deserializeDocumentUserData),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const tagDocument = async ({
  tagType,
  tagId,
  docId,
  docType,
  userOrder,
  tenant,
}: TagDocumentPayload): Promise<AxiosResponse<TaggedDocumentResponse>> => {
  const { data, ...rest } = await documentsApi.createTaggedDocument({
    requesterUuid: '',
    userRoles: '',
    tagType: toTagTypeParam(tagType),
    id: tagId,
    tenant,
    taggedDocumentForm: {
      document_id: docId,
      document_type: docType as string,
      user_order: serializeTagOrder(userOrder),
    },
  });

  return {
    ...rest,
    data: deserializeTaggedDocumentResponse(data),
  };
};

export const updateTagDocument = async ({
  tagType,
  tagId,
  docId,
  docType,
  userOrder,
  tenant,
}: TagDocumentPayload): Promise<AxiosResponse<TaggedDocumentResponse>> => {
  const { data, ...rest } = await documentsApi.replaceTaggedDocument({
    requesterUuid: '',
    userRoles: '',
    tenant,
    tagType: toTagTypeParam(tagType),
    id: tagId,
    documentId: docId,
    taggedDocumentUpdateForm: {
      document_type: docType as string,
      user_order: serializeTagOrder(userOrder),
    },
  });

  return {
    ...rest,
    data: deserializeTaggedDocumentResponse(data),
  };
};

export const createFavoriteDocument = async ({
  docId,
  docType,
  userOrder,
  tenant,
}: CreateFavoriteDocumentPayload): Promise<
  AxiosResponse<TaggedDocumentResponse>
> => {
  const { data, ...rest } = await documentsApi.createFavoriteTagDocument({
    requesterUuid: '',
    userRoles: '',
    tenant,
    taggedDocumentForm: {
      document_id: docId,
      document_type: docType as string,
      user_order: serializeTagOrder(userOrder),
    },
  });

  return {
    ...rest,
    data: deserializeTaggedDocumentResponse(data),
  };
};

export const updateFavoriteDocument = async ({
  docId,
  docType,
  userOrder,
  tenant,
}: CreateFavoriteDocumentPayload): Promise<
  AxiosResponse<TaggedDocumentResponse>
> => {
  const { data, ...rest } = await documentsApi.replaceFavoriteTagDocument({
    requesterUuid: '',
    userRoles: '',
    tenant,
    documentId: docId,
    taggedDocumentUpdateForm: {
      document_type: docType as string,
      user_order: serializeTagOrder(userOrder),
    },
  });

  return {
    ...rest,
    data: deserializeTaggedDocumentResponse(data),
  };
};

export const untagDocument = ({
  tagType,
  tagId,
  docId,
  tenant,
}: UntagDocumentPayload): Promise<AxiosResponse<void>> => {
  return documentsApi.deleteTaggedDocument({
    requesterUuid: '',
    userRoles: '',
    tenant,
    tagType: toTagTypeParam(tagType),
    id: tagId,
    documentId: docId,
  });
};

export const unFavoriteDocument = (
  docId: string,
  tenant: string
): Promise<AxiosResponse<void>> => {
  return documentsApi.deleteFavoriteTagDocument({
    requesterUuid: '',
    userRoles: '',
    tenant,
    documentId: docId,
  });
};
