import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

import {
  API_CONFIG,
  AXIOS_RETRY_CONFIG,
  BASE_API_URL,
  BASE_HEADERS,
} from './apiConfig';
import { isJWTValid } from '../common/utils/jwt-tokens';
import {
  AuthenticationApiFactory,
  Configuration,
} from '@zarn/vendor/dist/auth';

export const axiosInstance = axios.create(API_CONFIG);

const AUTH_URL = `${BASE_API_URL}/auth`;
const authenticationApi = AuthenticationApiFactory(
  new Configuration({ basePath: AUTH_URL, baseOptions: BASE_HEADERS }),
  AUTH_URL,
  axios
);

export const setAuthHeaders = async (config: AxiosRequestConfig) => {
  const headers = { ...config.headers };
  const refreshToken = localStorage.getItem('refresh-token');
  const accessToken = localStorage.getItem('token');

  const result = { ...config, headers };

  if (!accessToken && !result) {
    return result;
  }

  headers.Authorization = `Bearer ${accessToken}`;

  if (!isJWTValid(accessToken) && refreshToken && isJWTValid(refreshToken)) {
    try {
      const response = await authenticationApi.refresh({ jwt: refreshToken });
      const { jwt } = response.data;
      headers.Authorization = `Bearer ${jwt}`;
    } catch (error) {
      console.error('Error refreshing token', error);
    }
  }

  return result;
};

axiosInstance.interceptors.request.use(setAuthHeaders);

axiosRetry(axiosInstance, AXIOS_RETRY_CONFIG);

export default axiosInstance;
