import React, { useRef, useState } from 'react';
import { Box, CardContent, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { SendFeedbackScoreToggle } from '../SendFeedbackScoreToggle/SendFeedbackScoreToggle';
import { TextField } from 'common/components/TextField';

const useStyles = makeStyles(() => ({
  popoverContent: {
    maxWidth: 340,
  },
  buttonsWrapper: {
    display: 'inline-flex',
  },
}));

const DELAY = 500;

type Props = {
  withFeedbackMessage?: boolean;
  iconStyle?: React.CSSProperties;
};

export const SendFeedbackForm = ({ withFeedbackMessage, iconStyle }: Props) => {
  const { t } = useTranslation(['common', 'feedback']);
  const classes = useStyles();
  const scoreButtonsRef = useRef(null);
  const { submitForm, isSubmitting, setFieldValue } = useFormikContext();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const handleSubmit = async () => {
    await submitForm();
    setPopoverOpen(false);
  };

  const handleOpenPopover = () => setPopoverOpen(true);

  const handleClosePopover = async (
    e: Event,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') {
      await handleSubmit();
    } else {
      setPopoverOpen(false);
    }
  };

  const handleMouseDown = async ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const scoreValue = Number(currentTarget.value);
    const clickStart = new Date().valueOf();
    const openPopoverTimeout = setTimeout(() => {
      handleOpenPopover();
      if (Number.isInteger(scoreValue)) {
        setFieldValue('feedbackScore', scoreValue);
      }
    }, DELAY);

    const listener = async () => {
      const clickEnd = new Date().valueOf();
      if (clickEnd - clickStart < DELAY || !withFeedbackMessage) {
        clearTimeout(openPopoverTimeout);
        setFieldValue('feedbackScore', scoreValue);
        await handleSubmit();
      }

      currentTarget.removeEventListener('mouseup', listener);
      setPopoverOpen(false);
    };

    currentTarget.addEventListener('mouseup', listener);
  };

  return (
    <>
      <div ref={scoreButtonsRef} className={classes.buttonsWrapper}>
        <SendFeedbackScoreToggle
          onMouseDown={handleMouseDown}
          iconStyle={iconStyle}
        />
      </div>

      {withFeedbackMessage && (
        <Popover
          disablePortal
          open={popoverOpen}
          anchorEl={scoreButtonsRef?.current}
          onClose={handleClosePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <CardContent className={classes.popoverContent}>
            <Field name="feedbackText">
              {({ field }: FieldProps) => (
                <TextField
                  fullWidth
                  autoFocus
                  label={t('feedback:form.yourFeedback')}
                  multiline
                  {...field}
                />
              )}
            </Field>
          </CardContent>

          <Box p={2} pt={0} display="flex" justifyContent="flex-end">
            <SubmitButton
              text={t('feedback:form.sendFeedback')}
              aria-label="submit"
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              type="button"
              onClick={handleSubmit}
            />
          </Box>
        </Popover>
      )}
    </>
  );
};
