import React, { FC } from 'react';
import { useDocumentImage } from './hooks/useDocumentImage';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from '../../User/user.slice';
import { DocImage } from '../RetrievalUnitData.interface';
import { AdaptiveImage } from './components/AdaptiveImage';
import { DocCardComposition } from '../../User/User.enum';

interface Props {
  documentId: string;
  image: DocImage;
  fallback: string;
  cardComposition?: DocCardComposition;
}

export const DocMediaImage: FC<Props> = ({
  documentId,
  fallback,
  cardComposition,
}) => {
  const indexCluster = useSelector(selectIndexCluster);
  const { data, isLoading, error } = useDocumentImage({
    docId: documentId,
    asset: {
      assetType: DocAssetTypeEnum.Image,
      assetValue: '',
    },
    indexCluster: indexCluster,
  });

  return (
    <AdaptiveImage
      src={data?.url ?? fallback}
      fallback={fallback}
      isLoading={isLoading}
      error={error}
      cardComposition={cardComposition}
    />
  );
};
