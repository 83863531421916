import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const MIN_NOTE_LENGTH = 1;

export const useNoteFormValidation = () => {
  const { t } = useTranslation('common');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        content: Yup.string()
          .min(MIN_NOTE_LENGTH, t('notes.fields.content.errors.minLength'))
          .required(t('errors.required')),
      }),
    []
  );

  return { validationSchema };
};
