import { useCallback, useContext, useMemo } from 'react';
import { AuthContext, AuthContextValue } from 'containers/Auth/Auth.context';
import { RoleEnum } from 'containers/Auth/enums';
import { Undefined } from 'common/utils/assert';
import { getFirstInitial } from '../../../common/utils/strings.utils';

export interface UseAuthReturn extends AuthContextValue {
  hasAccess: (roles: RoleEnum[]) => boolean;
  isAuthenticated: boolean;
  accountId: Undefined<number>;
  userRoleId: Undefined<number>;
  accountName: string;
  organizationInitials: string;
}

export const useAuth = (): UseAuthReturn => {
  const authContext = useContext(AuthContext);

  const hasAccess = useCallback(
    (roles: RoleEnum[]) => authContext.me?.hasAccess(roles) ?? false,
    [authContext.me]
  );

  const isAuthenticated = useMemo(() => !!authContext.me, [authContext.me]);

  const userRoleId = useMemo(
    () => authContext.me?.organization?.userRoleId,
    [authContext.me?.organization?.userRoleId]
  );

  const accountName = useMemo(
    () => authContext.me?.organization?.accountName ?? '',
    [authContext.me?.organization?.accountName]
  );

  const organizationInitials = useMemo(
    () => getFirstInitial(accountName),
    [accountName]
  );

  const accountId = useMemo(
    () => authContext.me?.organization?.accountId,
    [authContext.me?.organization?.accountId]
  );

  return useMemo(
    () => ({
      ...authContext,
      isAuthenticated,
      userRoleId,
      accountName,
      accountId,
      hasAccess,
      organizationInitials,
    }),
    [
      accountId,
      accountName,
      authContext,
      hasAccess,
      isAuthenticated,
      userRoleId,
      organizationInitials,
    ]
  );
};
