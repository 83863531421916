/* eslint-disable prettier/prettier */
import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import {
  createFollowingTag,
  deleteFollowingTag,
  getTag,
  listFollowingTags,
  updateFollowingTag,
} from 'api/tagsApi/tagsApi';
import { TagsStateData } from '../tags.types';
import { deserializeAxiosError } from 'common/utils/error';
import { getTagsSorting, updateTagsSorting } from 'api/savedResultsApi';
import { deserializeTagsSortSettings, serializeTagsSortSettings, sortTagItems } from '../TagsSort/tagsSort.utils';
import { DEFAULT_FOLLOWING_TAGS_SORTING, TAG_SORT_ORDER_BY_SORT_BY } from '../TagsSort/tagsSort.const';
import { TagDetails, TagOrderUpdatePayload } from 'api/tagsApi/tagsApi.types';
import { RootState } from 'app/state/store';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { arrayMove } from 'common/utils/arrayHelpers';
import { TagsSortByEnum } from '../TagsSort/TagsSortBy.enum';
import { captureException } from '@sentry/react';

export const getFollowingTagsThunk: AsyncThunkPayloadCreator<
  TagsStateData,
  string
> = async (tenant) => {
  try {
    const [{ data: sortData }, { data: tags }] = await Promise.all([
      getTagsSorting(),
      listFollowingTags({ pageSize: 1000, tenant: tenant }),
    ]);

    const sortSettings = deserializeTagsSortSettings(
      sortData.following,
      DEFAULT_FOLLOWING_TAGS_SORTING
    );

    return {
      sortSettings,
      items: sortTagItems(tags.items, sortSettings),
    };
  } catch (err) {
    throw deserializeAxiosError(err);
  }
};

export const addFollowingTagsThunk: AsyncThunkPayloadCreator<
  TagDetails,
  { tagId: number; tenant: string }
> = async ({ tagId, tenant }) => {
  try {
    const { data } = await createFollowingTag({
      id: tagId,
      userOrder: 'last',
      lastSeen: new Date().toISOString(),
      tenant: tenant,
    });

    return data;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const removeFollowingTagsThunk: AsyncThunkPayloadCreator<
  number,
  { tagId: number; tenant: string }
> = async ({ tagId, tenant }) => {
  try {
    await deleteFollowingTag(tagId, tenant);
    return tagId;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateFollowingTagsLastSeenThunk: AsyncThunkPayloadCreator<
  TagDetails,
  { tagId: number; tenant: string },
  { state: RootState }
> = async ({ tagId, tenant }, { getState }) => {
  try {
    const tag = getState().tags.following.data.items.find(
      ({ id }) => id === tagId
    ) as TagDetails;

    await updateFollowingTag({
      id: tagId,
      userOrder: tag?.userOrder ?? 'last',
      lastSeen: new Date().toISOString(),
      tenant: tenant,
    });

    const { data } = await getTag(tagId, tenant, TagType.Following);

    return data;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateFollowingTagOrderThunk: AsyncThunkPayloadCreator<
  TagDetails[],
  TagOrderUpdatePayload,
  { state: RootState }
> = async (
  { id, oldIndex, newIndex, tenant }: TagOrderUpdatePayload,
  { getState }
) => {
  try {
    const followingTags = getState().tags.following.data.items;
    const tag = followingTags.find(
      ({ id: tagId }) => tagId === id
    ) as TagDetails & { lastSeen: string };

    await updateFollowingTag({
      id,
      lastSeen: tag.lastSeen,
      userOrder: newIndex + 1,
      tenant,
    });

    return arrayMove(followingTags, oldIndex, newIndex).map(
      (tagItem, index) => ({ ...tagItem, userOrder: index + 1 })
    );
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateFollowingTagSettingsThunk: AsyncThunkPayloadCreator<
  TagsStateData,
  TagsSortByEnum,
  { state: RootState }
> = async (sortBy: TagsSortByEnum, { getState }) => {
  try {
    const sortSettings = {
      sortBy,
      sortOrder: TAG_SORT_ORDER_BY_SORT_BY[sortBy],
    };
    await updateTagsSorting({
      following: serializeTagsSortSettings(sortSettings),
    });

    return {
      sortSettings,
      items: sortTagItems(getState().tags.following.data.items, sortSettings),
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};
