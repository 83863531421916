import React, { KeyboardEvent, useMemo } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { Button, CardActions, CardContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { AppDispatch } from 'app/state/store';
import { addTag } from '../ownTagsSlice/ownTags.slice';
import { TagDetails } from 'api/tagsApi/tagsApi.types';

import { useStyles } from './styles';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { TextField } from 'common/components/TextField';

interface TagFormValues {
  tagName: string;
}

const MIN_TAG_LENGTH = 1;
const MAX_TAG_LENGTH = 128;
const initialValues: TagFormValues = {
  tagName: '',
};

export type TagFormProps = {
  onSubmit: (tagItem: TagDetails) => void;
  onCancel: () => void;
};

export const TagForm = ({ onSubmit, onCancel }: TagFormProps) => {
  const { t } = useTranslation(['tags', 'common']);
  const dispatch = useDispatch<AppDispatch>();
  const { wrapper, actionsRoot, formActions } = useStyles();
  const { tenant } = useParsedHostname();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tagName: Yup.string()
          .min(MIN_TAG_LENGTH, t('tags:errors.minLength'))
          .max(MAX_TAG_LENGTH, t('tags:errors.maxLength'))
          .required(t('common:errors.required')),
      }),
    [t]
  );

  const handleSubmitForm = async (
    { tagName }: TagFormValues,
    { setSubmitting, setErrors }: FormikHelpers<TagFormValues>
  ) => {
    setSubmitting(true);

    const { type, payload } = await dispatch(
      addTag({ payload: { name: tagName }, tenant })
    );

    const error = type === addTag.rejected?.type && String(payload);

    setSubmitting(false);
    if (!error) {
      onSubmit(payload as TagDetails);
    } else {
      setErrors({ tagName: error });
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className={wrapper} data-testid="TagForm">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        onReset={onCancel}
        validationSchema={validationSchema}
      >
        {({ isValid, dirty, isSubmitting, handleSubmit }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
          >
            <CardContent>
              <Field name="tagName">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    autoFocus
                    label={t('tags:settings.tagName.label')}
                    multiline
                    onKeyPress={handleKeyPress}
                    inputProps={{ 'aria-label': 'Tag name input' }}
                    error={!!(meta.error && meta.touched)}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
            </CardContent>

            <CardActions classes={{ root: actionsRoot }}>
              <div className={formActions}>
                <Button type="reset">{t('common:buttons.cancel')}</Button>

                <SubmitButton
                  text={t('common:buttons.submit')}
                  aria-label="submit"
                  isSubmitting={isSubmitting}
                  disabled={!isValid || !dirty}
                  eventName={TrackEventName.TagCreated}
                />
              </div>
            </CardActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};
