import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { ConfirmDialogProps } from './ConfirmDialog.types';

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  message,
  successButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => {
  const { t } = useTranslation('common');

  return (
    <Dialog open={true} onClose={onCancel} data-testid="confirmationDialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {cancelButtonLabel ?? t('buttons.cancel')}
        </Button>
        <SubmitButton
          autoFocus
          text={successButtonLabel ?? t('buttons.confirm')}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
