import {
  Feedback,
  FeedbackForm,
  ResultType,
} from '@zarn/vendor/dist/query-logging';
import { UserFeedback } from '@zarn/vendor/dist/auth';
import {
  FeedbackData,
  FeedbackFormValues,
  FeedbackListPayload,
  SendFeedbackPayload,
} from './Feedback.interface';
import { FeedbackType } from './enums';

export const serializeFeedbackPayload = (
  payload: SendFeedbackPayload
): FeedbackForm => {
  return {
    items: [
      {
        result_id: payload.resultId,
        search_id: payload.searchId,
        result_type: payload.resultType,
        feedback_score: payload.score ?? -1,
        feedback_type: payload.feedbackType,
        feedback_text: payload.text,
        timestamp: new Date().toISOString().slice(0, -1),
      },
    ],
  };
};

export const serializeUserFeedbackPayload = ({
  subject,
  feedback,
}: FeedbackFormValues): UserFeedback => ({
  subject,
  text_body: feedback,
});

export const deserializeFeedback = (f: Feedback): FeedbackData => ({
  searchId: f.search_id,
  resultId: f.result_id,
  resultType: f.result_type,
  feedbackType: f.feedback_type as FeedbackType,
  score: f.feedback_score,
  text: f.feedback_text,
  timestamp: f.timestamp,
});

export type FeedbackListQueryKey = readonly [
  'feedbacks',
  'list',
  {
    readonly page?: number;
    readonly resultType?: ResultType;
    readonly feedbackType?: string;
    readonly searchIds?: string[];
    readonly resultIds?: string[];
  }
];

export const feedbackQueryKeys = {
  all: ['feedbacks'] as const,
  lists: () => [...feedbackQueryKeys.all, 'list'] as const,
  list: (payload: FeedbackListPayload): FeedbackListQueryKey =>
    [
      ...feedbackQueryKeys.lists(),
      {
        page: payload.page,
        resultType: payload.resultType,
        feedbackType: payload.feedbackType,
        searchIds: payload.searchIds,
        resultIds: payload.resultIds,
      },
    ] as const,
};
