import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SharingPermission } from 'containers/Sharing/Sharing.types';

export interface SharePermissionOption {
  label: string;
  value: SharingPermission;
}

export const useSharePermissionSelectOptions = () => {
  const { t } = useTranslation('common');
  const options = useMemo(
    () => [
      {
        label: t('share.permission.read'),
        value: 'read',
      },
      {
        label: t('share.permission.write'),
        value: 'write',
      },
    ],
    [t]
  );

  return options;
};
