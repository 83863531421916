import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Chip } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataFollowersProps = {
  numberOfFollowers: number;
};

const TagMetadataFollowers = ({
  numberOfFollowers,
}: TagMetadataFollowersProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagMetadataItem
      icon={SupervisedUserCircleIcon}
      translation={
        <Trans t={t} i18nKey="tagMetadata.numberOfFollowers.text">
          <Chip size="small" label={numberOfFollowers} />
        </Trans>
      }
    />
  );
};

TagMetadataFollowers.displayName = 'TagMetadataFollowers';

export default TagMetadataFollowers;
