import { Theme, alpha, ComponentsOverrides } from '@mui/material/styles';

export const stepIconOverrides: ComponentsOverrides<Theme>['MuiStepIcon'] = {
  root: ({ theme }) => ({
    borderRadius: 20,
    fontSize: 28,
    border: `4px solid ${alpha(theme.palette.secondary.main, 0.4)}`,
    color: 'transparent',
    '&.Mui-active': {
      boxShadow: theme.shadows[5],
      '& > .MuiStepIcon-text': {
        fill: theme.palette.common.white,
      },
    },
    '&.Mui-completed': {
      boxShadow: theme.shadows[3],
    },
    '&.Mui-active, &.Mui-completed': {
      color: theme.palette.secondary.main,
    },
  }),
  text: ({ theme }) => ({
    fill: theme.palette.primary.main,
  }),
};

export const stepConnectorOverrides: ComponentsOverrides<Theme>['MuiStepConnector'] =
  {
    vertical: {
      padding: 0,
      marginLeft: 13,
    },
    lineVertical: ({ theme }) => ({
      borderLeftWidth: 2,
      minHeight: 14,
      borderColor: alpha(theme.palette.secondary.main, 0.4),
    }),
  };

export const stepContentOverrides: ComponentsOverrides<Theme>['MuiStepContent'] =
  {
    root: ({ theme }) => ({
      marginTop: 0,
      marginLeft: 13,
      borderLeft: `2px solid ${alpha(theme.palette.secondary.main, 0.4)}`,
      paddingLeft: 22,
    }),
  };
