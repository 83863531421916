import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'sticky',
    top: '100%',
    marginTop: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  navigation: {
    display: 'flex',
    whiteSpace: 'nowrap',
    '& > a + a': {
      marginRight: theme.spacing(1),
    },
  },
  navLinkActive: {
    color: theme.palette.primary.main,
  },
  disclaimer: {
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
}));
