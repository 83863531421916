import { createAsyncThunk } from '@reduxjs/toolkit';

import { createNote, deleteNote, listNotes } from 'api/notesApi/notesApi';
import {
  CreateDocNotePayload,
  ListNotesPayload,
} from 'api/notesApi/notesApi.types';
import { objectTypeToNoteObject } from 'api/notesApi/notesApi.utils';
import { deserializeAxiosError } from 'common/utils/error';
import { NoteObjectType } from '@zarn/vendor/dist/saved-results';
import { SavedNotes } from './SavedNotes.interface';
import { captureException } from '@sentry/react';

export type DeleteNoteArgs = {
  objectId: string;
  noteId: number;
  tenant: string;
};

export const savedNotesActionsFactory = (
  sliceName: string,
  objectType: NoteObjectType
) => ({
  getNoteList: createAsyncThunk(
    `${sliceName}/get`,
    async (payload: ListNotesPayload) => {
      try {
        const { data } = await listNotes({
          ...payload,
          noteObjectType: objectTypeToNoteObject(objectType),
        });
        const mappedData: SavedNotes = {};
        data.items.forEach((noteDetails) => {
          if (noteDetails.objectId) {
            mappedData[noteDetails.objectId] = {
              id: String(noteDetails.objectId),
              numberOfNotes: data.count,
              notesData: [
                ...(noteDetails.objectId in mappedData
                  ? mappedData[noteDetails.objectId].notesData
                  : []),
                noteDetails,
              ],
            };
          }
        });
        return mappedData;
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    }
  ),
  createNote: createAsyncThunk(
    `${sliceName}/create`,
    async (payload: CreateDocNotePayload) => {
      try {
        const { data } = await createNote(payload);
        return {
          id: payload.objectId,
          data,
        };
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    }
  ),
  deleteNote: createAsyncThunk(
    `${sliceName}/delete`,
    async ({ objectId, noteId, tenant }: DeleteNoteArgs) => {
      try {
        await deleteNote(noteId, tenant);
        return { objectId, noteId };
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    }
  ),
});
