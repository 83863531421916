import { TagItemKeys } from 'api/tagsApi/tagsApi.types';
import { TagsSort } from './TagsSort.interface';
import { TagsSortByEnum } from './TagsSortBy.enum';
import { TagsSortOrderEnum } from './TagsSortOrder.enum';

export const DEFAULT_OWN_TAGS_SORTING: TagsSort = {
  sortBy: TagsSortByEnum.PriorityOrder,
  sortOrder: TagsSortOrderEnum.Asc,
};

export const DEFAULT_FOLLOWING_TAGS_SORTING: TagsSort = {
  sortBy: TagsSortByEnum.PriorityOrder,
  sortOrder: TagsSortOrderEnum.Asc,
};

export const DEFAULT_SHARED_TAGS_SORTING: TagsSort = {
  sortBy: TagsSortByEnum.Alphabetically,
  sortOrder: TagsSortOrderEnum.Asc,
};

export const TAG_SORT_ORDER_BY_SORT_BY: { [key: string]: TagsSortOrderEnum } = {
  [TagsSortByEnum.Alphabetically]: TagsSortOrderEnum.Asc,
  [TagsSortByEnum.PriorityOrder]: TagsSortOrderEnum.Asc,
  [TagsSortByEnum.CreationDate]: TagsSortOrderEnum.Desc,
  [TagsSortByEnum.ModifiedDate]: TagsSortOrderEnum.Desc,
};

export const TAG_ITEM_KEY_BY_TAG_SORT_BY: { [key: string]: TagItemKeys } = {
  [TagsSortByEnum.Alphabetically]: 'name',
  [TagsSortByEnum.PriorityOrder]: 'userOrder',
  [TagsSortByEnum.CreationDate]: 'dateCreated',
  [TagsSortByEnum.ModifiedDate]: 'dateModified',
};
