import {
  QueryForm,
  QueryFormQParams,
  Results,
  SystemResult,
} from '@zarn/vendor/dist/query-logging';
import { RetrievalMethodEnum } from 'common/enums';
import {
  SearchPayload,
  SearchPayloadSort,
} from 'containers/Search/SearchPayload.interface';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import {
  ExplanationQueryLoggingResult,
  QAKQueryLoggingResult,
  QAQueryLoggingResult,
  QueryLoggingResult,
} from './queryLoggingApi.types';

export const serializeQueryLoggingFilters = (
  payload: SearchPayload
): Object => {
  const { year, date, docIds, docSources, docTypes, term } = payload;

  return {
    term: [...(term ? [{ name: term[0], value: term[1] }] : [])],
    terms: [
      ...(docIds ? [{ name: 'doc_ids', values: docIds }] : []),
      ...(docSources ? [{ name: 'source', values: docSources }] : []),
      ...(docTypes ? [{ name: 'document_type', values: docTypes }] : []),
    ],
    range: [
      ...(year
        ? [{ name: 'years', lower_bound: year[0], upper_bound: year[1] }]
        : []),
      ...(date
        ? [
            {
              name: 'timestamp',
              lower_bound: date[0],
              upper_bound: date[1],
            },
          ]
        : []),
    ],
  };
};

export const serializeQueryLoggingSort = (
  sort: SearchPayloadSort | undefined
): Object | undefined => {
  return sort
    ? {
        field: sort[0],
        order: sort[1],
      }
    : undefined;
};

export const serializeQueryFormQParams = (
  payload: SearchPayload
): QueryFormQParams => {
  return {
    nn_search: payload.retrievalMethod === RetrievalMethodEnum.Knn,
    retrieval_unit: payload.retrievalUnit,
    filters: serializeQueryLoggingFilters(payload),
    sort: serializeQueryLoggingSort(payload.sort),
    extra: {
      searchEngine: payload.searchEngine,
    },
  };
};

export const serializeSystemResult = (
  { document, title }: RetrievalUnitData,
  rank: number
): SystemResult => {
  return {
    id: document.id,
    rank,
    human_readable: title,
  };
};

export const serializeSystemResultsItem = (
  data: QueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: data.documents.map(serializeSystemResult),
  };
};

export const serializeQueryLoggingPayload = (
  payload: SearchPayload,
  results: Array<QueryLoggingResult>
): QueryForm => {
  return {
    q: payload.queryString,
    q_params: serializeQueryFormQParams(payload),
    // fake session id that we use in an old logging system to distinguish anonymous users from logged in ones
    session_id: 'aaa_bbbb_111',
    results: results.map(serializeSystemResultsItem),
  };
};

export const serializeQASystemResultsItem = (
  data: QAQueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: [
      {
        id: '0',
        rank: 0,
        human_readable: data.explanation ?? '',
        extra: {
          evidences: data.documents.map((item) => ({
            id: item.document.id,
            highlight: item.highlight,
          })),
          question: data.question,
        },
      },
    ],
  };
};

export const serializeQAKSystemResultsItem = ({
  system,
  answers,
}: QAKQueryLoggingResult): Results => {
  return {
    system: system,
    system_results: answers.map((item) => ({
      id: item.id,
      rank: item.index,
      human_readable: item.answer ?? '',
      extra: {
        highlight: item.highlight,
        question: item.question,
      },
    })),
  };
};

export const serializeExplanationSystemResultsItem = (
  data: ExplanationQueryLoggingResult
): Results => {
  return {
    system: data.system,
    system_results: [
      {
        id: '0',
        rank: 0,
        human_readable: data.explanation ?? '',
        extra: {
          context_doc_ids: data.docIds,
          passage: data.passage,
        },
      },
    ],
  };
};
