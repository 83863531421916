import React from 'react';
import { Box, Card } from '@mui/material';

import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import DocDate from '../DocDate/DocDate';

export type DocCardCompactProps = {
  data: RetrievalUnitData;
  withDate?: boolean;
  withAuthors?: boolean;
};

const DocCardCompact = ({
  data,
  withDate,
  withAuthors,
}: DocCardCompactProps) => {
  const { title, document } = data;

  return (
    <Card elevation={0} variant="outlined">
      <Box p={1}>
        <DocTitle
          gutterBottom
          uri={data.uri}
          title={title}
          id={document.id}
          variant="body2"
        />

        {withDate && <DocDate date={data.date} year={data.year} />}
        {data.authors && withAuthors && <AuthorsList authors={data.authors} />}
      </Box>
    </Card>
  );
};

export default React.memo(DocCardCompact);
