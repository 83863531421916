import React, { useMemo } from 'react';
import { Typography } from '@mui/material';

import { ResultType } from '@zarn/vendor/dist/query-logging';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import {
  getPaperTextMaxLength,
  mergeHighlightAndAbstract,
} from 'common/utils/documents';
import { TruncatedContent } from 'common/components/Content/TruncatedContent/TruncatedContent';
import { FeedbackType } from 'containers/Feedback/enums';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { DocCardComposition } from 'containers/User/User.enum';

export type DocAbstractProps = {
  highlight?: string;
  abstractContent: string;
  id: string;
  searchId?: string;
  resultType?: ResultType;
  cardComposition?: DocCardComposition;
};

const DocAbstract = React.memo(
  ({
    highlight,
    abstractContent,
    id,
    searchId,
    resultType,
    cardComposition,
  }: DocAbstractProps) => {
    const text = useMemo(
      () => mergeHighlightAndAbstract(abstractContent, highlight),
      [abstractContent, highlight]
    );
    const maxTextLength = getPaperTextMaxLength(highlight, cardComposition);
    const { sendFeedback } = useSendFeedback();

    const handleAbstractFeedback = () => {
      if (searchId && resultType) {
        sendFeedback({
          searchId,
          resultType,
          resultId: id,
          feedbackType: FeedbackType.Abstract,
        });
      }
    };

    return (
      <Typography
        variant="body2"
        component="div"
        sx={{
          wordWrap: 'break-word',
          overflowWrap: 'anywhere',
          '& p': {
            margin: 0,
            display: 'inherit',
          },
          '& em': {
            fontStyle: 'normal',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <TruncatedContent
          renderInHTML
          maxTextLength={maxTextLength}
          onCollapse={handleAbstractFeedback}
          text={text}
          eventName={TrackEventName.SeeMoreClicked}
        />
      </Typography>
    );
  }
);

DocAbstract.displayName = 'DocAbstract';

export default DocAbstract;
