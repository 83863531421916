import React, { FC } from 'react';
import { useConfirmDialog } from './useConfirmDialog';
import { ConfirmDialog } from './ConfirmDialog';

export const AppConfirmDialog: FC = () => {
  const { dialog } = useConfirmDialog();

  if (!dialog) {
    return null;
  }

  return <ConfirmDialog {...dialog} />;
};
