import { RoleDB, UserPayload } from '@zarn/vendor/dist/auth';
import {
  getAccessTokenFromStore,
  getRefreshTokenFromStore,
  removeAccessTokenFromStore,
  removeRefreshTokenFromStore,
  storeOrRemoveAccessToken,
  storeOrRemoveRefreshToken,
} from 'common/utils/store';
import { UserRole } from './Auth.interface';
import { RoleEnum } from './enums';
import { createMe } from './models/createMe';
import { OidcAccessTokenPayloadInterface } from './interfaces/OidcAccessTokenPayload.interface';
import { AccessToken } from './interfaces/AccessToken';
import { Me } from './models/Me.interface';
import { captureException } from '@sentry/react';
import { isJWTValid } from 'common/utils/jwt-tokens';

export const deserializeUserRole = (rd: RoleDB): UserRole => ({
  userRoleId: rd.user_role_id,
  accountId: rd.account_id,
  accountName: rd.account_name,
  permission: rd.permission as RoleEnum,
  name: rd.name,
});

export const parseJwt = <T>(token: string): T | null => {
  try {
    return JSON.parse(atob(token.split('.')[1])) as T;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export const isCustomAccessToken = (token: AccessToken): token is UserPayload =>
  'user_roles' in token && 'role_data' in token.user_roles;

/**
 * Parse and decode access token `Payload` part.
 */
export const parseAccessTokenPayload = (
  accessToken: string | null
): Me | null => {
  if (!accessToken) return null;

  const tokenContent = parseJwt<UserPayload | OidcAccessTokenPayloadInterface>(
    accessToken
  );

  const me = tokenContent ? createMe(tokenContent) : null;

  return me;
};

export const isValidTokens = () => {
  const accessToken = getAccessTokenFromStore();
  const refreshToken = getRefreshTokenFromStore();

  return (
    !!accessToken &&
    isJWTValid(accessToken) &&
    !!refreshToken &&
    isJWTValid(refreshToken)
  );
};

export const isInvalidAccessTokenAndValidRefreshToken = () => {
  const accessToken = getAccessTokenFromStore();
  const refreshToken = getRefreshTokenFromStore();

  return (
    !!accessToken &&
    !isJWTValid(accessToken) &&
    !!refreshToken &&
    isJWTValid(refreshToken)
  );
};

export const getPayloadPartFromStoredAccessToken = () => {
  return parseAccessTokenPayload(getAccessTokenFromStore());
};

export const removeTokensFromStore = () => {
  removeAccessTokenFromStore();
  removeRefreshTokenFromStore();
};

export const storeOrRemoveTokens = (
  accessToken: string | null,
  refreshToken: string | null
) => {
  storeOrRemoveAccessToken(accessToken);
  storeOrRemoveRefreshToken(refreshToken);
};

/**
 * Parse user permission data
 */

enum PermissionsKey {
  permission = 'permission',
  userRoleId = 'user_role_id',
  accountId = 'account_id',
  accountName = 'account_name',
}

type PermissionDataMapType = {
  [key: string]: string;
};

const mapPermissionData = (role: string) => {
  if (role === RoleEnum.Admin) {
    return {
      [PermissionsKey.permission]: RoleEnum.Admin,
      [PermissionsKey.userRoleId]: '1',
    } as PermissionDataMapType;
  }

  return role.split(',').reduce((acc, element) => {
    const [key, value] = element.split(':');
    acc[key] = value;
    return acc;
  }, {} as PermissionDataMapType);
};

export const validateUserRole = (role: string): boolean => {
  const permissionDataMap = mapPermissionData(role);
  return (
    !!permissionDataMap[PermissionsKey.userRoleId] &&
    !!permissionDataMap[PermissionsKey.permission]
  );
};

export const mapUserPermission = (role: string) => {
  const permissionDataMap = mapPermissionData(role);

  return {
    userRoleId: Number(permissionDataMap[PermissionsKey.userRoleId]),
    accountId: Number(permissionDataMap[PermissionsKey.accountId]) ?? null,
    accountName: permissionDataMap[PermissionsKey.accountName] ?? null,
    permission: permissionDataMap[PermissionsKey.permission] ?? null,
    name: null,
  };
};
