import React, { FC } from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Theme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5),
    margin: theme.spacing(5),
  },
}));

const ContentLoader: FC<CircularProgressProps> = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress {...props} />
    </Box>
  );
};

export default ContentLoader;
