import React, { FC, ReactNode, useState, MouseEvent } from 'react';
import { Button, IconButton, Popover, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { useStyles } from './useStyles';

interface Props {
  title: string;
  popoverTitle: string;
  children: ReactNode;
  buttonIcon?: ReactNode;
  horizontal?: 'left' | 'right';
  classes?: {
    paper?: string;
  };
  handleReset: () => void;
  isValueFilled?: boolean;
}

export const FilterPopover: FC<Props> = ({
  title,
  popoverTitle,
  children,
  buttonIcon,
  handleReset,
  isValueFilled,
  horizontal = 'right',
  classes,
}) => {
  const styles = useStyles({ classes });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.toggleWrapper}>
        <Button
          aria-label="filter toggle"
          aria-describedby={id}
          disableElevation
          onClick={handleClick}
          color="neutral"
          endIcon={isValueFilled && <ExpandMoreIcon />}
          variant={!isValueFilled ? 'contained' : 'text'}
          className={styles.button}
          startIcon={buttonIcon}
        >
          {title}
        </Button>

        {!isValueFilled && (
          <IconButton
            size="small"
            aria-label="filter reset"
            onClick={handleReset}
            className={styles.resetValueBtn}
          >
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>

      <Popover
        id={id}
        open={open}
        elevation={4}
        anchorEl={anchorEl}
        onClose={handleClose}
        aria-label="filter popover"
        classes={{ paper: clsx(styles.paper, classes?.paper) }}
        anchorOrigin={{ vertical: 'bottom', horizontal }}
        transformOrigin={{ vertical: 'top', horizontal }}
      >
        <Typography className={styles.popoverTitle}>{popoverTitle}</Typography>

        {children}
      </Popover>
    </>
  );
};

FilterPopover.displayName = 'FilterPopover';

export default FilterPopover;
