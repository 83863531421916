import React, { useMemo } from 'react';
import { RadioGroup } from '@mui/material';

import ColorRadio from 'common/components/Fields/ColorRadio/ColorRadio';
import ColorPickerRadio from 'common/components/Fields/ColorPickerRadio/ColorPickerRadio';
import { PaletteColorOption } from './ColorPalette.interface';

export type ColorPaletteFieldProps = {
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  colorPalette: PaletteColorOption[];
};

export const ColorPaletteField = ({
  colorPalette,
  ...field
}: ColorPaletteFieldProps) => {
  const initialCustomColor = useMemo(() => {
    const isPredefinedColor = colorPalette.some(
      ({ value }) => value === field.value
    );
    return !isPredefinedColor ? field.value : undefined;
  }, []);

  return (
    <RadioGroup row aria-label="tag color" {...field}>
      {colorPalette.map(({ value, color }) => (
        <ColorRadio
          key={color}
          value={value}
          title={color}
          radioColor={color}
        />
      ))}

      <ColorPickerRadio initialColor={initialCustomColor} {...field} />
    </RadioGroup>
  );
};
