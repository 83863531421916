import React from 'react';
import { Chip, Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { errorChipStyles } from 'app/theme/styles/chip';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(0.5),
  },
  chip: {
    ...errorChipStyles(theme),
  },
}));

type Props = {
  chip?: boolean;
  chipTooltip?: string;
  chipLabel?: React.ReactText;
  children?: React.ReactNode;
};

export const MenuItemTitle = React.forwardRef<HTMLDivElement, Props>(
  ({ children, chip, chipLabel, chipTooltip = '' }, ref) => {
    const classes = useStyles();

    return (
      <div ref={ref} className={classes.wrapper}>
        {children}

        {chip && (
          <Tooltip data-testid="menuItemChip" title={chipTooltip}>
            <Chip size="small" className={classes.chip} label={chipLabel} />
          </Tooltip>
        )}
      </div>
    );
  }
);
