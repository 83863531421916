import {
  RecommendationItem,
  RecommendationTaskItem,
  TaskStatus,
} from '@zarn/vendor/dist/recommendations';

import {
  RecTask,
  RecommendedItem,
  RecTaskStatus,
} from './RecommendationsApi.types';

export const deserializeRecommendation = (
  rec: RecommendationItem
): RecommendedItem => ({
  recId: rec.recommendation_uuid,
  score: rec.score,
  resourceType: rec.identifier.resource_type,
  resourceId: rec.identifier.resource_id,
  explanation: rec.explanation,
  recCount: rec.rec_count,
  dateCreated: rec.date_created,
  explanations: rec.explanations.map((ex) => ({
    explanationId: ex.explanation_id,
    explanationType: ex.explanation_type,
  })),
});

export const taskStatusToRecTaskStatus = (
  status: TaskStatus
): RecTaskStatus => {
  switch (status) {
    case TaskStatus.Failed:
      return 'failed';
    case TaskStatus.Finished:
      return 'finished';
    case TaskStatus.Started:
      return 'started';
    default:
      return 'pending';
  }
};

export const deserializeRecommendationTaskItem = (
  rec: RecommendationTaskItem
): RecTask => ({
  uuid: rec.uuid,
  status: taskStatusToRecTaskStatus(rec.status),
  isStale: rec.is_stale,
  dateCreated: rec.date_created,
  dateUpdated: rec.date_updated,
  error: rec.error,
});
