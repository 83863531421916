import React, { FC } from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import welcomeImg from 'containers/OnboardingWizard/images/welcome_img.jpg';
import OnboardingWizardImg from 'common/components/OnboardingWizard/OnboardingWizardImg/OnboardingWizardImg';

export interface FirstStepProps {
  onNext: () => void;
}

export const FirstStep: FC<FirstStepProps> = ({ onNext }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);

  return (
    <Grid
      container
      spacing={3}
      aria-label={t('onboardingWizard:firstStep.label')}
    >
      <Grid item sm={12} md={5} lg={6}>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom>
            {t('onboardingWizard:firstStep.title')}
          </Typography>
        </Box>
        <Typography>{t('onboardingWizard:firstStep.subTitle')}</Typography>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="contained" color="secondary" onClick={onNext}>
            {t('common:buttons.start')}
          </Button>
        </Box>
      </Grid>
      <Grid item sm={12} md={7} lg={6}>
        <OnboardingWizardImg
          src={welcomeImg}
          alt={t('onboardingWizard:firstStep.imageAlt')}
        />
      </Grid>
    </Grid>
  );
};
