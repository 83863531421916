import {
  HighlightAction,
  HighlightContentType,
  HighlightRect,
} from '@zarn/pdf-viewer';

import {
  SharingPermission,
  SharingPolicies,
} from 'containers/Sharing/Sharing.types';
import {
  deserializeResourcePermission,
  deserializeSharingPolicy,
  serializeSharingPolicy,
} from 'containers/Sharing/sharing.utils';
import {
  AnnotationHighlightItem,
  AnnotationHighlightRect,
  NoteForm,
  NoteItem,
  NoteObjectType,
} from '@zarn/vendor/dist/saved-results';
import {
  AnnotationHighlight,
  CreateNotePayload,
  NoteDetails,
  NoteObject,
  UpdateNotePayload,
} from './notesApi.types';

export const deserializeShareable = (
  shareable: boolean,
  orgId?: number
): SharingPolicies => {
  return shareable && orgId
    ? { orgs: [{ id: orgId, permission: 'read' }], users: [] }
    : { orgs: [], users: [] };
};

export const noteObjectTypeToObjectType = (
  noteObjectType: NoteObjectType
): NoteObjectType => {
  switch (noteObjectType) {
    case NoteObjectType.Free:
      return NoteObjectType.Free;
    case NoteObjectType.Document:
      return NoteObjectType.Document;
    case NoteObjectType.Tag:
      return NoteObjectType.Tag;
  }
};

export const objectTypeToNoteObject = (
  objectType: NoteObjectType
): NoteObject => {
  switch (objectType) {
    case NoteObjectType.Document:
      return 'document';
    case NoteObjectType.Tag:
      return 'tag';
    default:
      return 'free';
  }
};

export const serializeNoteObjectType = (
  noteObjectType: NoteObject
): NoteObjectType => {
  switch (noteObjectType) {
    case 'free':
      return NoteObjectType.Free;
    case 'document':
      return NoteObjectType.Document;
    case 'tag':
      return NoteObjectType.Tag;
  }
};

export const deserializeAnnotationHighlightRect = ({
  page_number,
  ...rect
}: AnnotationHighlightRect): HighlightRect => {
  return {
    ...rect,
    pageNumber: page_number,
  };
};

export const serializeAnnotationHighlightRect = ({
  pageNumber,
  ...rect
}: HighlightRect): AnnotationHighlightRect => {
  return {
    ...rect,
    page_number: pageNumber,
  };
};

const deserializeAnnotationHighlightContentType = (
  contentType: string
): HighlightContentType => {
  switch (contentType) {
    case 'image':
      return 'image';
    default:
      return 'text';
  }
};

const deserializeAnnotationHighlight = (
  noteId: number,
  permission: SharingPermission,
  annotationHighlight: AnnotationHighlightItem | null
): AnnotationHighlight | null => {
  const actions: Exclude<HighlightAction, 'create'>[] =
    permission === 'modify' ? ['addNote', 'delete', 'hover'] : ['hover'];

  return (
    annotationHighlight && {
      id: noteId,
      color: annotationHighlight.color ?? undefined,
      content: {
        type: deserializeAnnotationHighlightContentType(
          annotationHighlight.content_type
        ),
        body: annotationHighlight.content_body,
      },
      searchHighlight: annotationHighlight.search_highlight,
      position: annotationHighlight.position,
      rects: annotationHighlight.rects.map(deserializeAnnotationHighlightRect),
      actions,
    }
  );
};

export const serializeAnnotationHighlight = ({
  content,
  position,
  rects,
  color,
}: AnnotationHighlight): AnnotationHighlightItem => ({
  position,
  color: color ?? null,
  content_type: content.type,
  content_body: content.body,
  rects: rects.map(serializeAnnotationHighlightRect),
});

export const deserializeNoteItem = (
  item: NoteItem
): Omit<NoteDetails, 'shareable' | 'tagType'> => {
  const permission = deserializeResourcePermission(item.permission);

  return {
    id: item.id,
    ownerId: item.owner,
    objectType: noteObjectTypeToObjectType(item.object_type),
    objectId: item.object_id,
    content: item.content,
    dateCreated: item.date_created,
    dateModified: item.date_modified ?? item.date_created,
    dateShared: item.date_shared,
    sharing: deserializeSharingPolicy(item.sharing),
    highlight: item.highlight,
    permission,
    annotationHighlight: deserializeAnnotationHighlight(
      item.id,
      permission,
      item.annotation_highlight
    ),
  };
};

export const savedNotesBackCompat = (
  item: Omit<NoteDetails, 'shareable' | 'tagType'>
): NoteDetails => ({
  id: item.id,
  ownerId: item.ownerId,
  objectId: item.objectId,
  objectType: item.objectType,
  content: item.content,
  dateCreated: item.dateCreated,
  dateModified: item.dateModified || item.dateCreated,
  dateShared: item.dateShared,
  sharing: item.sharing,
  shareable: !!item.sharing.orgs.length,
  highlight: item.highlight,
  permission: item.permission,
  annotationHighlight: item.annotationHighlight,
});

export const serializeUpdateNotePayload = ({
  annotationHighlight,
  ...payload
}: UpdateNotePayload): NoteForm => ({
  content: payload.content,
  object_type: serializeNoteObjectType(payload.objectType),
  object_id: payload.objectId,
  sharing: serializeSharingPolicy(payload.sharing),
  annotation_highlight:
    (annotationHighlight &&
      serializeAnnotationHighlight(annotationHighlight)) ??
    null,
});

export const serializeCreateNotePayload = ({
  annotationHighlight,
  ...payload
}: CreateNotePayload): NoteForm => ({
  content: payload.content,
  object_type: serializeNoteObjectType(payload.objectType),
  object_id: payload.objectId,
  sharing: serializeSharingPolicy(payload.sharing),
  annotation_highlight:
    (annotationHighlight &&
      serializeAnnotationHighlight(annotationHighlight)) ??
    null,
});
