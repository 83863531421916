import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

type UseInputCaretPositionReturn = {
  caretPosition: number;
  onKeyUp: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClick: (
    e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

type UseInputCaretPositionProps = {
  initPosition?: number;
};

export const useInputCaretPosition = ({
  initPosition = 0,
}: UseInputCaretPositionProps): UseInputCaretPositionReturn => {
  const [caretPosition, setCaretPosition] = useState<number>(initPosition);
  const debouncedSetCaretPosition = useCallback(
    debounce(
      (
        e:
          | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
          | React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (e.currentTarget) {
          setCaretPosition(e.currentTarget.selectionStart ?? initPosition);
        }
      },
      1000
    ),
    []
  );

  const handleInputKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    debouncedSetCaretPosition(e);
  };

  const handleInputClick = (
    e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    debouncedSetCaretPosition(e);
  };

  return {
    caretPosition,
    onKeyUp: handleInputKeyUp,
    onClick: handleInputClick,
  };
};
