import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  makeSearch,
  selectSearchResultsLoading,
  updateSearchPayload,
  updateSearchQueryString,
} from 'containers/SearchResults/searchResults.slice';
import { useSearchPayload } from 'common/hooks/useSearchPayload';
import {
  deserializeTermFilter,
  getQueryFromURL,
} from 'common/utils/searchQueryParams';
import { AppDispatch } from 'app/state/store';
import { useStyles } from './styles';
import { getPayloadAndSearchParams } from './searchField.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { selectIndexCluster } from 'containers/User/user.slice';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { BODY_COLOR } from 'app/theme';

export const SearchField = () => {
  const { t } = useTranslation('search');
  const classes = useStyles();
  const { push: historyPush, location } = useHistory();
  const payload = useSearchPayload();
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(selectSearchResultsLoading);
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);
  const [queryValue, setQueryValue] = useState(() =>
    getQueryFromURL(location.search)
  );
  const { searchDynamicsFilters } = useAssertTenantSettings();

  const urlQueryValue = useMemo(
    () => getQueryFromURL(location.search),
    [location]
  );

  const hasTermFilter = useMemo(
    () => !!deserializeTermFilter(new URLSearchParams(location.search)),
    [location]
  );

  useEffect(() => {
    setQueryValue(urlQueryValue);
  }, [location.search, setQueryValue, urlQueryValue]);

  const handleQuerySubmit = useCallback(async () => {
    if (urlQueryValue && urlQueryValue === queryValue && !hasTermFilter) {
      dispatch(makeSearch({ ...payload.searchPayload }));
    } else {
      const { searchParams, payload: searchPayload } =
        getPayloadAndSearchParams(
          queryValue,
          location.search,
          parsedHostname,
          indexCluster as string | undefined,
          searchDynamicsFilters
        );

      dispatch(updateSearchPayload(searchPayload));
      historyPush(`/${searchParams}`);
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, [
    urlQueryValue,
    queryValue,
    hasTermFilter,
    dispatch,
    payload.searchPayload,
    location.search,
    parsedHostname,
    indexCluster,
    searchDynamicsFilters,
    historyPush,
  ]);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setQueryValue(e.target.value);
  };

  const handleClear = (): void => {
    setQueryValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      void handleQuerySubmit();
    }
  };

  const handleBlur = (): void => {
    dispatch(updateSearchQueryString(queryValue));
  };

  const { tenantSettings } = useAssertTenantSettings();
  const background =
    tenantSettings.whitelabel?.searchBar?.background ?? BODY_COLOR;

  return (
    <div className={classes.search}>
      <InputBase
        placeholder={t('form.placeholder')}
        inputProps={{ 'aria-label': 'search' }}
        value={queryValue}
        onChange={handleQueryChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        inputRef={inputRef}
        classes={{ root: classes.searchInputRoot, input: classes.searchInput }}
        disabled={isLoading}
        sx={{
          input: { backgroundColor: background },
          root: { backgroundColor: background },
        }}
        endAdornment={
          <InputAdornment position="end" sx={{ ml: 0 }}>
            {queryValue && (
              <IconButton
                aria-label="clear search value"
                disabled={isLoading}
                size="small"
                onClick={handleClear}
                className={classes.resetButton}
                sx={{
                  backgroundColor: background,
                  ':hover': {
                    backgroundColor: background,
                  },
                  ':disabled': {
                    backgroundColor: background,
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
            )}

            <IconButton
              aria-label="search button"
              disabled={isLoading}
              onClick={handleQuerySubmit}
              className={classes.searchIcon}
              size="large"
              sx={{
                backgroundColor: background,
                ':hover': {
                  backgroundColor: background,
                },
                ':disabled': {
                  backgroundColor: background,
                },
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};
