import React, { FC, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { FormControl, FormHelperText, List } from '@mui/material';
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';

import { FileUpload } from 'common/components/FileUpload/FileUpload/FileUpload';
import { UploadedFileItem } from 'common/components/FileUpload/UploadedFileItem/UploadedFileItem';
import { Nullable } from '../../../utils/assert';

export type FileUploadFieldProps = DropzoneOptions & {
  name: string;
  label?: string;
  helperText?: string;
  loadingsStates?: Array<Nullable<'loading' | 'success' | 'error'>>;
  onFileDrop?: (files: FileWithPath[]) => void;
};

export const FileUploadField: FC<FileUploadFieldProps> = ({
  label,
  name,
  helperText,
  loadingsStates,
  onFileDrop,
  ...options
}) => {
  const [field, meta, fieldHelpers] = useField<FileWithPath[]>({
    name,
    type: 'file',
  });

  const hasError = !!(meta.touched && meta.error);

  const helperMessage = useMemo(() => {
    return hasError ? meta.error : helperText;
  }, [meta.error, hasError, helperText]);

  const handleDrop = (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
    _: DropEvent
  ) => {
    if (fileRejections.length !== 0) {
      return;
    }

    onFileDrop?.(acceptedFiles);

    void fieldHelpers.setValue(acceptedFiles.length > 0 ? acceptedFiles : []);
  };

  const handleDelete = useCallback(
    async (fileName: string) => {
      await fieldHelpers.setValue(
        field.value.filter((file) => file.name !== fileName)
      );
    },
    [field.value, fieldHelpers]
  );

  const dropzoneState = useDropzone({ ...options, onDrop: handleDrop });

  const acceptedFileTypes = options.accept
    ? Object.values(options.accept).flat()
    : [];

  return (
    <FormControl fullWidth margin="normal" error={hasError}>
      <FileUpload
        {...dropzoneState}
        label={label}
        acceptedFileTypes={acceptedFileTypes}
      />

      {helperMessage && (
        <FormHelperText variant="outlined">{helperMessage}</FormHelperText>
      )}

      <List disablePadding>
        {field.value.map(
          ({ size, path, name: fileName, type }: FileWithPath, index) => (
            <UploadedFileItem
              key={fileName}
              file={{ size, path, name: fileName, type }}
              status={loadingsStates?.[index]}
              onDelete={handleDelete}
            />
          )
        )}
      </List>
    </FormControl>
  );
};
