import { InfiniteData, useQueryClient } from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { taggedDocsQueryKeys } from '../taggedDocs.utils';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { TagDetails } from 'api/tagsApi';
import { tagsQueryKeys } from '../../Tags/tags.utils';

export const useOnDeleteTaggedDoc = () => {
  const queryClient = useQueryClient();

  return (
    tagId: number,
    organizeDocId: string,
    queryFilters: Omit<QueryFilters, 'queryKey'> = { active: true }
  ) => {
    queryClient.setQueriesData<
      InfiniteData<PaginatedResults<RetrievalUnitData>> | undefined
    >(
      { queryKey: taggedDocsQueryKeys.list(tagId), ...queryFilters },
      (oldResults) => {
        if (!oldResults) return oldResults;

        return {
          pageParams: oldResults.pageParams,
          pages: oldResults.pages.map((page) => {
            const filteredItems = page.items.filter(
              (taggedDoc) => taggedDoc.organizeDocId !== organizeDocId
            );
            return {
              ...page,
              count: filteredItems.length,
              items: filteredItems,
            };
          }),
        };
      }
    );

    queryClient.setQueriesData<TagDetails | undefined>(
      { queryKey: tagsQueryKeys.detail(tagId) },
      (oldResults) => {
        if (!oldResults) return oldResults;

        return {
          ...oldResults,
          content: {
            ...oldResults.content,
            numberOfDocuments: oldResults.content.numberOfDocuments - 1,
          },
        };
      }
    );
  };
};
