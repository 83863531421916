import { Theme } from '@mui/material/styles';

export const globalStyles = {
  body: (theme: Theme) => ({
    margin: 0,
    backgroundColor: theme.palette.body,
    minHeight: '100vh',
  }),
  '#root': {
    minHeight: '100vh',
  },
  blockquote: (theme: Theme) => ({
    marginLeft: 26,
    paddingLeft: 10,
    borderLeft: `4px solid ${theme.palette.divider}`,
  }),
  pre: (theme: Theme) => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.error.dark,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }),
  code: (theme: Theme) => ({
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.error.dark,
    padding: '.25em .5em',
  }),
  button: {
    padding: 0,
    border: 'none',
    backgroundColor: 'inherit',
    '&:focus': {
      outline: 0,
    },
  },
};
