export enum RetrievalMethodEnum {
  Knn = 'knn',
  Keyword = 'keyword',
  Hybrid = 'mixed',
}

export type RetrievalMethodEnumType =
  | RetrievalMethodEnum.Keyword
  | RetrievalMethodEnum.Knn
  | RetrievalMethodEnum.Hybrid;
