import { isChatAvailableForTenant } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { useSelector } from 'react-redux';
import { selectUser } from 'containers/User/user.slice';
import { useGoogleScholarTabAvailability } from './useGoogleScholarTabAvailability';
import { useAssertTenantSettings } from './useAssertTenantSettings';

export const useChatAvailability = () => {
  const userSettings = useSelector(selectUser);
  const { tenantSettings } = useAssertTenantSettings();
  const { isGoogleScholarTab } = useGoogleScholarTabAvailability();
  const chatSettings = tenantSettings.chat;

  return {
    isChatAvailable:
      !!userSettings?.useOpenAI &&
      !!chatSettings &&
      isChatAvailableForTenant(chatSettings) &&
      !isGoogleScholarTab,
  };
};
