import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'app/state/store';
import { TagsSortSelect } from 'containers/Tags/TagsSort/TagsSortSelect';
import {
  selectFollowingTagsSortSettings,
  updateFollowingTagsSortSettings,
} from 'containers/Tags/followingTagsSlice/followingTags.slice';
import { useTagsSortOptions } from 'containers/Tags/TagsSort/useTagsSortOptions';
import { TagsSortByEnum } from 'containers/Tags/TagsSort/TagsSortBy.enum';

export const FollowingTagsListActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const sortSettings = useSelector(selectFollowingTagsSortSettings);
  const sortOptions = useTagsSortOptions();

  const handleSortChange = async (sortBy: TagsSortByEnum) => {
    await dispatch(updateFollowingTagsSortSettings(sortBy));
  };

  return (
    <TagsSortSelect
      options={sortOptions}
      initialValue={sortSettings.sortBy}
      onChange={handleSortChange}
    />
  );
};
