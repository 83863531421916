import React from 'react';
import { Alert, Box, BoxProps, Typography } from '@mui/material';

export type EmptyStateProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  prependButton?: React.ReactNode;
} & Omit<BoxProps, 'title'>;

const EmptyState = ({
  title,
  subtitle,
  children,
  prependButton,
  ...boxProps
}: EmptyStateProps) => {
  return (
    <Alert
      severity="info"
      sx={{ mt: 0, mb: 0, flexDirection: 'column' }}
      data-testid="EmptyState"
    >
      <Box
        width="100%"
        maxWidth={400}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        {...boxProps}
      >
        <Typography variant="body1" align="center" gutterBottom>
          {title}
        </Typography>

        {subtitle && (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            gutterBottom
            sx={{ whiteSpace: 'pre-line' }}
          >
            {subtitle}
          </Typography>
        )}

        {children && <Box mt={2}>{children}</Box>}

        {prependButton && <Box my={0}>{prependButton}</Box>}
      </Box>
    </Alert>
  );
};

export default EmptyState;
