import React from 'react';
import {
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Grid,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { Formik, Form } from 'formik';
import Alert from '@mui/material/Alert';
import { Trans, useTranslation } from 'react-i18next';

import { LoginDialogState } from 'containers/Auth/Auth.context';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useSignUpForm } from './hooks/useSignUpForm';
import { FormTextField } from 'common/components/Fields/FormTextField';
import ButtonWithLoader from 'common/components/Buttons/ButtonWithLoader';
import ButtonLink from 'common/components/Buttons/ButtonLink';
import AlertAutoDismiss from 'common/components/AlertAutoDismiss';
import FormCaptchaField from 'common/components/Fields/FormCaptchaField';

export type SignUpFormProps = {
  onClose: () => void;
  setOpenState: React.Dispatch<React.SetStateAction<LoginDialogState>>;
  onFormSubmit: () => void;
};

const SignUpForm = ({
  onClose,
  onFormSubmit,
  setOpenState,
}: SignUpFormProps): JSX.Element => {
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    { noSsr: true }
  );

  const { validationSchema, initialValues, onReset, onSubmit, error, success } =
    useSignUpForm({
      onReset: onClose,
    });

  const handleLogin = () => {
    setOpenState(LoginDialogState.Login);
  };

  const handleDismiss = () => {
    onFormSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({ isSubmitting, submitCount, isValid }) => (
        <Form id="signUpForm" aria-label="Sign Up form">
          <DialogContent sx={{ pt: 0 }}>
            <Typography sx={{ mb: 2 }}>{t('user.signUp.subtitle')}</Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTextField
                  autoFocus
                  type="email"
                  name="email"
                  id="signUpEmail"
                  label={t('user.signUp.email.label')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="firstName"
                  id="signUpFirstName"
                  label={t('user.signUp.firstName.label')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="lastName"
                  id="signUpLastName"
                  label={t('user.signUp.lastName.label')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="affiliation"
                  id="signUpAffiliation"
                  label={t('user.signUp.affiliation.label')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormTextField
                  name="promoCode"
                  id="signUpPromoCode"
                  label={t('user.signUp.promoCode.label')}
                />
              </Grid>
            </Grid>

            {error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            {success && (
              <Box mt={2}>
                <AlertAutoDismiss
                  severity="success"
                  dismissIn={10000}
                  onDismiss={handleDismiss}
                  text={success}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Box gap={1} display="flex" flexDirection="column">
              <FormCaptchaField
                name="captchaToken"
                id="signUpCaptcha"
                size={isMobile ? 'compact' : 'normal'}
              />

              <ButtonWithLoader
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                isFetching={isSubmitting}
                disabled={isSubmitting || (!!submitCount && !isValid)}
                eventName={TrackEventName.SignUpButtonClicked}
              >
                {t('user.signUp.submitButton')}
              </ButtonWithLoader>

              <Typography variant="body2" color="textSecondary">
                <Trans t={t} i18nKey="user.signUp.terms.text">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={t('user.signUp.terms.website')}
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={t('user.signUp.terms.privacyLink')}
                  />
                </Trans>
              </Typography>
            </Box>
          </DialogActions>

          <Divider />

          <Box
            p={2}
            gap={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>{t('user.signUp.haveAccount')}</Typography>

            <ButtonLink variant="body1" underline="hover" onClick={handleLogin}>
              {t('user.signUp.loginButton')}
            </ButtonLink>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
