import { useMemo } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useBatchUploadPrivateDocsValidation = () => {
  const { t } = useTranslation(['common', 'privateDocs']);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        file: Yup.array<FileWithPath>()
          .min(1, t('common:errors.required'))
          .required(t('common:errors.required')),
      }),
    []
  );

  return validationSchema;
};
