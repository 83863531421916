import React, { FC, useMemo } from 'react';
import { OpenChatNoteButton } from '../../OpenChatNoteButton/OpenChatNoteButton';
import { ExplainNoteAction } from '../../ExplainNoteAction/ExplainNoteAction';
import { NoteObjectType } from '@zarn/vendor/dist/saved-results';
import { NoteItemShareableAction } from '../../NoteItemShareableAction';
import NoteItemActions from '../../NoteItemActions';
import SearchWithNoteOrHighlightDialog from 'containers/SearchWithNoteOrHighlight';
import { Box } from '@mui/material';
import {
  getAnnotationSearchQuery,
  getTextAnnotationContent,
} from 'containers/DocNotes/docNotes.utils';
import { useSearchPopper } from 'containers/SearchResults/SearchPopper/hooks/useSearchPopper';
import { NoteDetails, NoteObjectId } from 'api/notesApi/notesApi.types';

interface Props {
  note: NoteDetails;
  docId?: string | undefined;
  isChatConversation: boolean;
  editable: boolean;
  onDelete: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onToggleShareable: () => Promise<void>;
  onOpenChatNote: () => void;
  onEditModeToggle: () => void;
  isChatEnabled: boolean;
  isExplainVisible: boolean;
}

export const Actions: FC<Props> = ({
  note,
  docId,
  isChatConversation,
  editable,
  onDelete,
  onToggleShareable,
  onOpenChatNote,
  onEditModeToggle,
  isChatEnabled,
  isExplainVisible,
}) => {
  const { dialogOpen, onOpen, onClose } = useSearchPopper();

  const { annotationHighlight } = note;

  const annotationContent = getTextAnnotationContent(
    annotationHighlight?.content.type,
    annotationHighlight?.content.body
  );

  const searchQuery = useMemo(
    () => getAnnotationSearchQuery(annotationContent, note.content),
    [annotationContent, note.content]
  );

  const canSearch = note.content || annotationContent;
  const isActionEnabled = canSearch || docId;

  const handleSearchDialogOpen = () => {
    onOpen(searchQuery);
  };

  const EditableChat = editable ? (
    <OpenChatNoteButton noteId={note.id} onClick={onOpenChatNote} />
  ) : null;

  const ExplainAction = isExplainVisible ? (
    <ExplainNoteAction
      docId={docId}
      noteId={note.id}
      noteContent={note.content}
      annotationContentBody={annotationHighlight?.content.body}
    />
  ) : null;

  const Chat = isChatConversation ? EditableChat : ExplainAction;

  return (
    <Box flex="none" ml={1}>
      {isActionEnabled && isChatEnabled && Chat}

      {editable && (
        <>
          {note.objectType !== NoteObjectType.Free && (
            <NoteItemShareableAction
              noteId={note.id}
              shareable={note.shareable}
              onToggleShareable={onToggleShareable}
            />
          )}

          <NoteItemActions
            objectId={note.objectId}
            noteId={note.id}
            onEdit={onEditModeToggle}
            onDelete={onDelete}
            onOpenSearchDialog={handleSearchDialogOpen}
            isChatConversation={isChatConversation}
          />

          <SearchWithNoteOrHighlightDialog
            searchPopperOpen={dialogOpen}
            onSearchPopperClose={onClose}
            searchQuery={searchQuery}
            docId={docId}
          />
        </>
      )}
    </Box>
  );
};
