import React, { useState } from 'react';
import { Chip, Popover, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';

export const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    maxWidth: '80vw',
    padding: theme.spacing(2),
    width: 300,
  },
}));

type BetaButtonProps = {
  idValue: string;
  headerText: string;
  bodyText: string;
};

const BetaButton = ({ idValue, headerText, bodyText }: BetaButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? idValue : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        sx={{ alignSelf: 'flex-start', margin: 1 }}
        size="small"
        label="Beta"
        color="secondary"
        onDelete={handleClick}
        deleteIcon={<InfoIcon fontSize="small" />}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.popoverPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography gutterBottom variant="h6">
          {headerText}
        </Typography>

        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
      </Popover>
    </>
  );
};
export default BetaButton;
