import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const NoteItemSkeleton = () => {
  return (
    <Box mb={2} aria-label="note skeleton">
      <Skeleton height={15} width={100} />

      <Skeleton height={50} />
      <Skeleton height={15} />
      <Skeleton height={15} />
      <Skeleton height={15} width={40} />
    </Box>
  );
};
