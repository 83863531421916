import { AxiosResponse } from 'axios';

import axiosInstance from 'api/axiosInstance';
import { Configuration, DocumentAssetsApi } from '@zarn/vendor/dist/search';
import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';
import {
  DocumentAssetContentData,
  GetDocumentAssetContentPayload,
} from './documentAssetApi.types';

export const documentAssetsApi = new DocumentAssetsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const getDocumentAssetContent = async ({
  docId,
  assetType,
  indexCluster,
  tenant,
}: GetDocumentAssetContentPayload): Promise<
  AxiosResponse<DocumentAssetContentData>
> => {
  const { data, headers, ...rest } = await documentAssetsApi.retrieveContent(
    {
      requesterUuid: '',
      documentId: docId,
      assetType,
      indexCluster,
      tenant,
    },
    {
      responseType: 'arraybuffer',
    }
  );

  const contentType = headers['content-type'];
  const arrayBuffer = await new Response(data).arrayBuffer();

  return {
    ...rest,
    headers,
    data: {
      content: new Uint8Array(arrayBuffer),
      contentType: contentType,
    },
  };
};
