export enum FeedbackType {
  Click = 'click',
  RelevanceJudgment = 'relevance_judgment',
  Note = 'note',
  Tag = 'tag',
  Share = 'share',
  Abstract = 'abstract',
  Github = 'github',
  GithubRepoClick = 'github_repo_click',
  Tweets = 'tweets',
  TweetClick = 'tweet_click',
}
