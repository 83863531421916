import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import clsx from 'clsx';

import { errorChipStyles } from 'app/theme/styles/chip';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  chip: {
    ...errorChipStyles(theme),
    marginLeft: theme.spacing(1),
  },
}));

export type NotificationLinkItemProps = {
  to?: string;
  text: string;
  chipLabel?: React.ReactText;
  onClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  selected?: boolean;
  appendIcon?: ReactNode;
  nested?: boolean;
};

const NotificationLinkItem = ({
  to,
  icon: Icon,
  text,
  chipLabel,
  onClick,
  onAction,
  selected,
  appendIcon,
  nested,
}: WithTrackedActionWrappedProps<NotificationLinkItemProps>) => {
  const classes = useStyles();

  const handleClick = (e: any) => {
    onAction();
    if (!to) {
      e.preventDefault();
    }
    onClick();
  };

  return (
    <ListItem
      button
      selected={selected}
      {...{ component: RouterLink, to }}
      className={clsx(classes.root, nested && classes.nested)}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>

      <ListItemText
        primary={text}
        primaryTypographyProps={{
          sx: { textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 200 },
        }}
      />

      <Chip size="small" className={classes.chip} label={chipLabel} />

      {appendIcon}
    </ListItem>
  );
};

export default withTrackedAction<
  NotificationLinkItemProps & WithTrackedActionProps
>(NotificationLinkItem);
