import React, { useCallback } from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { TagType } from '@zarn/vendor/dist/saved-results';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';
import { normalizeInputValue } from '../tagsAutocomplete.utils';
import TagsAutocompleteOption from '../TagsAutocompleteOption';

const owners: { [key: string]: string } = {};

export const useTagsAutocompleteRenderOption = (
  ownTags: TagsAutocompleteOptionValue[]
) => {
  const { t } = useTranslation('tags');

  const renderOption = useCallback(
    (
      liProps: React.HTMLAttributes<HTMLLIElement>,
      option: TagsAutocompleteOptionValue,
      { inputValue }: AutocompleteRenderOptionState
    ) => {
      const narmalizedVal = normalizeInputValue(inputValue);
      const owner = (option.ownerId && owners[option.ownerId]) ?? '';

      if (option.name !== narmalizedVal) {
        return (
          <li {...liProps} key={option.id}>
            <TagsAutocompleteOption option={option} owner={owner} />
          </li>
        );
      }

      const isShared = option.type === TagType.Shared;
      const isExisting = ownTags.some((opt) => narmalizedVal === opt.name);

      return (
        <li {...liProps} key={option.id}>
          <TagsAutocompleteOption
            option={option}
            owner={owner}
            isNew={!isExisting && !isShared}
          />
        </li>
      );
    },
    [ownTags, t]
  );

  return renderOption;
};
