import { useCallback } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';

type UseDocTagsUpdateSnackbarReturn = {
  onErrorSnackbar: (message?: string, code?: string) => void;
  onDeleteSnackbar: (
    selectedTags: TagDetailsBase[],
    initialTags: TagDetailsBase[],
    options?: OptionsObject
  ) => void;
  onAddSnackbar: (
    selectedTags: TagDetailsBase[],
    initialTags: TagDetailsBase[],
    options?: OptionsObject
  ) => void;
};

export const useDocTagsUpdateSnackbar = (): UseDocTagsUpdateSnackbarReturn => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const onErrorSnackbar = useCallback(
    (message?: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    },
    [enqueueSnackbar]
  );

  const onAddSnackbar = useCallback(
    (
      selectedTags: TagDetailsBase[],
      initialTags: TagDetailsBase[],
      options?: OptionsObject
    ) => {
      const addedTags = selectedTags
        .filter((st) => !initialTags.find(({ id }) => id === st.id))
        .map(({ name }) => name)
        .join(', ');

      if (!addedTags.length) return;

      enqueueSnackbar(
        t('common:tagDocument.addMultiple.successMessage', { addedTags }),
        options
      );
    },
    [t, enqueueSnackbar]
  );

  const onDeleteSnackbar = useCallback(
    (
      selectedTags: TagDetailsBase[],
      initialTags: TagDetailsBase[],
      options?: OptionsObject | undefined
    ) => {
      const deletedTags = initialTags
        .filter((it) => !selectedTags.find(({ id }) => id === it.id))
        .map(({ name }) => name)
        .join(', ');

      if (!deletedTags.length) return;

      enqueueSnackbar(
        t('common:tagDocument.deleteMultiple.successMessage', { deletedTags }),
        options
      );
    },
    [t, enqueueSnackbar]
  );

  return {
    onAddSnackbar,
    onDeleteSnackbar,
    onErrorSnackbar,
  };
};
