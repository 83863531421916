import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

import { LoginDialogState } from 'containers/Auth/Auth.context';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import ButtonLink from 'common/components/Buttons/ButtonLink';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useLoginForm } from './hooks/useLoginForm';
import { TextField } from 'common/components/TextField';

type LoginFormProps = {
  onClose: () => void;
  setOpenState: React.Dispatch<React.SetStateAction<LoginDialogState>>;
  onLogin: () => void;
};

const LoginForm = ({
  onClose,
  onLogin,
  setOpenState,
}: LoginFormProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { validationSchema, initialValues, onReset, onSubmit, error } =
    useLoginForm({
      onReset: onClose,
      onSubmit: () => {
        onClose();
        onLogin();
      },
    });

  const handleForgotPassword = () =>
    setOpenState(LoginDialogState.ResetPassword);
  const handleSignUp = () => setOpenState(LoginDialogState.SignUp);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({ isSubmitting }) => (
        <Form id="loginForm" aria-label="Login form">
          <DialogContent>
            <Field name="email" type="email">
              {({ field, meta }: FieldProps) => (
                <TextField
                  {...field}
                  fullWidth
                  autoFocus
                  label={t('user.login.email.label')}
                  error={!!meta.error}
                  helperText={meta.error}
                  inputProps={{ 'aria-label': 'Email' }}
                />
              )}
            </Field>

            <Field name="password">
              {({ field, meta }: FieldProps) => (
                <TextField
                  {...field}
                  fullWidth
                  type="password"
                  label={t('user.login.password.label')}
                  style={{ marginTop: 16 }}
                  error={!!meta.error}
                  helperText={meta.error}
                  inputProps={{ 'aria-label': 'Password' }}
                />
              )}
            </Field>

            <Box mt={1} mb={2} justifyContent="flex-end" display="flex">
              <ButtonLink underline="hover" onClick={handleForgotPassword}>
                {t('user.login.forgotPasswordButton')}
              </ButtonLink>
            </Box>

            {error && (
              <Box mt={1} mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button type="reset">{t('buttons.cancel')}</Button>

            <SubmitButton
              text={t('user.login.loginButton')}
              isSubmitting={isSubmitting}
              eventName={TrackEventName.LoginButtonClicked}
            />
          </DialogActions>

          <Divider />

          <Box
            p={2}
            gap={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>{t('user.login.noAccount')}</Typography>

            <ButtonLink
              variant="body1"
              underline="hover"
              onClick={handleSignUp}
              eventName={TrackEventName.RequestAccountClicked}
            >
              {t('user.login.requestDemo')}
            </ButtonLink>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
