import React, { useCallback } from 'react';
import { Button, ClickAwayListener, Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Form, FormikProps } from 'formik';

import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';
import SubmitButton from '../Buttons/SubmitButton';
import NoteMarkdownField from './NoteMarkdownField/NoteMarkdownField';
import { WithTrackedActionProps } from '../TrackedActions/withTrackedAction';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

type Props = {
  submitButtonText: string;
  onClickAway?: () => void;
} & FormikProps<NoteFormValue> &
  WithTrackedActionProps;

export const NoteForm = ({
  handleSubmit,
  isSubmitting,
  dirty,
  isValid,
  submitButtonText,
  onClickAway,
  eventName,
  eventProps,
}: Props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const handleClickAway = useCallback((): void => {
    if (!dirty && onClickAway) {
      onClickAway();
      return;
    }

    handleSubmit();
  }, [dirty, onClickAway, handleSubmit]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Form
        role="form"
        className={classes.root}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            handleSubmit(e);
          }
        }}
      >
        <NoteMarkdownField
          name="content"
          placeholder={t('notes.form.contentField.label')}
          helperText={t('notes.form.contentField.helperText')}
        />

        <Grid item container justifyContent="flex-end" xs spacing={2}>
          <Grid item>
            <Button type="reset" disabled={isSubmitting}>
              {t('common:buttons.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <SubmitButton
              text={submitButtonText}
              isSubmitting={isSubmitting}
              disabled={!isValid || !dirty}
              eventName={eventName}
              eventProps={eventProps}
            />
          </Grid>
        </Grid>
      </Form>
    </ClickAwayListener>
  );
};
