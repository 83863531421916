import React from 'react';
import { Tooltip } from '@mui/material';
import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { useTranslation } from 'react-i18next';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';

type DocOwnerFromOrgProps = {
  ownerUuid?: string;
};

const DocOwnerFromOrg = ({ ownerUuid }: DocOwnerFromOrgProps) => {
  const { t } = useTranslation('common');
  const { data } = useUserDetails({ memberId: ownerUuid as string });

  if (!data?.firstName && !data?.lastName && !data?.initials) {
    return null;
  }

  return (
    <Tooltip
      title={t<string>('retrievalUnit.owner.tooltip', {
        owner: `${data.firstName} ${data.lastName}`,
      })}
    >
      <StyledAvatar size="small" color="secondary">
        {data.initials}
      </StyledAvatar>
    </Tooltip>
  );
};

DocOwnerFromOrg.displayName = 'DocOwnerFromOrg';

export default DocOwnerFromOrg;
