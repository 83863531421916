import React, { forwardRef, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';

import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { getSavedDocuments } from 'containers/SavedDocuments/savedDocuments.slice';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export type DocsCollectionProps = {
  organizeDocIds: string[];
} & BoxProps;

const DocsCollection = forwardRef(
  ({ organizeDocIds, children, ...boxProps }: DocsCollectionProps, ref) => {
    const dispatch = useAppDispatch();
    const { isAuthenticated } = useAuth();
    const [computedOrganizeDocIds, setComputedOrganizeDocIds] = useState<
      string[]
    >([]);
    const { tenant } = useParsedHostname();

    useEffect(() => {
      const newOrganizeDocIds = organizeDocIds.filter(
        (x) => !computedOrganizeDocIds.includes(x)
      );
      if (newOrganizeDocIds.length === 0 || !isAuthenticated) return;

      dispatch(
        getSavedDocuments({
          documentList: newOrganizeDocIds,
          tenant,
        })
      );

      setComputedOrganizeDocIds(organizeDocIds);
    }, [
      computedOrganizeDocIds,
      dispatch,
      isAuthenticated,
      organizeDocIds,
      tenant,
    ]);

    return (
      <Box
        ref={ref}
        display="flex"
        flexDirection="column"
        sx={{ gap: { xs: 1, md: 2 }, mb: { xs: 1, md: 2 } }}
        {...boxProps}
      >
        {children}
      </Box>
    );
  }
);

export default DocsCollection;
