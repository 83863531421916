import React from 'react';
import OpenAiIcon from 'common/components/CustomIcons/OpenAiIcon';

const OrangeGradientOpenAiIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={0} y2={1}>
        <stop offset={0} stopColor="#FA7625" />
        <stop offset={1} stopColor="#FFFF00" />
      </linearGradient>
    </svg>
    <OpenAiIcon sx={{ fill: 'url(#linearColors)' }} />
  </>
);

export default OrangeGradientOpenAiIcon;
