import React, { useContext } from 'react';
import { Drawer, Hidden, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { PageContext } from 'containers/Page/Page.context';
import { DrawerResize } from 'common/components/DrawerResize/DrawerResize';
import { SidebarContent } from './SidebarContent/SidebarContent';
import { useMainSidebarResize } from './useMainSidebarResize';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { MainSidebarSignInButton } from './MainSidebarSignInButton/MainSidebarSignInButton';
import { AppTheme } from 'app/theme';

const useStyles = makeStyles<AppTheme>((theme) => ({
  appDrawer: {
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      top: 64,
      position: 'sticky',
    },
  },
  drawerPaper: {
    background: theme.palette.sideDrawer.background,
    backgroundColor: theme.palette.sideDrawer.backgroundColor,
    position: 'relative',
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: 'calc(100vh - 64px)',
      maxWidth: '30vw',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '20vw',
    },
    [theme.breakpoints.down('md')]: {
      width: 400,
      maxWidth: '85vw',
    },
  },
  drawerMinified: {
    width: 70,
    overflow: 'visible',
  },
}));

export const MainSidebar = () => {
  const { pageDrawerMobileOpen, setPageDrawerMobileOpen, pageDrawerMinified } =
    useContext(PageContext);
  const { width, onResizeStart } = useMainSidebarResize();
  const theme = useTheme<AppTheme>();

  const classes = useStyles({ theme });

  const handleDrawerToggle = () => {
    setPageDrawerMobileOpen(!pageDrawerMobileOpen);
  };

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Sidebar menu' }}>
      <nav className={classes.appDrawer} aria-label="App sidebar">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={pageDrawerMobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <MainSidebarSignInButton />
            <SidebarContent />
          </Drawer>
        </Hidden>

        <Hidden mdDown>
          <Drawer
            open
            variant="permanent"
            PaperProps={{ style: !pageDrawerMinified ? { width } : {} }}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                pageDrawerMinified && classes.drawerMinified
              ),
            }}
          >
            {!pageDrawerMinified && (
              <DrawerResize onResizeStart={onResizeStart} />
            )}

            <SidebarContent minified={pageDrawerMinified} />
          </Drawer>
        </Hidden>
      </nav>
    </TrackedActionContext.Provider>
  );
};
