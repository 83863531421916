import React, { useCallback, useState } from 'react';
import { Box, BoxProps, Card } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { deserializeAxiosError } from 'common/utils/error';
import { NoteForm } from 'common/components/Notes/NoteForm';
import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';
import { useNoteFormValidation } from './useNoteFormValidation';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { TextField } from '../../TextField';
import { captureException } from '@sentry/react';

const initialValues: NoteFormValue = {
  content: '',
};

type Props = {
  initEditMode?: boolean;
  onAdd: (content: string) => Promise<void>;
} & BoxProps;

export const NoteCreate = ({
  onAdd,
  initEditMode = false,
  ...boxProps
}: Props) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { validationSchema } = useNoteFormValidation();
  const [editMode, setEditMode] = useState<boolean>(initEditMode);

  const editModeToggle = () => {
    setEditMode(!editMode);
  };

  const handleSubmit = useCallback(
    async (
      { content }: NoteFormValue,
      { setSubmitting, resetForm }: FormikHelpers<NoteFormValue>
    ) => {
      try {
        setSubmitting(true);
        await onAdd(content);
        enqueueSnackbar(t('notes.create.successMessage'));
        resetForm();
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
        setSubmitting(false);
      }
    },
    [t, enqueueSnackbar, onAdd]
  );

  return (
    <Box {...boxProps}>
      {!editMode && (
        <Card>
          <TextField
            fullWidth
            onClick={editModeToggle}
            label={t('notes.form.yourNote')}
          />
        </Card>
      )}

      {editMode && (
        <Card>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onReset={editModeToggle}
            validationSchema={validationSchema}
          >
            {(props) => (
              <NoteForm
                {...props}
                onClickAway={editModeToggle}
                submitButtonText={t('saved:tagDocuments.buttons.create')}
                eventName={TrackEventName.NoteCreated}
              />
            )}
          </Formik>
        </Card>
      )}
    </Box>
  );
};
