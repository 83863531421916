import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotesFilterVisibility } from '../NotesFilter.types';

const ALL_OPTIONS = Object.values(NotesFilterVisibility);

export const useNotesFilterVisibilityOptions = (
  options: NotesFilterVisibility[] = ALL_OPTIONS
) => {
  const { t } = useTranslation('common');

  return useMemo<{ value: NotesFilterVisibility; label: string }[]>(
    () => [
      ...(options.includes(NotesFilterVisibility.All)
        ? [
            {
              value: NotesFilterVisibility.All,
              label: t('notes.filters.visibility.options.all'),
            },
          ]
        : []),
      ...(options.includes(NotesFilterVisibility.OnlyMyNotes)
        ? [
            {
              value: NotesFilterVisibility.OnlyMyNotes,
              label: t('notes.filters.visibility.options.onlyMyNotes'),
            },
          ]
        : []),
      ...(options.includes(NotesFilterVisibility.OnlySharedNotes)
        ? [
            {
              value: NotesFilterVisibility.OnlySharedNotes,
              label: t('notes.filters.visibility.options.onlySharedNotes'),
            },
          ]
        : []),
    ],
    [t, options]
  );
};
