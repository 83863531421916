import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';

import { selectOwnTags } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import TagsAutocompleteSingle from 'containers/Tagging/TagsAutocomplete/TagsAutocompleteSingle';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { getTagNames } from 'containers/Tags/tags.utils';
import { useBatchUploadPrivateDocsValidation } from '../hooks/useBatchUploadPrivateDocsValidation';
import { useBatchUploadPrivateDocsForm } from '../hooks/useBatchUploadPrivateDocsForm';
import { BatchUploadPrivateDocsFormValues } from '../BatchUploadPrivateDocs.interface';
import { FileUploadField } from 'common/components/Fields/FileUploadField/FileUploadField';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

type BatchUploadPrivateDocsFormProps = {
  onFormSubmit: () => void;
};

const BatchUploadPrivateDocsForm = ({
  onFormSubmit,
}: BatchUploadPrivateDocsFormProps) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const validationSchema = useBatchUploadPrivateDocsValidation();
  const allTags = useSelector(selectOwnTags);
  const tagOptions = useMemo(() => getTagNames(allTags), [allTags]);
  const { onSubmit, initialValues } =
    useBatchUploadPrivateDocsForm(onFormSubmit);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onFormSubmit}
      validationSchema={validationSchema}
    >
      {(helpers: FormikProps<BatchUploadPrivateDocsFormValues>) => (
        <Form>
          <DialogContent>
            <DialogContentText>
              {t('privateDocs:batchUploadDialog.explanation')}
            </DialogContentText>

            <FileUploadField
              name="file"
              label={t('privateDocs:form.batchUploadFile.label')}
              accept={{ 'text/ris': ['.ris'] }}
            />

            <TagsAutocompleteSingle
              name="selectedTag"
              tags={tagOptions}
              margin="normal"
              helperText={t('privateDocs:form.batchUploadSelectTag.helperText')}
              label={t('privateDocs:form.batchUploadSelectTag.label')}
            />
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button type="reset" disabled={helpers.isSubmitting}>
                  {t('common:buttons.cancel')}
                </Button>
              </Grid>

              <Grid item>
                <SubmitButton
                  disabled={helpers.isSubmitting}
                  isSubmitting={helpers.isSubmitting}
                  text={t('privateDocs:form.submitButton.text')}
                  eventName={TrackEventName.PrivateDocumentBatchUploaded}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

BatchUploadPrivateDocsForm.displayName = 'BatchUploadPrivateDocsForm';

export default BatchUploadPrivateDocsForm;
