import React from 'react';
import { Link } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import TrackedLink from 'common/components/Links/TrackedLink/TrackedLink';

const MAX_LENGTH = 120;

type DocTitleLinkProps = {
  docTitle: string;
  docId: string;
  linkType?: 'link' | 'pdf';
} & React.ComponentProps<typeof Link>;

const DocTitleLink = ({
  docTitle,
  linkType = 'link',
  docId,
  ...linkProps
}: DocTitleLinkProps) => {
  const truncatedTitle =
    docTitle.length > MAX_LENGTH
      ? `${docTitle.slice(0, MAX_LENGTH)}...`
      : docTitle;

  return (
    <TrackedLink
      underline="hover"
      eventProps={{ docId }}
      sx={{ cursor: 'pointer' }}
      title={docTitle}
      {...linkProps}
    >
      {truncatedTitle}
      {linkType === 'pdf' && (
        <PictureAsPdfIcon color="primary" fontSize="small" sx={{ ml: 0.5 }} />
      )}
    </TrackedLink>
  );
};

DocTitleLink.displayName = 'DocTitleLink';

export default DocTitleLink;
