import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { captureException } from '@sentry/react';

import { AppDispatch } from 'app/state/store';
import {
  ExplanationQueryLoggingResult,
  postQuery,
  QAKQueryLoggingResult,
  QAQueryLoggingResult,
  QueryLoggingResult,
  updateExplanationQuery,
  updateQAKQuery,
  updateQAQuery,
  updateQuery,
} from 'api/queryLoggingApi';
import {
  selectSearchResultsHits,
  selectSearchResultsId,
  selectSearchResultsPayload,
  setSearchResultsId,
} from 'containers/SearchResults/searchResults.slice';

type UseSearchLoggingReturn = {
  createSearchLog: () => Promise<void>;
  updateSearchLog: (result: QueryLoggingResult) => Promise<void>;
  updateQASearchLog: (result: QAQueryLoggingResult) => Promise<void>;
  updateQAKSearchLog: (result: QAKQueryLoggingResult) => Promise<void>;
  updateExplanationSearchLog: (
    result: ExplanationQueryLoggingResult
  ) => Promise<void>;
};

export function useSearchResultsLogging(): UseSearchLoggingReturn {
  const dispatch = useDispatch<AppDispatch>();
  const searchId = useSelector(selectSearchResultsId);
  const searchPayload = useSelector(selectSearchResultsPayload);
  const documents = useSelector(selectSearchResultsHits);

  const createSearchLog = useCallback(async () => {
    try {
      const { data } = await postQuery(searchPayload, [
        {
          system: ResultType.Search,
          documents,
        },
      ]);
      dispatch(setSearchResultsId(data.search_id));
    } catch (error) {
      captureException(error);
    }
  }, [searchPayload, documents, dispatch]);

  const updateSearchLog = useCallback(
    async (result: QueryLoggingResult) => {
      await updateQuery(searchId, result, searchPayload.tenant);
    },
    [searchId, searchPayload.tenant]
  );

  const updateQASearchLog = useCallback(
    async (result: QAQueryLoggingResult) => {
      await updateQAQuery(searchId, result, searchPayload.tenant);
    },
    [searchId, searchPayload.tenant]
  );

  const updateQAKSearchLog = useCallback(
    async (result: QAKQueryLoggingResult) => {
      await updateQAKQuery(searchId, result, searchPayload.tenant);
    },
    [searchId, searchPayload.tenant]
  );

  const updateExplanationSearchLog = useCallback(
    async (result: ExplanationQueryLoggingResult) => {
      await updateExplanationQuery(searchId, result, searchPayload.tenant);
    },
    [searchId, searchPayload.tenant]
  );
  return {
    createSearchLog,
    updateSearchLog,
    updateQASearchLog,
    updateQAKSearchLog,
    updateExplanationSearchLog,
  };
}
