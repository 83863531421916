import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';

import { selectSearchResultsLoading } from './searchResults.slice';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
}));

export default function SearchLoader() {
  const isLoading = useSelector(selectSearchResultsLoading);
  const classes = useStyles();

  if (!isLoading) return null;

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}
