import React from 'react';
import { List, ListSubheader, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { TweetDocument } from '../types';
import { RetrievalUnitTweetsListItem } from './RetrievalUnitTweetsListItem';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 450,
    maxHeight: 400,
    overflowY: 'scroll',
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
}));

type Props = {
  tweets: TweetDocument[];
  numberOfTweets: number;
  onTweetClick: (e: React.MouseEvent<HTMLAnchorElement>, link: string) => void;
};

export const RetrievalUnitTweetsList = ({
  tweets,
  numberOfTweets,
  onTweetClick,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <List
      className={classes.root}
      aria-label="top tweets"
      subheader={
        <ListSubheader className={classes.subheader}>
          {t('retrievalUnit.tweets.popover.title', {
            count: tweets.length,
            totalAmount: numberOfTweets,
          })}
        </ListSubheader>
      }
    >
      {tweets.map((tweet) => (
        <RetrievalUnitTweetsListItem
          key={tweet.userName}
          tweet={tweet}
          onClick={onTweetClick}
        />
      ))}
    </List>
  );
};
