import React, { FC } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import AnimatedLogo from 'common/components/AnimatedLogo/AnimatedLogo';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
}));

export const PageLoader: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="PageLoader">
      <AnimatedLogo />
    </div>
  );
};
