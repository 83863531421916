import { Theme, ComponentsOverrides } from '@mui/material/styles';

export const chipOverrides: ComponentsOverrides<Theme>['MuiChip'] = {
  root: {
    height: 26,
    '& .MuiChip-avatar': {
      height: 22,
      width: 22,
      marginLeft: 2,
    },
  },
  outlined: {
    '& .MuiChip-avatar': {
      marginLeft: 1,
    },
  },
  label: ({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
  sizeSmall: {
    height: 18,
    fontSize: '.75rem',
  },
  labelSmall: {
    lineHeight: '18px',
  },
  iconSmall: {
    width: 12,
    height: 12,
    marginRight: -6,
  },
  deleteIconSmall: {
    marginLeft: -6,
    marginRight: 1,
  },
};
