import { format, isValid } from 'date-fns';
import { RegistrationData, UserUpdateData } from '@zarn/vendor/dist/auth';

import { CreateUserPayload, UpdateUserPayload } from './usersApi.types';

export const serializeRegistrationData = ({
  expireDate,
  ...values
}: CreateUserPayload): RegistrationData => {
  const validDate = expireDate && isValid(expireDate) && expireDate;

  return {
    first_name: values.firstName,
    last_name: values.lastName,
    affiliation: values.affiliation,
    date_expired: validDate ? format(validDate, 'dd-MM-yyyy') : null,
    creator: values.creator,
    promo_code: values.promoCode,
    email: values.email,
    active: values.active,
    use_openai: values.useOpenAI,
    captcha_token: values.captchaToken,
  };
};

export const serializeUserUpdateData = (
  values: UpdateUserPayload
): UserUpdateData => {
  return serializeRegistrationData(values);
};
