import { Undefined } from '../utils/assert';
import { Image } from 'api/tenantSettingsApi/WhitelabelSettings.types';
import React, { ReactNode } from 'react';

export const useImage = (image: Undefined<Image>, fallback: ReactNode) => {
  if (!image) {
    return fallback;
  }
  return <img {...image} alt="" />;
};
