import { AxiosResponse } from 'axios';

import {
  AnalyticsApiFactory,
  AnalyticsRequest,
  AnalyticsResponse,
  Configuration,
} from '@zarn/vendor/dist/search';
import { BASE_HEADERS, SERVICE_URL } from './apiConfig';
import AxiosInstance from './axiosInstance';

export const analyticsGraphApi = AnalyticsApiFactory(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const createAnalyticsGraph = (
  payload: AnalyticsRequest
): Promise<AxiosResponse<AnalyticsResponse>> =>
  analyticsGraphApi.analytics('', payload);
