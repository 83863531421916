import React from 'react';
import { Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import clsx from 'clsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: 2,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'dashed',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[50],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'default',
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      '& > $icon': {
        color: theme.palette.secondary.main,
      },
    },
  },
  dragActive: {
    borderColor: theme.palette.secondary.main,
    '& > $icon': {
      color: theme.palette.secondary.main,
    },
  },
  dragAccept: {
    borderColor: theme.palette.success.main,
    '& > $icon': {
      color: theme.palette.success.main,
    },
  },
  dragReject: {
    borderColor: theme.palette.error.main,
    '& > $icon': {
      color: theme.palette.error.main,
    },
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[300],
    fontSize: theme.typography.h3.fontSize,
    transition: 'color .24s ease-in-out',
  },
  browse: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  acceptedFileTypes: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
  acceptedFileTypesText: {
    marginRight: theme.spacing(1),
  },
  label: {
    position: 'absolute',
    left: theme.spacing(1),
    top: -10,
    padding: theme.spacing(0, 0.5),
    fontSize: theme.typography.caption.fontSize,
    background: `linear-gradient(0deg, ${theme.palette.grey[50]} 50%, ${theme.palette.background.paper} 50%)`,
  },
}));

export interface FileUploadProps {
  maxFiles?: number;
  label?: string;
  acceptedFileTypes?: string[];
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}

export const FileUpload = React.memo(
  ({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFileTypes,
    maxFiles = 1,
    label,
  }: FileUploadProps) => {
    const classes = useStyles();
    const { t } = useTranslation(['common']);

    const rootClasses = clsx(classes.root, {
      [classes.dragActive]: isDragActive,
      [classes.dragAccept]: isDragAccept,
      [classes.dragReject]: isDragReject,
    });

    return (
      <Tooltip
        title={`${t(
          'common:formFields.fileUpload.acceptedFileTypes'
        )} ${acceptedFileTypes?.join(', ')}`}
      >
        <div
          className={rootClasses}
          aria-label={t('common:formFields.fileUpload.ariaLabel')}
          {...getRootProps()}
          data-testid="FileUpload"
        >
          {label && <Typography className={classes.label}>{label}</Typography>}

          <input type="file" {...getInputProps()} />

          <CloudUploadIcon className={classes.icon} />

          <Typography variant="body2" component="div" align="center">
            <Trans
              t={t}
              i18nKey="common:formFields.fileUpload.text"
              count={maxFiles}
            >
              <b className={classes.browse} />
            </Trans>
          </Typography>
        </div>
      </Tooltip>
    );
  }
);
