import React from 'react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import IconButtonLink from 'common/components/Buttons/IconButtonLink/IconButtonLink';
import LaunchIcon from '@mui/icons-material/Launch';

export type SearchPopperHeaderProps = {
  searchQuery: string;
  onClose: () => void;
  children?: React.ReactNode;
};

const SearchPopperHeader = React.memo(
  ({ onClose, searchQuery, children }: SearchPopperHeaderProps) => {
    const { t } = useTranslation('search');

    return (
      <DialogActionTitle onClose={onClose}>
        {children}
        <Typography variant="body1" pt={2}>
          <Trans t={t} i18nKey="searchPopper.title"></Trans>
          <IconButtonLink href={`/${searchQuery}`}>
            <LaunchIcon />
          </IconButtonLink>
        </Typography>
      </DialogActionTitle>
    );
  }
);

SearchPopperHeader.displayName = 'SearchPopperHeader';

export default SearchPopperHeader;
