import React, { useContext } from 'react';
import { Button, ListItemIcon, MenuItem, Typography } from '@mui/material';

import { DocAction } from '../DocActions.interface';
import { DocActionsContext } from '../docActions.context';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { DocCardComposition } from 'containers/User/User.enum';

export type DocActionItemProps = {
  actionComposition?: DocCardComposition;
} & DocAction;

const DocActionItem = React.memo(
  ({
    onAction,
    title,
    action,
    Icon,
    color,
    actionComposition,
  }: WithTrackedActionWrappedProps<DocActionItemProps>) => {
    const { setAnchorEl } = useContext(DocActionsContext);
    const handleClick = () => {
      onAction();
      action();
      setAnchorEl(null);
    };

    if (actionComposition === DocCardComposition.Compressed) {
      return (
        <Button
          data-testid="findSimilarPapers"
          size="small"
          color="primary"
          onClick={handleClick}
          startIcon={<Icon style={{ color }} />}
          style={{
            alignSelf: 'start',
            width: '100%',
            alignItems: 'start',
            justifyContent: 'start',
          }}
        >
          {title}
        </Button>
      );
    }
    return (
      <MenuItem key={title} onClick={handleClick}>
        <ListItemIcon>
          <Icon style={{ color }} />
        </ListItemIcon>

        <Typography variant="inherit" noWrap>
          {title}
        </Typography>
      </MenuItem>
    );
  }
);

DocActionItem.displayName = 'DocActionItem';

export default withTrackedAction<DocActionItemProps & WithTrackedActionProps>(
  DocActionItem
);
