import { orderBy } from 'lodash';

import { TaggedDocument } from './TaggedDocument.interface';
import { TaggedDocumentResponse } from 'api/documentsApi/DocumentsApi.types';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export function deserializeTaggedDocs(
  savedDocs: TaggedDocumentResponse[],
  docs: RetrievalUnitData[],
  tagName: string,
  tagId: number
): TaggedDocument[] {
  const sortedSavedDocs = orderBy(
    savedDocs,
    [({ dateTagged }) => dateTagged],
    ['desc']
  );

  const taggedDocuments: TaggedDocument[] = sortedSavedDocs.reduce(
    (acc: TaggedDocument[], { docId }: TaggedDocumentResponse) => {
      const savedData = {
        docId,
        tagName,
        tagId,
      };

      const doc = docs.find(({ organizeDocId }) => organizeDocId === docId);

      if (doc) {
        acc.push({
          retrievalUnitData: doc,
          savedData,
        });
      }

      return acc;
    },
    []
  );

  return taggedDocuments;
}

export const taggedDocsQueryKeys = {
  all: ['taggedDocs'] as const,
  lists: () => [...taggedDocsQueryKeys.all, 'list'] as const,
  list: (
    tagId: number,
    pageSize?: number,
    indexCluster?: string,
    docIds?: string[]
  ) =>
    [
      ...taggedDocsQueryKeys.lists(),
      tagId,
      pageSize,
      indexCluster,
      ...(docIds ? docIds : []),
    ] as const,
};
