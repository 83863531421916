import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';

import { TagSharingPermission } from 'api/tagsApi';
import TagPermissionTooltip from '../TagPermissionTooltip';

export type TagPermissionChipProps = {
  permission: TagSharingPermission;
};

const TagPermissionChip = ({ permission }: TagPermissionChipProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagPermissionTooltip permission={permission}>
      <Chip
        size="small"
        variant="outlined"
        color="secondary"
        label={t<string>(`permissions.${permission}.chipLabel`)}
      />
    </TagPermissionTooltip>
  );
};

export default TagPermissionChip;
