import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(0.5, 0, 0.5),
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    '& > * + *': {
      paddingLeft: theme.spacing(1),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    },
  },
}));

export type DocMetadataProps = {
  children: React.ReactNode;
  cardStyle?: React.CSSProperties;
};

const DocMetadata = ({ children, cardStyle }: DocMetadataProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={cardStyle}>
      {children}
    </div>
  );
};

export default DocMetadata;
