import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box } from '@mui/material';

import FormAutoSave from 'common/components/Form/FormAutosave/FormAutoSave';
import TagsAutocomplete from 'containers/Tagging/TagsAutocomplete';
import { TagsAutocompleteOptionValue } from 'containers/Tagging/TagsAutocomplete/TagsAutocomplete.types';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { SaveDocumentFormValues } from 'containers/SavedDocuments/SavedDocuments.interface';

type DocInlineTagsEditProps = {
  initialValues: SaveDocumentFormValues;
  onUpdateDocumentTags: (tags: TagsAutocompleteOptionValue[]) => Promise<void>;
  onBlur?: () => void;
};

const DocInlineTagsEdit = ({
  initialValues,
  onUpdateDocumentTags,
  onBlur,
  onAction,
}: WithTrackedActionWrappedProps<DocInlineTagsEditProps>) => {
  const handleSubmit = async (
    { selectedTags }: SaveDocumentFormValues,
    { setSubmitting }: FormikHelpers<SaveDocumentFormValues>
  ) => {
    await onUpdateDocumentTags(selectedTags);
    setSubmitting(false);
    onAction();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Box component={Form} sx={{ w: '100%' }} name="documentTagsForm">
          <FormAutoSave debounceMs={300} />

          <TagsAutocomplete
            autoFocus
            name="selectedTags"
            suggestedTags={[]}
            onBlur={onBlur}
            loading={isSubmitting}
          />
        </Box>
      )}
    </Formik>
  );
};

export default withTrackedAction<
  DocInlineTagsEditProps & WithTrackedActionProps
>(DocInlineTagsEdit);
