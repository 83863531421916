import React from 'react';
import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  text?: string;
  isSubmitting?: boolean;
  startIcon?: React.ReactNode;
} & ButtonProps<ButtonTypeMap['defaultComponent'], {}>;

const SubmitButton = ({
  text,
  isSubmitting,
  startIcon,
  onClick,
  onAction,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const { t } = useTranslation('common');
  const btnText = text ?? t('buttons.submit');
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      startIcon={
        !isSubmitting ? (
          startIcon
        ) : (
          <CircularProgress size={18} color="inherit" />
        )
      }
      {...rest}
      onClick={handleClick}
    >
      {btnText}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(SubmitButton);
