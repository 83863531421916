import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { PageSection } from 'common/components/PageSection/PageSection';
import { PageContainer } from '../../common/components/Page/PageContainer/PageContainer';
import { MainToolbar } from '../../containers/MainToolbar';
import { MainSidebar } from '../../containers/MainSidebar';
import { PageMainContent } from '../../common/components/PageWrapper/PageMainContent';
import ContentLoader from '../../common/components/Loaders/ContentLoader/ContentLoader';
import { useStyles } from './useStyles';
import { useInfoPage } from './useInfoPage';

export const InfoPage: FC = () => {
  const classes = useStyles();
  const { title, content } = useInfoPage();

  return (
    <Box display="flex" alignItems="flex-start" sx={{ minHeight: '100vh' }}>
      <MainToolbar />
      <MainSidebar />
      <PageMainContent>
        <PageContainer maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs sm={12} md={8}>
              <PageSection sectionTitle={title}>
                <Typography
                  gutterBottom
                  component="div"
                  className={classes.content}
                >
                  {content ? (
                    <ReactMarkdown>{content}</ReactMarkdown>
                  ) : (
                    <ContentLoader />
                  )}
                </Typography>
              </PageSection>
            </Grid>
          </Grid>
        </PageContainer>
      </PageMainContent>
    </Box>
  );
};
