import React, { useMemo } from 'react';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize * 0.9,
    '& > * + *': {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export type NoteItemMetadataProps = {
  ownerFullName?: string;
  dateModified: string;
};

const NoteItemMetadata = ({
  dateModified,
  ownerFullName,
}: NoteItemMetadataProps) => {
  const classes = useStyles();
  const date = useMemo<string>(() => {
    const d = new Date(dateModified);
    const formatStr = isThisYear(d) ? 'dd MMM HH:mm' : 'dd MMM yyy HH:mm';

    return format(d, formatStr);
  }, [dateModified]);

  return (
    <Typography className={classes.root} component="div">
      {ownerFullName && <b aria-label="note owner">{ownerFullName}</b>}

      <span aria-label="note last modified date">{date}</span>
    </Typography>
  );
};

export default NoteItemMetadata;
