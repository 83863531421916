import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import LoginDialog from 'containers/Auth/LoginDialog';
import { posthogCapture } from 'common/utils/posthog.utils';
import { FeedbackDialogButton } from 'containers/Feedback/FeedbackDialogButton/FeedbackDialogButton';
import { Page400, Page403, Page404 } from './ErrorPages';
import { InnerPages } from './InnerPages';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { AuthContext } from '../containers/Auth/Auth.context';
import { InfoPage } from './InfoPage';

const SignUpPage = React.lazy(() => import('pages/SignUpPage'));
const ResetPasswordPage = React.lazy(() => import('pages/ResetPasswordPage'));
const CreatePasswordPage = React.lazy(() => import('pages/CreatePasswordPage'));

export const Routes = () => {
  const { pathname } = useLocation();
  const { oidcConfig } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    posthogCapture('$pageview');
  }, [pathname]);

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'Main page' }}>
      <Switch>
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/create-password" component={CreatePasswordPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/400" component={Page400} />
        <Route path="/403" component={Page403} />
        <Route path="/404" component={Page404} />
        <Route path="/info/:id" component={InfoPage} />
        <InnerPages />
      </Switch>

      <FeedbackDialogButton />

      {!oidcConfig?.enabled && <LoginDialog />}
    </TrackedActionContext.Provider>
  );
};
