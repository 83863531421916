import React, { useCallback, useState } from 'react';
import { ListSubheader, Menu, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import GitHubIcon from '@mui/icons-material/GitHub';
import StarIcon from '@mui/icons-material/StarRounded';

import DocumentMetadataButton from 'common/components/DocumentMetadataButton/DocumentMetadataButton';
import DocumentMetadataButtonChip from 'common/components/DocumentMetadataButton/DocumentMetadataButtonChip';
import { RetrievalUnitGithubRepo } from '../RetrievalUnitData.interface';
import { RetrievalUnitGithubRepoItem } from './RetrievalUnitGithubRepoItem';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { FeedbackType } from 'containers/Feedback/enums';

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    maxHeight: 400,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  githubIcon: {
    color: theme.palette.github,
  },
}));

export type RetrievalUnitGithubProps = {
  score: number;
  searchId?: string;
  resultType?: ResultType;
  documentId: string;
  repos: RetrievalUnitGithubRepo[];
};

export const RetrievalUnitGithub = ({
  score,
  repos,
  searchId,
  resultType,
  documentId,
}: RetrievalUnitGithubProps) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { sendFeedback } = useSendFeedback();
  const [popover, setPopover] = useState<HTMLButtonElement | null>(null);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setPopover(event.currentTarget);

      if (searchId && resultType) {
        sendFeedback({
          searchId,
          resultType,
          score: 0,
          resultId: documentId,
          feedbackType: FeedbackType.Github,
        });
      }
    },
    [sendFeedback, setPopover, searchId, resultType, documentId]
  );

  const handleGithubRepoClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, repoLink: string) => {
      setPopover(null);

      if (searchId && resultType) {
        sendFeedback({
          searchId,
          resultType,
          score: 0,
          text: `GitHub repo link: ${repoLink}`,
          resultId: documentId,
          feedbackType: FeedbackType.GithubRepoClick,
        });
      }
    },
    [sendFeedback, setPopover, searchId, resultType, documentId]
  );

  const handleClose = () => {
    setPopover(null);
  };

  return (
    <>
      <DocumentMetadataButton
        disabled={repos.length === 0}
        onClick={handleButtonClick}
        startIcon={<GitHubIcon className={classes.githubIcon} />}
        aria-label={t('retrievalUnit.github.button.ariaLabel')}
        tooltip={t<string>('retrievalUnit.github.button.tooltip')}
      >
        <DocumentMetadataButtonChip
          score={score}
          icon={<StarIcon fontSize="small" />}
        />
      </DocumentMetadataButton>

      <Menu
        open={!!popover}
        anchorEl={popover}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={classes.menuList}
        MenuListProps={{
          subheader: (
            <ListSubheader className={classes.subheader}>
              {t('retrievalUnit.github.totalText', {
                totalRepos: repos.length,
              })}
            </ListSubheader>
          ),
        }}
      >
        {repos.map((repo, index) => (
          // Workaround: use index as some repos don't have unique URL
          <RetrievalUnitGithubRepoItem
            key={`${repo.url}_${index}`}
            data={repo}
            onClick={handleGithubRepoClick}
          />
        ))}
      </Menu>
    </>
  );
};
