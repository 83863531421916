import { ComponentsOverrides, Theme } from '@mui/material/styles';

export const outlinedInputOverrides: ComponentsOverrides<Theme>['MuiOutlinedInput'] =
  {
    notchedOutline: {
      borderColor: 'rgba(0, 0, 0, 0.12)',
    },
    root: {
      '&[class*="MuiAutocomplete-inputRoot"] .MuiAutocomplete-input': {
        padding: '4px !important',
      },
    },
    adornedStart: ({ theme }) => ({
      paddingLeft: theme.spacing(0.5),
    }),
  };

export const formControlLabelOverrides: ComponentsOverrides<Theme>['MuiFormControlLabel'] =
  {
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0,
      justifyContent: 'space-between',
    },
  };
