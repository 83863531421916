import { AxiosResponse } from 'axios';

import {
  CreateFollowingTagPayload,
  CreateTagFollowersPayload,
  CreateTagPayload,
  ListTagsPayload,
  TagDetails,
  TagFollowers,
  TagSharingPermission,
  TagSharingPolicies,
  UpdateFavoriteTagPayload,
  UpdateFollowingTagPayload,
  UpdateTagPayload,
} from 'api/tagsApi/tagsApi.types';
import {
  Configuration,
  TagsApi,
  TagType,
} from '@zarn/vendor/dist/saved-results';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  deserializeFavoriteTagItem,
  deserializeFollowingTagItem,
  deserializeGenericTagItem,
  deserializeOwnTagItem,
  deserializeSharedTagItem,
  deserializeTagFollowersItem,
  serializeCreateTagForm,
  serializeFavoriteTagForm,
  serializeTagFollower,
  serializeUpdateFollowingTagForm,
  tagDetailsBackwardCompat,
} from './tagsApi.utils';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { deserializeAxiosError } from 'common/utils/error';
import { captureException } from '@sentry/react';

export const tagsApi = new TagsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const listTags = async (
  payload: ListTagsPayload
): Promise<AxiosResponse<PaginatedResults<TagDetails>>> => {
  const { data, ...rest } = await tagsApi.tagsFilterAll({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });

  return {
    ...rest,
    data: new PaginatedResults<TagDetails>(
      data.count,
      data.results.map(deserializeGenericTagItem),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const listOwnTags = async (
  payload: ListTagsPayload
): Promise<AxiosResponse<PaginatedResults<TagDetails>>> => {
  const { data, ...rest } = await tagsApi.tagsFilter({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });

  return {
    ...rest,
    data: new PaginatedResults<TagDetails>(
      data.count,
      data.results.map((tag) =>
        tagDetailsBackwardCompat().ownTag(deserializeOwnTagItem(tag))
      ),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const listSharedTags = async (
  payload: ListTagsPayload
): Promise<AxiosResponse<PaginatedResults<TagDetails>>> => {
  const { data, ...rest } = await tagsApi.sharedTagsFilter({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });

  return {
    ...rest,
    data: new PaginatedResults<TagDetails>(
      data.count,
      data.results.map((tag) =>
        tagDetailsBackwardCompat().sharedTag(deserializeSharedTagItem(tag))
      ),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const listFollowingTags = async (
  payload: ListTagsPayload
): Promise<AxiosResponse<PaginatedResults<TagDetails>>> => {
  const { data, ...rest } = await tagsApi.followingTagsFilter({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });

  /**
   * HACK As permissions in followingTags are incorrect
   * we need to get them from shared tags
   */
  const tagIds = data.results.map((t) => t.id);

  const {
    data: { items: sharedItems },
  } = await listSharedTags({
    tagIds,
    pageSize: tagIds.length,
    tenant: payload.tenant,
  });

  const sharedTagsMap = sharedItems.reduce<{ [key: number]: TagDetails }>(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  );

  const followingTagItems = data.results.map((tag) => {
    const followingTag = deserializeFollowingTagItem(tag);

    return tagDetailsBackwardCompat().followingTag({
      ...followingTag,
      permission: sharedTagsMap[tag.id]?.permission ?? followingTag.permission,
      canDeleteDoc:
        sharedTagsMap[tag.id]?.canDeleteDoc ?? followingTag.canDeleteDoc,
      canChangeDocOrder:
        sharedTagsMap[tag.id]?.canChangeDocOrder ??
        followingTag.canChangeDocOrder,
    });
  });

  return {
    ...rest,
    data: new PaginatedResults<TagDetails>(
      data.count,
      followingTagItems,
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const getFavoriteTag = async (
  tenant: string
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.favoriteTagRetrieve({
    requesterUuid: '',
    userRoles: '',
    tenant: tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().favoriteTag(
      deserializeFavoriteTagItem(data)
    ),
  };
};

export const updateFavoriteTag = async (
  payload: UpdateFavoriteTagPayload
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.favoriteTagReplace({
    requesterUuid: '',
    userRoles: '',
    favoriteTagForm: serializeFavoriteTagForm(payload),
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().favoriteTag(
      deserializeFavoriteTagItem(data)
    ),
  };
};

export const getOwnTag = async (
  id: number,
  tenant: string
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.tagRetrieve({
    requesterUuid: '',
    userRoles: '',
    id,
    tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().ownTag(deserializeOwnTagItem(data)),
  };
};

export const getSharedTag = async (
  id: number,
  tenant: string
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.sharedTagRetrieve({
    requesterUuid: '',
    userRoles: '',
    id,
    tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().sharedTag(deserializeSharedTagItem(data)),
  };
};

export const getFollowingTag = async (
  id: number,
  tenant: string
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.followedTagRetrieve({
    requesterUuid: '',
    userRoles: '',
    id,
    tenant,
  });

  let sharedPermission: TagSharingPermission;
  let shareSettings: TagSharingPolicies;
  let canDeleteDoc: boolean;
  let canChangeDocOrder: boolean;
  const followingTag = deserializeFollowingTagItem(data);
  try {
    /**
     * HACK
     * As permissions in followingTags are incorrect
     * we need to get them from shared tags
     */
    const res = (await getSharedTag(data.id, tenant)).data;
    sharedPermission = res.permission;
    shareSettings = res.shareSettings;
    canDeleteDoc = res.canDeleteDoc;
    canChangeDocOrder = res.canChangeDocOrder;
  } catch (error) {
    captureException(error);
    const err = deserializeAxiosError(error);

    if (err.status !== 404) {
      throw error;
    }

    sharedPermission = 'none';
    shareSettings = followingTag.shareSettings;
    canDeleteDoc = followingTag.canDeleteDoc;
    canChangeDocOrder = followingTag.canChangeDocOrder;
  }

  return {
    ...rest,
    data: tagDetailsBackwardCompat().followingTag({
      ...followingTag,
      permission: sharedPermission ?? followingTag.permission,
      shareSettings,
      canDeleteDoc,
      canChangeDocOrder,
    }),
  };
};

export const getTag = (
  id: number,
  tenant: string,
  tagType?: TagType
): Promise<AxiosResponse<TagDetails>> => {
  switch (tagType) {
    case TagType.Favourites:
      return getFavoriteTag(tenant);
    case TagType.Shared:
      return getSharedTag(id, tenant);
    case TagType.Following:
      return getFollowingTag(id, tenant);
    default:
      return getOwnTag(id, tenant);
  }
};

export const createTag = async (
  payload: CreateTagPayload
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.tagCreate({
    requesterUuid: '',
    userRoles: '',
    tagForm: serializeCreateTagForm(payload),
    tenant: payload.tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().ownTag(deserializeOwnTagItem(data)),
  };
};

export const deleteTag = (
  id: number,
  tenant: string
): Promise<AxiosResponse<void>> => {
  return tagsApi.tagDelete({ requesterUuid: '', userRoles: '', id, tenant });
};

export const updateTag = async (
  payload: UpdateTagPayload
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.tagReplace({
    requesterUuid: '',
    userRoles: '',
    id: payload.id,
    tagForm: serializeCreateTagForm(payload),
    tenant: payload.tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().ownTag(deserializeOwnTagItem(data)),
  };
};

export const updateFollowingTag = async (
  payload: UpdateFollowingTagPayload
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.followedTagReplace({
    requesterUuid: '',
    userRoles: '',
    id: payload.id,
    followedTagForm: serializeUpdateFollowingTagForm(payload),
    tenant: payload.tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().followingTag(
      deserializeFollowingTagItem(data)
    ),
  };
};

export const createFollowingTag = async (
  payload: CreateFollowingTagPayload
): Promise<AxiosResponse<TagDetails>> => {
  const { data, ...rest } = await tagsApi.followedTagCreate({
    requesterUuid: '',
    userRoles: '',
    id: payload.id,
    followedTagForm: serializeUpdateFollowingTagForm(payload),
    tenant: payload.tenant,
  });

  return {
    ...rest,
    data: tagDetailsBackwardCompat().followingTag(
      deserializeFollowingTagItem(data)
    ),
  };
};

export const deleteFollowingTag = async (
  tagId: number,
  tenant: string
): Promise<AxiosResponse<number>> => {
  const response = await tagsApi.followedTagDelete({
    requesterUuid: '',
    userRoles: '',
    id: tagId,
    tenant,
  });

  return {
    ...response,
    data: tagId,
  };
};

export const createTagFollowers = async ({
  tagId,
  users,
  tenant,
}: CreateTagFollowersPayload): Promise<AxiosResponse<TagFollowers>> => {
  const { data, ...rest } = await tagsApi.tagFollowersCreate({
    requesterUuid: '',
    userRoles: '',
    id: tagId,
    tagFollowersForm: {
      users: users.map(serializeTagFollower),
    },
    tenant,
  });

  return {
    ...rest,
    data: {
      users: data.users.map(deserializeTagFollowersItem),
    },
  };
};
