import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  moreBtnWrapper: {
    display: 'inline-block',
  },
  moreBtn: {
    boxShadow: theme.shadows[5],
  },
}));
