import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
} & NavLinkProps;

const TrackedNavLink = ({
  onClick,
  onAction,
  children,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <NavLink {...rest} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedNavLink
);
