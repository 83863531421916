import React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { useField } from 'formik';

type Props = {
  name: string;
  onValueChange?: (e: React.ChangeEvent<any>, checked: boolean) => void;
} & SwitchProps;

export const FormSwitchControl = ({ name, onValueChange, ...rest }: Props) => {
  const [field] = useField({ name, type: 'checkbox' });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    field.onChange(e);

    if (onValueChange) {
      onValueChange(e, checked);
    }
  };

  return (
    <Switch {...field} {...rest} onChange={handleChange} color="primary" />
  );
};
