import {
  Configuration,
  UserDocumentBatchesApi,
  UserDocumentsBatchForm,
} from '@zarn/vendor/dist/user-documents';
import axiosInstance from 'api/axiosInstance';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import { PrivateDocAccessRoleEnum } from 'common/enums';
import { blobToBase64 } from 'common/utils/fileHelpers';
import { PrivateDocBatchCreatePayload } from './privateDocsApi.types';

export const userDocumentBatchesApi = new UserDocumentBatchesApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const userDocumentBatchCreate = async ({
  tenant,
  batchName,
  files,
  indexId,
}: PrivateDocBatchCreatePayload) => {
  let binary: string = await blobToBase64(files[0]);

  const userDocumentsBatchForm: UserDocumentsBatchForm = {
    name: batchName,
    file_name: files[0].name,
    base64_content: binary,
    access_roles: [PrivateDocAccessRoleEnum.Own],
  };

  return userDocumentBatchesApi.createUserDocumentBatch({
    requesterUuid: '',
    userTenants: '',
    userRoles: '',
    indexId,
    tenant,
    userDocumentsBatchForm,
  });
};

export const userDocumentBatchListDocs = (
  tenant: string,
  documentBatchId: string,
  page?: number,
  pageSize?: number,
  indexId?: string
) => {
  return userDocumentBatchesApi.filterBatchUserDocuments({
    requesterUuid: '',
    userRoles: '',
    userTenants: '',
    tenant,
    indexId,
    documentBatchId,
    page,
    pageSize,
  });
};
