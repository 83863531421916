import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query';

import {
  listFavoriteDocs,
  listTaggedDocs,
} from 'api/documentsApi/documentsApi';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { BaseError } from 'common/models/Error.interface';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { taggedDocsQueryKeys } from '../taggedDocs.utils';
import { ListTaggedDocsPayload } from 'api/documentsApi/DocumentsApi.types';
import { deserializeAxiosError } from 'common/utils/error';
import { listDocuments } from 'api/searchApi';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from 'containers/User/user.slice';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { captureException } from '@sentry/react';

export type UseTaggedDocsReturn = UseInfiniteQueryResult<
  PaginatedResults<RetrievalUnitData>,
  BaseError
>;

export type UseTaggedDocsProps = {
  options?: UseInfiniteQueryOptions<
    PaginatedResults<RetrievalUnitData>,
    BaseError
  >;
} & Omit<ListTaggedDocsPayload, 'tenant'>;

export const useTaggedDocs = ({
  tagType,
  tagId,
  pageSize,
  docIds,
  page = 1,
  options = {},
}: UseTaggedDocsProps): UseTaggedDocsReturn => {
  const { tenant } = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);

  return useInfiniteQuery<PaginatedResults<RetrievalUnitData>, BaseError>(
    taggedDocsQueryKeys.list(
      tagId,
      pageSize,
      indexCluster as string | undefined,
      docIds
    ),
    async ({ pageParam = page }) => {
      try {
        const { data } =
          tagType === TagType.Favourites
            ? await listFavoriteDocs({
                pageSize,
                page: pageParam,
                tenant,
              })
            : await listTaggedDocs({
                tagType,
                tagId,
                pageSize,
                docIds,
                page: pageParam,
                tenant,
              });

        const {
          data: { items },
        } = await listDocuments({
          docIds: data.items.map(({ docId }) => docId),
          propertyName: 'organize_doc_id',
          tenant,
          indexCluster: indexCluster as string | undefined,
        });

        return new PaginatedResults<RetrievalUnitData>(
          data.count,
          items,
          data.nextPage,
          data.prevPage,
          pageParam,
          pageSize
        );
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      ...QUERY_OPTIONS,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.prevPage,
      ...options,
      enabled: options?.enabled && indexCluster !== null,
    }
  );
};
