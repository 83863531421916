import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { postFeedback } from 'api/feedbackApi/feedbackApi';
import {
  FeedbackListQueryKey,
  feedbackQueryKeys,
  serializeFeedbackPayload,
} from 'containers/Feedback/feedback.utils';
import { deserializeAxiosError } from 'common/utils/error';
import {
  FeedbackResultsData,
  SendFeedbackPayload,
} from 'containers/Feedback/Feedback.interface';
import { parseHostname } from 'common/utils/useParsedHostname';

export type UseSendRelevanceFeedbackReturn = {} & UseMutationResult<
  SendFeedbackPayload,
  any,
  SendFeedbackPayload
>;

export const useSendRelevanceFeedback = (): UseSendRelevanceFeedbackReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { tenant } = parseHostname();

  const mutation = useMutation(
    async (payload: SendFeedbackPayload) => {
      await postFeedback(tenant, serializeFeedbackPayload(payload));
      return payload;
    },
    {
      onSuccess({ searchId }: SendFeedbackPayload) {
        queryClient.invalidateQueries<FeedbackResultsData>(
          feedbackQueryKeys.lists(),
          {
            predicate: (query) => {
              const queryKey = query.queryKey as FeedbackListQueryKey;
              const searchIds = (queryKey[2] && queryKey[2].searchIds) ?? [];

              return searchIds.includes(searchId);
            },
          }
        );
      },
      onError(error: any) {
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
};
