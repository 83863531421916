import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';

import { PrivateDocForm } from 'containers/PrivateDocs/PrivateDocForm/PrivateDocForm';
import { PrivateDocFormValues } from 'containers/PrivateDocs/PrivateDocForm/PrivateDocForm.interface';
import { useEditPrivateDocForm } from '../useEditPrivateDocForm';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { usePrivateDocFormValidation } from 'containers/PrivateDocs/PrivateDocForm/usePrivateDocFormValidation';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import { LoaderContainer } from 'common/components/Loaders/LoaderContainer/LoaderContainer';

type EditPrivateDocDialogProps = {
  data: RetrievalUnitData;
  onFormSubmit?: (data: RetrievalUnitData) => void;
} & DialogProps;

export const EditPrivateDocDialog = ({
  onFormSubmit,
  data,
  ...dialogProps
}: EditPrivateDocDialogProps) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const { validationSchema } = usePrivateDocFormValidation();
  const { onSubmit, initialValues, isLoading } = useEditPrivateDocForm({
    onFormSubmit,
    data,
  });

  const handleClose = (e: any) => {
    if (dialogProps.onClose) {
      dialogProps.onClose(e, 'backdropClick');
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      aria-label={t('privateDocs:edit.dialog.ariaLabel')}
    >
      <LoaderContainer loading={isLoading}>
        <>
          <DialogActionTitle onClose={handleClose}>
            {t('privateDocs:edit.dialog.title')}
          </DialogActionTitle>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            onReset={handleClose}
            validationSchema={validationSchema}
          >
            {(helpers: FormikProps<PrivateDocFormValues>) => (
              <Form>
                <DialogContent>
                  <PrivateDocForm {...helpers} />
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement="start"
                      label={t('privateDocs:form.sharing.label')}
                      control={<FormSwitchControl name="shareWithOrg" />}
                    />
                  </FormGroup>
                </DialogContent>

                <DialogActions>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button type="reset" disabled={helpers.isSubmitting}>
                        {t('common:buttons.cancel')}
                      </Button>
                    </Grid>

                    <Grid item>
                      <SubmitButton
                        disabled={helpers.isSubmitting}
                        isSubmitting={helpers.isSubmitting}
                        eventName={TrackEventName.PrivateDocumentEdited}
                        eventProps={{ docId: data.documentId }}
                      />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </>
      </LoaderContainer>
    </Dialog>
  );
};
