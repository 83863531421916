import { MeCommonModel } from './MeCommon.model';
import { OidcAccessTokenPayloadInterface } from '../interfaces/OidcAccessTokenPayload.interface';
import { Me } from './Me.interface';
import { mapUserPermission, validateUserRole } from '../Auth.utils';

export class MeOidcModel extends MeCommonModel implements Me {
  constructor(props: OidcAccessTokenPayloadInterface) {
    super(props);
    this.userRolesData = this.userRoles
      .filter(validateUserRole)
      .map(mapUserPermission);
    this.organization = this.getOrganization();
  }
}
