import React, { FC } from 'react';
import { useContext } from 'react';

import { AuthContext } from '../../Auth/Auth.context';
import { MainToolbarOidcSignInButton } from './MainToolbarOidcSignInButton';
import { MainToolbarCustomSignInButton } from './MainToolbarCustomSignInButton';

export const MainToolbarSignInButton: FC = () => {
  const { oidcConfig } = useContext(AuthContext);

  return oidcConfig?.enabled ? (
    <MainToolbarOidcSignInButton />
  ) : (
    <MainToolbarCustomSignInButton />
  );
};
