import React, { FC, MutableRefObject, useMemo } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  ThemeProvider,
} from '@mui/material';
import {
  HighlightActionItem,
  PDFViewer,
  PDFViewerProps,
  theme,
} from '@zarn/pdf-viewer';
import { usePDFViewerActions } from '../hooks/usePDFViewerActions';
import { useDocPDFAsset } from '../../RetrievalUnit/hooks/useDocPDFAsset';
import { DocAssetTypeEnum } from 'common/enums/DocAssetType.enum';
import { RetrievalUnitData } from '../../RetrievalUnit/RetrievalUnitData.interface';
import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import OrangeGradientOpenAiIcon from 'common/components/CustomIcons/OrangeGradientOpenAiIcon';
import { useTranslation } from 'react-i18next';
import { useChatAvailability } from 'common/hooks/useChatAvailability';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';

export interface LoadingErrorDataProps {
  docId: string;
  docData?: RetrievalUnitData | null;
  highlights: AnnotationHighlight[];
  onHighlight: PDFViewerProps<string>['onHighlightAction'];
  documentRef: MutableRefObject<HTMLDivElement | null>;
}

export const LoadingErrorData: FC<LoadingErrorDataProps> = ({
  docId,
  docData,
  highlights,
  onHighlight,
  documentRef,
}) => {
  const { t } = useTranslation('common');
  const { isChatAvailable } = useChatAvailability();
  const docAsset =
    docData?.publicAsset ||
    docData?.privateAsset ||
    docData?.externalPublicAsset;

  const {
    data: pdfData,
    isLoading: pdfLoading,
    error: pdfError,
  } = useDocPDFAsset({
    docId: docData?.documentId ?? '',
    asset: docAsset ?? {
      assetType: DocAssetTypeEnum.PublicAsset,
      assetValue: '',
    },
    ownerUuid: docData?.ownerUuid,
    options: {
      enabled: !!(docData?.documentId && docAsset),
    },
  });
  const onPDFViewerAction = usePDFViewerActions(docId);

  const customHighlightActions = useMemo<HighlightActionItem<string>[]>(() => {
    if (isChatAvailable) {
      return [
        {
          action: 'explain',
          title: t('pdfViewer.actions.addToChat'),
          icon: () => <OrangeGradientOpenAiIcon />,
          highlightTypes: isFeatureEnabled('ff-multimodal-chat')
            ? ['text', 'image']
            : ['text'],
        },
        {
          action: 'discover',
          title: t('pdfViewer.actions.discover'),
          icon: () => <PlagiarismIcon />,
          highlightTypes: ['text'],
        },
      ];
    }

    return [
      {
        action: 'discover',
        title: t('pdfViewer.actions.search'),
        icon: () => <OrangeGradientOpenAiIcon />,
        highlightTypes: ['text'],
      },
    ];
  }, [isChatAvailable, t]);

  if (pdfLoading) {
    return (
      <Box display="flex" justifyContent="center" m="auto">
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (pdfError) {
    return (
      <Alert severity="error">
        <AlertTitle>{pdfError.status}</AlertTitle>
        {pdfError.message}
      </Alert>
    );
  }

  if (pdfData) {
    return (
      <ThemeProvider theme={theme}>
        <PDFViewer
          content={pdfData.content}
          highlights={highlights}
          documentRef={documentRef}
          style={{ height: '100%' }}
          onHighlightAction={onHighlight}
          onPDFViewerAction={onPDFViewerAction}
          customHighlightActions={customHighlightActions}
        />
      </ThemeProvider>
    );
  }

  return null;
};
