import { useEffect, useState } from 'react';
import { UseInfiniteQueryOptions } from 'react-query';

import { useTaggedDocs } from 'containers/TaggedDocs/hooks/useTaggedDocs';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { BaseError } from 'common/models/Error.interface';

interface UseAllTaggedDocsProps {
  tagId: number;
  tagType: TagType;
  options?: UseInfiniteQueryOptions<
    PaginatedResults<RetrievalUnitData>,
    BaseError
  >;
}

export const useAllTaggedDocs = ({
  tagId,
  tagType,
  options,
}: UseAllTaggedDocsProps) => {
  const [result, setResult] = useState<RetrievalUnitData[]>([]);
  const [loading, setLoading] = useState(false);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useTaggedDocs({
      tagId,
      tagType,
      options,
      pageSize: 100,
    });

  const getAllPageItems = (taggedDocs: any) => {
    let combinedPages: RetrievalUnitData[] = [];
    taggedDocs.pages.forEach((page: any) => {
      combinedPages = combinedPages.concat(page.items);
    });
    return combinedPages;
  };

  useEffect(() => {
    if (options?.enabled) {
      if (isLoading || isFetchingNextPage) {
        setLoading(true);
        return;
      }
      if (data) {
        if (hasNextPage) {
          setLoading(true);
          fetchNextPage();
        } else {
          setResult(getAllPageItems(data));
          setLoading(false);
        }
      }
    }
  }, [data, options?.enabled, isLoading]);

  return {
    data: result,
    isAllTaggedDocsLoading: loading,
  };
};
