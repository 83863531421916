import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@mui/material';

import { useIndeterminateCheckbox } from 'common/hooks/useIndeterminateCheckbox';

export type ShareSelectionNotifyAllProps = {
  allUsersCount: number;
  notifiedUsersCount: number;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const ShareSelectionNotifyAll = React.memo(
  ({
    allUsersCount,
    notifiedUsersCount,
    onChange,
  }: ShareSelectionNotifyAllProps) => {
    const { t } = useTranslation('common');
    const { checked, indeterminate } = useIndeterminateCheckbox(
      notifiedUsersCount === allUsersCount,
      notifiedUsersCount === 0
    );

    return (
      <FormControlLabel
        sx={{ m: 0 }}
        label={t('share.settings.userSelection.notifyAll')}
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChange}
            color="primary"
          />
        }
      />
    );
  }
);

ShareSelectionNotifyAll.displayName = 'ShareSelectionNotifyAll';

export default ShareSelectionNotifyAll;
