import React, { FC } from 'react';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { windowReload } from 'common/utils/dom.utils';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300,
    padding: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& > button': {
      flex: 'auto',
      '& + button': {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

interface Props {
  onClick: () => void;
}

export const ChangelogUpdatesNotification: FC<Props> = ({ onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('common');

  const handleNavigate = async () => {
    onClick();
    history.push('/product/release-notes');
  };

  const handleRefresh = async () => {
    windowReload();
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {t('navBar.releaseNotes.description')}
      </Typography>

      <div className={classes.buttons}>
        <TrackedButton
          variant="contained"
          onClick={handleNavigate}
          eventName={TrackEventName.ReleaseNotesButtonClicked}
        >
          {t('navBar.releaseNotes.linkTitle')}
        </TrackedButton>

        <TrackedButton
          variant="contained"
          color="secondary"
          onClick={handleRefresh}
          eventName={TrackEventName.UpdateRefreshButtonClicked}
        >
          {t('navBar.releaseNotes.update')}
        </TrackedButton>
      </div>
    </div>
  );
};
