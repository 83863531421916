import { useCallback, useEffect, useState } from 'react';
import { AnnotationHighlight } from 'api/notesApi/notesApi.types';

export const useVirtualHighlights = (highlights: AnnotationHighlight[]) => {
  const [virtualHighlights, setVirtualHighlights] = useState(highlights);

  useEffect(() => {
    setVirtualHighlights(highlights);
  }, [highlights]);

  const addVirtualHighlight = useCallback(
    (temporaryHighlights: AnnotationHighlight[]) => {
      setVirtualHighlights([...highlights, ...temporaryHighlights]);

      setTimeout(() => {
        setVirtualHighlights(highlights);
      }, 3500);
    },
    [highlights]
  );
  return { virtualHighlights, addVirtualHighlight };
};
