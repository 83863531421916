import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Chip } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

import TagMetadataItem from '../TagMetadataItem/TagMetadataItem';

export type TagMetadataPapersProps = {
  numberOfPapers: number;
};

const TagMetadataPapers = ({ numberOfPapers }: TagMetadataPapersProps) => {
  const { t } = useTranslation('tags');

  return (
    <TagMetadataItem
      icon={DescriptionIcon}
      translation={
        <Trans t={t} i18nKey="tagMetadata.numberOfPapers.text">
          <Chip size="small" label={numberOfPapers} />
        </Trans>
      }
    />
  );
};

TagMetadataPapers.displayName = 'TagMetadataPapers';

export default TagMetadataPapers;
