import { Configuration, QueryApi } from '@zarn/vendor/dist/query-logging';

import {
  serializeExplanationSystemResultsItem,
  serializeQAKSystemResultsItem,
  serializeQASystemResultsItem,
  serializeQueryLoggingPayload,
  serializeSystemResultsItem,
} from './queryLoggingApi.utils';
import {
  ExplanationQueryLoggingResult,
  QAKQueryLoggingResult,
  QAQueryLoggingResult,
  QueryLoggingResult,
} from './queryLoggingApi.types';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';
import axiosInstance from 'api/axiosInstance';

export const QueryLoggingApi = new QueryApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const postQuery = (
  searchPayload: SearchPayload,
  results: QueryLoggingResult[]
) => {
  const queryForm = serializeQueryLoggingPayload(searchPayload, results);

  return QueryLoggingApi.querylogPost({
    tenant: searchPayload.tenant,
    queryForm,
  });
};

export const updateQuery = (
  searchId: string,
  result: QueryLoggingResult,
  tenant: string
) => {
  const results = serializeSystemResultsItem(result);
  return QueryLoggingApi.querylogPut({
    requesterUuid: '',
    tenant: tenant,
    searchId,
    results,
  });
};

export const updateQAQuery = (
  searchId: string,
  result: QAQueryLoggingResult,
  tenant: string
) => {
  const results = serializeQASystemResultsItem(result);

  return QueryLoggingApi.querylogPut({
    requesterUuid: '',
    tenant: tenant,
    searchId,
    results,
  });
};

export const updateQAKQuery = (
  searchId: string,
  result: QAKQueryLoggingResult,
  tenant: string
) => {
  const results = serializeQAKSystemResultsItem(result);

  return QueryLoggingApi.querylogPut({
    requesterUuid: '',
    tenant: tenant,
    searchId,
    results,
  });
};

export const updateExplanationQuery = (
  searchId: string,
  result: ExplanationQueryLoggingResult,
  tenant: string
) => {
  const results = serializeExplanationSystemResultsItem(result);

  return QueryLoggingApi.querylogPut({
    requesterUuid: '',
    tenant: tenant,
    searchId,
    results,
  });
};
