import { useCallback, useReducer } from 'react';
import { AddPrivateDocsMethodEnum } from '../AddPrivateDocsMethod.enum';

export interface AddPrivateDocsDialogState {
  method: AddPrivateDocsMethodEnum;
  open: boolean;
}

type AddPrivateDocsDialogAction =
  | { type: 'open'; payload: AddPrivateDocsMethodEnum }
  | { type: 'close' };

const reducer = (
  state: AddPrivateDocsDialogState,
  action: AddPrivateDocsDialogAction
): AddPrivateDocsDialogState => {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        open: true,
        method: action.payload,
      };
    case 'close':
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export const intitialState: AddPrivateDocsDialogState = {
  open: false,
  method: AddPrivateDocsMethodEnum.SinglePaper,
};

export const useAddPrivateDocsDialog = () => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, intitialState);

  const handleClose = useCallback(() => {
    dispatch({ type: 'close' });
  }, [dispatch]);

  const handleOpen = useCallback(
    (method: AddPrivateDocsMethodEnum) => {
      dispatch({ type: 'open', payload: method });
    },
    [dispatch]
  );

  return {
    state,
    dispatch,
    onClose: handleClose,
    onOpen: handleOpen,
  };
};
