/* eslint-disable prettier/prettier */
import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { getTagsSorting, updateTagsSorting } from 'api/savedResultsApi';
import { listSharedTags } from 'api/tagsApi/tagsApi';
import { RootState } from 'app/state/store';
import { deserializeAxiosError } from 'common/utils/error';
import { TagsStateData } from '../tags.types';
import { DEFAULT_SHARED_TAGS_SORTING, TAG_SORT_ORDER_BY_SORT_BY } from '../TagsSort/tagsSort.const';
import { deserializeTagsSortSettings, serializeTagsSortSettings, sortTagItems } from '../TagsSort/tagsSort.utils';
import { TagsSortByEnum } from '../TagsSort/TagsSortBy.enum';
import { captureException } from '@sentry/react';

export const getSharedTagsThunk: AsyncThunkPayloadCreator<
  TagsStateData,
  string
> = async (tenant) => {
  try {
    const [{ data: sortData }, { data: tags }] = await Promise.all([
      getTagsSorting(),
      listSharedTags({ pageSize: 10000, tenant: tenant }),
    ]);

    const sortSettings = deserializeTagsSortSettings(
      sortData.following,
      DEFAULT_SHARED_TAGS_SORTING
    );

    return {
      sortSettings,
      items: sortTagItems(tags.items, sortSettings),
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateSharedTagsSortSettingsThunk: AsyncThunkPayloadCreator<
  TagsStateData,
  TagsSortByEnum,
  { state: RootState }
> = async (sortBy: TagsSortByEnum, { getState }) => {
  try {
    const sortSettings = {
      sortBy,
      sortOrder: TAG_SORT_ORDER_BY_SORT_BY[sortBy],
    };
    await updateTagsSorting({
      following: serializeTagsSortSettings(sortSettings),
    });

    return {
      sortSettings,
      items: sortTagItems(getState().tags.shared.data.items, sortSettings),
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};
