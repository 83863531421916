import { useCallback, useReducer } from 'react';

import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { postFeedback } from 'api/feedbackApi/feedbackApi';
import { serializeFeedbackPayload } from 'containers/Feedback/feedback.utils';
import { deserializeAxiosError } from 'common/utils/error';
import { SendFeedbackPayload } from 'containers/Feedback/Feedback.interface';
import { parseHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';

type SendFeedbackState = {
  fetchState: FiniteStatesType;
  error: string | null;
};

type SendFeedbackAction =
  | { type: 'success' }
  | { type: 'failure'; payload: string };

export const sendFeedbackInitialState: SendFeedbackState = {
  error: null,
  fetchState: FiniteStates.Idle,
};

const sendFeedbackReducer = (
  state: SendFeedbackState,
  action: SendFeedbackAction
): SendFeedbackState => {
  switch (action.type) {
    case 'success':
      return {
        error: null,
        fetchState: FiniteStates.Success,
      };
    case 'failure':
      return {
        ...state,
        fetchState: FiniteStates.Failure,
        error: action.payload,
      };
    default:
      throw new Error();
  }
};

export type UseSendFeedbackReturn = {
  state: SendFeedbackState;
  sendFeedback: (payload: SendFeedbackPayload) => Promise<void>;
};

export const useSendFeedback = (): UseSendFeedbackReturn => {
  const [state, dispatch] = useReducer(
    sendFeedbackReducer,
    sendFeedbackInitialState
  );
  const { tenant } = parseHostname();

  const sendFeedback = useCallback(
    async (payload: SendFeedbackPayload) => {
      try {
        await postFeedback(tenant, serializeFeedbackPayload(payload));
        dispatch({ type: 'success' });
      } catch (error) {
        captureException(error);
        dispatch({
          type: 'failure',
          payload: deserializeAxiosError(error).message,
        });
      }
    },
    [tenant]
  );

  return {
    state,
    sendFeedback,
  };
};
