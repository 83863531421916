import { combineReducers } from 'redux';

import own from 'containers/Tags/ownTagsSlice/ownTags.slice';
import shared from 'containers/Tags/sharedTagsSlice/sharedTags.slice';
import following from 'containers/Tags/followingTagsSlice/followingTags.slice';

export default combineReducers({
  own,
  shared,
  following,
});
