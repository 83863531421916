import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import { Footer } from '../Footer/Footer';
import { useTheme } from '@mui/material/styles';
import { AppTheme } from 'app/theme';

const useStyles = makeStyles<AppTheme>((theme) => ({
  main: {
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    overflow: 'hidden',
    background: theme.palette.mainContent.background,
    marginTop: '64px',
  },
}));

type Props = {
  children: React.ReactNode;
};

export const PageMainContent = ({ children }: Props) => {
  const theme = useTheme<AppTheme>();
  const classes = useStyles({ theme });

  return (
    <main className={classes.main}>
      {children}

      <Footer />
    </main>
  );
};
