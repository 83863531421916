import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { PRIMARY } from 'app/theme';

const useStyles = makeStyles(() => ({
  root: {
    width: 50,
    height: 50,
    animation: `$root 2000ms infinite ease-in-out`,
  },
  svgPath: {
    fill: PRIMARY,
    stroke: PRIMARY,
    transformOrigin: 'center',
  },
  arrow: {
    animation: `$arrow 2000ms infinite ease-in-out`,
  },
  zeta: {
    animation: `$zeta 2000ms infinite ease-in-out`,
  },
  alpha: {
    animation: `$alpha 2000ms infinite ease-in-out`,
  },
  '@keyframes zeta': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '30%, 75%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
  },
  '@keyframes alpha': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '35%, 80%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
  },
  '@keyframes arrow': {
    '0%, 30%': {
      transform: 'translateX(-30%) scaleX(0)',
      opacity: 0,
    },
    '50%, 80%': {
      transform: 'translateX(0) scaleX(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'translateX(100%)',
      opacity: 0,
    },
  },
  '@keyframes root': {
    '0%': {
      opacity: 0,
    },
    '25%, 75%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

export type AnimatedLogoProps = {};

const AnimatedLogo = (_: AnimatedLogoProps) => {
  const classes = useStyles();

  return (
    <svg
      focusable="false"
      x="0px"
      y="0px"
      viewBox="0 0 240 240"
      xmlSpace="preserve"
      className={classes.root}
    >
      <path
        id="alpha"
        className={clsx(classes.svgPath, classes.alpha)}
        d="M111.9,135.3c0-11.3,4.3-21.4,12.8-29.9s18-12.8,28.2-12.8c7,0,12.6,2,16.7,6c4.2,4,7.1,8.9,8.5,14.6
	c0.6,2.9,1,6.2,1,10v4.4c4.9-7.4,8.6-15.6,11.2-24.8c0.4-1.4,0.7-2.2,0.9-2.4c0.2-0.2,0.5-0.3,1.2-0.3c0.8-0.1,1.6-0.2,2.4-0.1
	c1.3,0.1,2,0.5,2,1.1c0,0.9-0.4,2.9-1.3,5.8c-0.9,3-2.7,7.2-5.4,12.7s-5.8,10.5-9.2,14.9l-1.7,2.1l0.2,3.6c0.2,5.6,0.6,9.3,1.2,11.3
	c0.6,2,1.6,3.1,2.9,3.1c1.4-0.2,2.7-0.8,3.9-1.7s2-2,2.5-3.4c0.2-0.7,0.4-1.1,0.8-1.2c0.3-0.1,0.7-0.2,1.4-0.3
	c0.6-0.1,1.2-0.1,1.9,0c1.3,0.1,1.9,0.5,1.9,1.1s-0.3,1.6-0.9,2.9c-1.2,2.4-3,4.3-5.2,5.7s-4.4,2.2-6.5,2.3h-1
	c-6.7,0-11.2-3.6-13.6-10.7l-0.4-1l-1.7,1.2c-1,0.8-2.5,1.8-4.4,3c-2,1.2-4.3,2.4-6.7,3.4s-5.4,2.1-8.6,3c-3.3,0.9-6.4,1.3-9.6,1.3
	c-7.8,0-14-2.5-18.6-7.5C114.2,147.8,111.9,142,111.9,135.3z M137.9,154.7c4.9,0,9.8-1,14.6-3.1s8.5-4.3,10.9-6.4l3.6-3.3
	c-0.2-14.2-0.5-23.1-1-26.6c-1.4-11.4-5.6-17.3-13.8-17.2c-6.9,0.2-12.9,3.7-18,10.9c-2.7,4-4.9,9.3-6.5,16.1
	c-1.7,6.8-2.6,11.9-2.7,15.5c0,4.9,1.2,8.5,3.7,10.6C131.2,153.4,134.2,154.6,137.9,154.7z"
      />
      <path
        id="zeta"
        className={clsx(classes.svgPath, classes.zeta)}
        d="M82.9,75.2l1.3-0.9C86,76.1,88.6,77,92,77h2.1c8.1-0.2,12.3-2.2,12.7-6c0-3.3-3.8-5-11.5-5h-1.5
	l-1.5,0.2c-0.9,0-1.9,0.1-3,0.3c-1.1,0.2-1.7,0.4-1.9,0.6c-0.2,0-0.3,0.1-0.4,0.2c0-0.2-0.1-0.5-0.2-1c-0.1-0.5-0.2-1.2-0.2-2.3
	c0-1.3,0.2-2.9,0.4-4.6c0.3-1.8,0.4-3,0.4-3.4c0-1.7-0.9-2.5-2.7-2.5c-2.6,0-4,3.1-4.2,9.2c0,3,0.3,5.4,0.9,7.1l-1.3,0.8
	c-9.5,5.7-18,14.6-25.7,26.9S43,122,43,134.7c0.2,5.8,1.2,10.7,3.1,14.6c1.9,3.9,4.4,6.8,7.6,8.6c3.2,1.9,6.5,3.6,10,5
	s6.8,2.5,10,3.3s5.8,2,7.8,3.6s3,3.7,3,6.3c0,2.1-0.6,3.8-1.8,5.2s-2.5,2.2-4,2.4c-3.6-0.1-6.7-0.9-9.2-2.3
	c-2.6-1.4-4.1-2.1-4.4-2.1c-1.1,0-2,0.4-2.7,1.3c-0.6,1.1-0.6,2-0.2,2.9c0.4,0.5,1.5,1.2,3.1,2.3c1.7,1,3.3,1.7,4.8,2.1
	c2.2,0.8,4.6,1.2,7.3,1.2c1.8,0,3.1-0.1,3.9-0.3c3.9-1.3,7.1-3.7,9.6-7.1c2.5-3.5,3.7-7.1,3.7-11.1c0-6.3-2.7-10.7-8-13.4
	c-2.5-1.7-5.8-3.3-10.3-4.4c-15.6-3.5-21-11.5-22.6-18.4c-0.1-1.3-0.2-2.7-0.2-4.2c0-2.7,0.3-5.4,0.7-8.2l0,0l0,0
	c1.2-7.4,4-15,8.4-22.8C68.6,88.4,75.3,80.5,82.9,75.2z"
      />
      <path
        id="arrow"
        className={clsx(classes.svgPath, classes.arrow)}
        d="M129.9,62.7h49.3c-2.4-3.7-3.6-6.7-3.6-8.8c0-0.8,0.3-1.5,0.8-2.1s1.2-0.9,2.3-0.9
	c1,0,1.7,0.4,2.3,1.2c0.5,0.8,0.9,1.7,1.2,2.9c0.3,1.1,1,2.4,1.9,4c1,1.6,2.4,3,4,4c1.1,0.7,1.7,1.6,1.7,2.7c0,0.9-0.3,1.7-1,2.3
	s-1.6,1.1-2.5,1.5c-1,0.3-2.5,1.3-4.4,2.9c-2,1.5-3.9,3.5-5.7,6c-1.4,1.5-2.5,2.3-3.3,2.3s-1.5-0.3-2.1-0.9s-0.9-1.3-0.9-2.1
	c0-0.8,0.5-1.9,1.7-3.4c1.1-1.5,2.3-2.7,3.4-3.7c1.2-1,1.8-1.6,1.9-1.7h-25.5l-21.3-0.2c-1.6,0-2.9-1.3-3-2.9l0,0
	C126.8,64.1,128.2,62.7,129.9,62.7z"
      />
      <path
        id="frame"
        className={classes.svgPath}
        d="M0,0v240h240V0H0z M232,232H8V8h224V232z"
      />
    </svg>
  );
};

AnimatedLogo.displayName = 'AnimatedLogo';

export default AnimatedLogo;
