import React, { FC, useMemo } from 'react';
import { Alert, AlertColor, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';

import { UserDocumentStatus } from '@zarn/vendor/dist/user-documents';
import { RetrievalUnitStatus } from '../RetrievalUnitData.interface';
import { AlertProps } from '@mui/material/Alert/Alert';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.25, 1),
    fontSize: theme.typography.body2.fontSize,
    marginBottom: theme.spacing(1),
  },
}));

interface Props extends AlertProps {
  status: RetrievalUnitStatus;
}

const DocStatus: FC<Props> = ({ status, ...props }) => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const classes = useStyles();
  const mapping = useMemo<{
    severity: AlertColor;
    statusText: string;
    codeDescription: string[];
    errorResolution: string;
  }>(() => {
    const codeDescription = status.codes.map((code) =>
      t(`privateDocs:status.codes.${code}`)
    );

    switch (status.title) {
      case UserDocumentStatus.Pending:
        return {
          codeDescription,
          severity: 'info',
          statusText: t('common:retrievalUnit.status.pending'),
          errorResolution: status.message,
        };
      case UserDocumentStatus.Error:
        return {
          codeDescription,
          severity: 'error',
          statusText:
            codeDescription.length > 0
              ? t('common:retrievalUnit.status.error')
              : t('common:retrievalUnit.status.unknownError'),
          errorResolution:
            status.message === ''
              ? t('privateDocs:errorResolution.text')
              : status.message,
        };
      default:
        return {
          codeDescription,
          severity: 'success',
          statusText: t('common:retrievalUnit.status.success'),
          errorResolution: status.message,
        };
    }
  }, [t, status]);

  if (status.title === UserDocumentStatus.Success) {
    return null;
  }

  return (
    <Alert
      severity={mapping.severity}
      className={classes.root}
      data-testid="retrievalUnitExplanation"
      {...props}
    >
      <div>{mapping.statusText}</div>

      {mapping.codeDescription.length > 0 && (
        <ul>
          {mapping.codeDescription.map((d) => (
            <li key={d}>{d}</li>
          ))}
        </ul>
      )}

      {mapping.errorResolution}
    </Alert>
  );
};

DocStatus.displayName = 'DocStatus';

export default DocStatus;
