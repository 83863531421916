import { Configuration, UsersApi } from '@zarn/vendor/dist/auth';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  serializeRegistrationData,
  serializeUserUpdateData,
} from './usersApi.utils';
import { axiosResponseMock } from 'test-helpers/test.utils';
import { CreateUserPayload } from './usersApi.types';

export const usersApi = new UsersApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const createUser = (values: CreateUserPayload, tenant: string) => {
  return usersApi.createUser({
    requesterUuid: '',
    userRoles: '',
    registrationData: serializeRegistrationData(values),
    tenant,
  });
};

export const updateUser = (
  userUuid: string,
  values: CreateUserPayload,
  tenant: string
) => {
  return usersApi.updateUser({
    requesterUuid: '',
    userRoles: '',
    userUuid,
    userUpdateData: serializeUserUpdateData(values),
    tenant,
  });
};

export const deleteUser = (userId: string, tenant: string) => {
  return new Promise((r) =>
    r({ ...axiosResponseMock, userId, tenant, status: 204 })
  );
};
