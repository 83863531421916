import { getAccessTokenFromStore } from 'common/utils/store';
import { useEffect, useState } from 'react';
import { isJWTValid } from 'common/utils/jwt-tokens';

const getAuthStatusChange = (
  prevAccessToken: string | null,
  accessToken: string | null
) => {
  const a = isJWTValid(accessToken);
  const b = isJWTValid(prevAccessToken);
  return a && !b ? 'login' : !a && b ? 'logout' : undefined;
};

export const useAuthStatusChange = () => {
  const [prevAccessToken, setPrevAccessToken] = useState<string | null>(null);
  const accessToken = getAccessTokenFromStore();
  const [authStatusChange, setAuthStatusChange] = useState<
    'login' | 'logout' | undefined
  >(undefined);

  useEffect(() => {
    if (prevAccessToken === accessToken) {
      return;
    }
    const nextAuthStatusChange = getAuthStatusChange(
      prevAccessToken,
      accessToken
    );
    if (nextAuthStatusChange !== authStatusChange) {
      setAuthStatusChange(nextAuthStatusChange);
    }
    setPrevAccessToken(accessToken);
  }, [accessToken, authStatusChange, prevAccessToken]);

  return { authStatusChange };
};
