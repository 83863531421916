import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';
import { ShareSelectionOptionValue } from './ShareSelection.interface';
import ScrollList from 'common/components/List/ScrollList/ScrollList';
import ShareSelectedOption from '../ShareSelectedOption/ShareSelectedOption';
import ShareSelectionNotifyAll from './ShareSelectionNotifyAll/ShareSelectionNotifyAll';
import { useShareSelectionOptions } from './hooks/useShareSelectionOptions';
import ShareSelectionOptionContent from './ShareSelectionOptionContent/ShareSelectionOptionContent';
import { TextField } from '../../../TextField';

export interface ShareSelectionProps {
  fieldName: string;
  userRoleId: number;
  accountName: string;
  organizationInitials: string;
  users: OrganizationMemberData[];
}

const ShareSelection = React.memo(
  ({
    fieldName,
    users,
    userRoleId,
    accountName,
    organizationInitials,
  }: ShareSelectionProps) => {
    const { t } = useTranslation('common');

    const [field, , fieldHelpers] =
      useField<ShareSelectionOptionValue[]>(fieldName);
    const clearSelectionText = t<string>(
      'share.shareSettings.userSelection.clearButton'
    );

    const options = useShareSelectionOptions({
      userRoleId,
      accountName,
      initials: organizationInitials,
      selected: field.value,
      users,
    });

    const notifiedUsers = useMemo<ShareSelectionOptionValue[]>(
      () => field.value.filter(({ notify }) => notify),
      [field.value]
    );

    const handleChange = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target;

        if (String(value) === String(userRoleId)) {
          void fieldHelpers.setValue([...options, ...field.value]);
          return;
        }

        const selectedUser = options.find(({ id }) => id === value);

        if (selectedUser) {
          void fieldHelpers.setValue([selectedUser, ...field.value]);
        }
      },
      [userRoleId, options, fieldHelpers, field.value]
    );

    const handleNotifyAllChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        void fieldHelpers.setValue(
          field.value.map((selectedUser) => ({
            ...selectedUser,
            notify: checked,
          }))
        );
      },
      [fieldHelpers, field.value]
    );

    const handleClearSelection = () => {
      void fieldHelpers.setValue([]);
    };

    return (
      <>
        <TextField
          select
          fullWidth
          onChange={handleChange}
          inputProps={{ role: 'textbox' }}
          value={''}
          label={
            options.length
              ? t('share.addUserGroupSelect.label')
              : t('share.addUserGroupSelect.allSelectedLabel')
          }
          disabled={!options.length}
          helperText={t('share.addUserGroupSelect.helperText')}
          SelectProps={{
            MenuProps: {
              style: { maxHeight: 400 },
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <ShareSelectionOptionContent {...option} />
            </MenuItem>
          ))}
        </TextField>

        <FieldArray
          name={fieldName}
          render={(arrayHelpers: FieldArrayRenderProps) =>
            field.value.length > 0 && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ShareSelectionNotifyAll
                    allUsersCount={field.value.length}
                    notifiedUsersCount={notifiedUsers.length}
                    onChange={handleNotifyAllChange}
                  />

                  <Tooltip placement="left" title={clearSelectionText}>
                    <IconButton
                      size="small"
                      onClick={handleClearSelection}
                      aria-label={clearSelectionText}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <ScrollList>
                  {field.value.map(
                    (value: ShareSelectionOptionValue, index) => {
                      const isUser = value.type === 'user';
                      const initials = value.initials ?? value.name?.[0] ?? '';
                      const secondary = isUser
                        ? value.email
                        : t('share.shareSettings.userSelection.options.team');

                      return (
                        <ShareSelectedOption
                          key={value.id}
                          primary={value.name}
                          onDelete={() => arrayHelpers.remove(index)}
                          hideNotifyCheckbox={!isUser}
                          notifyFieldName={`${fieldName}[${index}].notify`}
                          permissionFieldName={`${fieldName}[${index}].permission`}
                          secondary={secondary}
                          initials={initials}
                        />
                      );
                    }
                  )}
                </ScrollList>
              </>
            )
          }
        />
      </>
    );
  }
);

ShareSelection.displayName = 'ShareSelection';

export default ShareSelection;
