import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import ButtonLink from 'common/components/Buttons/ButtonLink';
import { TweetDocument } from '../types';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export const useStyles = makeStyles(() => ({
  userName: {
    verticalAlign: 'top',
  },
}));

type Props = {
  tweet: TweetDocument;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>, link: string) => void;
};

export const RetrievalUnitTweetsListItem = React.memo(
  ({ tweet, onClick }: Props) => {
    const classes = useStyles();

    const handleClickUserName = (e: React.MouseEvent<any, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      window.open(tweet.userProfileUrl, '_blank');
    };

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      onClick(e, tweet.url);
    };

    return (
      <ListItem
        button
        href={tweet.url}
        target="_blank"
        component="a"
        onClick={handleClick}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <StyledAvatar alt={tweet.name} src={tweet.userProfilePictureUrl} />
        </ListItemAvatar>
        <ListItemText
          secondary={
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                <ButtonLink
                  aria-label="open twitter handle"
                  className={classes.userName}
                  onClick={handleClickUserName}
                  eventName={TrackEventName.TwitterHandleClicked}
                  eventProps={{ twitterUserName: tweet.userName }}
                >
                  {tweet.userName}:
                </ButtonLink>
                {tweet.text}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  }
);
