import { Configuration, TenantSettingsApi } from '@zarn/vendor/dist/search';
import { TenantSettings } from './tenantSettingsApi.types';
import AxiosInstance from '../axiosInstance';
import { AxiosResponse } from 'axios';
import { BASE_HEADERS, SERVICE_URL } from 'api/apiConfig';
import { deserializeTenantSettings } from './tenantSettingsApi.utils';

const tenantSettingsApi = new TenantSettingsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const retrieveTenantSettings = async (
  tenant: string,
  indexCluster: string | null
): Promise<AxiosResponse<TenantSettings>> => {
  const { data, ...response } = await tenantSettingsApi.retrieveTenantSettings({
    tenant,
  });

  return { ...response, data: deserializeTenantSettings(data, indexCluster) };
};
