import React, { useEffect } from 'react';
import { Collapse, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { PageDrawerSectionItem } from './PageDrawerSectionItem';

const useStyles = makeStyles((theme: Theme) => ({
  collapse: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  sectionTitle: string;
  children: React.ReactNode;
  sectionPath?: string;
  defaultExpanded?: boolean;
  expanded?: boolean;
  subheaderActions?: React.ReactElement;
  icon?: React.ReactNode;
  onValueChange?: () => void;
};

export const PageDrawerMenu = ({
  sectionTitle,
  defaultExpanded,
  expanded,
  children,
  subheaderActions,
  onValueChange,
  icon,
  sectionPath,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(!!defaultExpanded);

  useEffect(() => {
    if (typeof expanded !== 'undefined') {
      setOpen(expanded);
    }
  }, [expanded]);

  const handleToggle = () => {
    setOpen(!open);

    if (onValueChange) {
      onValueChange();
    }
  };

  return (
    <>
      <PageDrawerSectionItem
        onToggle={handleToggle}
        text={sectionTitle}
        expanded={open}
        icon={icon}
        actions={subheaderActions}
        sectionPath={sectionPath}
      />

      <Collapse mountOnEnter in={open} className={classes.collapse}>
        {children}
      </Collapse>
    </>
  );
};
