export enum SearchTypeEnum {
  Term = 'term',
  Query = 'query',
  Default = 'default',
}

export type SearchType =
  | SearchTypeEnum.Default
  | SearchTypeEnum.Query
  | SearchTypeEnum.Term;
