// TODO test
import {
  deserializeResourcePermission,
  deserializeSharingPolicy,
  serializeSharingPolicy,
} from 'containers/Sharing/sharing.utils';
import {
  DefaultUserOrder,
  FavoriteTagForm,
  FavoriteTagItem,
  FollowedTagForm,
  FollowedTagItem,
  GenericTagItem,
  SharedTagItem,
  TagFollowersItem,
  TagForm,
  TagItem,
  TagType,
} from '@zarn/vendor/dist/saved-results';
import {
  TagFollower,
  CreateTagPayload,
  DefaultTagOrder,
  FavoriteTagDetails,
  FollowingTagDetails,
  OwnTagDetails,
  SharedTagDetails,
  TagDetails,
  TagOwner,
  TagTypeParam,
  UpdateFollowingTagPayload,
  UpdateFavoriteTagPayload,
  UpdateTagPayload,
} from './tagsApi.types';

export const serializeTagFollower = ({
  id,
  notify,
}: TagFollower): TagFollowersItem => ({
  user_uuid: id,
  notify,
});

export const tagTypeParamToTagType = (tagType: TagTypeParam): TagType => {
  switch (tagType) {
    case 'favorites':
      return TagType.Favourites;
    case 'shared':
      return TagType.Shared;
    case 'following':
      return TagType.Following;
    case 'own':
    default:
      return TagType.Own;
  }
};

export const tagTypeToTagTypeParam = (tagType: TagType): TagTypeParam => {
  switch (tagType) {
    case TagType.Favourites:
      return 'favorites';
    case TagType.Shared:
      return 'shared';
    case TagType.Following:
      return 'following';
    case TagType.Own:
    default:
      return 'own';
  }
};

export const serializeTagOrder = (
  order: number | DefaultTagOrder
): number | DefaultUserOrder => {
  switch (order) {
    case 'last':
      return DefaultUserOrder.Last;
    case 'first':
      return DefaultUserOrder.First;
    default:
      return order;
  }
};

export const tagDetailsToTagUpdatePayload = (
  tag: TagDetails,
  tenant: string
): UpdateTagPayload => ({
  id: tag.id,
  description: tag.description ?? null,
  name: tag.name,
  color: tag.color ?? null,
  settings: {
    newsletterSchedule: tag.newsletterScheduleDate ?? null,
    recommendationsEnabled: !tag.disableRecommendations,
  },
  sharing: tag.shareSettings,
  userOrder: tag.userOrder ?? 'last',
  tenant: tenant,
});

export const deserializeTagFollowersItem = ({
  notify,
  user_uuid,
}: TagFollowersItem): TagFollower => ({
  notify,
  id: user_uuid,
});

export const deserializeGenericTagItem = ({
  settings,
  stats,
  sharing,
  ...tag
}: GenericTagItem): TagDetails => ({
  id: tag.id,
  name: tag.name ?? 'Favorite',
  color: tag.color ?? undefined,
  type: tag.tag_type,
  dateCreated: tag.date_created,
  dateModified: tag.date_modified ?? tag.date_created,
  dateFollowing: tag.date_following,
  userOrder: tag.user_order ?? null,
  content: {
    numberOfDocuments: stats.documents_count,
  },
  numberOfFollowers: stats.followers_count,
  shareSettings: sharing
    ? deserializeSharingPolicy(sharing)
    : { orgs: [], users: [] },
  newDocuments: stats.new_documents_count ?? 0,
  newTagNotes: stats.new_notes_count ?? 0,
  disableRecommendations: settings?.recommendations_enabled,
  permission: tag.permission && deserializeResourcePermission(tag.permission),
  owner: tag.owner
    ? {
        id: tag.owner,
        fullName: null,
        organizationName: null,
      }
    : undefined,
  canDeleteDoc: !!tag.delete_docs_url,
  canChangeDocOrder: !!tag.add_docs_url,
});

export const deserializeOwnTagItem = ({
  settings,
  stats,
  sharing,
  ...tag
}: TagItem): OwnTagDetails => ({
  id: tag.id,
  name: tag.name,
  color: tag.color ?? undefined,
  type: 'own',
  ownerId: tag.owner,
  description: tag.description,
  dateCreated: tag.date_created,
  dateModified: tag.date_modified ?? tag.date_created,
  userOrder: tag.user_order,
  sharing: deserializeSharingPolicy(sharing),
  stats: {
    documentsCount: stats.documents_count,
    notesCount: stats.notes_count,
    followersCount: stats.followers_count,
  },
  settings: {
    newsletterSchedule: settings.newsletter_schedule,
    recommendationsEnabled: settings.recommendations_enabled,
  },
  permission: deserializeResourcePermission(tag.permission),
  canDeleteDoc: !!tag.delete_docs_url,
  canChangeDocOrder: !!tag.add_docs_url,
});

export const deserializeSharedTagItem = ({
  stats,
  ...tag
}: SharedTagItem): SharedTagDetails => ({
  id: tag.id,
  name: tag.name,
  color: tag.color ?? undefined,
  type: 'shared',
  ownerId: tag.owner,
  description: tag.description,
  dateCreated: tag.date_created,
  dateModified: tag.date_modified ?? tag.date_created,
  permission: deserializeResourcePermission(tag.permission),
  stats: {
    documentsCount: stats.documents_count,
    notesCount: stats.notes_count,
    followersCount: stats.followers_count,
  },
  sharing: deserializeSharingPolicy(tag.sharing),
  canDeleteDoc: !!tag.delete_docs_url,
  canChangeDocOrder: !!tag.add_docs_url,
});

export const deserializeFollowingTagItem = ({
  stats,
  ...tag
}: FollowedTagItem): FollowingTagDetails => ({
  id: tag.id,
  name: tag.name,
  color: tag.color ?? undefined,
  type: 'following',
  ownerId: tag.owner,
  description: tag.description,
  dateCreated: tag.date_created,
  dateFollowing: tag.date_following,
  lastSeen: tag.last_seen,
  dateModified: tag.date_modified ?? tag.date_created,
  userOrder: tag.user_order,
  stats: {
    documentsCount: stats.documents_count,
    notesCount: stats.notes_count,
    followersCount: stats.followers_count,
    newDocumentsCount: stats.new_documents_count,
    newNotesCount: stats.new_notes_count,
  },
  permission: deserializeResourcePermission(tag.permission),
  canDeleteDoc: !!tag.delete_docs_url,
  canChangeDocOrder: !!tag.add_docs_url,
  shareSettings: {
    orgs: [],
    users: [],
  },
});

export const deserializeFavoriteTagItem = ({
  stats,
  settings,
  ...tag
}: FavoriteTagItem): FavoriteTagDetails => ({
  id: tag.id,
  name: 'Favorite',
  type: 'favorites',
  dateCreated: tag.date_created,
  dateModified: tag.date_modified ?? tag.date_created,
  stats: {
    documentsCount: stats.documents_count,
    notesCount: stats.notes_count,
  },
  settings: {
    recommendationsEnabled: settings.recommendations_enabled,
  },
  permission: deserializeResourcePermission(tag.permission),
  canDeleteDoc: !!tag.delete_docs_url,
  canChangeDocOrder: !!tag.add_docs_url,
});

export const tagDetailsBackwardCompat = () => {
  const tagOwnerBackwardCompat = (ownerId: string): TagOwner => ({
    id: ownerId,
    fullName: null,
    organizationName: null,
  });

  return {
    ownTag: (tag: OwnTagDetails): TagDetails => ({
      id: tag.id,
      name: tag.name,
      color: tag.color ?? undefined,
      type: tagTypeParamToTagType(tag.type),
      newDocuments: 0,
      newTagNotes: 0,
      dateCreated: tag.dateCreated,
      dateModified: tag.dateModified,
      userOrder: tag.userOrder,
      numberOfFollowers: tag.stats.followersCount,
      newsletterScheduleDate: tag.settings.newsletterSchedule ?? undefined,
      disableRecommendations: !tag.settings.recommendationsEnabled,
      owner: tagOwnerBackwardCompat(tag.ownerId),
      content: { numberOfDocuments: tag.stats.documentsCount },
      shareSettings: tag.sharing,
      description: tag.description ?? undefined,
      permission: tag.permission,
      canDeleteDoc: tag.canDeleteDoc,
      canChangeDocOrder: tag.canChangeDocOrder,
    }),
    favoriteTag: (tag: FavoriteTagDetails): TagDetails => ({
      id: tag.id,
      name: tag.name,
      color: tag.color ?? undefined,
      type: tagTypeParamToTagType(tag.type),
      newDocuments: 0,
      newTagNotes: 0,
      dateCreated: tag.dateCreated,
      dateModified: tag.dateModified,
      userOrder: null,
      disableRecommendations: !tag.settings.recommendationsEnabled,
      content: { numberOfDocuments: tag.stats.documentsCount },
      shareSettings: {
        orgs: [],
        users: [],
      },
      permission: tag.permission,
      canDeleteDoc: tag.canDeleteDoc,
      canChangeDocOrder: tag.canDeleteDoc,
    }),
    followingTag: (tag: FollowingTagDetails): TagDetails => ({
      id: tag.id,
      name: tag.name,
      color: tag.color ?? undefined,
      type: tagTypeParamToTagType(tag.type),
      newDocuments: tag.stats.newDocumentsCount,
      newTagNotes: tag.stats.newNotesCount,
      dateCreated: tag.dateCreated,
      dateModified: tag.dateModified,
      dateFollowing: tag.dateFollowing,
      lastSeen: tag.lastSeen,
      owner: tagOwnerBackwardCompat(tag.ownerId),
      numberOfFollowers: tag.stats.followersCount,
      userOrder: tag.userOrder,
      content: { numberOfDocuments: tag.stats.documentsCount },
      shareSettings: tag.shareSettings,
      description: tag.description ?? undefined,
      permission: tag.permission,
      canDeleteDoc: tag.canDeleteDoc,
      canChangeDocOrder: tag.canChangeDocOrder,
    }),
    sharedTag: (tag: SharedTagDetails): TagDetails => ({
      id: tag.id,
      name: tag.name,
      color: tag.color ?? undefined,
      type: tagTypeParamToTagType(tag.type),
      newDocuments: 0,
      newTagNotes: 0,
      dateCreated: tag.dateCreated,
      dateModified: tag.dateModified,
      owner: tagOwnerBackwardCompat(tag.ownerId),
      numberOfFollowers: tag.stats.followersCount,
      userOrder: null,
      content: { numberOfDocuments: tag.stats.documentsCount },
      shareSettings: tag.sharing,
      description: tag.description ?? undefined,
      permission: tag.permission,
      canDeleteDoc: tag.canDeleteDoc,
      canChangeDocOrder: tag.canChangeDocOrder,
    }),
  };
};

export const serializeCreateTagForm = ({
  settings,
  sharing,
  userOrder,
  ...payload
}: CreateTagPayload): TagForm => ({
  name: payload.name,
  description: payload.description,
  color: payload.color,
  settings: {
    newsletter_schedule: settings.newsletterSchedule,
    recommendations_enabled: settings.recommendationsEnabled,
  },
  sharing: serializeSharingPolicy(sharing),
  user_order: serializeTagOrder(userOrder),
});

export const serializeUpdateFollowingTagForm = ({
  lastSeen,
  userOrder,
}: UpdateFollowingTagPayload): FollowedTagForm => ({
  last_seen: lastSeen,
  user_order: serializeTagOrder(userOrder),
});

export const serializeFavoriteTagForm = ({
  settings,
}: UpdateFavoriteTagPayload): FavoriteTagForm => ({
  settings: {
    recommendations_enabled: settings.recommendationsEnabled,
  },
});
