import React, { memo } from 'react';
import {
  Button,
  ButtonProps,
  ButtonTypeMap,
  Theme,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

const useStyles = makeStyles((theme: Theme) => ({
  sizeSmall: {
    minWidth: 'auto',
    marginRight: 0,
  },
  iconSizeSmall: {
    margin: theme.spacing(0, 0.5, 0, -0.25),
  },
}));

export type DocumentMetadataButtonProps = {
  tooltip?: string;
} & ButtonProps<ButtonTypeMap['defaultComponent'], {}>;

const DocumentMetadataButton = memo(
  ({
    children,
    tooltip,
    onAction,
    onClick,
    ...rest
  }: WithTrackedActionWrappedProps<DocumentMetadataButtonProps>) => {
    const classes = useStyles();

    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      onAction();
      if (!onClick) return;

      onClick(event);
    };

    if (!tooltip) {
      return (
        <Button
          {...rest}
          onClick={handleClick}
          size="small"
          classes={{ ...classes }}
        >
          {children}
        </Button>
      );
    }

    return (
      <Tooltip title={tooltip} placement="top">
        <span>
          <Button
            {...rest}
            onClick={handleClick}
            size="small"
            classes={{ ...classes }}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  }
);

DocumentMetadataButton.displayName = 'DocumentMetadataButton';

export default withTrackedAction<
  DocumentMetadataButtonProps & WithTrackedActionProps
>(DocumentMetadataButton);
