import { InvitationForm } from '@zarn/vendor/dist/auth';
import { UserInvitationPayload } from './invitationsApi.types';

export const serializeUserInvitationForm = (
  data: UserInvitationPayload
): InvitationForm => {
  return {
    first_name: data.firstName ?? null,
    email: data.email,
  };
};
