import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import {
  createTag,
  createTagFollowers,
  deleteTag,
  getFavoriteTag,
  listOwnTags,
  updateFavoriteTag,
  updateTag,
} from 'api/tagsApi/tagsApi';
import { RootState } from 'app/state/store';
import {
  TagDetails,
  TagOrderUpdatePayload,
  TagSharingPolicies,
  UpdateTagPayload,
} from 'api/tagsApi/tagsApi.types';
import { OwnTagsStateData, TagsStateData } from '../tags.types';
import { deserializeAxiosError } from 'common/utils/error';
import { getTagsSorting, updateTagsSorting } from 'api/savedResultsApi';
import {
  deserializeTagsSortSettings,
  serializeTagsSortSettings,
  sortTagItems,
} from '../TagsSort/tagsSort.utils';
import {
  DEFAULT_OWN_TAGS_SORTING,
  TAG_SORT_ORDER_BY_SORT_BY,
} from '../TagsSort/tagsSort.const';
import { arrayMove } from 'common/utils/arrayHelpers';
import { TagsSortByEnum } from '../TagsSort/TagsSortBy.enum';
import { captureException } from '@sentry/react';

export type TagUpdatePayload = {
  id: number;
  name?: string;
  shareSettings?: TagSharingPolicies;
  userOrder?: number;
  color?: string;
  description?: string;
  disableRecommendations?: boolean;
  newsletterScheduleDate?: string;
};

const tagUpdatePayloadBackwardCompat = (
  payload: TagUpdatePayload,
  tag: TagDetails,
  tenant: string
): UpdateTagPayload => {
  return {
    id: payload.id,
    name: payload.name ?? tag.name,
    description: payload.description ?? tag.description ?? null,
    color: payload.color ?? tag.color ?? null,
    userOrder: payload.userOrder ?? tag.userOrder ?? 'last',
    sharing: payload.shareSettings ?? tag.shareSettings,
    settings: {
      newsletterSchedule:
        payload.newsletterScheduleDate ?? tag.newsletterScheduleDate ?? null,
      recommendationsEnabled: !(
        payload.disableRecommendations ?? tag.disableRecommendations
      ),
    },
    tenant: tenant,
  };
};

export const updateTagThunk: AsyncThunkPayloadCreator<
  { index: number; data: TagDetails },
  { payload: TagUpdatePayload; tenant: string },
  { state: RootState }
> = async ({ payload, tenant }, { getState }) => {
  try {
    const {
      tags: {
        own: { data: ownTags },
      },
    } = getState();

    const index = ownTags.items.findIndex(({ id }) => id === payload.id);
    const tag = ownTags.items[index];

    const { data: updatedTag } = await updateTag(
      tagUpdatePayloadBackwardCompat(payload, tag, tenant)
    );

    if (payload.shareSettings?.users.length) {
      await createTagFollowers({
        tagId: payload.id,
        users: payload.shareSettings?.users,
        tenant: tenant,
      });
    }

    return { index, data: updatedTag };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export interface AddTagArgs {
  name: string;
  shareSettings?: TagSharingPolicies;
}

export const addTagThunk: AsyncThunkPayloadCreator<
  TagDetails,
  { payload: AddTagArgs; tenant: string },
  { state: RootState }
> = async ({ payload, tenant }: { payload: AddTagArgs; tenant: string }) => {
  try {
    const { data } = await createTag({
      name: payload.name,
      description: null,
      color: null,
      settings: {
        newsletterSchedule: null,
        recommendationsEnabled: true,
      },
      sharing: {
        orgs: [],
        users: [],
      },
      userOrder: 'last',
      tenant: tenant,
    });

    return data;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const addMultipleTagsThunk: AsyncThunkPayloadCreator<
  TagDetails[],
  { payload: string[]; tenant: string },
  { state: RootState }
> = async ({ payload, tenant }: { payload: string[]; tenant: string }) => {
  let tagResponses: TagDetails[] = [];

  for (const tagName of payload) {
    try {
      const { data } = await createTag({
        name: tagName,
        description: null,
        color: null,
        settings: {
          newsletterSchedule: null,
          recommendationsEnabled: true,
        },
        sharing: {
          orgs: [],
          users: [],
        },
        userOrder: 'last',
        tenant: tenant,
      });

      tagResponses = [...tagResponses, data];
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }

  return tagResponses;
};

export const getOwnTagsThunk: AsyncThunkPayloadCreator<
  OwnTagsStateData,
  string
> = async (tenant) => {
  try {
    const [{ data: sortData }, { data: tags }, { data: favouriteTag }] =
      await Promise.all([
        getTagsSorting(),
        listOwnTags({ pageSize: 1000, tenant: tenant }),
        getFavoriteTag(tenant),
      ]);

    const sortSettings = deserializeTagsSortSettings(
      sortData.own,
      DEFAULT_OWN_TAGS_SORTING
    );

    return {
      sortSettings,
      favouriteTag,
      items: sortTagItems(tags.items, sortSettings),
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export interface UpdateFavouriteTagArgs {
  id: number;
  disableRecommendations?: boolean;
}

export const updateFavouriteTagThunk: AsyncThunkPayloadCreator<
  TagDetails,
  { payload: UpdateFavouriteTagArgs; tenant: string },
  { state: RootState }
> = async ({
  payload,
  tenant,
}: {
  payload: UpdateFavouriteTagArgs;
  tenant: string;
}) => {
  try {
    const { data } = await updateFavoriteTag({
      id: payload.id,
      settings: {
        recommendationsEnabled: !payload.disableRecommendations,
      },
      tenant: tenant,
    });
    return data;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateOwnTagOrderThunk: AsyncThunkPayloadCreator<
  TagDetails[],
  TagOrderUpdatePayload,
  { state: RootState }
> = async ({ id, oldIndex, newIndex, tenant }, { getState }) => {
  try {
    const {
      tags: {
        own: {
          data: { items: ownTags },
        },
      },
    } = getState();

    const tag = ownTags.find((t) => t.id === id) as TagDetails;

    await updateTag(
      tagUpdatePayloadBackwardCompat(
        { id, userOrder: newIndex + 1 },
        tag,
        tenant
      )
    );

    return arrayMove(ownTags, oldIndex, newIndex).map((tagItem, index) => ({
      ...tagItem,
      userOrder: index + 1,
    }));
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateOwnTagsSortSettingsThunk: AsyncThunkPayloadCreator<
  TagsStateData,
  TagsSortByEnum,
  { state: RootState }
> = async (sortBy: TagsSortByEnum, { getState }) => {
  try {
    const sortSettings = {
      sortBy,
      sortOrder: TAG_SORT_ORDER_BY_SORT_BY[sortBy],
    };
    await updateTagsSorting({
      own: serializeTagsSortSettings(sortSettings),
    });

    return {
      sortSettings,
      items: sortTagItems(getState().tags.own.data.items, sortSettings),
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const deleteTagThunk: AsyncThunkPayloadCreator<
  number,
  { tagId: number; tenant: string },
  { state: RootState }
> = async (
  { tagId, tenant }: { tagId: number; tenant: string },
  { rejectWithValue }
) => {
  try {
    await deleteTag(tagId, tenant);
    return tagId;
  } catch (err) {
    return rejectWithValue(deserializeAxiosError(err));
  }
};
