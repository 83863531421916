import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import { TagsListContext } from './TagsList.context';
import { TagsListType } from './TagsListType.enum';
import { selectHasFollowingTags } from '../followingTagsSlice/followingTags.slice';
import { useStyles } from './styles';

export const TagsListAnchors = () => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const hasFollowingTags = useSelector(selectHasFollowingTags);
  const { anchorsRefs, searchVisibility, toggleExpandedListState } =
    useContext(TagsListContext);

  const handleAnchorClick = useCallback(
    async (
      listRef: React.MutableRefObject<HTMLDivElement | null>,
      listType: TagsListType
    ): Promise<void> => {
      const { current: wrapper } = anchorsRefs.wrapper;
      const { current: list } = listRef;

      await toggleExpandedListState(listType);

      if (list && wrapper) {
        setTimeout(() => {
          wrapper.scrollTo({
            top: list.offsetTop - wrapper.offsetTop,
            behavior: 'smooth',
          });
        }, 300);
      }
    },
    [anchorsRefs.wrapper, toggleExpandedListState]
  );

  const anchors = useMemo(
    () => [
      {
        title: t('myTags.anchorText'),
        handleClick: async () =>
          handleAnchorClick(anchorsRefs.own, TagsListType.Own),
        icon: <PersonIcon />,
      },
      ...(hasFollowingTags
        ? [
            {
              title: t('followingTags.anchorText'),
              handleClick: async () =>
                handleAnchorClick(
                  anchorsRefs.following,
                  TagsListType.Following
                ),
              icon: <PersonPinIcon />,
            },
          ]
        : []),
      {
        title: t('sharedTags.anchorText'),
        handleClick: async () =>
          handleAnchorClick(anchorsRefs.shared, TagsListType.Shared),
        icon: <Diversity3Icon />,
      },
    ],
    [
      anchorsRefs.following,
      anchorsRefs.own,
      anchorsRefs.shared,
      handleAnchorClick,
      hasFollowingTags,
      t,
    ]
  );

  return (
    <div
      className={clsx(
        classes.anchorsWrapper,
        searchVisibility && classes.anchorsWrapperHidden
      )}
    >
      {anchors.map(({ title, handleClick, icon }) => (
        <Tooltip title={title} key={title}>
          <IconButton onClick={handleClick}>{icon}</IconButton>
        </Tooltip>
      ))}
    </div>
  );
};
