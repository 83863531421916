import { AxiosResponse } from 'axios';
import { chunk } from 'lodash';
import {
  Configuration,
  DocumentsApi,
  ListResponse,
} from '@zarn/vendor/dist/search';
import { serializeSearchPostPayload } from 'containers/Search/search.utils';
import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { PaginatedResults } from '../models/PaginatedResults';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { deserializeSearchHit } from 'common/utils/documents';
import { mapSearchResults } from 'containers/SearchResults/searchResults.utils';
import { ListDocumentsPayload } from './searchApi.types';
import { sortListDocHitsByPropertyName } from './searchApi.utils';
import { SearchResults } from 'containers/SearchResults/SearchResults.interface';

export const documentsSearchApi = new DocumentsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const searchDocuments = async (
  payload: SearchPayload
): Promise<AxiosResponse<SearchResults>> => {
  const request = serializeSearchPostPayload(payload);
  const response = await documentsSearchApi.documentSearchPost(request);

  return {
    ...response,
    data: mapSearchResults(response.data),
  };
};

export const listDocuments = async ({
  docIds,
  propertyName = 'id',
  tenant,
  indexCluster,
}: ListDocumentsPayload): Promise<
  AxiosResponse<PaginatedResults<RetrievalUnitData>>
> => {
  const PAGE_SIZE = docIds.length;
  const PAGE = 1;
  let response: AxiosResponse<ListResponse, any> | null = null;

  if (docIds.length > 0) {
    const chunkedIds = chunk(docIds, 100);
    for (const ids of chunkedIds) {
      const docs = await documentsSearchApi.documentList({
        retrievalUnit: 'document',
        propertyName,
        propertyValues: ids,
        tenant,
        indexCluster,
        pageSize: 100,
        page: PAGE,
      });

      if (!response) {
        response = docs;
      } else {
        response.data.hits.push(...docs.data.hits);
      }
    }
  }

  if (response) {
    const { data, ...rest } = response;
    const sortedHits = sortListDocHitsByPropertyName(
      data.hits,
      docIds,
      propertyName
      // Dirty hack
    );
    return {
      ...rest,
      data: new PaginatedResults(
        data.total_hits,
        sortedHits.map(deserializeSearchHit),
        data.next && data.page ? data.page + 1 : undefined,
        data.previous && data.page ? data.page - 1 : undefined,
        data.page ?? PAGE,
        PAGE_SIZE
      ),
    };
  } else {
    return {
      status: 200,
      statusText: '',
      headers: {},
      config: {},
      data: new PaginatedResults(0, [], undefined, undefined, PAGE, PAGE_SIZE),
    };
  }
};
