import { TagType } from '@zarn/vendor/dist/saved-results';
import { NotesPayload } from './tagNotes.types';

export const tagNotesQueryKeys = {
  all: ['notes'] as const,
  lists: () => [...tagNotesQueryKeys.all, 'list'] as const,
  list: (notesPayload: NotesPayload) =>
    [...tagNotesQueryKeys.lists(), notesPayload] as const,
};

export const toTagTypeParams = (
  tagType: TagType
): 'own' | 'shared' | 'following' | 'favorites' => {
  switch (tagType) {
    case TagType.Following:
      return 'following';
    case TagType.Shared:
      return 'shared';
    case TagType.Favourites:
      return 'favorites';
    case TagType.Own:
      return 'own';
  }
};
