import { useEffect, useCallback } from 'react';

import { useAuth } from 'containers/Auth/hooks/useAuth';
import { FiniteStates } from 'app/state/finiteStates.enum';
import {
  savedResultsSettingsGet,
  savedResultsSettingsUpdate,
} from 'api/savedResultsApi';
import { SimpleState, useSimpleState } from 'common/hooks/useSimpleState';
import { SavedResultsSettings } from './SavedResultsSettings.interface';

type SavedResultsSettingsState = SimpleState<SavedResultsSettings>;

type SavedResultsSettingsReturnValue = {
  state: SavedResultsSettingsState;
  resetFetchState: () => void;
  handleUpdate: (settings: SavedResultsSettings) => Promise<void>;
};

export const savedResultsSettingsInitialState: SavedResultsSettingsState = {
  error: null,
  fetchState: FiniteStates.Idle,
  data: {
    notesShareableDefault: false,
    notesDefaultSharingRole: {
      orgs: [],
      users: [],
    },
  },
};

export const useSavedResultsSettings = (): SavedResultsSettingsReturnValue => {
  const { me } = useAuth();
  const { state, setFailure, setLoading, resetFetchState, setValue } =
    useSimpleState<SavedResultsSettings>(savedResultsSettingsInitialState);

  const handleUpdate = useCallback(
    async (settings: SavedResultsSettings) => {
      try {
        await savedResultsSettingsUpdate({
          notes_shareable_default: settings.notesShareableDefault,
        });
        setValue(settings, FiniteStates.Updated);
      } catch (e) {
        setFailure(e);
      }
    },
    [setFailure, setValue]
  );

  useEffect(() => {
    let isSubscribed = true;
    setLoading();

    (async function makeSearch() {
      try {
        const { data } = await savedResultsSettingsGet();

        if (isSubscribed) {
          setValue({
            notesShareableDefault: data.notes_shareable_default,
            notesDefaultSharingRole:
              data.notes_shareable_default && me && me.organization
                ? {
                    orgs: [
                      {
                        id: me.organization.userRoleId,
                        permission: 'read',
                      },
                    ],
                    users: [],
                  }
                : { orgs: [], users: [] },
          });
        }
      } catch (e) {
        if (isSubscribed) setFailure(e);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [setLoading, setFailure, setValue, me]);

  return { state, resetFetchState, handleUpdate };
};
