import { RectBase } from '@zarn/pdf-viewer';
import { Dimensions } from '../interfaces/rectangle.interfaces';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';

export const matchRectangleSize = (size: number, scaleFactor: number) =>
  Math.round(size * scaleFactor);

export const matchRectangleSizes = (
  rect: ChunkHighlight,
  pageDimensions: Dimensions,
  zoomLevel: number
): RectBase => {
  // The highlights in the PDFViewer take the zoom level into account, c.f.
  // packages/pdf-viewer/src/components/Highlights/HighlightRectItem/HighlightRectItem.tsx
  // This function scales the rectangle coordinates to match the current rendered
  // page dimensions and discounts the zoom level applied to the highlights.
  const widthScaleFactor = pageDimensions.width / zoomLevel / rect.pageWidth;
  const heightScaleFactor = pageDimensions.height / zoomLevel / rect.pageHeight;

  return {
    ...rect,
    x1: matchRectangleSize(rect.x1, widthScaleFactor),
    x2: matchRectangleSize(rect.x2, widthScaleFactor),
    y1: matchRectangleSize(rect.y1, heightScaleFactor),
    y2: matchRectangleSize(rect.y2, heightScaleFactor),
    width: matchRectangleSize(rect.width, widthScaleFactor),
    height: matchRectangleSize(rect.height, heightScaleFactor),
  };
};
