import React, { useState } from 'react';
import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { errorChipStyles } from 'app/theme/styles/chip';
import NotificationLinkItem from './NotificationLinkItem';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    ...errorChipStyles(theme),
    marginLeft: theme.spacing(1),
  },
}));

type SubItem = {
  id: string | number;
  label: string;
  notifications: number;
  to: string;
};

type WithExpandableItemsProps = {
  text: string;
  chipLabel?: React.ReactText;
  onClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  subItems: SubItem[];
};

const NotificationLinkItemExpandable = ({
  subItems,
  icon: Icon,
  text,
  chipLabel,
  onClick,
}: WithExpandableItemsProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button selected={open} onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>

        <ListItemText primary={text} />

        <Chip size="small" className={classes.chip} label={chipLabel} />

        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map((item) => (
            <NotificationLinkItem
              nested
              key={item.id}
              onClick={onClick}
              to={item.to}
              text={item.label}
              chipLabel={item.notifications}
              icon={SupervisedUserCircleRoundedIcon}
              eventName={TrackEventName.TagUpdateNotificationClicked}
              eventProps={{ tagId: item.id }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NotificationLinkItemExpandable;
