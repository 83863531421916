import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../Buttons/SubmitButton';
import { WithTrackedActionProps } from '../TrackedActions/withTrackedAction';

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  additionalConfirmation?: boolean;
  isLoading?: boolean;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
} & DialogProps &
  WithTrackedActionProps;

export const ExtendedConfirmationDialog = ({
  setOpen,
  open,
  onSubmit,
  children,
  isLoading,
  additionalConfirmation,
  eventName,
  eventProps,
  ...dialogProps
}: Props) => {
  const { t } = useTranslation('common');
  const [confirm, setConfirm] = useState<boolean>(false);

  const toggleChecked = () => {
    setConfirm(!confirm);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={handleClose}
      data-testid="confirmationDialog"
      {...dialogProps}
    >
      <DialogContent>
        <DialogContentText component="div">{children}</DialogContentText>

        {additionalConfirmation && (
          <FormControl fullWidth>
            <FormControlLabel
              control={<Switch checked={confirm} onChange={toggleChecked} />}
              labelPlacement="start"
              label={t('confirmationDialog.confirmActionLabel')}
            />
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{t('buttons.cancel')}</Button>

        <SubmitButton
          autoFocus
          text={t('buttons.confirm')}
          isSubmitting={isLoading}
          onClick={onSubmit}
          disabled={(additionalConfirmation && !confirm) || isLoading}
          eventName={eventName}
          eventProps={eventProps}
        />
      </DialogActions>
    </Dialog>
  );
};
