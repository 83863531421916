import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSearchByTerm } from 'common/hooks/useSearchByTerm';
import { SearchTermEnum } from 'common/enums';
import { RetrievalUnitAuthor } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useHiddenItems } from 'common/hooks/useHiddenItems';
import ButtonLink from '../Buttons/ButtonLink';
import { TrackEventName } from '../TrackedActions/withTrackedAction.interface';

type Props = {
  authors: RetrievalUnitAuthor[];
};

export const AuthorsList = ({ authors }: Props) => {
  const { t } = useTranslation('common');
  const { searchByTerm } = useSearchByTerm();
  const {
    visibleItems,
    hasHiddenItems,
    isHidden,
    hiddenItemsNumber,
    toggleVisibility,
  } = useHiddenItems(authors);
  const last = visibleItems.length - 1;
  const penultimate = authors.length - 2;

  return (
    <Typography
      variant="body2"
      component="div"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {visibleItems.map(({ fullName, id }, i) => (
        <React.Fragment key={`${fullName}${id}`}>
          {id ? (
            <ButtonLink
              underline="hover"
              aria-label={`get all papers by ${fullName}`}
              onClick={() => searchByTerm(SearchTermEnum.AuthoredBy, id)}
              eventName={TrackEventName.AuthorNameClicked}
              eventProps={{ ontologyId: id }}
              sx={{ userSelect: 'initial' }}
            >
              {fullName}
            </ButtonLink>
          ) : (
            <Typography variant="body2">{fullName}</Typography>
          )}

          {last > i &&
            (penultimate === i ? (
              <Box mx={0.5} data-testid="author link amp">
                &
              </Box>
            ) : (
              <Box mr={0.5} data-testid="author link comma">
                ,
              </Box>
            ))}
        </React.Fragment>
      ))}
      {hasHiddenItems && (
        <>
          {isHidden && (
            <Box ml={0.5} data-testid="amp before toggle">
              &
            </Box>
          )}
          <Box ml={0.5} display="inherit">
            <ButtonLink
              underline="hover"
              aria-label="toggle authors list"
              onClick={toggleVisibility}
              sx={{ fontWeight: 'bold' }}
              eventName={TrackEventName.SeeMoreAuthorsClicked}
            >
              {isHidden
                ? t('authorsList.hidden', { hiddenItemsNumber })
                : t('authorsList.shown')}
            </ButtonLink>
          </Box>
        </>
      )}
    </Typography>
  );
};
