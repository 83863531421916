import { useChatAvailability } from 'common/hooks/useChatAvailability';

import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { HomePageContext } from 'pages/HomePage/HomePageContextProvider';
import { useContext } from 'react';
import { useRetrievalUnitFromNote } from './useRetrievalUnitFromNote';

interface Props {
  onOpenChatClick?: () => void;
  noteId: number;
  noteContent: string;
}

export const useOpenQAChat = ({
  onOpenChatClick,
  noteId,
  noteContent,
}: Props) => {
  const navigation = useNavigationWithState();
  const { isChatAvailable } = useChatAvailability();

  const openChatNote = useLoggedInFeature<boolean>(() => {
    navigation.push(`/?tab=chat&noteId=${noteId}`);
  });

  const homeContext = useContext(HomePageContext);
  const { getRetrievalUnitFromNote } = useRetrievalUnitFromNote(noteContent);

  const openQAChat = async () => {
    onOpenChatClick?.();

    if (!isChatAvailable) {
      return;
    }
    const retrievalUnit = await getRetrievalUnitFromNote();
    if (retrievalUnit) {
      homeContext?.setRetrievalUnit(retrievalUnit);
    }

    openChatNote(true);
  };

  return { openQAChat };
};
