import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { useMemo } from 'react';
import { useParsedHostname } from './useParsedHostname';

export const useIndexCluster = () => {
  const { tenant } = useParsedHostname();

  const indexCluster = useMemo(() => {
    const fromUrl = new SearchPayloadFromURL(window.location.search, {
      tenant,
    }).getSearchPayload().indexCluster;
    if (fromUrl) {
      return fromUrl;
    }
    const fromLs = localStorage.getItem('indexCluster');
    if (fromLs) {
      return fromLs;
    }
    return null;
  }, [tenant]);

  return { indexCluster };
};
