import React, { createContext, FC, ReactNode, useState } from 'react';
import { ChatResponse } from 'api/chatApi/chatApi.types';
import { RetrievalUnitEnum } from 'common/enums';

export interface HomePageContextValue {
  bestAnswerQuestion: string | null;
  setBestAnswerQuestion: (question: string | null) => void;
  bestAnswerAnswer: string | null;
  setBestAnswerAnswer: (answer: string | null) => void;
  chatResponse: ChatResponse | null;
  setChatResponse: (chatResponse: ChatResponse | null) => void;
  retrievalUnit: RetrievalUnitEnum | null;
  setRetrievalUnit: (retrievalUnit: RetrievalUnitEnum | null) => void;
}

export const HomePageContext = createContext<HomePageContextValue | null>(null);

export const HomePageContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [bestAnswerQuestion, setBestAnswerQuestion] = useState<string | null>(
    null
  );
  const [bestAnswerAnswer, setBestAnswerAnswer] = useState<string | null>(null);
  const [chatResponse, setChatResponse] = useState<ChatResponse | null>(null);
  const [retrievalUnit, setRetrievalUnit] = useState<RetrievalUnitEnum | null>(
    null
  );

  return (
    <HomePageContext.Provider
      value={{
        bestAnswerQuestion,
        setBestAnswerQuestion,
        bestAnswerAnswer,
        setBestAnswerAnswer,
        chatResponse,
        setChatResponse,
        retrievalUnit,
        setRetrievalUnit,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};
