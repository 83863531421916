import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { TagsAutocompleteOptionValue } from './TagsAutocomplete.types';

export const normalizeInputValue = (inputValue: string) => inputValue.trim();

export const deserializeTagsAutocompleteOption = (
  tag: TagDetails
): TagsAutocompleteOptionValue => ({
  id: tag.id,
  name: tag.name,
  color: tag.color,
  type: tag.type,
  permission: tag.permission,
  ownerId: tag.owner?.id,
});
