import React from 'react';
import { Box } from '@mui/material';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { MainSidebar } from 'containers/MainSidebar';
import { MainToolbar } from 'containers/MainToolbar';
import { PrivateRoute } from './PrivateRoute';
import { RoleEnum } from 'containers/Auth/enums';
import { PageMainContent } from 'common/components/PageWrapper/PageMainContent';
import { LocationState } from './routes.config';

const HomePage = React.lazy(() => import('pages/HomePage/HomePage'));
const DocumentPage = React.lazy(() => import('pages/DocumentPage'));
const PDFDialogPage = React.lazy(() => import('pages/PDFDialogPage'));
const TagsPage = React.lazy(() => import('pages/TagsPage/TagsPage'));
const SettingsPage = React.lazy(() => import('pages/SettingsPage'));
const ProductPage = React.lazy(() => import('pages/ProductPage'));
const FavouritesPage = React.lazy(() => import('pages/FavouritesPage'));
const NotesPage = React.lazy(() => import('pages/NotesPage'));
const ManagementPage = React.lazy(() => import('pages/ManagementPage'));
const PeoplePage = React.lazy(() => import('pages/PeoplePage'));
const MyDocumentsPage = React.lazy(() => import('pages/MyDocumentsPage'));
const RecommendationsPage = React.lazy(
  () => import('pages/RecommendationsPage')
);

export const InnerPages = () => {
  const location = useLocation<LocationState>();

  return (
    <Box display="flex" alignItems="flex-start" sx={{ minHeight: '100vh' }}>
      <MainToolbar />
      <MainSidebar />

      <PageMainContent>
        <Switch location={location}>
          <Route path="/product" component={ProductPage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <Route path="/tags" component={TagsPage} />
          <PrivateRoute path="/favorites" component={FavouritesPage} />
          <PrivateRoute path="/people" component={PeoplePage} />
          <PrivateRoute path="/my-documents" component={MyDocumentsPage} />
          <PrivateRoute
            path="/management"
            component={ManagementPage}
            roles={[RoleEnum.Admin, RoleEnum.Manager]}
          />
          <PrivateRoute exact path="/notes" component={NotesPage} />
          <PrivateRoute
            exact
            path="/recommendations"
            component={RecommendationsPage}
          />
          <PrivateRoute path="/pdf/:docId" component={PDFDialogPage} />
          <Route exact path="/documents/:docId" component={DocumentPage} />
          <Route exact path="/" component={HomePage} />
          <Redirect from="*" to="/" />
        </Switch>
      </PageMainContent>
    </Box>
  );
};
