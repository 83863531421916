import { useDispatch } from 'react-redux';

import { JWTs, UserCredentials } from '@zarn/vendor/dist/auth';
import { authLogin } from 'api/authApi';
import { AppDispatch } from 'app/state/store';
import { getUser } from 'containers/User/user.slice';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { getDocumentSources } from 'containers/DocumentSources/documentSources.slice';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export function useLogin(): (values: UserCredentials) => Promise<JWTs> {
  const dispatch = useDispatch<AppDispatch>();
  const { tenant } = useParsedHostname();
  const { setTokens } = useAuth();

  return async (values: UserCredentials): Promise<JWTs> => {
    const { data } = await authLogin(values);

    setTokens(data.jwt, data.refresh_token);

    await dispatch(getUser(tenant));

    await dispatch(
      getDocumentSources({
        tenant,
        userHasLoggedIn: true,
      })
    );

    return data;
  };
}
