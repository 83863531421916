import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import SearchPopperContent from 'containers/SearchResults/SearchPopper/SearchPopperContent';
import SearchPopperHeader from 'containers/SearchResults/SearchPopper/SearchPopperHeader';
import { selectUser } from 'containers/User/user.slice';
import ExplainHighlightCard from 'containers/ExplainHighlight';

export type SearchWithNoteOrHighlightDialogProps = {
  searchPopperOpen: boolean;
  onSearchPopperClose: () => void;
  searchQuery: string;
  docId?: string;
};

const SearchWithNoteOrHighlightDialog = ({
  searchPopperOpen,
  onSearchPopperClose,
  searchQuery,
  docId,
}: SearchWithNoteOrHighlightDialogProps) => {
  const userSettings = useSelector(selectUser);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={searchPopperOpen}
      onClose={onSearchPopperClose}
      aria-label="Search results for given query"
      scroll="body"
    >
      <SearchPopperHeader
        onClose={onSearchPopperClose}
        searchQuery={searchQuery}
      >
        {userSettings !== undefined && userSettings?.useOpenAI === true ? (
          <ExplainHighlightCard searchQuery={searchQuery} docId={docId} />
        ) : undefined}
      </SearchPopperHeader>

      <SearchPopperContent searchQuery={searchQuery} />
    </Dialog>
  );
};

export default SearchWithNoteOrHighlightDialog;
