import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  navLink: {
    width: '100%',
    color: 'inherit',
    textDecoration: 'inherit',
    padding: theme.spacing(0.5, 0, 0.5, '20px'),
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.body2.fontSize,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  navLinkSelected: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    fontWeight: 'bold',
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
  },
  listItemContainer: {
    '& > .MuiListItemSecondaryAction-root': {
      right: theme.spacing(1),
    },
    '&:hover': {
      '& > .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
  },
  secondaryAction: {
    '& > $navLink': {
      paddingRight: theme.spacing(4),
    },
  },
  buttonSelected: {
    '& + .MuiListItemSecondaryAction-root': {
      opacity: 1,
    },
  },
  tooltipPopper: {
    display: 'none',
  },
  disabledDiv: {
    pointerEvents: 'none',
    opacity: 0.5,
    width: '100%',
    color: 'inherit',
    textDecoration: 'inherit',
    padding: 0,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.body2.fontSize,
  },
}));
