import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backdropFilter: 'blur(4px)',
  },
  spacing: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}));
