import React, { useMemo, useRef, useState } from 'react';
import { Chip, ListItemIcon, Menu } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ResultType } from '@zarn/vendor/dist/query-logging';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { normalizePaperSource } from 'common/utils/documents';
import {
  DuplicateDocument,
  RetrievalUnitData,
} from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { FeedbackType } from 'containers/Feedback/enums';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import TrackedMenuItem from 'common/components/Menu/TrackedMenuItem';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type DocSourceWithIconProps = {
  id: string;
  source: string;
  duplicates: DuplicateDocument[];
  searchId?: string;
  resultType?: ResultType;
  uri?: string | null;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  data: RetrievalUnitData;
};

const DocSourceWithIcon = ({
  onClick,
  onAction,
  resultType,
  searchId,
  id,
  source,
  duplicates,
  uri,
  data,
}: WithTrackedActionWrappedProps<DocSourceWithIconProps>) => {
  const chipRef = useRef<HTMLDivElement | null>(null);
  const { sendFeedback } = useSendFeedback();
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const normalizedSource = useMemo(
    () => normalizePaperSource(source),
    [source]
  );
  const hasDuplicates = duplicates.length > 0;

  const { resources } = data;
  const [imgUrl] = useState<string | undefined>(() => {
    if (resources) {
      const imageResource = resources.find(
        (resource) => resource.resourceType === 'image_url'
      );
      if (imageResource) return imageResource.resourceValue;
    }
  });
  const handleMenuOpen = () => {
    if (chipRef?.current) {
      setMenu(chipRef.current);
    }
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleClick = (resultId: string) => {
    handleMenuClose();

    if (searchId && resultType) {
      sendFeedback({
        score: 0,
        searchId,
        resultType,
        resultId,
        feedbackType: FeedbackType.Click,
      });
    }
  };

  const handleSourceChipClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };

  if (!hasDuplicates) {
    return (
      <Chip
        size="small"
        data-testid="documentSource"
        label={normalizedSource}
        icon={
          imgUrl ? (
            <img
              style={{ borderRadius: '50%', height: '16px', width: '16px' }}
              src={imgUrl}
              alt="document img"
            />
          ) : (
            <LocalLibraryIcon />
          )
        }
        component="a"
        href={uri ?? undefined}
        target="_blank"
        clickable
        rel="noopener noreferrer"
        sx={{ mr: 0.5, display: 'inline-flex', fontSize: '0.85rem' }}
        onClick={handleSourceChipClick}
      />
    );
  }

  return (
    <>
      <Chip
        ref={chipRef}
        size="small"
        data-testid="documentSource"
        label={
          <>
            {normalizedSource}

            {hasDuplicates ? <b color="">{` + ${duplicates.length}`}</b> : ''}
          </>
        }
        icon={
          imgUrl ? (
            <img
              style={{ borderRadius: '50%', height: '16px', width: '16px' }}
              src={imgUrl}
              alt="document img"
            />
          ) : (
            <LocalLibraryIcon />
          )
        }
        onClick={handleMenuOpen}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={handleMenuOpen}
        sx={{ mr: 0.5, display: 'inline-flex' }}
      />

      <Menu
        anchorEl={menu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!menu}
        onClose={handleMenuClose}
      >
        <TrackedMenuItem
          component="a"
          href={uri ?? ''}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(id)}
          eventName={TrackEventName.DocumentSourceClicked}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>

          {source}
        </TrackedMenuItem>

        {duplicates.map((duplicate) => (
          <TrackedMenuItem
            component="a"
            key={duplicate.document.id}
            href={duplicate.uri ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleClick(duplicate.document.id)}
            eventName={TrackEventName.DocumentSourceClicked}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>

            {duplicate.source}
          </TrackedMenuItem>
        ))}
      </Menu>
    </>
  );
};

DocSourceWithIcon.deisplayName = 'DocSourceWithIcon';

export default withTrackedAction<
  DocSourceWithIconProps & WithTrackedActionProps
>(DocSourceWithIcon, TrackEventName.DocumentSourceClicked);
