import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, IconButtonProps } from '@mui/material';

import { WithTrackedActionProps } from 'common/components/TrackedActions/withTrackedAction';
import TrackedIconButton from '../TrackedIconButton/TrackedIconButton';

type Props = {
  children: React.ReactNode;
  href?: string;
  to?: string;
} & IconButtonProps &
  WithTrackedActionProps;

export default function IconButtonLink({ href, children, to, ...rest }: Props) {
  if (href) {
    return (
      <Link href={href} target="_blank" rel="noreferrer" title={href}>
        <TrackedIconButton color="secondary" {...rest}>
          {children}
        </TrackedIconButton>
      </Link>
    );
  }

  if (to) {
    return (
      <RouterLink to={to} title={to}>
        <TrackedIconButton {...rest}>{children}</TrackedIconButton>
      </RouterLink>
    );
  }

  return (
    <IconButton disabled size="large">
      {children}
    </IconButton>
  );
}
