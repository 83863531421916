import { deserializeUserRole } from '../Auth.utils';
import { MeCommonModel } from './MeCommon.model';
import { UserPayload } from '@zarn/vendor/dist/auth';
import { Me } from './Me.interface';

export class MeCustomModel extends MeCommonModel implements Me {
  constructor(token: UserPayload) {
    super(token);
    this.userRolesData = token.user_roles.role_data.map(deserializeUserRole);
    this.organization = this.getOrganization();
  }
}
