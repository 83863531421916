import { AxiosResponse } from 'axios';
import {
  Configuration,
  GroupedTaggedResourcesApi,
  TaggedResourcesApi,
  TaggedResourcesPostRequest,
  TagType,
} from '@zarn/vendor/dist/saved-results';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';
import { GroupedTaggedResourcesGetPayload } from 'containers/TaggedResources/TaggedResources.interface';
import {
  CreateFavoriteResourcePayload,
  CreateTaggedResourcePayload,
  DeleteFavoriteResourcePayload,
  DeleteTaggedResourcePayload,
  FavoriteResourcesFilterPayload,
  TaggedResourceDetails,
  TaggedResourcesFilterPayload,
} from './taggedResourcesApi.types';
import { PaginatedResults } from 'api/models/PaginatedResults';
import {
  deserializeTaggedResourceDetails,
  serializeCreateFavoriteResourcePayload,
  serializeCreateTaggedResourcePayload,
  serializeDeleteFavoriteResourcePayload,
  serializeDeleteTaggedResourcePayload,
  serializeFavoriteResourcesFilterPayload,
  serializeTaggedResourcesFilterPayload,
} from './taggedResourcesApi.utils';

export const taggedResourcesApi = new TaggedResourcesApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const groupedTaggedResourcesApi = new GroupedTaggedResourcesApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  AxiosInstance
);

export const listTaggedResources = async (
  payload: TaggedResourcesFilterPayload | FavoriteResourcesFilterPayload
): Promise<AxiosResponse<PaginatedResults<TaggedResourceDetails>>> => {
  // If it's not favorite tag
  if (
    'tagType' in payload &&
    'tagId' in payload &&
    payload.tagType !== TagType.Favourites
  ) {
    const { data, ...rest } = await taggedResourcesApi.taggedResourcesFilter(
      serializeTaggedResourcesFilterPayload(payload)
    );

    return {
      ...rest,
      data: new PaginatedResults(
        data.count,
        data.results.map(deserializeTaggedResourceDetails),
        data.next?.page,
        data.previous?.page,
        payload.page,
        payload.pageSize
      ),
    };
  }

  const { data, ...rest } =
    await taggedResourcesApi.favoriteTaggedResourcesFilter(
      serializeFavoriteResourcesFilterPayload(payload)
    );

  return {
    ...rest,
    data: new PaginatedResults(
      data.count,
      data.results.map(deserializeTaggedResourceDetails),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const createTaggedResource = async (
  payload: CreateTaggedResourcePayload
): Promise<AxiosResponse<TaggedResourceDetails>> => {
  const { data, ...rest } = await taggedResourcesApi.createTaggedResource(
    serializeCreateTaggedResourcePayload(payload)
  );

  return {
    ...rest,
    data: deserializeTaggedResourceDetails(data),
  };
};

export const createFavoriteResource = async (
  payload: CreateFavoriteResourcePayload
): Promise<AxiosResponse<TaggedResourceDetails>> => {
  const { data, ...rest } =
    await taggedResourcesApi.createFavoriteTaggedResource(
      serializeCreateFavoriteResourcePayload(payload)
    );

  return {
    ...rest,
    data: deserializeTaggedResourceDetails(data),
  };
};

export const deleteTaggedResource = async (
  payload: DeleteTaggedResourcePayload
): Promise<AxiosResponse<string>> => {
  const response = await taggedResourcesApi.deleteTaggedResource(
    serializeDeleteTaggedResourcePayload(payload)
  );

  return {
    ...response,
    data: payload.resourceId,
  };
};

export const deleteFavoriteResource = async (
  payload: DeleteFavoriteResourcePayload
): Promise<AxiosResponse<string>> => {
  const response = await taggedResourcesApi.deleteFavoriteTaggedResource(
    serializeDeleteFavoriteResourcePayload(payload)
  );

  return {
    ...response,
    data: payload.resourceId,
  };
};

export const getGroupedTaggedResources = (
  payload: GroupedTaggedResourcesGetPayload
) => {
  return groupedTaggedResourcesApi.getGroupedTaggedResources({
    requesterUuid: '',
    userRoles: '',
    ...payload,
  });
};

/** @deprecated */
export const saveTaggedResource = (
  taggedResourcesPostRequest: TaggedResourcesPostRequest
) => {
  return taggedResourcesApi.taggedResourceSave({
    requesterUuid: '',
    taggedResourcesPostRequest,
  });
};

/** @deprecated */
export const deleteTaggedResource_depr = (id: number) => {
  return taggedResourcesApi.taggedResourceDelete({
    requesterUuid: '',
    id,
  });
};
