import { TagType } from '@zarn/vendor/dist/saved-results';
import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';

export class NewTagsAutocompleteOptionValue
  implements TagsAutocompleteOptionValue
{
  readonly id = -1;
  readonly type = TagType.Own;
  readonly permission = 'modify';
  readonly name: string;

  constructor(tagName: string) {
    this.name = tagName;
  }
}
