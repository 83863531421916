import { groupBy } from 'lodash';

import { D_NEW_LINE } from 'common/utils/share';
import {
  getSharedTagLink,
  getTaggedDocsEmailContent,
} from 'containers/TagShare/shareTag.utils';
import { TaggedDocument } from 'containers/TaggedDocs/TaggedDocument.interface';
import { TagDetails, TagSharingPolicies } from 'api/tagsApi/tagsApi.types';
import { TagShareFormValues } from './TagShareSettings.interface';
import {
  ShareOrgOptionValue,
  ShareUserOptionValue,
} from 'common/components/Share/ShareSettings/ShareSelection/ShareSelection.interface';

export const serializeTagShareSettingsFormValues = ({
  selection,
  isPublicTag,
}: TagShareFormValues): TagSharingPolicies => {
  const groupedSelection: {
    user?: ShareUserOptionValue[];
    organization?: ShareOrgOptionValue[];
  } = groupBy(selection, 'type');

  return {
    orgs: (groupedSelection.organization ?? []).map(({ id, permission }) => ({
      id,
      permission,
    })),
    users: (groupedSelection.user ?? []).map(({ id, permission, notify }) => ({
      id,
      permission,
      notify,
    })),
    public: isPublicTag ? { permission: 'read' } : undefined,
  };
};

export const getNotifyByEmailDocsText = (
  taggedDocs: TaggedDocument[]
): string => {
  const max = 3;
  const theRestDocsText =
    taggedDocs.length > max
      ? `${D_NEW_LINE}and ${taggedDocs.length - max} other papers`
      : '';

  const content = getTaggedDocsEmailContent({
    taggedDocs: taggedDocs
      .slice(0, max)
      .map(({ retrievalUnitData }) => retrievalUnitData),
    tagsNotes: [],
    documentsNotes: [],
  });

  return `${content}${theRestDocsText}`;
};

export const getNotifyByEmailText = (
  tag: TagDetails,
  taggedDocs: TaggedDocument[]
): string => {
  const url = getSharedTagLink(tag.id);
  const content = getNotifyByEmailDocsText(taggedDocs);
  return `mailto:?subject=Here are some interesting papers for you&body=Hi,${D_NEW_LINE}Check out this collection of papers about "${tag.name}" via Zeta Alpha:${D_NEW_LINE}${url}${D_NEW_LINE}${content}`;
};
