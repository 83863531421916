import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import TagSettingsDialog from '../TagSettingsForm/TagSettingsDialog/TagSettingsDialog';

type TagItemSettingsButtonProps = {
  tag: TagDetails;
};

const TagItemSettingsButton = ({ tag }: TagItemSettingsButtonProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="edit tag"
        size="small"
        onClick={handleOpen}
      >
        <SettingsIcon fontSize="small" />
      </IconButton>

      <TagSettingsDialog tag={tag} open={isOpen} setOpen={setOpen} />
    </>
  );
};

TagItemSettingsButton.displayName = 'TagItemSettingsButton';

export default TagItemSettingsButton;
