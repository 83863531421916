import { useDocNoteCreate } from './useDocNoteCreate';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useCallback, useMemo } from 'react';
import { Highlight } from '@zarn/pdf-viewer';
import { useDocNoteDelete } from './useDocNoteDelete';
import { useDocNoteUpdate } from './useDocNoteUpdate';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useAuth } from '../../Auth/hooks/useAuth';
import { SharingPolicies } from '../../Sharing/Sharing.types';
import { deserializeShareable } from 'api/notesApi/notesApi.utils';

export const useNoteApi = (docId: string) => {
  const { tenant } = useParsedHostname();
  const orgId = useAuth().me?.organization?.userRoleId;

  const createMutation = useDocNoteCreate(docId);
  const updateMutation = useDocNoteUpdate({ docId });
  const deleteMutation = useDocNoteDelete({ docId });

  const create = useCallback(
    async (
      content = '',
      highlight: Highlight | null = null,
      sharing: SharingPolicies = { orgs: [], users: [] }
    ) => {
      return createMutation.mutateAsync({
        content,
        sharing,
        annotationHighlight: highlight,
        tenant,
      });
    },
    [createMutation, tenant]
  );

  const remove = useCallback(
    async (id: number) => {
      return deleteMutation.mutate(id);
    },
    [deleteMutation]
  );

  const update = useCallback(
    async (note: NoteDetails, highlight?: Highlight) => {
      return updateMutation.mutate({
        objectId: docId,
        noteId: note.id,
        objectType: 'document',
        content: note.content,
        sharing: deserializeShareable(note.shareable, orgId),
        annotationHighlight: highlight ?? note.annotationHighlight ?? null,
        tenant,
      });
    },
    [docId, orgId, tenant, updateMutation]
  );

  return useMemo(() => ({ create, update, remove }), [create, remove, update]);
};
