import { LayoutSettings, WidgetsConfiguration } from '@zarn/vendor/dist/search';
import {
  LayoutColumnSettings,
  WidgetLayoutSettings,
  WidgetName,
} from '../tenantSettingsApi.types';
import { DEFAULT_WIDGET_LAYOUT } from 'common/constants/widgetsLayout.const';
import { OrderedSet } from './orderedSet';
import { isEmpty } from 'lodash';

const deserializeWidgetName = (name: string): WidgetName | null => {
  switch (name) {
    case 'vos_viewer':
      return 'vosViewer';
    case 'analytics':
      return 'analytics';
    case 'expert_search':
      return 'expertSearch';
    case 'query_analysis':
      return 'queryAnalysis';
    case 'search_results':
      return 'searchResults';
    case 'find_similar_document':
      return 'findSimilarDocument';
    case 'find_authored_by':
      return 'findAuthoredBy';
    case 'qa':
      return 'QA';
    default:
      return null;
  }
};

const isLayoutInWidgets = (widgets: WidgetsConfiguration | null) =>
  Object.values(widgets ?? {}).some((widget) => !!widget.layout);

const createNewColumn = (
  widgetLayout: LayoutSettings,
  widgetName: WidgetName
) => {
  const orderedWidgets = new OrderedSet<WidgetName>();
  if (widgetLayout.row) {
    orderedWidgets.setWidgetToRow(widgetLayout.row, widgetName);
  } else {
    orderedWidgets.addUnorderedElement(widgetName);
  }
  return orderedWidgets;
};

const buildTopFullWidthWidgets = (
  widgets: Array<[WidgetName, LayoutSettings]>
) =>
  widgets.reduce((acc, [widgetName, widgetLayout]) => {
    if (widgetLayout.row) {
      acc.setWidgetToRow(widgetLayout.row, widgetName);
    } else {
      acc.addUnorderedElement(widgetName);
    }
    return acc;
  }, new OrderedSet<WidgetName>());

const updateOrderedWidgetsSizes = (
  orderedWidgets: Omit<LayoutColumnSettings, 'widgets'> & {
    widgets: OrderedSet<WidgetName>;
  },
  widgetLayout: LayoutSettings
) => {
  if (
    (!orderedWidgets.sizes || isEmpty(orderedWidgets.sizes)) &&
    widgetLayout.column_widths &&
    !isEmpty(widgetLayout.column_widths)
  ) {
    orderedWidgets.sizes = widgetLayout.column_widths;
  }
};

const buildOrderedColumns = (widgets: Array<[WidgetName, LayoutSettings]>) => {
  return widgets.reduce((acc, [widgetName, widgetLayout]) => {
    if (!acc.hasOrderedElement(widgetLayout.column!)) {
      const orderedWidgets = createNewColumn(widgetLayout, widgetName);
      acc.setWidgetToRow(widgetLayout.column!, {
        sizes: widgetLayout.column_widths,
        widgets: orderedWidgets,
      });
    } else {
      const orderedWidgets = acc.getOrderedElement(widgetLayout.column!)!;
      orderedWidgets.widgets.add(widgetLayout.row, widgetName);
      updateOrderedWidgetsSizes(orderedWidgets, widgetLayout);
      acc.setOrderedElement(widgetLayout.column!, orderedWidgets);
    }
    return acc;
  }, new OrderedSet<Omit<LayoutColumnSettings, 'widgets'> & { widgets: OrderedSet<WidgetName> }>());
};

export const deserializeWidgetsLayout = (
  widgetsConfiguration: WidgetsConfiguration | null
): WidgetLayoutSettings => {
  if (!isLayoutInWidgets(widgetsConfiguration)) {
    return DEFAULT_WIDGET_LAYOUT;
  }

  const allWidgets: Array<[WidgetName, LayoutSettings]> = Object.entries(
    widgetsConfiguration ?? {}
  )
    .map(([widgetNameSnakeCase, widgetConfig]) => [
      deserializeWidgetName(widgetNameSnakeCase),
      widgetConfig,
    ])
    .filter(([widgetName, widgetConfig]) => widgetName && widgetConfig.layout)
    .map(([widgetName, widgetConfig]) => [widgetName, widgetConfig.layout]);

  const widgetsWithColumns = allWidgets.filter(
    ([_, widgetLayout]) => !!widgetLayout.column
  );
  const widgetsWithoutColumns = allWidgets.filter(
    ([_, widgetLayout]) => !widgetLayout.column
  );

  const orderedTopFullWidthWidgets = buildTopFullWidthWidgets(
    widgetsWithoutColumns
  );

  const orderedColumns = buildOrderedColumns(widgetsWithColumns);

  return {
    topFullWidthWidgets: orderedTopFullWidthWidgets.getOrderedSet(),
    columns: orderedColumns.getOrderedSet().map((column) => ({
      ...column,
      widgets: column.widgets.getOrderedSet(),
    })),
  };
};
