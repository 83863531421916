import { useMutation, UseMutationOptions } from 'react-query';

import {
  CreateExternalDocPayload,
  externalDocumentCreate,
} from 'api/externalDocsApi';
import { BaseError } from 'common/models/Error.interface';
import { listDocuments } from 'api/searchApi';
import { useSnackbar } from 'notistack';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from 'containers/User/user.slice';

export const useExternalDocCreate = (
  options: UseMutationOptions<
    CreateExternalDocPayload,
    BaseError,
    Omit<CreateExternalDocPayload, 'objectId' | 'objectType'>
  > = {}
) => {
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);

  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation<
    CreateExternalDocPayload,
    BaseError,
    CreateExternalDocPayload
  >(
    async (payload: CreateExternalDocPayload) => {
      const {
        data: { items },
      } = await listDocuments({
        docIds: [payload.organizeDocId],
        propertyName: 'organize_doc_id',
        tenant: parsedHostname.tenant,
        indexCluster: indexCluster as string | undefined,
      });
      const existingDocument = items[0];

      if (existingDocument) {
        return payload;
      }

      await externalDocumentCreate(payload, parsedHostname.tenant);

      return payload;
    },
    {
      ...options,
      onError(error: BaseError) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
};
