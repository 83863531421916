import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { listRecommendations } from 'api/recommendationsApi/recommendationsApi';
import { listDocuments } from 'api/searchApi';
import { listTags } from 'api/tagsApi/tagsApi';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { getSeenRecommendationsByID } from 'containers/SeenRecommendations/seenRecommendations.slice';
import { RecommendationsData } from './Recommendations.interface';
import {
  getGroupedRecommendationResults,
  getIdsByResourceType,
} from './recommendations.utils';

export type GetRecommendationsResultsArgs = {
  page: number;
  recId?: string;
  docId?: string;
  tagId?: string;
  tenant: string;
  indexCluster?: string;
};

export const getRecommendationsResultsThunk: AsyncThunkPayloadCreator<
  RecommendationsData,
  GetRecommendationsResultsArgs
> = async ({ page, recId, tagId, tenant, indexCluster }, { dispatch }) => {
  const { data } = await listRecommendations({
    page,
    findRecInContext: recId,
    tagId: tagId !== undefined ? parseInt(tagId) : undefined,
  });

  if (data.count === 0) {
    return {
      page: data.page,
      count: data.count,
      results: data.items,
      groupedResults: [],
      pageSize: data.pageSize,
    };
  }

  const { docIds, tagIds } = getIdsByResourceType(data.items);

  let tags: TagDetails[] = [];

  const {
    data: { items: docs },
  } = await listDocuments({
    docIds,
    tenant: tenant,
    indexCluster: indexCluster,
  });

  if (tagIds.length > 0) {
    tags = (await listTags({ tagIds, pageSize: tagIds.length, tenant })).data
      .items;
  }

  const groupedResults = getGroupedRecommendationResults(
    data.items,
    docs,
    tags
  );
  const recIds = groupedResults.map(({ id }) => id);

  await dispatch(getSeenRecommendationsByID(recIds));

  return {
    page: data.page,
    count: data.count,
    results: data.items,
    pageSize: data.pageSize,
    groupedResults,
  };
};
