import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';

import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  title: string;
  to: string;
  children: React.ReactNode;
};

const NavButtonLink = ({
  title,
  to,
  children,
  onAction,
}: WithTrackedActionWrappedProps<Props>) => {
  return (
    <Tooltip title={title} aria-label={title}>
      <IconButton to={to} component={NavLink} onClick={onAction} size="large">
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(NavButtonLink);
