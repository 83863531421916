import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { selectOwnTags } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { selectEditorSharedTags } from 'containers/Tags/sharedTagsSlice/sharedTags.slice';

export type UseTagsAutocompleteOptionsReturn = {
  ownTags: TagDetails[];
  sharedTags: TagDetails[];
  options: TagsAutocompleteOptionValue[];
};

export const useTagsAutocompleteOptions =
  (): UseTagsAutocompleteOptionsReturn => {
    const ownTags = useSelector(selectOwnTags);

    const sharedTags = useSelector(selectEditorSharedTags);

    const options = useMemo<TagsAutocompleteOptionValue[]>(
      () =>
        [...ownTags, ...sharedTags].map(
          (tag: TagDetails): TagsAutocompleteOptionValue => ({
            id: tag.id,
            name: tag.name,
            type: tag.type,
            ownerId: tag.owner?.id,
            permission: tag.permission,
          })
        ),
      [ownTags, sharedTags]
    );

    return {
      ownTags,
      sharedTags,
      options,
    };
  };
