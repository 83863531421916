import { useCallback } from 'react';
import { PDFViewerActionPayload } from '@zarn/pdf-viewer';

import { posthogPDFReader } from 'common/utils/posthog.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const usePDFViewerActions = (docId: string) => {
  const { tenant } = useParsedHostname();
  return useCallback(
    async (payload: PDFViewerActionPayload) => {
      const { fileName } = payload.data;

      switch (payload.action) {
        case 'download': {
          posthogPDFReader.download(tenant, docId, fileName);
          break;
        }
        case 'print': {
          posthogPDFReader.print(tenant, docId, fileName);
          break;
        }
        default:
          break;
      }
    },
    [docId, tenant]
  );
};
