import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'app/state/store';
import {
  getInitSeenRecommendations,
  selectSeenRecommendations,
} from 'containers/SeenRecommendations/seenRecommendations.slice';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export interface UseRecommendationsUpdatesReturn {
  unseenRecsCount: number;
  hasNewRecs: boolean;
}

export const useRecommendationsUpdates =
  (): UseRecommendationsUpdatesReturn => {
    const dispatch = useDispatch<AppDispatch>();
    const { seenRecCount, allRecCount, hasNewRecs } = useSelector(
      selectSeenRecommendations
    );
    const { isAuthenticated } = useAuth();

    useEffect(() => {
      if (!isAuthenticated) return;
      const interval = setInterval(
        () => dispatch(getInitSeenRecommendations()),
        3600000
      );
      const getInitSeenRecs = dispatch(getInitSeenRecommendations());
      return () => {
        getInitSeenRecs.abort();
        clearInterval(interval);
      };
    }, [dispatch, isAuthenticated]);

    return {
      unseenRecsCount: allRecCount - seenRecCount,
      hasNewRecs,
    };
  };
