import { useMemo } from 'react';

import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

export const useOrganizeDocIds = (items?: RetrievalUnitData[]) => {
  return useMemo<string[]>(
    () => (items ?? []).map(({ organizeDocId }) => organizeDocId),
    [items]
  );
};
