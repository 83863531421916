import { PaginatedResults } from 'api/models/PaginatedResults';

// Moves an array item from one position in an array to another
export function arrayMove<T extends any>(
  arr: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  const newArr = [...arr];
  newArr.splice(newIndex, 0, newArr.splice(oldIndex, 1)[0]);
  return newArr;
}

export interface PaginatedIndex {
  pageIndex: number;
  itemIndex: number;
}

export function findIndexInPages<T extends any>(
  arr: PaginatedResults<T>[],
  predicate: (value: T, index: number, obj: T[]) => unknown
): PaginatedIndex {
  let paginatedIndex: PaginatedIndex = {
    pageIndex: -1,
    itemIndex: -1,
  };

  arr.some((paginated, pageIndex) => {
    const itemIndex = paginated.items.findIndex(predicate);

    if (itemIndex !== -1) {
      paginatedIndex = {
        pageIndex,
        itemIndex,
      };

      return true;
    }

    return false;
  });

  return paginatedIndex;
}

// Moves item from one page and position to another
export function paginatedArrayMove<T extends any>(
  arr: PaginatedResults<T>[],
  oldPaginatedIndex: PaginatedIndex,
  newPaginatedIndex: PaginatedIndex
): PaginatedResults<T>[] {
  const newArr = [...arr.map((pag) => ({ ...pag, items: [...pag.items] }))];

  // if we move an item across pages, we need to shift the edges
  if (newPaginatedIndex.pageIndex > oldPaginatedIndex.pageIndex) {
    // move the first element of the next page into the last element of the previous page
    Array(newPaginatedIndex.pageIndex - oldPaginatedIndex.pageIndex)
      .fill(undefined)
      .forEach((_, idx) => {
        const firstItem = newArr[
          oldPaginatedIndex.pageIndex + idx + 1
        ].items.splice(0, 1)[0];
        if (firstItem) {
          newArr[oldPaginatedIndex.pageIndex + idx].items.push(firstItem);
        }
      });
  }

  // remove item at oldPaginatedIndex
  const removedItem = newArr[oldPaginatedIndex.pageIndex].items.splice(
    oldPaginatedIndex.itemIndex,
    1
  )[0];

  // insert removed item at newPaginatedIndex
  newArr[newPaginatedIndex.pageIndex].items.splice(
    newPaginatedIndex.itemIndex,
    0,
    removedItem
  );

  // if we move an item across pages, we need to shift the edges
  if (newPaginatedIndex.pageIndex < oldPaginatedIndex.pageIndex) {
    // move the las element of the previous page into the first element of the next page
    Array(oldPaginatedIndex.pageIndex - newPaginatedIndex.pageIndex)
      .fill(undefined)
      .forEach((_, idx) => {
        const lastItem = newArr[newPaginatedIndex.pageIndex + idx].items.pop();
        if (lastItem) {
          newArr[newPaginatedIndex.pageIndex + idx + 1].items.unshift(lastItem);
        }
      });
  }

  return newArr;
}
