import { useQuery, UseQueryOptions } from 'react-query';

import { getDocumentAssetContent } from 'api/documentAssetApi/documentAssetApi';
import { BaseError } from 'common/models/Error.interface';
import { DocImage } from '../../RetrievalUnitData.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { toSrcUrl } from 'common/utils/bytesUtils';

const docImageQueryKeys = {
  all: ['documentImage'] as const,
  details: (
    docId: string,
    assetType: string,
    assetValue: string,
    indexCluster?: string
  ) =>
    [
      ...docImageQueryKeys.all,
      docId,
      assetType,
      assetValue,
      indexCluster,
    ] as const,
};

interface DocumentImageData {
  url: string | undefined;
}

interface DocumentImageProps {
  docId: string;
  asset: DocImage;
  options?: Omit<
    UseQueryOptions<DocumentImageData, BaseError>,
    'queryKey' | 'queryFn'
  >;
  indexCluster?: string | null;
}

export const useDocumentImage = ({
  docId,
  asset: { assetType, assetValue },
  options,
  indexCluster,
}: DocumentImageProps) => {
  const { tenant } = useParsedHostname();
  const query = useQuery<DocumentImageData, BaseError>(
    docImageQueryKeys.details(
      docId,
      assetType,
      assetValue,
      indexCluster as string | undefined
    ),
    async () => {
      const { data } = await getDocumentAssetContent({
        docId,
        assetType: 'image_url',
        indexCluster: indexCluster ?? undefined,
        tenant,
      });

      return {
        url: data ? toSrcUrl(data.content, data.contentType) : undefined,
      };
    },
    {
      ...QUERY_OPTIONS,
      retry: 1,
      ...options,
      enabled: options?.enabled,
    }
  );

  return query;
};
