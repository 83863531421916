import { AxiosPromise, AxiosRequestConfig } from 'axios';

import {
  AuthenticationApiFactory,
  Configuration,
  CredentialsApiFactory,
  Email,
  FeedbackApiFactory,
  JWT,
  JWTs,
  MagicToken,
  MagicTokenAndResetType,
  PatchPassword,
  ResetPassword,
  SettingsApiFactory,
  UserChangelog,
  UserCredentials,
  UserFeedback,
  UserSettings,
  UserSettingsPatch,
} from '@zarn/vendor/dist/auth';
import { BASE_HEADERS, BASE_API_URL, SERVICE_URL } from '../apiConfig';
import AxiosInstance from '../axiosInstance';

export const AUTH_URL = `${BASE_API_URL}/auth`;

// Authentication
export const authenticationApi = AuthenticationApiFactory(
  new Configuration({ basePath: AUTH_URL, baseOptions: BASE_HEADERS }),
  AUTH_URL,
  AxiosInstance
);

export const authRefresh = (
  body: JWT,
  config?: AxiosRequestConfig
): AxiosPromise<JWT> => authenticationApi.refresh(body, config);

export const authLogin = (payload: UserCredentials): AxiosPromise<JWTs> =>
  authenticationApi.authenticate(payload);

// Credentials API
export const credentialsApi = CredentialsApiFactory(
  new Configuration({
    basePath: SERVICE_URL,
    baseOptions: BASE_HEADERS,
  }),
  SERVICE_URL,
  AxiosInstance
);

export const changePassword = (payload: PatchPassword): AxiosPromise<JWT> =>
  credentialsApi.changePassword('', payload);

export const authResetPassword = (payload: ResetPassword): AxiosPromise<JWTs> =>
  credentialsApi.passwordReset(payload);

export const authCheckMagicToken = (
  payload: MagicToken
): AxiosPromise<MagicToken> =>
  credentialsApi.magictokenRetrieve(payload.magic_token);

export const authRefreshMagicToken = (
  tenant: string,
  payload: MagicTokenAndResetType
): AxiosPromise<void> => credentialsApi.magictokenRefresh(payload, tenant);

export const authRequestMagicToken = (
  tenant: string,
  payload: Email
): AxiosPromise<void> => credentialsApi.magictokenCreate(payload, tenant);

// User API
export const userSettingsApi = SettingsApiFactory(
  new Configuration({
    basePath: SERVICE_URL,
    baseOptions: BASE_HEADERS,
  }),
  SERVICE_URL,
  AxiosInstance
);

// User Feedback API
export const userFeedbackApi = FeedbackApiFactory(
  new Configuration({
    basePath: SERVICE_URL,
    baseOptions: BASE_HEADERS,
  }),
  SERVICE_URL,
  AxiosInstance
);

export const getUserSettings = (tenant: string): AxiosPromise<UserSettings> => {
  return userSettingsApi.settingsRetrieve('', tenant);
};

export const getChangelogDate = (tenant: string): AxiosPromise<UserChangelog> =>
  userSettingsApi.changelogDateRetrieve('', tenant);

export const updateChangelogDate = (): AxiosPromise<void> =>
  userSettingsApi.changelogDateUpdate('');

export const updateUserSettings = (
  payload: UserSettingsPatch
): AxiosPromise<void> => userSettingsApi.settingsUpdate('', payload);

export const sendUserFeedback = (
  payload: UserFeedback,
  tenant: string
): AxiosPromise<void> => userFeedbackApi.sendFeedback('', payload, tenant);
