import React, { useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useTranslation } from 'react-i18next';
import { ResultType } from '@zarn/vendor/dist/query-logging';

import { RetrievalUnitAuthor } from '../RetrievalUnitData.interface';
import DocSharePopover from '../DocSharePopover';
import { DocCardComposition } from 'containers/User/User.enum';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

export type DocShareProps = {
  docId: string;
  docTitle: string;
  docAuthors?: RetrievalUnitAuthor[];
  searchId?: string;
  resultType?: ResultType;
  shareUri?: string;
  actionComposition?: DocCardComposition;
};

const DocShare = ({
  docId,
  docTitle,
  docAuthors,
  searchId,
  resultType,
  shareUri,
  actionComposition,
  onAction,
}: WithTrackedActionWrappedProps<DocShareProps>) => {
  const { t } = useTranslation(['common']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onAction();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t<string>('common:buttons.share')}>
        {actionComposition === DocCardComposition.Compressed ? (
          <Button
            size="small"
            aria-label="document share"
            aria-controls="actions"
            aria-haspopup="true"
            onClick={handleOpen}
            style={{
              alignSelf: 'start',
              width: '100%',
              justifyContent: 'start',
              lineHeight: 'normal',
            }}
          >
            <Box mr={1} ml={-0.2}>
              <ShareIcon color="primary" sx={{ fontSize: 18 }} />
            </Box>
            {t('retrievalUnit.actions.share.label')}
          </Button>
        ) : (
          <IconButton
            size="small"
            aria-label="document share"
            aria-controls="actions"
            aria-haspopup="true"
            onClick={handleOpen}
          >
            <ShareIcon color="primary" />
          </IconButton>
        )}
      </Tooltip>

      <DocSharePopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        docId={docId}
        docTitle={docTitle}
        docAuthors={docAuthors}
        searchId={searchId}
        resultType={resultType}
        shareUri={shareUri}
      />
    </>
  );
};

export default withTrackedAction<DocShareProps & WithTrackedActionProps>(
  DocShare
);
