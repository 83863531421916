import * as Sentry from '@sentry/react';
import { parseHostname } from './useParsedHostname';
import { History } from 'history';
import { ALLOW_SENTRY_TENANTS } from '../constants/sentry';

export const sentryInit = <T>(history: History<T>) => {
  if (!window.env.SENTRY_DNS) {
    return;
  }
  const { tenant } = parseHostname();
  if (!ALLOW_SENTRY_TENANTS.includes(tenant)) {
    return;
  }
  Sentry.init({
    dsn: window.env.SENTRY_DNS,
    environment: window.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: `javascript-react@${window.REACT_APP_VERSION}`,

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    // Performance Monitoring
    tracesSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    tracePropagationTargets: [
      'localhost',
      /https:\/\/api(-[\w-]*)?\.zeta-alpha\.com\//,
    ],
  });
};
