import React, { useState } from 'react';
import { Button, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { TagForm } from 'containers/Tags/TagForm/TagForm';
import EmptyState from 'common/components/EmptyState';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';

export const OwnTagsEmptyState = () => {
  const history = useHistory();
  const { t } = useTranslation('tags');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (tagItem: TagDetails) => {
    handlePopoverClose();
    history.push(`/tags/${tagItem.id}`);
  };

  return (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Own tags empty state' }}
    >
      <EmptyState
        m={0}
        py={2}
        title={t('myTags.emptyState.title')}
        subtitle={t('myTags.emptyState.subTitle')}
        data-testid="ownTagsEmptyState"
      >
        <Button
          size="small"
          onClick={handlePopoverOpen}
          startIcon={<AddIcon />}
          color="secondary"
        >
          {t('myTags.emptyState.addTag')}
        </Button>

        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <TagForm onSubmit={handleSubmit} onCancel={handlePopoverClose} />
        </Popover>
      </EmptyState>
    </TrackedActionContext.Provider>
  );
};
