import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ShareEnum, ShareType } from 'common/enums';
import {
  getDocumentsNotesData,
  selectDocumentsNotes,
  selectDocumentsNotesLoading,
} from 'containers/SavedNotes/DocumentsNotes/documentsNotes.slice';
import { getSavedNoteById } from 'containers/SavedNotes/savedNotes.utils';
import { ShareDocumentsOptions } from 'common/components/ShareDocuments/ShareDocuments.interface';
import {
  getDocsNotesIdsWithoutData,
  getShareTagUri,
  getTaggedDocsNotes,
} from 'containers/TagShare/shareTag.utils';
import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { selectTagsNotes } from 'containers/SavedNotes/TagsNotes/tagsNotes.slice';
import { useTaggedDocs } from 'containers/TaggedDocs/hooks/useTaggedDocs';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useTagDetails } from 'containers/Tags/hooks/useTagDetails';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export type useShareTagByEmailProps = {
  tagId: number;
  tagType: TagType;
};

export const useShareTagByEmail = ({
  tagId,
  tagType,
}: useShareTagByEmailProps) => {
  const dispatch = useAppDispatch();
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const { data: taggedDocPages, isLoading } = useTaggedDocs({ tagId, tagType });
  const {
    queryResult: { data: tag },
  } = useTagDetails({ tagType, tagId });
  const [taggedDocs, setTaggedDocs] = useState<RetrievalUnitData[]>([]);
  const tagsNotes = useSelector(selectTagsNotes);
  const savedNotes = useMemo(
    () => getSavedNoteById(tagsNotes, String(tagId)),
    [tagsNotes, tagId]
  );
  const savedDocNotes = useSelector(selectDocumentsNotes);
  const notesLoading = useSelector(selectDocumentsNotesLoading);
  const parsedHostname = useParsedHostname();

  useEffect(() => {
    if (!isLoading && taggedDocPages) {
      setTaggedDocs(
        taggedDocPages.pages.reduce(
          (acc, currPage) => acc.concat(...currPage.items),
          [] as RetrievalUnitData[]
        )
      );
    }
    return undefined;
  }, [taggedDocPages, isLoading]);

  const taggedDocsNotes = useMemo(
    () => getTaggedDocsNotes(savedDocNotes, taggedDocs),
    [savedDocNotes, taggedDocs]
  );
  const hasNotes = useMemo(
    () => !!savedNotes?.numberOfNotes || taggedDocsNotes.length > 0,
    [savedNotes, taggedDocsNotes.length]
  );

  const handleGetShareLink = useCallback(
    (_: ShareType, options?: ShareDocumentsOptions) => {
      return getShareTagUri[ShareEnum.Email]({
        tagId: tagId,
        tagName: tag?.name ?? '',

        taggedDocs,
        tagsNotes: options?.includeNotes ? savedNotes?.notesData ?? [] : [],
        documentsNotes: options?.includeNotes ? taggedDocsNotes : [],
      });
    },
    [taggedDocs, savedNotes, taggedDocsNotes, tag]
  );

  const handleNotesInclude = useCallback(() => {
    const docIdsWithoutData = getDocsNotesIdsWithoutData(taggedDocsNotes);
    if (docIdsWithoutData.length > 0) {
      for (const id of docIdsWithoutData) {
        dispatch(
          getDocumentsNotesData({
            noteObjectId: id,
            tenant: parsedHostname.tenant,
          })
        );
      }
    }
  }, [taggedDocsNotes, dispatch]);

  return {
    hasNotes,
    shareDialogOpen,
    notesLoading,
    setShareDialogOpen,
    getShareLink: handleGetShareLink,
    onNotesInclude: handleNotesInclude,
  };
};
