import React from 'react';
import {
  Tooltip,
  IconButton,
  IconButtonProps,
  IconButtonTypeMap,
  Theme,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  tooltipTitle: string;
} & IconButtonProps<IconButtonTypeMap['defaultComponent'], {}>;

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
}));

export const PageDrawerMenuAction = ({
  tooltipTitle,
  children,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Tooltip disableInteractive title={tooltipTitle}>
      <IconButton className={classes.iconButton} size="large" {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
