import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import TagChip from 'common/components/TagChip/TagChip';
import { TagsAutocompleteOptionValue } from 'containers/Tagging/TagsAutocomplete/TagsAutocomplete.types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    '&:hover $button': {
      opacity: 1,
    },
    marginTop: 4,
  },
  button: {
    opacity: 0.3,
  },
}));

interface DocInlineTagsViewProps {
  tags: TagDetails[];
  suggestedTags: TagDetails[];
  onButtonClick: () => void;
  onUpdateDocumentTags: (tags: TagsAutocompleteOptionValue[]) => Promise<void>;
}

export const DocInlineTagsView: FC<DocInlineTagsViewProps> = ({
  tags,
  suggestedTags,
  onButtonClick,
  onUpdateDocumentTags,
}) => {
  const { t } = useTranslation(['common', 'tags']);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (tag: TagDetails): void => {
    history.push(`/tags/${tag.id}`);
  };

  const handleSuggestedTagClick = async (tag: TagDetails): Promise<void> => {
    await onUpdateDocumentTags([...tags, tag]);
  };

  return (
    <div className={classes.wrapper}>
      {tags.map((tag) => (
        <TagChip
          key={tag.id}
          label={tag.name}
          color={tag.color}
          onClick={() => handleClick(tag)}
        />
      ))}

      {suggestedTags.map((tag) => (
        <Tooltip key={tag.id} title={t('tags:tagChip.suggested.title')}>
          <TagChip
            label={tag.name}
            color={tag.color}
            variant="suggested"
            onClick={() => handleSuggestedTagClick(tag)}
          />
        </Tooltip>
      ))}

      <Tooltip title={t<string>('common:retrievalUnit.tags.edit.title')}>
        <IconButton
          aria-label={t('common:retrievalUnit.tags.edit.ariaLabel')}
          data-testid="editDocumentTagsList"
          color="default"
          size="small"
          onClick={onButtonClick}
          className={classes.button}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
