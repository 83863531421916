import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import withInviteUserDialog, {
  WithInviteUserDialogWrappedProps,
} from 'containers/Users/InviteUser/hocs/withInviteUserDialog';
import TrackedButton from 'common/components/Buttons/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

const InviteUserButton = ({
  setDialogOpen,
}: WithInviteUserDialogWrappedProps) => {
  const { t } = useTranslation('common');

  const handleClick = () => setDialogOpen(true);

  return (
    <TrackedButton
      fullWidth
      startIcon={<PersonAddIcon />}
      variant="contained"
      color="secondary"
      onClick={handleClick}
      eventName={TrackEventName.ShowInviteUserPopoverClicked}
    >
      {t('inviteUser.button.text')}
    </TrackedButton>
  );
};

export default withInviteUserDialog(InviteUserButton);
