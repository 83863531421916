import React, { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { MarkdownText } from '../../../Markdown/MarkdownText';
import { useStyles } from './useStyles';
import { useTranslation } from 'react-i18next';

export interface NoteItemMarkdownProps {
  editable?: boolean;
  content: string;
}

export const NoteItemMarkdown: FC<NoteItemMarkdownProps> = ({
  editable,
  content,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const preparedContent = useMemo(
    () => content.replace(/<(\/)?b>/g, '**'),
    [content]
  );

  return (
    <Typography
      variant="body2"
      component="div"
      aria-label="note content"
      color={!preparedContent ? 'textSecondary' : 'textPrimary'}
      className={clsx(classes.content, editable && classes.editable)}
      data-testid="NoteItemMarkdown"
    >
      <MarkdownText>
        {preparedContent || t('notes.content.addNotePlaceholder')}
      </MarkdownText>
    </Typography>
  );
};
