import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = ButtonProps;

const TrackedButton = ({
  onClick,
  onAction,
  children,
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Button {...rest} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(TrackedButton);
