import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { TagsListContext } from '../TagsList.context';
import { useInViewEffect } from 'react-hook-inview';

export type UseScrollToActiveTagReturn = [(node: Element | null) => void];

// TODO: Test e2e
export const useScrollToActiveTag = (
  tag: TagDetails
): UseScrollToActiveTagReturn => {
  const {
    anchorsRefs: { wrapper: wrapperRef },
  } = useContext(TagsListContext);

  const match = useRouteMatch<{ tagId: string }>({
    path: `/tags/:tagId`,
  });

  const ref = useInViewEffect(
    ([entry], observer) => {
      const isActive = match?.params.tagId === String(tag.id);
      const wrapper = wrapperRef.current;

      // Disconnect tagItem from observing
      // when it's in view
      if (entry.isIntersecting) {
        observer.disconnect();
        return;
      }

      if (!wrapper || !entry.boundingClientRect) return;

      const { top } = entry.boundingClientRect;
      const { top: wrapperTop } = wrapper.getBoundingClientRect();
      const scrollToTop = top - wrapperTop - 40;
      // Scroll to the active tag and then unubserve that tag
      if (!entry.isIntersecting && isActive && scrollToTop > 0) {
        wrapper.scrollTo({
          top: scrollToTop,
          behavior: 'smooth',
        });

        observer.unobserve(entry.target);
      }
    },
    { threshold: 0.5 },
    [match?.params.tagId, wrapperRef]
  );

  return [ref];
};
