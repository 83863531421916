import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';
import { RecommendationsData } from './Recommendations.interface';
import {
  GetRecommendationsResultsArgs,
  getRecommendationsResultsThunk,
} from './recommendationsSlice.utils';

export type RecommendationsState = {
  fetchState: FiniteStatesType;
  data: RecommendationsData;
  error: string | null;
};

const initialState: RecommendationsState = {
  error: null,
  fetchState: FiniteStates.Idle,
  data: {
    count: 0,
    page: 1,
    pageSize: 10,
    results: [],
    groupedResults: [],
  },
};

export const getRecommendationsResults = createAsyncThunk<
  RecommendationsData,
  GetRecommendationsResultsArgs,
  { state: RootState }
>('recommendations/getResults', getRecommendationsResultsThunk, {
  condition: (_, { getState }) => {
    const {
      recommendations: { fetchState },
    } = getState();
    if (fetchState === FiniteStates.Loading) {
      return false;
    }
    return undefined;
  },
});

const recommendations = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecommendationsResults.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getRecommendationsResults.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message || 'Sorry, unexpected error happened';
    });
    builder.addCase(getRecommendationsResults.fulfilled, (state, action) => {
      state.fetchState = FiniteStates.Success;
      state.data = action.payload;
      state.error = null;
    });
  },
});

const recommendationsSelector = (state: RootState) => state.recommendations;

export const selectRecommendations = createSelector(
  recommendationsSelector,
  ({ data }) => data
);

export const selectRecommendedDocsOrganizeDocIds = createSelector(
  recommendationsSelector,
  ({ data }): string[] =>
    data.groupedResults.reduce((acc, cur) => {
      return [...acc, ...cur.documents.map((d) => d.data.organizeDocId)];
    }, [] as string[])
);

export const selectRecommendationIDCounts = createSelector(
  selectRecommendations,
  ({ groupedResults }) =>
    groupedResults.map(({ id, totalRecCount }) => ({
      uuid: id,
      count: totalRecCount,
    }))
);

export const selectRecommendationsLoading = createSelector(
  recommendationsSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const selectRecommendationsFailure = createSelector(
  recommendationsSelector,
  ({ fetchState, error }) => fetchState === FiniteStates.Failure && error
);

export default recommendations.reducer;
