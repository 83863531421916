import {
  UserDocumentAccessRights,
  UserDocumentForm,
} from '@zarn/vendor/dist/user-documents';

import { CreateExternalDocumentPayload } from './externalDocApi.types';
import { serializeDocResource } from 'common/utils/documents';
import { getAuthorsFullNames } from 'containers/RetrievalUnit/retrievalUnit.utils';

export const serializeCreateExternalDocumentPayload = ({
  uri,
  numberOfCitations,
  resources = [],
  title,
  abstractContent,
  date,
  year,
  source,
  authors,
  searchEngine,
}: CreateExternalDocumentPayload): UserDocumentForm => {
  return {
    access_roles: [UserDocumentAccessRights.Own],
    uri: uri ?? undefined,
    document_metadata: {
      title: title,
      description: abstractContent,
      date: date || new Date().toISOString(),
      year: year ?? undefined,
      resources: resources?.map(serializeDocResource),
      source: source,
      authors: getAuthorsFullNames({ authors }),
      search_engine: searchEngine,
      cited_by_count: numberOfCitations ?? null,
    },
  };
};
