import { Box, Paper, styled } from '@mui/material';
import { ComponentProps, JSXElementConstructor } from 'react';

export const generateWithoutScroll = <
  C extends JSXElementConstructor<ComponentProps<C>>
>(
  Component: C
) =>
  styled(Component)(() => ({
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }));

export const BoxWithoutScroll = generateWithoutScroll(Box);
export const PaperWithoutScroll = generateWithoutScroll(Paper);
