import React from 'react';
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  SvgIconTypeMap,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type MoreActionItem = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick: Function;
};

type Props = {
  testId?: string;
  title: string;
  actions: MoreActionItem[];
  size?: 'small' | 'medium';
  disabled?: boolean;
};

export const MoreActions = React.memo(
  ({ disabled, title, actions, size, testId }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleShow = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      callback: Function
    ) => {
      handleClose();
      callback();
    };

    return (
      <>
        <Tooltip title={title}>
          <span>
            <IconButton
              data-testid={testId}
              onClick={handleShow}
              disabled={disabled}
              size={size}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {actions.map(({ label, Icon, onClick }) => (
            <MenuItem
              key={label}
              aria-label={label}
              onClick={(e) => handleItemClick(e, onClick)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
);

MoreActions.displayName = 'MoreActions';
