import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteNote } from 'api/notesApi/notesApi';
import { BaseError } from 'common/models/Error.interface';
import { docNotesQueryKeys } from '../docNotes.utils';
import { NoteDetails, NoteListSortBy } from 'api/notesApi/notesApi.types';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { paginatedNotesOptimisticDelete } from 'containers/SavedNotes/savedNotes.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export type UseDocNoteDeleteProps = {
  docId: string;
  q?: string;
  sortBy?: NoteListSortBy;
  withAnnotations?: boolean;
  options?: UseMutationOptions<
    number,
    BaseError,
    number,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >;
};

export const useDocNoteDelete = ({
  docId,
  options = {},
}: UseDocNoteDeleteProps) => {
  const parsedHostname = useParsedHostname();
  const queryClient = useQueryClient();

  return useMutation<
    number,
    BaseError,
    number,
    { previousNotes?: PaginatedResults<NoteDetails> }
  >(
    async (noteId) => {
      await deleteNote(noteId, parsedHostname.tenant);

      return noteId;
    },
    {
      onMutate(noteId) {
        const previousNotes = queryClient.getQueryData<
          PaginatedResults<NoteDetails>
        >(docNotesQueryKeys.list(docId));

        queryClient.setQueryData<PaginatedResults<NoteDetails> | undefined>(
          docNotesQueryKeys.list(docId),
          (oldResults) => paginatedNotesOptimisticDelete(noteId, oldResults)
        );

        return { previousNotes };
      },
      onError: (_, __, context) => {
        queryClient.setQueryData(
          docNotesQueryKeys.list(docId),
          context?.previousNotes
        );
      },
      onSettled() {
        queryClient.invalidateQueries<PaginatedResults<NoteDetails>>(
          docNotesQueryKeys.list(docId)
        );
      },
      ...options,
    }
  );
};
