import React, { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useTranslation } from 'react-i18next';

import {
  MoreActionItem,
  MoreActions,
} from 'common/components/MoreActions/MoreActions';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { NoteObjectId } from 'api/notesApi/notesApi.types';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export type NoteItemActionsProps = {
  objectId: NoteObjectId;
  noteId: number;
  onEdit: () => void;
  onDelete: (objectId: NoteObjectId, noteId: number) => Promise<void>;
  onOpenSearchDialog: () => void;
  isChatConversation: boolean;
};

const NoteItemActions = ({
  objectId,
  noteId,
  onEdit,
  onDelete,
  onOpenSearchDialog,
  isChatConversation,
}: NoteItemActionsProps) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  const moreActionItems: MoreActionItem[] = useMemo(() => {
    const actionItems: MoreActionItem[] = [];

    if (!isChatConversation) {
      actionItems.push({
        label: t('notes.actions.discover'),
        Icon: PlagiarismIcon,
        onClick: onOpenSearchDialog,
      });
    }

    if (!isChatConversation) {
      actionItems.push({
        label: t('notes.actions.editNote'),
        Icon: EditIcon,
        onClick: onEdit,
      });
    }

    actionItems.push({
      label: t('notes.actions.deleteNote'),
      Icon: DeleteIcon,
      onClick: () => setOpen(true),
    });

    return actionItems;
  }, [isChatConversation, onEdit, onOpenSearchDialog, t]);

  const handleDelete = useCallback(async () => {
    setOpen(false);
    await onDelete(objectId, noteId);
  }, [onDelete, objectId, noteId]);

  return (
    <>
      <MoreActions
        testId="noteActions"
        title={t('notes.dialog.title')}
        actions={moreActionItems}
        size="small"
      />

      <ExtendedConfirmationDialog
        open={open}
        setOpen={setOpen}
        onSubmit={handleDelete}
        aria-label="delete note"
        eventName={TrackEventName.NoteDeleted}
        eventProps={{ noteId }}
      >
        {t('notes.dialog.body')}
      </ExtendedConfirmationDialog>
    </>
  );
};

export default NoteItemActions;
