import React, { FC } from 'react';
import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOidcSignIn } from 'common/hooks/useSignIn/useOidcSignIn';
import { AppTheme } from 'app/theme';

export const MainToolbarOidcSignInButton: FC = () => {
  const { t } = useTranslation('common');
  const { handleSignIn } = useOidcSignIn();
  const theme = useTheme<AppTheme>();

  return (
    <Button
      sx={{ color: theme.palette.header.iconColor }}
      onClick={handleSignIn}
    >
      {t('buttons.signIn')}
    </Button>
  );
};
