import React, { useCallback, useContext, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { NoteForm } from 'common/components/Notes/NoteForm';
import { NoteItemContext } from 'common/components/Notes/noteItem.context';
import { NoteFormValue } from 'containers/SavedNotes/SavedNotes.interface';
import { getDateModified } from 'containers/SavedNotes/savedNotes.utils';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { TrackEventName } from '../TrackedActions/withTrackedAction.interface';

const MIN_NOTE_LENGTH = 1;

interface Props {
  onUpdate: (note: NoteDetails) => Promise<void>;
  validate?: boolean;
}

export const NoteContentUpdate = React.forwardRef(
  ({ onUpdate, validate }: Props, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation('saved');
    const { editModeToggle, note } = useContext(NoteItemContext);
    const initialValues = useMemo(() => ({ content: note.content }), [note]);

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          content: Yup.string()
            .min(MIN_NOTE_LENGTH, t('saved:tagDocuments.errors.minLength'))
            .required(t('common:errors.required')),
        }),
      [t]
    );

    const handleSubmit = useCallback(
      async (
        { content }: NoteFormValue,
        { setSubmitting }: FormikHelpers<NoteFormValue>
      ) => {
        setSubmitting(true);
        await onUpdate({
          ...note,
          content,
          dateModified: getDateModified(),
        });
        setSubmitting(false);
        editModeToggle();
      },
      [editModeToggle, note, onUpdate]
    );

    return (
      <div ref={ref} aria-label="edit mode" data-testid="NoteContentUpdate">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onReset={editModeToggle}
          validationSchema={!validate && validationSchema}
        >
          {(props) => (
            <NoteForm
              {...props}
              onClickAway={editModeToggle}
              submitButtonText={t('tagDocuments.buttons.update')}
              eventName={TrackEventName.NoteUpdated}
              eventProps={{ noteId: note.id }}
            />
          )}
        </Formik>
      </div>
    );
  }
);

NoteContentUpdate.displayName = 'NoteContentUpdate';
