import React from 'react';
import { Skeleton } from '@mui/material';

const ShareSelectionSkeleton = () => {
  return (
    <div>
      <Skeleton variant="rectangular" height={40} />

      <Skeleton height={20} width="20%" />

      <Skeleton height={100} />
    </div>
  );
};

ShareSelectionSkeleton.displayName = 'ShareSelectionSkeleton';

export default ShareSelectionSkeleton;
