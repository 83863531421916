import { useTenantSettings } from './useTenantSettings';

export const useAssertTenantSettings = () => {
  const data = useTenantSettings();

  return {
    ...data,
    tenantSettings: data.assertTenantSettings(),
  };
};
