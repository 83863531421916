import React, { FC } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from 'react-i18next';

import {
  withPrivateDocsMenu,
  WithPrivateDocsMenuProps,
} from 'containers/PrivateDocs/hocs/withPrivateDocsMenu';
import { AppTheme } from 'app/theme';

const MainToolbarPrivateDocs: FC<WithPrivateDocsMenuProps> = ({
  onMenuOpen,
}) => {
  const { t } = useTranslation('privateDocs');
  const theme = useTheme<AppTheme>();

  return (
    <Tooltip title={t<string>('addButton.text')}>
      <IconButton
        aria-label="Add private docs dropdown button"
        data-testid="addPrivateDocsDropdownBtn"
        onClick={onMenuOpen}
        size="large"
      >
        <AddBoxIcon sx={{ color: theme.palette.header.iconColor }} />
      </IconButton>
    </Tooltip>
  );
};

MainToolbarPrivateDocs.displayName = 'MainToolbarPrivateDocs';

export default withPrivateDocsMenu(MainToolbarPrivateDocs);
