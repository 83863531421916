import { useQuery, UseQueryResult } from 'react-query';
import { TagType } from '@zarn/vendor/dist/saved-results';

import { listTaggedDocs } from 'api/documentsApi/documentsApi';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { HitType } from 'common/enums';
import { listDocuments } from 'api/searchApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { useSelector } from 'react-redux';
import { selectIndexCluster } from 'containers/User/user.slice';
import { QUERY_OPTIONS } from 'common/constants/query-options';

export interface TagPreviewDocsData {
  count: number;
  items: RetrievalUnitData[];
}

export type UseTagPreviewDocsReturn = {} & UseQueryResult<TagPreviewDocsData>;

export type UseTagPreviewDocsProps = {
  tagId: number;
  tagType: TagType;
  tagName: string;
};

export const useTagPreviewDocs = ({
  tagId,
  tagType,
}: UseTagPreviewDocsProps): UseTagPreviewDocsReturn => {
  const parsedHostname = useParsedHostname();
  const indexCluster = useSelector(selectIndexCluster);

  const query = useQuery<TagPreviewDocsData>(
    ['tagPreviewDocs', tagId, indexCluster],
    async () => {
      const {
        data: { items: data },
      } = await listTaggedDocs({
        tagId,
        tagType,
        pageSize: 100000, // TODO change to useInfiniteQuery?
        tenant: parsedHostname.tenant,
      });

      const count = data.length;

      const docIds: string[] = [];

      for (let i = 0; i < data.length; i++) {
        const { docId, docType } = data[i];

        if (docIds.length === 3) break;

        if (docType === HitType.Document) {
          docIds.push(docId);
        }
      }

      if (docIds.length === 0) {
        return {
          count,
          items: [],
        };
      }

      const {
        data: { items },
      } = await listDocuments({
        docIds,
        propertyName: 'organize_doc_id',
        tenant: parsedHostname.tenant,
        indexCluster: indexCluster as string | undefined,
      });

      return {
        count,
        items,
      };
    },
    {
      ...QUERY_OPTIONS,
      enabled: false,
    }
  );

  return query;
};
