import React, { useEffect, useRef, useState } from 'react';
import { captureException } from '@sentry/react';

const isReady = () =>
  typeof window?.grecaptcha !== 'undefined' &&
  typeof window?.grecaptcha.render === 'function';

export type RecaptchaProps = {
  id: string;
  onVerify?: (token: string) => void;
  onExpired?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  className?: string;
  theme?: grecaptcha.GRecaptchaTheme;
  size?: grecaptcha.GRecaptchaSize;
  tabindex?: number;
};

const Recaptcha = ({
  id,
  className,
  tabindex,
  onVerify,
  onExpired,
  onError,
  onLoad,
  theme = 'light',
  size = 'normal',
}: RecaptchaProps) => {
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const [captcha, setCaptcha] = useState<number | null>(null);
  const [ready, setReady] = useState<boolean>(isReady);

  useEffect(() => {
    try {
      const el = widgetRef.current;

      if (!ready || !el || captcha !== null) return;

      const newCaptcha = grecaptcha.render(el, {
        sitekey: window.env.RECAPTCHA_SITEKEY,
        theme: theme,
        size: size,
        tabindex: tabindex,
        callback: onVerify,
        'expired-callback': onExpired,
        'error-callback': onError,
      });

      setCaptcha(newCaptcha);

      onLoad?.();
    } catch (error) {
      captureException(error);
      console.error(error);
    }
  }, [
    captcha,
    widgetRef,
    ready,
    theme,
    size,
    tabindex,
    onVerify,
    onExpired,
    onError,
  ]);

  useEffect(() => {
    if (ready) return;

    const int = setInterval(() => {
      setReady(isReady());
    });

    return () => {
      clearInterval(int);
    };
  }, [ready]);

  return <div ref={widgetRef} id={id} className={className} />;
};

export default Recaptcha;
