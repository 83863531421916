import { useState } from 'react';

import { ListNotesPayload, NoteListSortBy } from 'api/notesApi/notesApi.types';
import { NotesFilterVisibility } from 'containers/NotesFilter/NotesFilter.types';
import { serializeNotesFilterVisibility } from 'containers/NotesFilter/notesFilter.utils';

export interface DocPDFNotesFormValues {
  sortBy: NoteListSortBy;
  visibility: NotesFilterVisibility;
}

export const initialValues: DocPDFNotesFormValues = {
  sortBy: 'annotation_highlight_position:asc',
  visibility: NotesFilterVisibility.All,
};

export const useDocPDFNotesForm = () => {
  const [notesPayload, setNotesPayload] = useState<
    Pick<ListNotesPayload, 'sortBy' | 'createdByMe'>
  >({
    sortBy: initialValues.sortBy,
    createdByMe: serializeNotesFilterVisibility(initialValues.visibility),
  });

  const handleSubmit = async ({
    sortBy,
    visibility,
  }: DocPDFNotesFormValues): Promise<void> => {
    setNotesPayload({
      createdByMe: serializeNotesFilterVisibility(visibility),
      sortBy,
    });
  };

  return {
    notesPayload,
    initialValues,
    onSubmit: handleSubmit,
  };
};
