import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, Fab, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useHiddenForPages } from 'common/hooks/useHiddenForPages';
import { FeedbackForm } from '../FeedbackForm/FeedbackForm';

export const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
  },
}));

type Props = {
  buttonText?: string;
};

export const FeedbackDialogButton = ({ buttonText }: Props) => {
  const { t } = useTranslation('feedback');
  const isHidden = useHiddenForPages(['/reset-password', '/create-password']);
  const { isAuthenticated } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  if (isHidden || !isAuthenticated) return null;

  return (
    <div data-testid="feedbackDialogButton">
      {buttonText ? (
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleDialogOpen}
        >
          {buttonText}
        </Button>
      ) : (
        <Fab
          size="small"
          className={classes.fab}
          aria-label="feedback button"
          color="secondary"
          onClick={handleDialogOpen}
        >
          <FeedbackIcon />
        </Fab>
      )}

      <Dialog
        fullWidth
        open={open}
        maxWidth="sm"
        onClose={handleDialogClose}
        aria-label="feedback dialog"
      >
        <DialogTitle>{t('dialogLeaveFeedback.title')}</DialogTitle>

        <FeedbackForm handleClose={handleDialogClose} />
      </Dialog>
    </div>
  );
};
