import React, { FC } from 'react';
import { Link } from '@mui/material';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & React.ComponentProps<typeof Link>;

const TrackedRouterLink: FC<WithTrackedActionWrappedProps<Props>> = ({
  onClick,
  onAction,
  children,
  ...rest
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Link {...rest} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(
  TrackedRouterLink
);
