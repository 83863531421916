import React from 'react';
import { Link, LinkProps, TypographyProps } from '@mui/material';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';

type Props = {
  children: React.ReactNode;
  variant?: TypographyProps['variant'];
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
} & LinkProps<'button', {}>;

const ButtonLink = ({
  onClick,
  onAction,
  children,
  variant = 'body2',
  ...rest
}: WithTrackedActionWrappedProps<Props>) => {
  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    onAction();
    if (!onClick) return;

    onClick(event);
  };
  return (
    <Link
      component="button"
      variant={variant}
      type="button"
      {...rest}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default withTrackedAction<Props & WithTrackedActionProps>(ButtonLink);
