import React, { useState } from 'react';
import { CSSProperties } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../../Buttons/ButtonLink';
import { MarkdownText } from '../../Markdown/MarkdownText';
import { removeHtmlTags } from 'common/utils/removeHtmlTags';
import { WithTrackedActionProps } from 'common/components/TrackedActions/withTrackedAction';

export const useStyles = makeStyles((theme: Theme) => ({
  collapseButton: {
    verticalAlign: 'inherit',
    marginLeft: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
  },
}));

type Props = {
  maxTextLength: number;
  text: string;
  renderInHTML?: boolean;
  renderMarkdown?: boolean;
  onCollapse?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => any;
  expandable?: boolean;
  contentClassname?: string;
} & WithTrackedActionProps;

export const TruncatedContent = React.memo(
  ({
    text,
    maxTextLength,
    renderInHTML,
    renderMarkdown,
    onCollapse,
    contentClassname,
    eventName,
    eventProps,
    expandable = true,
  }: Props) => {
    const { t } = useTranslation('common');
    const { collapseButton } = useStyles();
    const [collapsed, setCollapsed] = useState(true);

    const shouldBeTruncated = React.useMemo(
      () => maxTextLength <= text.length,
      [text, maxTextLength, onCollapse]
    );

    const truncatedText = React.useMemo(() => {
      return shouldBeTruncated ? `${text.slice(0, maxTextLength)} ...` : text;
    }, [text, maxTextLength, shouldBeTruncated]);

    const toggleCollapse = (
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      setCollapsed(!collapsed);
      if (onCollapse && collapsed) {
        onCollapse(e);
      }
    };

    const content = React.useMemo(() => {
      const toRender = removeHtmlTags(collapsed ? truncatedText : text);
      if (renderInHTML) {
        // eslint-disable-next-line react/no-danger
        return (
          <span
            dangerouslySetInnerHTML={{ __html: toRender }}
            className={contentClassname}
          />
        );
      }
      if (renderMarkdown) {
        return (
          <MarkdownText className={contentClassname}>{toRender}</MarkdownText>
        );
      }
      return toRender;
    }, [
      truncatedText,
      contentClassname,
      text,
      renderInHTML,
      renderMarkdown,
      collapsed,
    ]);

    return (
      <>
        {content}
        {shouldBeTruncated && expandable && (
          <ButtonLink
            underline="hover"
            onClick={toggleCollapse}
            className={collapseButton}
            eventName={eventName}
            eventProps={eventProps}
          >
            {collapsed ? t('buttons.more') : t('buttons.less')}
          </ButtonLink>
        )}
      </>
    );
  }
);

TruncatedContent.displayName = 'TruncatedContent';
