import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  tagName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  owner: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    flex: 'none',
  },
}));

export type TagsAutocompleteOptionProps = {
  option: TagsAutocompleteOptionValue;
  owner: string;
  isNew?: boolean;
};

const TagsAutocompleteOption = ({
  option,
  isNew,
}: TagsAutocompleteOptionProps) => {
  const classes = useStyles();
  const { t } = useTranslation('tags');
  const isShared = option.type === TagType.Shared;
  const { data } = useUserDetails({
    memberId: option.ownerId ?? '',
    options: { enabled: !!option.ownerId, staleTime: Infinity },
  });

  if (isNew) {
    return (
      <Typography noWrap title={option.name}>
        <strong>
          {t('tagsAutocomplete.option.createNewTag', { tagName: option.name })}
        </strong>
      </Typography>
    );
  }

  return (
    <Typography className={classes.root} title={option.name}>
      <span className={classes.tagName}>{option.name}</span>

      {isShared && data?.firstName && (
        <i className={classes.owner}>
          {t('tagsAutocomplete.option.tagOwner', { owner: data.firstName })}
        </i>
      )}
    </Typography>
  );
};

export default TagsAutocompleteOption;
