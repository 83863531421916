import { newSearchParamsOnQueryChange } from 'common/utils/searchQueryParams';
import { ParsedHostname } from 'common/utils/useParsedHostname';
import { SearchPayloadFromURL } from '../models/SearchPayloadFromURL';
import { DynamicSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';

// TODO: test
export const getPayloadAndSearchParams = (
  value: string,
  search: string,
  parsedHostname: ParsedHostname,
  indexCluster?: string,
  searchDynamicsFilters?: DynamicSettings[] | null
) => {
  const searchParams = newSearchParamsOnQueryChange(value, search);
  const payloadFromURL = new SearchPayloadFromURL(
    searchParams,
    parsedHostname,
    1,
    indexCluster,
    searchDynamicsFilters
  );
  const payload = payloadFromURL.getSearchPayload();

  return {
    searchParams,
    payload,
  };
};
