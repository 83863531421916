import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchTermEnum } from 'common/enums';
import DocumentCitesIcon from 'common/components/CustomIcons/DocumentCitesIcon';
import { useSearchByTerm } from 'common/hooks/useSearchByTerm';
import DocumentMetadataButton from 'common/components/DocumentMetadataButton/DocumentMetadataButton';
import DocumentMetadataButtonChip from 'common/components/DocumentMetadataButton/DocumentMetadataButtonChip';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export type DocCitesProps = {
  score: number;
  getCitesId: string | null;
};

const DocCites = ({ score, getCitesId }: DocCitesProps) => {
  const { t } = useTranslation('common');
  const { searchByTerm } = useSearchByTerm();

  const handleClick = useCallback(() => {
    if (getCitesId) {
      searchByTerm(SearchTermEnum.Cites, getCitesId);
    }
  }, [searchByTerm, getCitesId]);

  return (
    <DocumentMetadataButton
      onClick={handleClick}
      disabled={!score || !getCitesId}
      startIcon={<DocumentCitesIcon color="action" />}
      tooltip={t('retrievalUnit.actions.citedBy', { numberOfCitations: score })}
      eventName={TrackEventName.CitedByButtonClicked}
      eventProps={{ ontologyId: getCitesId }}
    >
      <DocumentMetadataButtonChip showZero score={score} />
    </DocumentMetadataButton>
  );
};

export default DocCites;
