import React, { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useNoteFormValidation } from 'common/components/Notes/NoteCreate/useNoteFormValidation';
import { ExplainHighlightInputForm } from './ExplainHighlightInputForm';
import { InputFormValue } from 'containers/SearchWidgets/QuestionAnsweringWidget/Header/Input/SearchAnswers.interface';
import { captureException } from '@sentry/react';

const initialValues: InputFormValue = {
  content: '',
};

type ExplainHighlightInputProps = {
  onAdd: (content: string) => void;
  question: string;
  editModeToggle: () => void;
} & BoxProps;

const ExplainHighlightInput = ({
  onAdd,
  question,
  editModeToggle,
  ...boxProps
}: ExplainHighlightInputProps) => {
  const { t } = useTranslation('common');
  const { validationSchema } = useNoteFormValidation();
  const [inputValue, setInputValue] = useState<string>(question);

  useEffect(() => {
    setInputValue(question);
  }, [question]);

  const handleSubmit = useCallback(
    async (
      { content }: InputFormValue,
      { setSubmitting }: FormikHelpers<InputFormValue>
    ) => {
      try {
        setSubmitting(true);
        await onAdd(content);
      } catch (error) {
        captureException(error);
        setSubmitting(false);
      }
    },
    [onAdd]
  );

  return (
    <Box {...boxProps} sx={{ mx: 0, width: '95%' }}>
      <Formik
        border={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onReset={editModeToggle}
        validationSchema={validationSchema}
      >
        {(props) => (
          <ExplainHighlightInputForm
            {...props}
            name="content"
            onClickAway={editModeToggle}
            submitButtonText={t('buttons.go')}
            setInputValue={setInputValue}
            inputValue={inputValue}
            eventName={TrackEventName.UpdatePDFViewerExplainHighlightQuestion}
          />
        )}
      </Formik>
    </Box>
  );
};

export default ExplainHighlightInput;
