import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { createInitialQuery, SavedQueryDetails } from 'api/savedQueriesApi';
import { BaseError } from 'common/models/Error.interface';
import { INITIAL_QUERY_KEY } from 'containers/InitialQuery/initialQuery.utils';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';

export const useCreateInitialQuery = (
  options?: UseMutationOptions<SavedQueryDetails, BaseError, number>
) => {
  const { t } = useTranslation('search');
  const { tenant } = useParsedHostname();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { searchDynamicsFilters } = useAssertTenantSettings();

  return useMutation<SavedQueryDetails, BaseError, number>(
    async (payload: number) => {
      try {
        return (
          await createInitialQuery(payload, tenant, searchDynamicsFilters)
        ).data;
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      onSuccess(data: SavedQueryDetails) {
        queryClient.setQueryData<SavedQueryDetails>(
          INITIAL_QUERY_KEY,
          () => data
        );
        enqueueSnackbar(
          t('initialQuery.update.successMessage', { queryName: data.queryName })
        );
      },
      ...options,
    }
  );
};
