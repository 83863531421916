import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { LoginDialogState } from 'containers/Auth/Auth.context';
import AlertAutoDismiss from 'common/components/AlertAutoDismiss';
import { authRequestMagicToken } from 'api/authApi';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { useStyles } from './styles';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';

interface ForgotPasswordFormValues {
  email: string;
}

const initialValues: ForgotPasswordFormValues = {
  email: '',
};

type ForgotPasswordFormProps = {
  handleClose: () => void;
  setOpenState: React.Dispatch<React.SetStateAction<LoginDialogState>>;
};

const ForgotPasswordForm = ({
  handleClose,
  setOpenState,
}: ForgotPasswordFormProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { tenant } = useParsedHostname();
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const resetPasswordSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('common:errors.invalidEmail'))
          .required(t('common:errors.required')),
      }),
    []
  );

  const goBack = () => {
    setOpenState(LoginDialogState.Login);
  };

  const handleReset = () => {
    handleClose();
  };

  const handleDismiss = useCallback(() => {
    handleClose();
  }, [setSuccess]);

  const handleErrorDismiss = useCallback(() => {
    setErrorAlert(null);
  }, [setSuccess]);

  const handleSubmit = async (
    values: ForgotPasswordFormValues,
    {
      validateForm,
      setFieldError,
      setSubmitting,
    }: FormikHelpers<ForgotPasswordFormValues>
  ): Promise<void> => {
    await validateForm(values);

    try {
      setSubmitting(true);
      await authRequestMagicToken(tenant, values);
      setSuccess(true);
    } catch (error: any) {
      captureException(error);
      const emailError = error?.response?.data?.detail;
      const overallError = error?.response?.data?.message;
      if (emailError) setFieldError('email', emailError);
      if (overallError) setErrorAlert(overallError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={resetPasswordSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      {({ isSubmitting }) => (
        <Form id="resetPasswordForm" aria-label="Reset password form">
          <DialogContent>
            <DialogContentText gutterBottom>
              {t('user.forgotPassword.dialogText')}
            </DialogContentText>

            <FormTextField
              name="email"
              type="email"
              label={t('user.forgotPassword.email')}
            />

            {errorAlert && (
              <Box mt={3}>
                <AlertAutoDismiss
                  dismissIn={3000}
                  severity="error"
                  onDismiss={handleErrorDismiss}
                  text={errorAlert}
                />
              </Box>
            )}

            {success && (
              <Box mt={3}>
                <AlertAutoDismiss
                  dismissIn={6000}
                  onDismiss={handleDismiss}
                  text={t('user.forgotPassword.successMessage')}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button onClick={goBack}>{t('buttons.back')}</Button>

            <div className={classes.rightActions}>
              <Button type="reset">{t('buttons.cancel')}</Button>

              <SubmitButton
                text={t('buttons.resetPassword')}
                isSubmitting={isSubmitting}
                disabled={success}
                eventName={TrackEventName.ResetPasswordClicked}
              />
            </div>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
