import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AnalyticsRequest } from '@zarn/vendor/dist/search';
import { createAnalyticsGraph } from 'api/analyticsGraphApi';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { AnalyticsGraphNameEnum } from 'common/enums';
import { RootState } from 'app/state/store';
import { deserializeAxiosError } from 'common/utils/error';
import { mapAnalyticsResponse } from './analyticsGraph.utils';
import { AnalyticsGraph } from './AnalyticsGraph.interface';
import { captureException } from '@sentry/react';

export type AnalyticsGraphState = {
  fetchState: FiniteStatesType;
  data: {
    [AnalyticsGraphNameEnum.Interest]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Publications]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Organizations]: AnalyticsGraph | null;
    [AnalyticsGraphNameEnum.Countries]: AnalyticsGraph | null;
  };
  error: string | null;
};

export const initialState: AnalyticsGraphState = {
  error: null,
  fetchState: FiniteStates.Idle,
  data: {
    [AnalyticsGraphNameEnum.Interest]: null,
    [AnalyticsGraphNameEnum.Publications]: null,
    [AnalyticsGraphNameEnum.Organizations]: null,
    [AnalyticsGraphNameEnum.Countries]: null,
  },
};

export type GetAnalyticsArg = {
  payload: AnalyticsRequest;
  graphName: AnalyticsGraphNameEnum;
};

export const getAnalyticsGraph = createAsyncThunk(
  'analyticsGraph/getAnalyticsGraph',
  async ({ payload, graphName }: GetAnalyticsArg) => {
    try {
      const { data } = await createAnalyticsGraph(payload);
      return {
        graphName,
        data: mapAnalyticsResponse(data),
      };
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

const analyticsGraph = createSlice({
  name: 'analyticsGraph',
  initialState,
  reducers: {
    resetAnalyticsGraphState: (
      state,
      { payload }: PayloadAction<AnalyticsGraphNameEnum>
    ) => {
      state.data[payload] = null;
      state.fetchState = initialState.fetchState;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsGraph.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getAnalyticsGraph.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error = action.error.message ?? 'unexpected error';
    });
    builder.addCase(getAnalyticsGraph.fulfilled, (state, { payload }) => {
      state.fetchState = FiniteStates.Success;
      state.data[payload.graphName] = payload.data;
      state.error = null;
    });
  },
});

const getAnalyticsGraphSelector = (state: RootState) => state.analyticsGraph;

export const analyticsGraphDataSelector = (state: RootState) =>
  state.analyticsGraph.data;

export const selectAnalytics = (target: AnalyticsGraphNameEnum) => {
  return createSelector(analyticsGraphDataSelector, (data) => data[target]);
};

export const selectAnalyticsGraphLoading = createSelector(
  getAnalyticsGraphSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const { resetAnalyticsGraphState } = analyticsGraph.actions;

export default analyticsGraph.reducer;
