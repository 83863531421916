import React, { useRef } from 'react';
import { alpha, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { useScrollIntoView } from 'common/hooks/useScrollIntoView';
import { useSpotlightHighlighted } from './hooks/useSpotlightHighlighted';

export const useStyles = makeStyles<Theme, { highlightColor?: string }>(
  (theme: Theme) => ({
    root: {
      transition: theme.transitions.create(['box-shadow', 'background-color']),
    },
    rootHighlighted: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: ({ highlightColor }) =>
        alpha(highlightColor ?? theme.palette.secondary.main, 0.1),
      boxShadow: ({ highlightColor }) =>
        `0px 0px 6px 6px ${alpha(
          highlightColor ?? theme.palette.secondary.main,
          0.1
        )}`,
    },
  })
);

export type SpotlightProps = {
  active: boolean;
  highlightStopDelay?: number;
  highlightColor?: string;
  shouldHighlight?: boolean;
  shouldScroll?: boolean;
  children: React.ReactNode;
};

const Spotlight = ({
  children,
  active,
  shouldScroll,
  shouldHighlight,
  highlightColor,
  highlightStopDelay,
}: SpotlightProps) => {
  const classes = useStyles({ highlightColor });
  const ref = useRef<HTMLDivElement | null>(null);
  useScrollIntoView(ref, active && shouldScroll);
  const highlighted = useSpotlightHighlighted(
    active && shouldHighlight,
    highlightStopDelay
  );

  return (
    <div
      ref={ref}
      className={clsx(classes.root, highlighted && classes.rootHighlighted)}
    >
      {children}
    </div>
  );
};

export default Spotlight;
