import { isNotDefined } from './assert';

export interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export const openFullscreen = (
  el: DocumentElementWithFullscreen = document.body
) => {
  if (el.requestFullscreen) {
    void el.requestFullscreen();
  } else if (el.msRequestFullscreen) {
    el.msRequestFullscreen();
  } else if (el.webkitRequestFullscreen) {
    el.webkitRequestFullscreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  }
};

export const closeFullscreen = (el: DocumentWithFullscreen = document) => {
  if (el.fullscreenElement && el['exitFullscreen']) {
    el['exitFullscreen']();
  } else if (el.webkitExitFullscreen) {
    /* Safari */
    el.webkitExitFullscreen();
  } else if (el.msExitFullscreen) {
    /* IE11 */
    el.msExitFullscreen();
  } else if (el.mozCancelFullScreen) {
    el.mozCancelFullScreen();
  }
};

export const toggleFullscreen = (isFullscreen: boolean | undefined) => {
  if (isNotDefined(isFullscreen)) {
    return;
  }
  return isFullscreen ? openFullscreen() : closeFullscreen();
};

export const tryToToggleFullscreen = (isFullscreen: boolean | undefined) => {
  try {
    toggleFullscreen(isFullscreen);
  } catch (error) {
    console.error('Error while trying to Toggle fullscreen', error);
  }
};
