import { UserPayload } from '@zarn/vendor/dist/auth';

export const jwtAccessTokenMock =
  'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwiZXhwIjoyNTk2MDYwNjgyLCJpYXQiOjI1OTYwMzE4ODIsIm5iZiI6MjU5NjAzMTg4Miwic3ViIjoiOWI3Yzc0MGQtNmQ2ZC00NWUyLTgyODgtNmMwNmQ1NTA2MGNhIiwicm9sZXMiOltdLCJhY2Nlc3NfcmlnaHRzIjpbInB1YmxpYyIsInVzZXJfdXVpZDo5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiXSwib3JnX3Jlc291cmNlcyI6W10sInVzZXJfcm9sZXMiOnsicm9sZXMiOlsicHVibGljIiwidXNlcl91dWlkOjliN2M3NDBkLTZkNmQtNDVlMi04Mjg4LTZjMDZkNTUwNjBjYSIsInVzZXJfcm9sZV9pZDozMSJdLCJyb2xlX2RhdGEiOlt7InVzZXJfcm9sZV9pZCI6MzEsImFjY291bnRfaWQiOjE3LCJhY2NvdW50X25hbWUiOiJaZXRhIEFscGhhIiwibmFtZSI6bnVsbCwicGVybWlzc2lvbiI6Im1lbWJlciJ9XX0sInVzZXJfdGVuYW50cyI6eyJ0ZW5hbnRzIjpbImJhciIsImZvbyJdfSwiYWxnIjoiSFMyNTYifQ.E5LT-guDJle5eBpwYz661rxYox2ToMVJ2VvLx-zWyUU';

export const jwtAccessTokenExpiredMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwiZXhwIjoxNjA0NzcxMDQ4LCJuYmYiOjE2MDQ3NzA0NDgsImlhdCI6MTYwNDc3MDQ0OCwicm9sZXMiOlsiYWRtaW4iXSwiYWNjZXNzX3JpZ2h0cyI6WyJwdWJsaWMiLCJ1c2VyX3V1aWQ6OWI3Yzc0MGQtNmQ2ZC00NWUyLTgyODgtNmMwNmQ1NTA2MGNhIl0sIm9yZ19yZXNvdXJjZXMiOlt7Im5hbWUiOiJhZG1pbiIsInJlc291cmNlIjoiKiIsImFjdGlvbiI6IioifV0sInVzZXJfcm9sZXMiOnsicm9sZXMiOlsicHVibGljIiwidXNlcl91dWlkOjliN2M3NDBkLTZkNmQtNDVlMi04Mjg4LTZjMDZkNTUwNjBjYSIsImFkbWluIiwidXNlcl9yb2xlX2lkOjMxIl0sInJvbGVfZGF0YSI6W3sidXNlcl9yb2xlX2lkIjo3LCJhY2NvdW50X2lkIjpudWxsLCJhY2NvdW50X25hbWUiOm51bGwsIm5hbWUiOm51bGwsInBlcm1pc3Npb24iOiJhZG1pbiJ9LHsidXNlcl9yb2xlX2lkIjozMSwiYWNjb3VudF9pZCI6MTcsImFjY291bnRfbmFtZSI6IlpldGEgQWxwaGEiLCJuYW1lIjpudWxsLCJwZXJtaXNzaW9uIjoibWVtYmVyIn1dfX0.N8HOQUQvpj_YmULwKL_GEWCVfeKEsGSnk24aEeeIrRc';

export const jwtRefreshTokenMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwiZXhwIjoyNjA1NDkzOTA1LCJuYmYiOjI2MDI5MDE5MDUsImlhdCI6MjYwMjkwMTkwNSwicmVmcmVzaF90b2tlbiI6Im9HcTRUQjJmVFVJOXozajh2bXROQ3BsMGNjeWFUWGNGbWRTV2NUbGRVdGVCUThoTUxIeUpGSkpZZ2NnUkoxekIifQ.zOCj56y1eqCxLzLVedNPKjefBqf3z2C0yevDzWuFkfE';

export const accessTokenMock: UserPayload = {
  email: 'mammadov@zeta-alpha.com',
  exp: 2596060682,
  iat: 2596031882,
  nbf: 2596031882,
  sub: '9b7c740d-6d6d-45e2-8288-6c06d55060ca',
  user_roles: {
    roles: [
      'public',
      'user_uuid:9b7c740d-6d6d-45e2-8288-6c06d55060ca',
      'user_role_id:31',
    ],
    role_data: [
      {
        user_role_id: 31,
        account_id: 17,
        account_name: 'Zeta Alpha',
        name: null,
        permission: 'member',
      },
    ],
  },
  user_tenants: { tenants: ['bar', 'foo'] },
};

export const accessTokenAdminMock: UserPayload = {
  ...accessTokenMock,
  user_roles: {
    roles: [
      'public',
      'user_uuid:9b7c740d-6d6d-45e2-8288-6c06d55060ca',
      'admin',
      'user_role_id:31',
    ],
    role_data: [
      {
        user_role_id: 7,
        account_id: null,
        account_name: null,
        name: null,
        permission: 'admin',
      },
      {
        user_role_id: 31,
        account_id: 17,
        account_name: 'Zeta Alpha',
        name: null,
        permission: 'member',
      },
    ],
  },
};

export const accessTokenManagerMock: UserPayload = {
  ...accessTokenMock,
  user_roles: {
    roles: [
      'public',
      'user_uuid:9b7c740d-6d6d-45e2-8288-6c06d55060ca',
      'manager',
      'user_role_id:31',
    ],
    role_data: [
      {
        user_role_id: 31,
        account_id: 17,
        account_name: 'Zeta Alpha',
        name: null,
        permission: 'manager',
      },
      {
        user_role_id: 31,
        account_id: 17,
        account_name: 'Zeta Alpha',
        name: null,
        permission: 'member',
      },
    ],
  },
};
