import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLogout } from '../../Auth/hooks/useLogout';
import { NavbarProfileLogoutButtonsLayout } from './NavbarProfileLogoutButtonsLayout';

export const NavbarProfileLogoutOidcButtons: FC = () => {
  const auth = useAuth();
  const logout = useLogout();

  const handleLogout = async () => {
    await logout();
    await auth.signoutRedirect();
  };

  return <NavbarProfileLogoutButtonsLayout onLogout={handleLogout} />;
};
