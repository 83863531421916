import { useMemo } from 'react';
import { FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const usePrivateDocFormValidation = () => {
  const { t } = useTranslation(['privateDocs']);
  const defaultShape = useMemo(
    () => ({
      uri: Yup.string().url(t('privateDocs:form.uri.error')),
      file: Yup.array<FileWithPath>(),
      title: Yup.string(),
      abstractText: Yup.string(),
      year: Yup.number().max(
        new Date().getFullYear(),
        t('privateDocs:form.year.error')
      ),
      source: Yup.string(),
      authors: Yup.string(),
    }),
    [t]
  );

  const validationSchema = useMemo(
    () => Yup.object().shape(defaultShape),
    [defaultShape]
  );

  return {
    defaultShape,
    validationSchema,
  };
};
