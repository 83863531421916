import React, { useRef } from 'react';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getSharedTagLink } from 'containers/TagShare/shareTag.utils';
import { FieldCopyButton } from 'common/components/Fields/FieldCopyButton';
import { TextField } from '../../../TextField';

export type TagShareLinkCopyFieldProps = {
  tagId: number;
};

const TagShareLinkCopyField = React.memo(
  ({ tagId }: TagShareLinkCopyFieldProps) => {
    const { t } = useTranslation('tags');
    const inputRef = useRef<HTMLInputElement>();
    const sharedTagLink = getSharedTagLink(tagId);

    return (
      <TextField
        fullWidth
        value={sharedTagLink}
        label={t('shareSettings.sharedTagLinkInput.label')}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <FieldCopyButton inputRef={inputRef} />
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

TagShareLinkCopyField.displayName = 'TagShareLinkCopyField';

export default TagShareLinkCopyField;
