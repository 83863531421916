import React, { memo } from 'react';
import { Chip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  chip: {
    cursor: 'pointer',
  },
}));

export type DocumentMetadataButtonChipProps = {
  score: number;
  showZero?: boolean;
  icon?: React.ReactElement;
};

const addPostfix = (score: number): string | number => {
  if (score >= 1000000) {
    return (score / 1000000).toFixed(1) + 'M';
  }

  if (score >= 1000) {
    return (score / 1000).toFixed(1) + 'k';
  }

  return score;
};

const DocumentMetadataButtonChip = memo(
  ({ score, icon, showZero }: DocumentMetadataButtonChipProps) => {
    const classes = useStyles();

    if (score === 0 && !showZero) return null;

    return (
      <Chip
        size="small"
        label={addPostfix(score)}
        className={classes.chip}
        icon={icon}
      />
    );
  }
);

DocumentMetadataButtonChip.displayName = 'DocumentMetadataButtonChip';

export default DocumentMetadataButtonChip;
