/* eslint-disable camelcase */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import i18n from 'i18next';

import { parseQuery } from 'api/entityApi';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';
import { deserializeAxiosError } from 'common/utils/error';
import { QueryEntity } from './QueryEntities.interface';
import { mapParsedQueryResponse } from './queryEntities.utils';
import { captureException } from '@sentry/react';

export type QueryEntitiesState = {
  fetchState: FiniteStatesType;
  data: QueryEntity[];
  error: string | null;
};

export const initialState: QueryEntitiesState = {
  error: null,
  fetchState: FiniteStates.Idle,
  data: [],
};

export const getParsedQueryEntities = createAsyncThunk(
  'queryEntities/get',
  async ({ queryString, tenant }: { queryString: string; tenant: string }) => {
    if (!queryString) return [];

    try {
      const { data } = await parseQuery(queryString, tenant);
      return mapParsedQueryResponse(data);
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

const queryEntities = createSlice({
  name: 'queryEntities',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
    clearQueryAnalysis: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParsedQueryEntities.pending, (state) => {
      state.fetchState = FiniteStates.Loading;
      state.error = null;
    });
    builder.addCase(getParsedQueryEntities.rejected, (state, action) => {
      state.fetchState = FiniteStates.Failure;
      state.error =
        action.error.message || i18n.t('common:errors.unexpectedError.title');
    });
    builder.addCase(getParsedQueryEntities.fulfilled, (state, action) => {
      state.fetchState = FiniteStates.Success;
      state.data = action.payload;
      state.error = null;
    });
  },
});

const queryEntitiesSelector = (state: RootState) => state.queryEntities;

export const selectQueryEntities = createSelector(
  queryEntitiesSelector,
  ({ data }) => data
);

export const selectQueryEntitiesLoading = createSelector(
  queryEntitiesSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const selectQueryEntitiesFailure = createSelector(
  queryEntitiesSelector,
  ({ fetchState, error }) => fetchState === FiniteStates.Failure && error
);

export const { clearQueryAnalysis } = queryEntities.actions;

export default queryEntities.reducer;
