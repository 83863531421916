import { NoteListSortBy } from 'api/notesApi/notesApi.types';
import { NotesFilterVisibility } from './NotesFilter.types';

export const serializeNotesFilterVisibility = (
  visibility: string | null
): boolean | undefined => {
  switch (visibility) {
    case NotesFilterVisibility.OnlyMyNotes:
      return true;
    case NotesFilterVisibility.OnlySharedNotes:
      return false;
    default:
      return;
  }
};

export const deserializeNotesFilterSearchParam = (
  value: string | null
): NotesFilterVisibility => {
  for (const v of Object.values(NotesFilterVisibility)) {
    if (v === value?.toLowerCase()) return v;
  }

  return NotesFilterVisibility.All;
};

export const deserializeNotesSortBySearchParam = (
  value: string | null,
  defaultSort: NoteListSortBy = 'date_created:desc'
): NoteListSortBy => {
  if (value?.toLowerCase() as NoteListSortBy) return value as NoteListSortBy;

  return defaultSort;
};
