import React, { FC, useContext } from 'react';
import { Box } from '@mui/material';
import { PageContext } from 'containers/Page/Page.context';
import { useCustomSignIn } from 'common/hooks/useSignIn/useCustomSignIn';
import { SignInMobileButton } from 'common/components/Buttons/SignInMobileButton';

export const MainSidebarCustomSignInButton: FC = () => {
  const { handleSignIn: handleSignInDialog } = useCustomSignIn();
  const { setPageDrawerMobileOpen } = useContext(PageContext);

  const handleSignIn = () => {
    setPageDrawerMobileOpen(false);
    handleSignInDialog();
  };

  return (
    <Box mb={2} px={2}>
      <SignInMobileButton onSignIn={handleSignIn} />
    </Box>
  );
};
