import { SERVICE_URL } from 'api/apiConfig';
import { AxiosResponse } from 'axios';
import { BaseError } from 'common/models/Error.interface';
import { deserializeSearchHit } from 'common/utils/documents';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions } from 'react-query';
import axiosInstance from 'api/axiosInstance';
import { QUERY_OPTIONS } from 'common/constants/query-options';

interface useEvidenceTitleProps {
  documentHitUrl?: string;
  options?: Omit<
    UseQueryOptions<RetrievalUnitData | null, BaseError>,
    'queryKey' | 'queryFn'
  >;
}

export const evidenceTitleQueryKeys = {
  all: ['evidence'] as const,
  details: () => [...evidenceTitleQueryKeys.all, 'details'] as const,
  detailsItem: (documentHitUrl: string | undefined) =>
    [...evidenceTitleQueryKeys.details(), documentHitUrl] as const,
};

export const useEvidenceHit = ({
  documentHitUrl,
  options = {},
}: useEvidenceTitleProps) => {
  const { t } = useTranslation('common');

  const getDocumentData = async (): Promise<
    AxiosResponse<RetrievalUnitData | null>
  > => {
    // TODO: Chang it to use API from vendors
    const response = await axiosInstance.get(`${SERVICE_URL}${documentHitUrl}`);
    const { data, ...rest } = response;
    const hit = data.hits[0];
    return {
      ...rest,
      data: hit ? deserializeSearchHit(hit) : null,
    };
  };

  const query = useQuery<RetrievalUnitData | null, BaseError>(
    evidenceTitleQueryKeys.detailsItem(documentHitUrl),

    async () => {
      if (documentHitUrl) {
        const response = await getDocumentData();
        return response.data;
      }
      return null;
    },
    {
      ...QUERY_OPTIONS,
      ...options,
      retry: 1,
      staleTime: Infinity,
    }
  );

  const isLoading = query.isLoading;

  const title: string = query.data?.title ?? t('chat.evidence.title.notFound');

  return {
    isLoading,
    title,
    chunkHighlights: query.data?.representations.chunkHighlights ?? null,
  };
};
