import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NoteObjectType } from '@zarn/vendor/dist/saved-results';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';
import { SavedNotes } from '../SavedNotes.interface';
import { savedNotesActionsFactory } from '../savedNotes.actions';

const SLICE_NAME = 'documentsNotes';

const SavedNotesActions = savedNotesActionsFactory(
  SLICE_NAME,
  NoteObjectType.Document
);

export type DocumentsNotesState = {
  fetchState: FiniteStatesType;
  data: SavedNotes;
  error: string | null;
};

export const initialState: DocumentsNotesState = {
  error: null,
  fetchState: FiniteStates.Idle,
  data: {},
};

export const { getNoteList: getDocumentsNotesData, createNote } =
  SavedNotesActions;

const documentsNotes = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
    addNotesCountToDocuments: (
      state,
      { payload }: PayloadAction<{ docId: string; notesCount: number }[]>
    ) => {
      const data = payload.reduce((acc, { docId, notesCount }) => {
        return {
          ...acc,
          [docId]: {
            id: docId,
            numberOfNotes: notesCount,
            notesData: [],
          },
        };
      }, {});

      state.data = {
        ...state.data,
        ...data,
      };
    },
  },
  extraReducers: (builder) => {
    // Get saved note list
    builder
      .addCase(getDocumentsNotesData.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(getDocumentsNotesData.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error = action.error.message || 'Unexpected error';
      })
      .addCase(getDocumentsNotesData.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data = {
          ...state.data,
          ...payload,
        };
        state.error = null;
      });
    // Create note
    builder.addCase(createNote.fulfilled, (state, { payload }) => {
      const { id, data } = payload;
      state.fetchState = FiniteStates.Success;
      if (id in state.data) {
        state.data[id].numberOfNotes += 1;
        state.data[id].notesData.unshift(data);
      } else {
        state.data[id] = { id, numberOfNotes: 1, notesData: [data] };
      }
      state.error = null;
    });
  },
});

export const { addNotesCountToDocuments } = documentsNotes.actions;

const documentsNotesSelector = (state: RootState) => state.documentsNotes;

export const selectDocumentsNotes = createSelector(
  documentsNotesSelector,
  ({ data }) => data
);

export const selectDocumentsNotesLoading = createSelector(
  documentsNotesSelector,
  ({ fetchState }) => fetchState === FiniteStates.Loading
);

export const selectDocumentsNotesError = createSelector(
  documentsNotesSelector,
  ({ error }) => error
);

export default documentsNotes.reducer;
