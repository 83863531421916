import React from 'react';
import { Divider, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export const FormSectionDivider = () => {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
};
