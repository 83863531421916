import {
  SearchPayload,
  SearchPayloadDate,
  SearchPayloadSort,
  SearchPayloadTerm,
} from 'containers/Search/SearchPayload.interface';
import {
  deserializeDateRange,
  deserializeDocIds,
  deserializeDocSources,
  deserializeFederatedSearchValue,
  deserializeIndexCluster,
  deserializeOnlyWithCodeFilterValue,
  deserializeQueryEncoderService,
  deserializeRelativeDate,
  deserializeRerank,
  deserializeReranker,
  deserializeRerankerService,
  deserializeRerankTopN,
  deserializeRetrievalMethod,
  deserializeRetrievalUnit,
  deserializeSearchQueryArrayParam,
  deserializeSortBy,
  deserializeTermFilter,
  deserializeVisiblityFilterValue,
  getDateRangeFromDateFilterValue,
  getDocTypes,
  serializeWithContent,
} from 'common/utils/searchQueryParams';
import {
  SearchSortField,
  SearchSortMethodEnum,
  SearchUrlParamsEnum,
  VisibilityAccessRightsEnum,
} from 'common/enums';
import {
  DEFAULT_SEARCH_PAYLOAD,
  SEARCH_SORT_METHOD_TO_FIELD,
} from '../constants/defaultSearchValues';
import { SearchDateFilterValueEnum } from '../SearchFilters/SearchDateFilter/SearchDateFilterValue.enum';
import { SearchVisibilityFilterValue } from '../SearchFilters/SearchFilters.interface';
import { ParsedHostname } from 'common/utils/useParsedHostname';
import {
  deserializeDynamicFiltersSearchQuery,
  isDynamicFilter,
} from 'common/utils/dynamicFilters.utils';
import { DynamicSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';

export class SearchPayloadFromURL {
  readonly searchParams: URLSearchParams;
  readonly page: number | undefined = undefined;
  readonly parsedHostname: ParsedHostname;
  readonly indexCluster: string | null | undefined = undefined;
  private readonly dynamicFiltersSettings: DynamicSettings[] | null;

  constructor(
    search: string,
    parsedHostname: ParsedHostname,
    page?: number,
    indexCluster?: string | null,
    dynamicFiltersSettings?: DynamicSettings[] | null
  ) {
    this.searchParams = new URLSearchParams(search);
    this.page = page;
    this.parsedHostname = parsedHostname;
    this.indexCluster = indexCluster;
    this.dynamicFiltersSettings = dynamicFiltersSettings ?? null;
  }

  get term(): SearchPayloadTerm | undefined {
    return deserializeTermFilter(this.searchParams);
  }

  getSearchPayload(): SearchPayload {
    if (this.searchParams.toString() === '') {
      return {
        ...DEFAULT_SEARCH_PAYLOAD,
        tenant: this.parsedHostname.tenant,
        indexCluster: this.indexCluster,
      };
    }

    return {
      queryString: this.getQueryValue(),
      retrievalUnit: deserializeRetrievalUnit(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      searchEngine: deserializeFederatedSearchValue(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      retrievalMethod: deserializeRetrievalMethod(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      relativeDate: deserializeRelativeDate(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      date: this.getDateRange(),
      sort: this.getSearchSort(),
      docSources: deserializeDocSources(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      docIds: deserializeDocIds(this.searchParams, this.dynamicFiltersSettings),
      docTypes: getDocTypes(this.searchParams, this.dynamicFiltersSettings),
      page: this.getPage(),
      term: this.term,
      withCode: deserializeOnlyWithCodeFilterValue(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      withContent: serializeWithContent(this.searchParams),
      queryEncoderService: deserializeQueryEncoderService(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      rerankerService: deserializeRerankerService(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      tenant: this.parsedHostname.tenant,
      rerank: deserializeRerank(this.searchParams, this.dynamicFiltersSettings),
      reranker: deserializeReranker(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      rerankTopN: deserializeRerankTopN(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
      visibility: this.getVisibility(),
      similarTo: !isDynamicFilter(
        SearchUrlParamsEnum.SimilarTo,
        this.dynamicFiltersSettings
      )
        ? deserializeSearchQueryArrayParam(
            this.searchParams.get(SearchUrlParamsEnum.SimilarTo)
          )
        : undefined,
      locationCountries: !isDynamicFilter(
        SearchUrlParamsEnum.Countries,
        this.dynamicFiltersSettings
      )
        ? deserializeSearchQueryArrayParam(
            this.searchParams.get(SearchUrlParamsEnum.Countries)
          )
        : undefined,
      tagIds: !isDynamicFilter(
        SearchUrlParamsEnum.Tag,
        this.dynamicFiltersSettings
      )
        ? deserializeSearchQueryArrayParam(
            this.searchParams.get(SearchUrlParamsEnum.Tag)
          )
        : undefined,
      organizations: !isDynamicFilter(
        SearchUrlParamsEnum.Organizations,
        this.dynamicFiltersSettings
      )
        ? deserializeSearchQueryArrayParam(
            this.searchParams.get(SearchUrlParamsEnum.Organizations)
          )
        : undefined,
      indexCluster:
        deserializeIndexCluster(this.searchParams) ?? this.indexCluster,

      dynamicFilters: deserializeDynamicFiltersSearchQuery(
        this.searchParams,
        this.dynamicFiltersSettings
      ),
    };
  }

  getSearchSort(): SearchPayloadSort | undefined {
    const sortMethod = deserializeSortBy(this.searchParams);

    if (sortMethod === SearchSortMethodEnum.Relevance) return undefined;

    const field =
      SEARCH_SORT_METHOD_TO_FIELD[sortMethod] ?? SearchSortField.Date;

    return [field, 'desc'];
  }

  getPage(): number {
    return this.page || parseInt(this.searchParams.get('page') || '1', 10);
  }

  protected getQueryValue(): string {
    return (
      (!this.term && this.searchParams.get(SearchUrlParamsEnum.Query)) || ''
    );
  }

  protected getVisibility(): VisibilityAccessRightsEnum[] | undefined {
    const value = deserializeVisiblityFilterValue(this.searchParams);

    switch (value) {
      case SearchVisibilityFilterValue.Private:
        return [VisibilityAccessRightsEnum.Private];
      case SearchVisibilityFilterValue.Public:
        return [VisibilityAccessRightsEnum.Public];
      default:
        return;
    }
  }

  protected getDateRange(): SearchPayloadDate | undefined {
    const dateFilter = this.searchParams.get(SearchUrlParamsEnum.Date) ?? '';
    const dateRangeFilter = deserializeDateRange(this.searchParams);

    if (dateFilter === SearchDateFilterValueEnum.Custom) {
      return dateRangeFilter ?? undefined;
    }

    return getDateRangeFromDateFilterValue(dateFilter);
  }
}
