import {
  AuthenticationSettingsProtocolEnum,
  ClientIndexSettingsTypeEnum,
  TenantSettings as TenantSettingsResponse,
} from '@zarn/vendor/dist/search';
import {
  SearchFilterDynamicsType,
  TenantSettings,
} from '../tenantSettingsApi.types';

export const tenantSettingsResponseMock: TenantSettingsResponse = {
  authentication_settings: {
    protocol: AuthenticationSettingsProtocolEnum.Oidc,
    protocol_settings: {
      oidc: {
        issuer: 'https://login.zeta-alpha.com/realms/azure-test/',
        client_id: 'test-tenant-research-navigator',
        redirect_uri: 'test',
      },
    },
  },
  client_settings: {
    indexes: [
      {
        type: ClientIndexSettingsTypeEnum.Internal,
        title: 'Test Tenant',
        search_engine_identifier: 'default',
        search_filters_config: [
          {
            field_name: 'metadata.DCMI.date',
            display_name: 'Date',
          },
          {
            field_name: 'document_type',
            display_name: 'Type',
          },
          {
            field_name: 'testCheckbox',
            display_name: 'Test Checkbox',
            url_param: 'test_checkbox',
            faceted: false,
            filter_type: 'checkbox',
            filter_type_settings: {
              checkbox: {
                values: [
                  { label: 'one', value: '1' },
                  { label: 'two', value: '2' },
                  { label: 'three', value: '3' },
                  { label: 'four', value: '4' },
                ],
              },
            },
          },
        ],
        search_sorting_config: [
          {
            display_name: 'Relevance',
            field_name: 'relevance',
            url_param: 'relevance',
          },
          {
            display_name: 'Date',
            field_name: 'date',
            url_param: 'date',
          },
          {
            display_name: 'Citations',
            field_name: 'citations',
            url_param: 'citations',
          },
          {
            display_name: 'Influence',
            field_name: 'authority',
            url_param: 'authority',
            retrieval_unit: 'document',
          },
          {
            display_name: 'Popularity',
            field_name: 'popularity',
            url_param: 'popularity',
            retrieval_unit: 'document',
          },
          {
            display_name: 'Code',
            field_name: 'code',
            url_param: 'code',
            retrieval_unit: 'document',
          },
        ],
      },
      {
        type: ClientIndexSettingsTypeEnum.Federated,
        title: 'All the Web',
        search_engine_identifier: 'google',
        search_filters_config: [],
      },
    ],
    widgets: {
      vos_viewer: {
        title: 'Vos wiewer test',
        default_size: 100,
        layout: {
          row: 2,
          column_widths: {},
        },
      },
      expert_search: {
        title: 'Expert search',
        layout: {
          row: 1,
          column_widths: {},
        },
      },
      analytics: {
        title: 'Analytics',
        targets: [],
        layout: {
          column: 1,
          column_widths: { xs: 12, sm: 6, md: 6, xl: 6 },
        },
      },
      search_results: {
        layout: {
          row: 1,
          column: 2,
          column_widths: { xs: 12, sm: 6, md: 6, xl: 6 },
        },
      },
      query_analysis: {
        title: '',
        layout: {
          row: 1,
          column: 1,
          column_widths: { xs: 12, sm: 6, md: 6, xl: 6 },
        },
      },
      qa: { chat: { default_bot_identifier: 'chat_with_qa_widget' } },
      find_similar_document: {
        title: 'Find similar document',
      },
      find_authored_by: {
        title: 'Find authored by',
      },
    },
    pdf_viewer: {
      chat: {
        default_bot_identifier: 'chat_with_pdf',
        extra_bot_identifiers: ['summary'],
      },
    },
    tags_section: {
      chat: {
        default_bot_identifier: 'chat_with_tag',
        extra_bot_identifiers: [],
      },
      export_options: ['bibtex'],
      sharing_options: ['public'],
    },
    document_card: {
      export_options: ['bibtex'],
      sharing_options: ['public'],
    },
    bot_configurations: [
      {
        bot_identifier: 'chat_with_pdf',
        display_name: 'Default',
        bot_type: 'default_evidence',
        bot_params: {},
      },
      {
        bot_identifier: 'summary',
        display_name: 'Summarize',
        bot_type: 'summary',
        bot_params: {},
      },
      {
        bot_identifier: 'chat_with_tag',
        display_name: 'Chat with tag',
        bot_type: 'chat_with_tag',
        bot_params: {},
      },
      {
        bot_identifier: 'chat_with_qa_widget',
        display_name: 'Chat with QA',
        bot_type: 'chat_with_multiple_docs',
        bot_params: {},
      },
    ],
    white_label_settings: {
      header: {
        background:
          'linear-gradient(92deg, rgba(128, 93, 131, 0.15) 25%, rgba(128, 93, 131, 0.15) 25%, rgba(117, 160, 26, 0.15) 65%, rgba(41, 217, 195, 0.15) 100%)',
      },
      logo: {
        web: {
          src: 'https://freelogopng.com/images/all_img/1686390747tesla-logo-transparent.png',
          width: 180,
          height: 52,
        },
        mobile: {
          src: 'https://freelogopng.com/images/all_img/1686389484tesla-car-logo-png.png',
          width: 32,
          height: 32,
        },
      },
      pages: {
        about: {
          title: 'About',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
        features: {
          title: 'Features',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
        contact: {
          title: 'Contact',
          url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
        },
      },
    },
  },
  features: {
    recommendations: { status: 'active' },
    people: { status: 'active' },
    document_uploads: { status: 'active' },
  },
};

export const testTenantSettingsMock: TenantSettings = {
  displayConfiguration: null,
  indexCluster: '',
  searchFilters: {
    dateFilterEnabled: true,
    sourceFilterEnabled: false,
    codeFilterEnabled: false,
    countryFilterEnabled: false,
    organizationFilterEnabled: false,
    ownerFilterEnabled: false,
    typeFilterEnabled: true,
    tagFilterEnabled: false,
  },
  searchFiltersDynamic: [
    {
      label: 'Test Checkbox',
      fieldName: 'testCheckbox',
      urlParam: 'test_checkbox',
      faceted: false,
      filterType: SearchFilterDynamicsType.Checkbox,
      filterTypeSettings: {
        values: [
          { label: 'one', value: '1' },
          { label: 'two', value: '2' },
          { label: 'three', value: '3' },
          { label: 'four', value: '4' },
        ],
      },
    },
  ],
  searchSorting: {
    document: [
      {
        value: 'relevance',
        urlParam: 'relevance',
        title: 'Relevance',
      },
      {
        value: 'date',
        urlParam: 'date',
        title: 'Date',
      },
      {
        value: 'citations',
        urlParam: 'citations',
        title: 'Citations',
      },
      {
        value: 'authority',
        urlParam: 'authority',
        title: 'Influence',
      },
      {
        title: 'Popularity',
        value: 'popularity',
        urlParam: 'popularity',
      },
      {
        value: 'code',
        urlParam: 'code',
        title: 'Code',
      },
    ],
    chunk: [
      {
        value: 'relevance',
        urlParam: 'relevance',
        title: 'Relevance',
      },
      {
        value: 'date',
        urlParam: 'date',
        title: 'Date',
      },
      {
        value: 'citations',
        urlParam: 'citations',
        title: 'Citations',
      },
    ],
  },
  widgets: {
    vosViewerWidgetEnabled: true,
    analyticsWidgetEnabled: true,
    expertSearchWidgetEnabled: true,
    queryAnalysisWidgetEnabled: true,
    qaWidgetEnabled: true,
    findSimilarDocumentWidgetEnabled: true,
    findAuthoredByWidgetEnabled: true,
    searchResultsWidgetEnabled: true,
    layout: {
      topFullWidthWidgets: ['expertSearch', 'vosViewer'],
      columns: [
        {
          sizes: { xs: 12, sm: 6, md: 6, xl: 6 },
          widgets: ['queryAnalysis', 'analytics'],
        },
        {
          sizes: { xs: 12, sm: 6, md: 6, xl: 6 },
          widgets: ['searchResults'],
        },
      ],
    },
  },
  tabs: {
    default: {
      tabTitle: 'Test Tenant',
      enabled: true,
    },
    googleScholar: {
      tabTitle: '',
      enabled: false,
    },
    google: {
      tabTitle: 'All the Web',
      enabled: true,
    },
  },
  oidc: {
    enabled: true,
    authority: 'https://login.zeta-alpha.com/realms/azure-test/',
    clientId: 'test-tenant-research-navigator',
  },
  chat: {
    defaultBot: 'chat_with_pdf',
    tagDefaultBot: 'chat_with_tag',
    qaDefaultBot: 'chat_with_qa_widget',
    botCapabilities: [
      'chat_with_pdf',
      'chat_with_tag',
      'chat_with_qa_widget',
      'summary',
    ],
    botsConfiguration: {
      chat_with_pdf: {
        tenantBotType: 'default_evidence',
      },
      summary: {
        tenantBotType: 'summary',
      },
      chat_with_tag: {
        tenantBotType: 'chat_with_tag',
      },
      chat_with_qa_widget: {
        tenantBotType: 'chat_with_multiple_docs',
      },
    },
  },
  features: {
    recommendations: {
      status: 'active',
    },
    people: {
      status: 'active',
    },
    documentUploads: {
      status: 'active',
    },
  },
  tag: {
    sharing: ['public'],
    exports: ['bibtex'],
  },
  document: {
    sharing: ['public'],
    exports: ['bibtex'],
  },
  whitelabel: {
    logo: {
      web: {
        src: 'https://freelogopng.com/images/all_img/1686390747tesla-logo-transparent.png',
        width: 180,
        height: 52,
      },
      mobile: {
        src: 'https://freelogopng.com/images/all_img/1686389484tesla-car-logo-png.png',
        width: 32,
        height: 32,
      },
    },
    header: {
      background:
        'linear-gradient(92deg, rgba(128, 93, 131, 0.15) 25%, rgba(128, 93, 131, 0.15) 25%, rgba(117, 160, 26, 0.15) 65%, rgba(41, 217, 195, 0.15) 100%)',
    },
    pages: {
      about: {
        title: 'About',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
      features: {
        title: 'Features',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
      contact: {
        title: 'Contact',
        url: 'https://zav-release-notes.s3.eu-central-1.amazonaws.com/content.txt',
      },
    },
  },
};

export const ZETA_ALPHA_TENAT_SETTINGS: TenantSettings = {
  indexCluster: '',
  searchFilters: {
    dateFilterEnabled: true,
    sourceFilterEnabled: true,
    codeFilterEnabled: true,
    countryFilterEnabled: true,
    organizationFilterEnabled: true,
    ownerFilterEnabled: true,
    typeFilterEnabled: true,
    tagFilterEnabled: true,
  },
  widgets: {
    vosViewerWidgetEnabled: true,
    analyticsWidgetEnabled: true,
    expertSearchWidgetEnabled: true,
    queryAnalysisWidgetEnabled: true,
    qaWidgetEnabled: true,
    findSimilarDocumentWidgetEnabled: true,
    findAuthoredByWidgetEnabled: true,
    searchResultsWidgetEnabled: true,
    layout: {
      topFullWidthWidgets: [],
      columns: [
        {
          sizes: { xs: 12, sm: 6, md: 6, xl: 6 },
          widgets: ['QA', 'searchResults'],
        },
        {
          sizes: { xs: 12, sm: 6, md: 6, xl: 6 },
          widgets: [
            'findAuthoredBy',
            'findSimilarDocument',
            'vosViewer',
            'analytics',
            'expertSearch',
            'queryAnalysis',
          ],
        },
      ],
    },
  },
  tabs: {
    default: {
      tabTitle: 'R&D in AI',
      enabled: true,
    },
    googleScholar: {
      tabTitle: 'All of Science',
      enabled: true,
    },
    google: {
      tabTitle: 'All the Web',
      enabled: true,
    },
  },
  oidc: {
    enabled: false,
    authority: 'https://login.zeta-alpha.com/realms/azure-test/',
    clientId: 'research-navigator',
  },
  chat: {
    defaultBot: 'chat_with_pdf',
    tagDefaultBot: 'chat_with_tag',
    qaDefaultBot: 'chat_with_qa_widget',
    botCapabilities: [
      'chat_with_pdf',
      'summary',
      'quizbot',
      'chat_with_tag',
      'chat_with_qa_widget',
    ],
    botsConfiguration: {
      chat_with_pdf: {
        tenantBotType: 'default_evidence',
      },
      summary: {
        tenantBotType: 'summary',
      },
      quizbot: {
        tenantBotType: 'quizbot',
      },
      chat_with_tag: {
        tenantBotType: 'chat_with_tag',
      },
      chat_with_qa_widget: {
        tenantBotType: 'chat_with_multiple_docs',
      },
    },
  },
  features: {
    recommendations: {
      status: 'active',
    },
    people: {
      status: 'active',
    },
    documentUploads: {
      status: 'active',
    },
  },
  tag: {
    sharing: ['public'],
    exports: ['bibtex'],
  },
  document: {
    sharing: ['public'],
    exports: ['bibtex'],
  },
};
