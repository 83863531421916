import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchTermEnum } from 'common/enums';
import DocumentRefsIcon from 'common/components/CustomIcons/DocumentRefsIcon';
import { useSearchByTerm } from 'common/hooks/useSearchByTerm';
import DocumentMetadataButton from 'common/components/DocumentMetadataButton/DocumentMetadataButton';
import DocumentMetadataButtonChip from 'common/components/DocumentMetadataButton/DocumentMetadataButtonChip';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export type DocRefsProps = {
  score: number;
  getRefsId: string | null;
};

const DocRefs = ({ score, getRefsId }: DocRefsProps) => {
  const { t } = useTranslation('common');
  const { searchByTerm } = useSearchByTerm();

  const handleClick = useCallback(() => {
    if (getRefsId) {
      searchByTerm(SearchTermEnum.CitedBy, getRefsId);
    }
  }, [searchByTerm, getRefsId]);

  return (
    <DocumentMetadataButton
      onClick={handleClick}
      disabled={!score || !getRefsId}
      startIcon={<DocumentRefsIcon color="action" />}
      tooltip={t('retrievalUnit.actions.cites', { numberOfRefs: score })}
      eventName={TrackEventName.ReferencesButtonClicked}
      eventProps={{ ontologyId: getRefsId }}
    >
      <DocumentMetadataButtonChip showZero score={score} />
    </DocumentMetadataButton>
  );
};

export default DocRefs;
