import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { useDeletePrivateDoc } from '../useDeletePrivateDoc';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export type DeletePrivateDocDialogProps = {
  id: string;
  open: boolean;
  setOpen: (state: boolean) => void;
  onPrivateDocDelete?: (privateDocId: string) => void;
};

const DeletePrivateDocDialog = ({
  id,
  open,
  setOpen,
  onPrivateDocDelete,
}: DeletePrivateDocDialogProps) => {
  const { t } = useTranslation(['privateDocs']);
  const { mutate, isLoading } = useDeletePrivateDoc(id, onPrivateDocDelete);
  const handleSubmit = useCallback(() => {
    mutate();
  }, [onPrivateDocDelete, id]);

  return (
    <ExtendedConfirmationDialog
      open={open}
      setOpen={setOpen}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      data-testid="deletePrivateDoc"
      aria-label={t('privateDocs:delete.dialog.ariaLabel')}
      eventName={TrackEventName.PrivateDocumentDeleted}
      eventProps={{ docId: id }}
    >
      {t('privateDocs:delete.dialog.confirmationText')}
    </ExtendedConfirmationDialog>
  );
};

DeletePrivateDocDialog.displayName = 'DeletePrivateDocDialog';

export default DeletePrivateDocDialog;
