import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { getInitialQuery, SavedQueryDetails } from 'api/savedQueriesApi';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { INITIAL_QUERY_KEY } from 'containers/InitialQuery/initialQuery.utils';
import { useCreateInitialQuery } from './useCreateInitialQuery';
import { useDeleteInitialQuery } from './useDeleteInitialQuery';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { QUERY_OPTIONS } from 'common/constants/query-options';
import { captureException } from '@sentry/react';
import { useTenantSettings } from 'common/hooks/useTenantSettings';

export const useInitialQuery = (
  options?: UseQueryOptions<any, BaseError, SavedQueryDetails | null>
) => {
  const { isAuthenticated } = useAuth();
  const { tenant } = useParsedHostname();
  const { searchDynamicsFilters } = useTenantSettings();

  const queryClient = useQueryClient();
  const query = useQuery<SavedQueryDetails | null, BaseError>(
    INITIAL_QUERY_KEY,
    async () => {
      try {
        return (await getInitialQuery(tenant, searchDynamicsFilters)).data;
      } catch (err) {
        const error = deserializeAxiosError(err);
        if (error.status !== 404) {
          captureException(error);
        }
        throw error;
      }
    },
    {
      ...QUERY_OPTIONS,
      onError(err) {
        // 404 error when initialQuery doesn't exist for the user
        if (err.status === 404) {
          queryClient.setQueryData(INITIAL_QUERY_KEY, () => null);
        }
      },
      retry: (failureCount: number, err: BaseError) =>
        err.status !== 404 && failureCount === 1,
      staleTime: Infinity,
      enabled: isAuthenticated,
      ...options,
    }
  );
  const createMutation = useCreateInitialQuery();
  const deleteMutation = useDeleteInitialQuery();

  return {
    query,
    createMutation,
    deleteMutation,
  };
};
