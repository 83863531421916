import { useQuery } from 'react-query';

import { listOrganizationMembers } from 'api/organizationApi/organizationMembersApi';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { Me } from '../../../Auth/models/Me.interface';
import { QUERY_OPTIONS } from 'common/constants/query-options';

export type UseTagShareTeamProps = {
  enabled: boolean;
  me: Me | null;
};

export const useTagShareTeam = ({ me, enabled }: UseTagShareTeamProps) => {
  const organizationId = me?.organization?.accountId;
  const { tenant } = useParsedHostname();

  const query = useQuery(
    ['teamMembers', organizationId],
    async () => {
      const { data } = await listOrganizationMembers({
        tenant,
        organizationId: organizationId ?? 0,
        pageSize: 1000,
      });

      return {
        ...data,
        items: data.items.filter(({ uuid }) => uuid !== me?.sub),
      };
    },
    {
      ...QUERY_OPTIONS,
      enabled: typeof organizationId === 'number' && enabled,
      staleTime: Infinity,
    }
  );

  return query;
};
