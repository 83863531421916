import { useState, useMemo, useCallback } from 'react';

type useHiddenItemsReturn<T> = {
  visibleItems: T[];
  hasHiddenItems: boolean;
  isHidden: boolean;
  hiddenItemsNumber: number;
  toggleVisibility: () => void;
};

const DEFAULT_MAX = 3;

export function useHiddenItems<T>(
  allItems: T[],
  maxVisible: number = DEFAULT_MAX
): useHiddenItemsReturn<T> {
  const hasHiddenItems = useMemo(
    () => allItems.length > maxVisible,
    [allItems.length, maxVisible]
  );

  const [isHidden, setIsHidden] = useState<boolean>(() => hasHiddenItems);

  const toggleVisibility = useCallback(() => {
    if (isHidden) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, [isHidden, setIsHidden]);

  const visibleItems = useMemo(
    () => (isHidden ? allItems.slice(0, maxVisible) : allItems),
    [allItems.length, isHidden, maxVisible]
  );

  const hiddenItemsNumber = useMemo(
    () => (hasHiddenItems ? allItems.length - maxVisible : 0),
    [allItems.length, maxVisible, hasHiddenItems]
  );

  return {
    visibleItems,
    toggleVisibility,
    hasHiddenItems,
    isHidden,
    hiddenItemsNumber,
  };
}
