import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import rootReducer from './reducers';

export type RootState = ReturnType<typeof rootReducer>;

export const createStore = (preloadedState: PreloadedState<RootState> = {}) =>
  configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV === 'development',
  });

const store = createStore();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    import('./reducers').then((newRootReducer) => {
      store.replaceReducer(newRootReducer.default);
    });
  });
}

export type AppStore = typeof store;

export type AppDispatch = typeof store.dispatch;

export declare type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
};

export default store;
