import React, { createContext } from 'react';
import { TagsListType } from './TagsListType.enum';

export interface TagsListContextValue {
  anchorsRefs: TagsListAnchorsRefs;
  expandedListState: TagsListExpandedState;
  toggleExpandedListState: (listType: TagsListType) => Promise<void>;
  searchVisibility: boolean;
}

export interface TagsListAnchorsRefs {
  wrapper: React.MutableRefObject<HTMLDivElement | null>;
  own: React.MutableRefObject<HTMLDivElement | null>;
  following: React.MutableRefObject<HTMLDivElement | null>;
  shared: React.MutableRefObject<HTMLDivElement | null>;
}

export interface TagsListExpandedState {
  [TagsListType.Own]: boolean;
  [TagsListType.Following]: boolean;
  [TagsListType.Shared]: boolean;
}

export const tagsListContextInitialValue: TagsListContextValue = {
  anchorsRefs: {
    wrapper: { current: null },
    own: { current: null },
    following: { current: null },
    shared: { current: null },
  },
  expandedListState: {
    own: true,
    following: true,
    shared: false,
  },
  toggleExpandedListState: () => Promise.resolve(),
  searchVisibility: false,
};

export const TagsListContext = createContext<TagsListContextValue>(
  tagsListContextInitialValue
);
