import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, DialogActions, List, ListItem } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import SubmitButton from 'common/components/Buttons/SubmitButton';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { sendUserFeedback } from 'api/authApi';
import AlertAutoDismiss from 'common/components/AlertAutoDismiss';
import { FeedbackFormValues } from '../Feedback.interface';
import { serializeUserFeedbackPayload } from '../feedback.utils';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';

const initialValues = { subject: '', feedback: '' };

type Props = {
  handleClose: () => void;
};

export const FeedbackForm = ({ handleClose }: Props) => {
  const { t } = useTranslation(['common', 'feedback']);
  const { tenant } = useParsedHostname();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        subject: Yup.string().required(t('common:errors.required')),
        feedback: Yup.string()
          .required(t('common:errors.required'))
          .min(10, t('feedback:errors.minLength')),
      }),
    []
  );

  const handleDismiss = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (
    values: FeedbackFormValues,
    { setSubmitting }: FormikHelpers<FeedbackFormValues>
  ): Promise<void> => {
    try {
      setSubmitting(true);
      await sendUserFeedback(serializeUserFeedbackPayload(values), tenant);
      setSuccess(true);
    } catch (error: any) {
      captureException(error);
      const overallError = error?.response?.data?.message;
      if (overallError) setErrorAlert(overallError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        onReset={handleClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box mx={1}>
              <List>
                <ListItem>
                  <FormTextField
                    name="subject"
                    label={t('feedback:form.subject')}
                  />
                </ListItem>

                <ListItem>
                  <FormTextField
                    multiline
                    minRows={4}
                    name="feedback"
                    label={t('feedback:form.yourFeedback')}
                  />
                </ListItem>
              </List>
            </Box>

            <Box px={2}>
              {(success || errorAlert) && (
                <AlertAutoDismiss
                  dismissIn={3000}
                  onDismiss={handleDismiss}
                  severity={errorAlert ? 'error' : 'success'}
                  text={errorAlert ?? t('feedback:successfullySent')}
                />
              )}
            </Box>

            <DialogActions>
              <Button type="reset">{t('common:buttons.cancel')}</Button>

              <SubmitButton
                disabled={success || isSubmitting}
                eventName={TrackEventName.FeedbackSubmitted}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};
