import React from 'react';
import { Avatar, AvatarProps, AvatarTypeMap, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = {
  size?: 'small' | 'large';
  color?: 'secondary' | 'primary';
} & AvatarProps<AvatarTypeMap['defaultComponent'], {}>;

export const StyledAvatar = React.forwardRef(
  (
    { size, color, className, ...rest }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const classes = useStyles();

    return (
      <Avatar
        {...rest}
        ref={ref}
        className={clsx(
          className,
          size === 'small' && classes.small,
          size === 'large' && classes.large,
          color === 'secondary' && classes.colorSecondary,
          color === 'primary' && classes.colorPrimary
        )}
      />
    );
  }
);

StyledAvatar.displayName = 'StyledAvatar';
