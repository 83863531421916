import React, { useCallback } from 'react';
import { Box, Divider } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { NoteItem } from 'common/components/Notes/NoteItem';
import { NoteItemSkeleton } from 'common/components/Notes/NoteItemSkeleton';
import {
  AnnotationHighlight,
  ListNotesPayloadParams,
  NoteDetails,
} from 'api/notesApi/notesApi.types';
import EmptyState from 'common/components/EmptyState';
import Spotlight from 'common/components/Spotlight';
import { useDocNotes } from 'containers/DocNotes/hooks/useDocNotes';
import { useNoteApi } from '../../DocNotes/hooks/useNoteApi';
import { deserializeAxiosError } from 'common/utils/error';
import { useSnackbar } from 'notistack';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { captureException } from '@sentry/react';

export interface DocPDFAnnotationsProps {
  docId: string;
  activeNoteId: number | null;
  onHighlightClick?: (highlight: AnnotationHighlight) => void;
  notesPayload: ListNotesPayloadParams;
}

export const DocPDFAnnotations = ({
  docId,
  notesPayload,
  activeNoteId,
  onHighlightClick,
}: DocPDFAnnotationsProps) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading } = useDocNotes({
    docId,
    ...notesPayload,
  });
  const noteApi = useNoteApi(docId);
  const { noteId } = useLocationParams('noteId');
  const { push } = useNavigationWithState();

  const handleNoteUpdate = useCallback(
    async (note: NoteDetails) => {
      try {
        await noteApi.update(note);
        enqueueSnackbar(t('notes.update.successMessage'));
      } catch (error) {
        captureException(error);
        enqueueSnackbar(deserializeAxiosError(error).message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, noteApi, t]
  );

  const handleNoteDelete = async (id: number) => {
    if (Number(noteId) === id) {
      push(`/pdf/${docId}?tab=notes`);
    }
    await noteApi.remove(id);
  };

  if (isLoading) return <NoteItemSkeleton />;

  if (!data?.items.length) {
    return (
      <Box display="flex" justifyContent="center">
        <EmptyState
          subtitle={t('pdfViewer.annotations.emptyStateSubtitle')}
          title={
            <Trans t={t} i18nKey="pdfViewer.annotations.emptyStateTitle">
              <strong />
            </Trans>
          }
        />
      </Box>
    );
  }

  return (
    <>
      {data?.items.map((note, i) => (
        <React.Fragment key={note.id}>
          <Spotlight
            shouldScroll
            shouldHighlight={true}
            highlightStopDelay={4000}
            active={activeNoteId === note.id}
          >
            <NoteItem
              note={note}
              docId={docId}
              onDelete={(_, id) => handleNoteDelete(id)}
              onUpdate={handleNoteUpdate}
              onHighlightClick={onHighlightClick}
            />
          </Spotlight>

          {data?.items.length - 1 !== i && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};

DocPDFAnnotations.displayName = 'DocPDFAnnotations';
