import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import ContentLoader from 'common/components/Loaders/ContentLoader/ContentLoader';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading?: boolean;
  greetings?: ReactNode;
}

export const Greetings: FC<Props> = ({ isLoading, greetings }) => {
  const { t } = useTranslation('chat');

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <ContentLoader />
      ) : greetings ? (
        greetings
      ) : (
        <>
          <Typography
            variant="h2"
            sx={{ m: 1, textAlign: 'center', color: '#eee' }}
          >
            {t('start.label')}
          </Typography>
          <Typography
            variant="h1"
            sx={{ m: 1, textAlign: 'center', color: '#eee' }}
          >
            ⤵
          </Typography>
        </>
      )}
    </Box>
  );
};
