import { deserializeAxiosError } from '../utils/error';
import { BaseError } from '../models/Error.interface';

export const QUERY_OPTIONS = {
  retry: false,
  refetchInterval: false,
  refetchOnReconnect: false,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  onError: (error: BaseError | Error | unknown) => {
    return deserializeAxiosError(error);
  },
} as const;

export const REFETCH_INTERVAL = 10_000;
