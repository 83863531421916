import { AxiosResponse } from 'axios';
import { AnswersApiFactory, Configuration } from '@zarn/vendor/dist/search';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  BestAnswerRequest,
  BestAnswerResponse,
  ExplanationRequest,
  ExplanationResponse,
  KAnswerRequest,
  KAnswerResponse,
} from './qaApi.types';
import {
  deserializeBestAnswerItem,
  deserializeExplanationItem,
  deserializeKAnswerItem,
  serializeBestAnswerForm,
  serializeExplanationForm,
  serializeKAnswersForm,
} from './qaApi.utils';

export const answersApi = AnswersApiFactory(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const createBestAnswer = async (
  payload: BestAnswerRequest,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<BestAnswerResponse>> => {
  const { data, ...rest } = await answersApi.bestAnswer(
    serializeBestAnswerForm(payload),
    '',
    '',
    tenant,
    indexCluster
  );
  return {
    ...rest,
    data: deserializeBestAnswerItem(data),
  };
};

export const createKAnswer = async (
  payload: KAnswerRequest,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<KAnswerResponse>> => {
  const { data, ...rest } = await answersApi.kAnswers(
    serializeKAnswersForm(payload),
    '',
    '',
    tenant,
    indexCluster
  );
  return {
    ...rest,
    data: deserializeKAnswerItem(data),
  };
};

export const createExplanation = async (
  payload: ExplanationRequest,
  tenant: string,
  indexCluster?: string
): Promise<AxiosResponse<ExplanationResponse>> => {
  const { data, ...rest } = await answersApi.explain(
    serializeExplanationForm(payload),
    '',
    '',
    tenant,
    indexCluster
  );
  return {
    ...rest,
    data: deserializeExplanationItem(data),
  };
};
