import { FeedbackListPayload } from 'containers/Feedback/Feedback.interface';
import {
  Configuration,
  FeedbackApi,
  FeedbackForm,
} from '@zarn/vendor/dist/query-logging';
import { SERVICE_URL, BASE_HEADERS } from '../apiConfig';
import axiosInstance from '../axiosInstance';

export const feedbackApi = new FeedbackApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const postFeedback = (tenant: string, feedbackForm: FeedbackForm) =>
  feedbackApi.feedbackPost({
    requesterUuid: '',
    feedbackForm,
    tenant,
  });

export const getFeedbackList = (payload: FeedbackListPayload) =>
  feedbackApi.feedbackList({
    ...payload,
    requesterUuid: '',
  });
