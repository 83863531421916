import { TagDragItemEnum } from 'containers/Tags/TagsList/TagsListOrdering/TagDragItem.enum';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { RetrievalUnitData } from './RetrievalUnitData.interface';

export const tagDragItemToTagType = (tagDragItem: TagDragItemEnum): TagType => {
  switch (tagDragItem) {
    case TagDragItemEnum.Following:
      return TagType.Following;
    default:
      return TagType.Own;
  }
};

export const getAuthorsFullNames = ({
  authors,
}: Pick<RetrievalUnitData, 'authors'>): string[] =>
  authors?.map(({ fullName }) => fullName) ?? [];
