import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPageContent } from 'common/components/ErrorPageContent/ErrorPageContent';

export const Page400 = () => {
  const { t } = useTranslation(['pages', 'common']);

  return (
    <ErrorPageContent
      title={t('pages:400.title')}
      description={t('pages:400.desc')}
    />
  );
};
