import { createContext } from 'react';

export type DocActionsContextValue = {
  open: boolean;
  anchorEl: null | HTMLElement;
  setAnchorEl: (v: null | HTMLElement) => void;
};

const initialValue: DocActionsContextValue = {
  open: false,
  anchorEl: null,
  setAnchorEl: () => {},
};

export const DocActionsContext =
  createContext<DocActionsContextValue>(initialValue);
