import React, { useCallback, useRef, useState } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { debounce } from 'lodash';

import ColorRadio from 'common/components/Fields/ColorRadio/ColorRadio';

const useStyles = makeStyles<Theme, { color: string }>((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    marginLeft: theme.spacing(1),
  },
  colorPicker: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 30,
    width: 30,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.7,
    transition: theme.transitions.create('opacity'),
    color: ({ color }) => theme.palette.getContrastText(color),
    '&:hover': {
      opacity: 1,
    },
    '& input': {
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      cursor: 'pointer',
    },
  },
}));

export type ColorPickerRadioProps = {
  name: string;
  value?: string;
  initialColor?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const ColorPickerRadio = ({
  initialColor = '#e0e0e0',
  onChange,
  name,
}: ColorPickerRadioProps) => {
  const [color, setColor] = useState<string>(initialColor);
  const pickerRef = useRef<HTMLInputElement>(null);
  const classes = useStyles({ color });

  const handleChangeColor = (newColor: string): void => {
    setColor(newColor);

    // Workaround to call onFocus event which will fire
    // onChange event set to the field
    if (pickerRef.current) {
      pickerRef.current.blur();
      pickerRef.current.focus();
    }
  };

  const debouncedSetColor = useCallback(debounce(handleChangeColor, 400), []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetColor(e.target.value);
  };

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, true);
    }
  };

  return (
    <div className={classes.wrapper}>
      <ColorRadio
        value={color}
        radioColor={color}
        title={color}
        onChange={onChange}
      />

      <label className={classes.colorPicker}>
        <input
          ref={pickerRef}
          type="color"
          name={name}
          value={color}
          role="button"
          aria-label="color picker"
          title="color picker"
          onChange={handleChange}
          onFocus={handleFocus}
        />

        <ColorizeIcon fontSize="small" />
      </label>
    </div>
  );
};

export default ColorPickerRadio;
