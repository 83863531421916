import { Configuration, InvitationsApi } from '@zarn/vendor/dist/auth';
import { SERVICE_URL, BASE_HEADERS } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  ResendInvitationEmailPayload,
  UserInvitationPayload,
} from './invitationsApi.types';
import { serializeUserInvitationForm } from './invitationsApi.utils';

export const invitationsApi = new InvitationsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const resendInvitationEmail = (
  tenant: string,
  payload: ResendInvitationEmailPayload
) => {
  return invitationsApi.userInviteResend({
    requesterUuid: '',
    userRoles: '',
    tenant,
    ...payload,
  });
};
export const sendInvite = (tenant: string, payload: UserInvitationPayload) => {
  return invitationsApi.createInvitation({
    requesterUuid: '',
    userRoles: '',
    tenant,
    invitationForm: serializeUserInvitationForm(payload),
  });
};
