import {
  SearchSortField,
  SearchTermEnum,
  SearchTermEnumType,
} from 'common/enums';
import {
  SearchApiDocumentSearchRequestRange,
  SearchPayload,
  SearchPayloadDate,
  SearchPayloadSort,
  SearchPayloadTerm,
  SearchPayloadYear,
} from 'containers/Search/SearchPayload.interface';
import {
  DocumentsApiDocumentSearchPostRequest,
  DocumentsApiDocumentSearchRequest,
  SearchPostRequest,
  SortSchema,
} from '@zarn/vendor/dist/search';
import { DEFAULT_DOC_TYPES } from './constants/defaultSearchValues';
import { Optional } from '../../common/utils/assert';
import { serializeFilters } from './dynamicSearch.utils';

export const serializeTimeRange = (
  range: SearchPayloadYear | SearchPayloadDate | undefined
): SearchApiDocumentSearchRequestRange | undefined => {
  return range
    ? {
        lower_bound: String(range[0]),
        upper_bound: String(range[1]),
      }
    : undefined;
};

const serializeSort = (
  sort: SearchPayloadSort | undefined
): SortSchema | undefined => {
  return sort && { [sort[0]]: sort[1] };
};

const serializeSortOrder = (
  sort: SearchPayloadSort | undefined
): SearchSortField[] | undefined => {
  return sort && [sort[0]];
};

export const serializeEntity = (
  term: SearchPayloadTerm | undefined,
  termType: SearchTermEnumType
): string | undefined => {
  return term && term[0] === termType ? term[1] : undefined;
};

const serializeArray = <T>(array: Optional<Array<T>>): T[] | undefined =>
  array && array.length > 0 ? array : undefined;

export const serializeSearchPayload = (
  payload: SearchPayload
): DocumentsApiDocumentSearchRequest => {
  const { sort, year, date, term, tenant } = payload;

  return {
    tenant: tenant,
    retrievalUnit: payload.retrievalUnit,
    retrievalMethod: payload.retrievalMethod,
    searchEngine: payload.searchEngine,
    queryString: payload.queryString,
    year: serializeTimeRange(year),
    date: serializeTimeRange(date),
    sort: serializeSort(sort),
    sortOrder: serializeSortOrder(sort),
    sources: payload.docSources,
    docIds: payload.docIds,
    tagIds: serializeArray(payload.tagIds),
    documentTypes: payload.docTypes ?? DEFAULT_DOC_TYPES,
    page: payload.page,
    citedBy: serializeEntity(term, SearchTermEnum.CitedBy),
    cites: serializeEntity(term, SearchTermEnum.Cites),
    authoredBy: serializeEntity(term, SearchTermEnum.AuthoredBy),
    hasConcept: serializeEntity(term, SearchTermEnum.HasConcept),
    similarTo: payload.similarTo,
    withCode: payload.withCode,
    queryEncoderService: payload.queryEncoderService,
    rerankerService: payload.rerankerService,
    rerank: payload.rerank,
    // reranker: payload.reranker,
    rerankTopN: payload.rerankTopN,
    visibility: payload.visibility,
    locationCountries: payload.locationCountries,
    organizations: payload.organizations,
    indexCluster:
      payload.indexCluster === null ? undefined : payload.indexCluster,
  };
};

export const serializeSearchPostPayload = (
  payload: SearchPayload
): DocumentsApiDocumentSearchPostRequest => {
  const { sort, year, date, term, tenant } = payload;

  const searchPostRequest: SearchPostRequest = {
    tenant: tenant,
    retrieval_unit: payload.retrievalUnit,
    retrieval_method: payload.retrievalMethod,
    search_engine: payload.searchEngine,
    query_string: payload.queryString,
    page: payload.page,
    rerank: payload.rerank,
    reranker_service: payload.reranker,
    rerank_top_n: payload.rerankTopN,
    // Custom fields:
    year: serializeTimeRange(year),
    date: serializeTimeRange(date),
    sort: serializeSort(sort),
    sort_order: serializeSortOrder(sort),
    sources: serializeArray(payload.docSources),
    doc_ids: serializeArray(payload.docIds),
    tag_ids: serializeArray(payload.tagIds),
    document_types: payload.docTypes ?? DEFAULT_DOC_TYPES,
    cited_by: serializeEntity(term, SearchTermEnum.CitedBy),
    cites: serializeEntity(term, SearchTermEnum.Cites),
    authored_by: serializeEntity(term, SearchTermEnum.AuthoredBy),
    has_concept: serializeEntity(term, SearchTermEnum.HasConcept),
    similar_to: payload.similarTo,
    with_code: payload.withCode,
    query_encoder_service: payload.queryEncoderService,
    visibility: serializeArray(payload.visibility),
    location_countries: serializeArray(payload.locationCountries),
    organizations: serializeArray(payload.organizations),
    index_cluster:
      payload.indexCluster === null ? undefined : payload.indexCluster,
    filters: serializeFilters(payload.dynamicFilters),
  };

  return {
    searchPostRequest,
  };
};
