import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InfoPagesKeys } from 'api/tenantSettingsApi/WhitelabelSettings.types';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useInfoPages } from 'common/hooks/useInfoPages';
import { isNull } from 'common/utils/assert';
import { captureException } from '@sentry/react';

const PAGE_NOT_FOUND = '/404';

export const useInfoPage = () => {
  const { push } = useNavigationWithState();
  const { id } = useParams<{ id: InfoPagesKeys }>();
  const page = useInfoPages(id);
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    let isRendered = true;

    (async function refreshUser() {
      try {
        if (!page?.url) {
          return;
        }
        const response = await fetch(page.url);
        const text = await response.text();

        if (isRendered) {
          setContent(text);
        }
      } catch (error) {
        captureException(error);
        console.error(error);
        push(PAGE_NOT_FOUND);
      }
    })();

    return () => {
      isRendered = false;
    };
  }, [page?.url, push]);

  if (isNull(page)) {
    push(PAGE_NOT_FOUND);
    return {
      title: '',
      content: '',
    };
  }

  return {
    title: page?.title || '',
    content,
  };
};
