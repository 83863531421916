import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { captureException } from '@sentry/react';
import { useSelector } from 'react-redux';

import { deserializeAxiosError } from 'common/utils/error';
import { userDocumentUpdate } from 'api/privateDocsApi/privateDocsApi';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { UserDocumentStatus } from '@zarn/vendor/dist/user-documents';
import { useMutation, useQueryClient } from 'react-query';
import { PrivateDocAccessRoleEnum } from 'common/enums';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { usePrivateDocumentRetrieve } from 'containers/PrivateDocs/PrivateDocsResults/usePrivateDocumentRetrieve';
import { getAuthorsText } from 'common/utils/share';

import { selectUser } from '../../User/user.slice';
import { getAuthorsFullNames } from '../../RetrievalUnit/retrievalUnit.utils';
import { PrivateDocFormValues } from '../PrivateDocForm/PrivateDocForm.interface';
import { sanitizePrivateDocUri } from '../privateDocs.utils';

interface UseEditPrivateDocFormReturn {
  initialValues: PrivateDocFormValues;
  onSubmit: (
    values: PrivateDocFormValues,
    { setSubmitting }: FormikHelpers<PrivateDocFormValues>
  ) => Promise<void>;
  isLoading: boolean;
}

type useEditPrivateDocFormProps = {
  onFormSubmit?: (data: RetrievalUnitData) => void;
  data: RetrievalUnitData;
};

export const useEditPrivateDocForm = ({
  data,
  onFormSubmit,
}: useEditPrivateDocFormProps): UseEditPrivateDocFormReturn => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useParsedHostname();
  const user = useSelector(selectUser);

  const content = usePrivateDocumentRetrieve(data.privateDocId ?? null);

  const initialValues = useMemo<PrivateDocFormValues>(() => {
    const file =
      content.data &&
      content.data.filename &&
      new File([''], content.data.filename, {
        type: 'application/pdf',
      });

    return {
      uri: sanitizePrivateDocUri(data.uri) ?? '',
      title: data.title,
      file: file ? [file] : [],
      abstractText: data.abstractContent,
      year:
        data.year ||
        (data.date && new Date(data.date).getFullYear()) ||
        undefined,
      source: data.source,
      authors: getAuthorsText(getAuthorsFullNames(data)),
      shareWithOrg: !!data.sharing?.includes(PrivateDocAccessRoleEnum.Org),
    };
  }, [data, content.data]);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, values }: { id: string; values: PrivateDocFormValues }) =>
      userDocumentUpdate(id, values, tenant, user?.useOpenAI),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['privateDocs', 'results']),
    }
  );

  const handleSubmit = useCallback(
    async (
      values: PrivateDocFormValues,
      { setSubmitting }: FormikHelpers<PrivateDocFormValues>
    ) => {
      setSubmitting(true);

      try {
        const updatedPrivateDoc = (
          await mutation.mutateAsync({
            id: data.privateDocId ?? '',
            values,
          })
        ).data;

        enqueueSnackbar(t('privateDocs:editPrivateDocForm.successMessage'));

        if (onFormSubmit) {
          onFormSubmit({
            ...data,
            ...updatedPrivateDoc,
            status: {
              title: UserDocumentStatus.Pending,
              message: '',
              codes: [],
            },
          });
        }
      } catch (error) {
        captureException(error);
        const err = deserializeAxiosError(error);

        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }

      setSubmitting(false);
    },
    [mutation, data, enqueueSnackbar, t, onFormSubmit]
  );

  const isLoading = content.status === 'loading';

  return {
    initialValues,
    onSubmit: handleSubmit,
    isLoading,
  };
};
