import React, { useCallback } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/StarRounded';
import { useTranslation } from 'react-i18next';
import StarOutlineIcon from '@mui/icons-material/StarOutlineRounded';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ResultType } from '@zarn/vendor/dist/query-logging';

import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { useSendFeedback } from 'containers/Feedback/SendFeedback/hooks/useSendFeedback';
import { HitType, SearchEngineEnum } from 'common/enums';
import {
  removeDocumentFromFavourites,
  saveDocumentToFavourites,
  selectFavouriteDocByOrganizeDocId,
} from 'containers/SavedDocuments/savedDocuments.slice';
import { selectFavouriteTag } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { useLoggedInFeature } from 'containers/Auth/hooks/useLoggedInFeature';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { FeedbackType } from 'containers/Feedback/enums';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useExternalDocCreate } from 'containers/ExternalDocs/hooks/useExternalDocCreate';
import { RetrievalUnitData } from '../RetrievalUnitData.interface';
import { useLocation } from 'react-router-dom';
import { useOnAddTaggedDoc } from 'containers/TaggedDocs/hooks/useOnAddTaggedDoc';
import { useOnDeleteTaggedDoc } from 'containers/TaggedDocs/hooks/useOnDeleteTaggedDoc';
import { DocCardComposition } from 'containers/User/User.enum';
import withTrackedAction, {
  WithTrackedActionProps,
  WithTrackedActionWrappedProps,
} from 'common/components/TrackedActions/withTrackedAction';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export type DocFavoriteButtonProps = {
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
  searchId?: string;
  resultType?: ResultType;
  actionComposition?: DocCardComposition;
};

const DocFavoriteButton = ({
  searchEngine,
  docData,
  resultType,
  searchId,
  actionComposition,
  onAction,
}: WithTrackedActionWrappedProps<DocFavoriteButtonProps>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { sendFeedback } = useSendFeedback();
  const {
    organizeDocId,
    document: { id: docId, type: docType },
  } = docData;
  const savedDoc = useSelector(
    selectFavouriteDocByOrganizeDocId(organizeDocId)
  );
  const favouriteTag = useSelector(selectFavouriteTag);
  const [open, setOpen] = React.useState(false);
  const title = savedDoc
    ? t('retrievalUnit.actions.favourite.remove.tooltip')
    : t('retrievalUnit.actions.favourite.add.tooltip');
  const { mutateAsync: externalDocCreate } = useExternalDocCreate();
  const isExternalDoc =
    searchEngine && docData.document.type === HitType.ExternalDocument;
  const onAddTaggedDoc = useOnAddTaggedDoc();
  const onDeleteTaggedDoc = useOnDeleteTaggedDoc();
  const { pathname } = useLocation();
  const isFavoritesPage = pathname === '/favorites';
  const parsedHostname = useParsedHostname();

  const toggleFavourite = async () => {
    if (!savedDoc && favouriteTag) {
      const action = await dispatch(
        saveDocumentToFavourites({
          organizeDocId,
          docType: docType as HitType,
          tenant: parsedHostname.tenant,
        })
      );

      if (isFavoritesPage) {
        onAddTaggedDoc(favouriteTag.id, docData);
      } else {
        onAddTaggedDoc(favouriteTag.id, docData, { active: undefined });
      }

      if (isExternalDoc) {
        await externalDocCreate({ ...docData, searchEngine });
      }

      if (saveDocumentToFavourites.rejected.match(action)) {
        enqueueSnackbar(action.error.message, {
          variant: 'error',
        });

        return;
      }

      enqueueSnackbar(t('retrievalUnit.actions.favourite.add.successMessage'));

      if (searchId && resultType) {
        sendFeedback({
          searchId,
          resultType,
          resultId: docId,
          feedbackType: FeedbackType.Tag,
        });
      }
    } else {
      setOpen(true);
    }
  };

  const clickAction = useLoggedInFeature(toggleFavourite);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    clickAction(event.currentTarget);
    onAction();
  };

  const handleRemoveFavourites = useCallback(async () => {
    setOpen(false);
    const action = await dispatch(
      removeDocumentFromFavourites({
        documentId: organizeDocId,
        tenant: parsedHostname.tenant,
      })
    );
    if (favouriteTag) {
      if (isFavoritesPage) {
        onDeleteTaggedDoc(favouriteTag.id, organizeDocId);
      } else {
        onDeleteTaggedDoc(favouriteTag.id, organizeDocId, {
          active: undefined,
        });
      }
    }

    if (removeDocumentFromFavourites.rejected.match(action)) {
      enqueueSnackbar(action.error.message, {
        variant: 'error',
      });

      return;
    }

    enqueueSnackbar(t('retrievalUnit.actions.favourite.remove.successMessage'));
  }, [dispatch, setOpen, organizeDocId]);

  return (
    <>
      <Tooltip title={title}>
        {actionComposition === DocCardComposition.Compressed ? (
          <Button
            size="small"
            onClick={handleClick}
            color={savedDoc ? 'secondary' : 'primary'}
            style={{ lineHeight: 'normal', width: '100%' }}
          >
            <Box mr={1} ml={-0.2}>
              {savedDoc ? (
                <StarIcon sx={{ fontSize: 18 }} />
              ) : (
                <StarOutlineIcon sx={{ fontSize: 18 }} />
              )}
            </Box>
            {t('retrievalUnit.actions.favourite.label')}
          </Button>
        ) : (
          <TrackedIconButton
            size="small"
            onClick={handleClick}
            color={savedDoc ? 'secondary' : 'primary'}
            eventName={
              savedDoc
                ? TrackEventName.DocumentUnfavorited
                : TrackEventName.DocumentFavorited
            }
            eventProps={{ docId }}
          >
            {savedDoc ? <StarIcon /> : <StarOutlineIcon />}
          </TrackedIconButton>
        )}
      </Tooltip>

      <ExtendedConfirmationDialog
        open={open}
        setOpen={setOpen}
        onSubmit={handleRemoveFavourites}
        aria-label="remove from favourites"
      >
        {t('retrievalUnit.actions.favourite.confirmDialog.removeText')}
      </ExtendedConfirmationDialog>
    </>
  );
};

export default withTrackedAction<
  DocFavoriteButtonProps & WithTrackedActionProps
>(DocFavoriteButton);
