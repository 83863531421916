import React from 'react';
import { List, ListProps } from '@mui/material';

export type ScrollListStyleProps = {
  maxHeight?: number | string;
};

export type ScrollListProps = {} & ScrollListStyleProps & ListProps;

const ScrollList = React.memo(
  ({ maxHeight = 200, children }: ScrollListProps) => {
    return (
      <List
        sx={{
          maxHeight,
          width: '100%',
          overflowY: 'auto',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'palette.divider',
        }}
      >
        {children}
      </List>
    );
  }
);

ScrollList.displayName = 'ScrollList';

export default ScrollList;
