import React, { useRef } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  PageDrawerMenuItem,
  PageDrawerMenuItemProps,
} from 'common/components/PageDrawer/PageDrawerMenuItem/PageDrawerMenuItem';
import { selectSavedDocuments } from 'containers/SavedDocuments/savedDocuments.slice';
import {
  DocumentDragItem,
  DocumentDragItemEnum,
} from 'containers/RetrievalUnit/RetrievalUnitDraggable';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { TagDragItemEnum } from './TagDragItem.enum';
import { TagDragItem, TagDropItem } from './TagDragItem.interface';

export const useStyles = makeStyles((theme: Theme) => ({
  dragPlaceholder: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    '& > *': {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  container: {
    padding: 0,
    '&:hover $dragIndicator': {
      opacity: 1,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& a': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  dragIndicator: {
    position: 'absolute',
    left: 2,
    opacity: 0.5,
  },
  activeDropItem: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.light}`,
    '& $dragIndicator': {
      opacity: 1,
    },
  },
  hoveredDropItem: {
    boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}, ${theme.shadows[10]}`,
  },
  tagListEl: {
    padding: 0,
    '& a': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
}));

export type Props = {
  tag: TagDetails;
  dragType: TagDragItemEnum;
  dropTypes: string[];
  onMove: (draggedId: number, id: number) => void;
  onTagDrop: (item: TagDragItem, id: number) => void;
  onDocumentDrop?: (item: DocumentDragItem, tag: TagDetails) => void;
  draggable?: boolean;
} & PageDrawerMenuItemProps;

export const TagListDragItem = ({
  tag,
  dragType,
  dropTypes,
  onMove,
  onTagDrop,
  onDocumentDrop,
  children,
  draggable = true,
  ...rest
}: Props) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const savedDocuments = useSelector(selectSavedDocuments);
  const [{ isDragging }, connectDrag] = useDrag<
    TagDragItem,
    unknown,
    { isDragging: boolean }
  >(() => ({
    type: dragType,
    item: {
      id: tag.id,
      type: dragType,
      tag,
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));
  const [{ draggingType, isOver, canDrop }, connectDrop] = useDrop({
    accept: dropTypes,
    hover(dropItem: TagDropItem) {
      if (
        dropItem.type !== DocumentDragItemEnum.Document &&
        dropItem.id !== tag.id &&
        (dropTypes.includes(TagDragItemEnum.Own) ||
          dropTypes.includes(TagDragItemEnum.Following))
      ) {
        onMove(dropItem.id, tag.id);
      }
    },
    drop(dropItem: TagDropItem) {
      if (dropItem.type === DocumentDragItemEnum.Document && onDocumentDrop) {
        onDocumentDrop(dropItem, tag);
      }

      if (dropItem.type !== DocumentDragItemEnum.Document) {
        onTagDrop(dropItem, tag.id);
      }
    },
    canDrop(dropItem: TagDropItem) {
      if (
        dropItem.type === DocumentDragItemEnum.Document ||
        dragType !== TagDragItemEnum.Following
      ) {
        return !savedDocuments[dropItem.id]?.some(
          (saved) => saved.tag.id === tag.id
        );
      }

      return draggable;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingType: monitor.getItemType(),
    }),
  });
  connectDrag(ref);
  connectDrop(ref);

  if (!draggable) {
    return (
      <PageDrawerMenuItem className={classes.tagListEl} {...rest}>
        {children}
      </PageDrawerMenuItem>
    );
  }

  return (
    <PageDrawerMenuItem
      ref={ref}
      className={clsx(
        classes.container,
        isDragging && classes.dragPlaceholder,
        draggingType === 'document' && canDrop && classes.activeDropItem,
        draggingType === 'document' &&
          canDrop &&
          isOver &&
          classes.hoveredDropItem
      )}
      {...rest}
    >
      <DragIndicatorIcon fontSize="small" className={classes.dragIndicator} />
      {children}
    </PageDrawerMenuItem>
  );
};
