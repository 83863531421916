import React, { FC } from 'react';
import { TagOnboardingBotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useChatMessages } from '../hooks/useChatMessages';
import { useTranslation } from 'react-i18next';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { ButtonProps } from '@mui/material';

interface Props extends Omit<ButtonProps, 'onClick'> {
  onClick: (botMessage: TagOnboardingBotTypeMessage) => void;
}

export const OnboardingModeBotButton: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation('common');
  const { buildTagOnboardingMessage } = useChatMessages();

  const handleClick = () => onClick(buildTagOnboardingMessage());

  return (
    <TrackedButton
      onClick={handleClick}
      data-testid="onboarding-modeButton"
      color="secondary"
      variant="contained"
      size="small"
      eventName={TrackEventName.TagOnboardingStartClicked}
      {...props}
    >
      {t('buttons.getStarted')}
    </TrackedButton>
  );
};
