import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { AuthProvider as AuthProviderOIDC } from 'react-oidc-context';
import { AuthContext, LoginDialogState } from './Auth.context';
import { AuthProviderCustom } from './AuthProviderCustom';
import {
  getPayloadPartFromStoredAccessToken,
  parseAccessTokenPayload,
  storeOrRemoveTokens,
} from './Auth.utils';
import posthog from 'posthog-js';
import { AuthProviderOidc } from './AuthProviderOidc';
import { OidcConfig } from './Auth.interface';
import { Me } from './models/Me.interface';
import { getUser } from '../User/user.slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/state/store';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export interface AuthProviderProps {
  oidcConfig: OidcConfig;
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({
  oidcConfig,
  children,
}) => {
  const [me, setMe] = useState<Me | null>(
    getPayloadPartFromStoredAccessToken()
  );

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  const [loginDialog, setLoginDialog] = useState<LoginDialogState>(
    LoginDialogState.Closed
  );

  const dispatch = useDispatch<AppDispatch>();
  const { tenant } = useParsedHostname();

  useEffect(() => {
    if (me) {
      dispatch(getUser(tenant));
    }
  }, [dispatch, me, tenant]);

  const setTokens = (
    _accessToken: string | null,
    _refreshToken: string | null
  ) => {
    setAccessToken(_accessToken);
    setRefreshToken(_refreshToken);
    storeOrRemoveTokens(_accessToken, _refreshToken);
    const parsedAccessTokenPayload = parseAccessTokenPayload(_accessToken);
    if (parsedAccessTokenPayload) {
      posthog.identify(parsedAccessTokenPayload.sub, {
        roles: parsedAccessTokenPayload.roles,
      });
      Sentry.setUser({
        id: parsedAccessTokenPayload.sub,
      });
    }
    setMe(parsedAccessTokenPayload);
  };

  const showLoginDialog = useCallback(() => {
    setLoginDialog(LoginDialogState.Login);
  }, []);

  const handleSignin = () => {
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider
      value={{
        me,
        oidcConfig,
        setTokens,
        setLoginDialog,
        loginDialog,
      }}
    >
      {oidcConfig.enabled ? (
        <AuthProviderOIDC
          client_id={oidcConfig.clientId}
          redirect_uri={oidcConfig.redirectUri}
          post_logout_redirect_uri={oidcConfig.postLogoutRedirectUri}
          authority={oidcConfig.authority}
          onSigninCallback={handleSignin}
        >
          <AuthProviderOidc
            isAuthenticated={!!me}
            setMe={setMe}
            setTokens={setTokens}
            tokens={{ accessToken, refreshToken }}
          >
            {children}
          </AuthProviderOidc>
        </AuthProviderOIDC>
      ) : (
        <AuthProviderCustom setMe={setMe} showLoginDialog={showLoginDialog}>
          {children}
        </AuthProviderCustom>
      )}
    </AuthContext.Provider>
  );
};
