import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { BaseError } from 'common/models/Error.interface';
import { searchResultsQueryKeys } from '../searchResults.utils';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { searchDocuments } from 'api/searchApi';
import { SearchResults } from '../SearchResults.interface';
import { QUERY_OPTIONS } from 'common/constants/query-options';

export type UseSearchResultsReturn = UseQueryResult<SearchResults, BaseError>;

export type UseSearchResultsProps = {
  options?: Omit<
    UseQueryOptions<SearchResults, BaseError>,
    'queryKey' | 'queryFn'
  >;
} & SearchPayload;

export const useSearchResults = ({
  page,
  pageSize = 10,
  options = {},
  ...searchPayload
}: UseSearchResultsProps): UseSearchResultsReturn =>
  useQuery<SearchResults, BaseError>(
    searchResultsQueryKeys.list({ ...searchPayload, page }),
    async ({ pageParam = page }) => {
      const { data } = await searchDocuments({
        ...searchPayload,
        page: pageParam,
        pageSize,
      });

      return data;
    },
    {
      ...QUERY_OPTIONS,
      ...options,
    }
  );
