import React, { FC, MouseEventHandler } from 'react';
import { Box, Chip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useStyles } from './useStyles';
import clsx from 'clsx';

export interface MoreLessButtonProps {
  isLess: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  wrapperClassName?: string;
}

export const MoreLessButton: FC<MoreLessButtonProps> = ({
  onClick,
  isLess,
  className,
  wrapperClassName,
}) => {
  const { t } = useTranslation('search');
  const classes = useStyles();

  return (
    <Box className={clsx(wrapperClassName, classes.moreBtnWrapper)}>
      <Chip
        size="small"
        color="secondary"
        className={clsx(className, classes.moreBtn)}
        onClick={onClick}
        icon={isLess ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        label={
          isLess ? t('filters.expandBtn.less') : t('filters.expandBtn.more')
        }
      />
    </Box>
  );
};
