import { useCallback } from 'react';
import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';
import { normalizeInputValue } from '../tagsAutocomplete.utils';
import { TagsAutocompleteOptionValue } from '../TagsAutocomplete.types';
import { TagType } from '@zarn/vendor/dist/saved-results';
import { NewTagsAutocompleteOptionValue } from '../models/NewTagsAutocompleteOption';

const filter = createFilterOptions<TagsAutocompleteOptionValue>();

export const useTagsAutocompleteFilterOptions = () => {
  const renderInput = useCallback(
    (
      options: TagsAutocompleteOptionValue[],
      params: FilterOptionsState<TagsAutocompleteOptionValue>
    ): TagsAutocompleteOptionValue[] => {
      const inputValue = normalizeInputValue(params.inputValue);

      const filtered = filter(options, { ...params, inputValue });

      const isExisting = options.some(
        (option) => inputValue === option.name && option.type === TagType.Own
      );

      if (inputValue !== '' && !isExisting) {
        filtered.push(new NewTagsAutocompleteOptionValue(inputValue));
      }

      return filtered;
    },
    []
  );

  return renderInput;
};
