import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { BatchUploadPrivateDocsFormValues } from '../BatchUploadPrivateDocs.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { userDocumentBatchCreate } from 'api/privateDocsApi/privateDocsBatchApi';
import { TagDetailsBase } from 'api/tagsApi/tagsApi.types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../User/user.slice';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';

interface UseBatchUploadPrivateDocsFormReturn {
  initialValues: BatchUploadPrivateDocsFormValues;
  onSubmit: (
    values: BatchUploadPrivateDocsFormValues,
    { setSubmitting }: FormikHelpers<BatchUploadPrivateDocsFormValues>
  ) => Promise<void>;
}

export const useBatchUploadPrivateDocsForm = (
  onFormSubmit: () => void
): UseBatchUploadPrivateDocsFormReturn => {
  const { t } = useTranslation(['common', 'privateDocs']);
  const history = useHistory();
  const { tenant } = useParsedHostname();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectUser);

  const initialValues = useMemo(
    (): BatchUploadPrivateDocsFormValues => ({
      file: [],
      selectedTag: '',
    }),
    []
  );

  const handleSubmit = useCallback(
    async (
      values: BatchUploadPrivateDocsFormValues,
      { setSubmitting }: FormikHelpers<BatchUploadPrivateDocsFormValues>
    ) => {
      setSubmitting(true);

      try {
        const selectedTag: TagDetailsBase | null =
          values.selectedTag !== '' ? values.selectedTag : null;

        const batchName: string =
          selectedTag?.name ??
          `My Collection ${format(new Date(), 'yyyy-MM-dd')}`;

        await userDocumentBatchCreate({
          tenant,
          batchName,
          files: values.file,
          userOpenai: user?.useOpenAI,
        });

        enqueueSnackbar(t('privateDocs:batchUploadDialog.successMessage'));

        onFormSubmit();

        if (selectedTag) {
          history.push(`/tags/${selectedTag.id}`);
        }
      } catch (error) {
        captureException(error);
        const err = deserializeAxiosError(error);

        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      }

      setSubmitting(false);
    },
    [user?.useOpenAI, enqueueSnackbar, t, onFormSubmit, history]
  );

  return {
    initialValues,
    onSubmit: handleSubmit,
  };
};
