import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 300,
    maxHeight: 'calc(100% - 140px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  popoverTitle: {
    padding: theme.spacing(2),
  },
  popoverTitleSmall: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)} `,
  },
  resetValueBtn: {
    width: 32,
    height: 32,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  toggleWrapper: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));
