import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import {
  createTagFollowers,
  TagDetails,
  updateTag,
  UpdateTagPayload,
} from 'api/tagsApi';
import { tagsQueryKeys } from '../tags.utils';
import { useAppDispatch } from 'app/state/hooks/useAppDispatch';
import { replaceTagDetails } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import { captureException } from '@sentry/react';

export const useUpdateTagDetails = (
  options?: UseMutationOptions<TagDetails, BaseError, UpdateTagPayload>
) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation<TagDetails, BaseError, UpdateTagPayload>(
    async (payload) => {
      try {
        const { data } = await updateTag(payload);

        if (payload.sharing.users.length) {
          await createTagFollowers({
            tagId: payload.id,
            users: payload.sharing.users,
            tenant: payload.tenant,
          });
        }

        return data;
      } catch (error) {
        captureException(error);
        throw deserializeAxiosError(error);
      }
    },
    {
      ...options,
      onSuccess(data: TagDetails) {
        queryClient.setQueryData(tagsQueryKeys.detail(data.id), () => data);
        dispatch(replaceTagDetails(data));
      },
    }
  );
};
