import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { EnhancedStore } from '@reduxjs/toolkit';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';

import { RootState } from 'app/state/store';
import { AuthContext, LoginDialogState } from 'containers/Auth/Auth.context';
import { UseAuthReturn } from 'containers/Auth/hooks/useAuth';
import { APIError } from 'common/models/Error.interface';
import { buildTheme } from 'app/theme';
import { meMock } from 'containers/Auth/__mocks__/me.mock';
import { testTenantSettingsMock } from 'api/tenantSettingsApi/__mocks__/tenantSettingsApi.mock';

// Custom query client logger
// Mute all query-client logs
setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
  },
});

type WrappedWithReduxProps = {
  children: React.ReactNode;
  store: EnhancedStore<RootState>;
};

export const WrappedWithRedux = ({
  children,
  store,
}: WrappedWithReduxProps) => {
  return <Provider store={store}>{children}</Provider>;
};

export const WrappedWithAuth = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <AuthContext.Provider
      value={{
        me: meMock,
        oidcConfig: null,
        setTokens: () => {},
        setLoginDialog: () => {},
        loginDialog: LoginDialogState.Closed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const WrappedWithTheme = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={buildTheme(testTenantSettingsMock)}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const WrappedWithQueryClient = ({ children }: any) => {
  return (
    <QueryClientProvider client={testQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

export const axiosResponseMock: AxiosResponse = {
  data: undefined,
  status: 200,
  statusText: 'OK',
  config: {},
  headers: {},
};

export const axiosErrorMock: AxiosError<APIError> = {
  config: {},
  code: 'NOT_FOUND',
  name: 'error',
  message: 'Something went wrong',
  response: {
    status: 404,
    statusText: 'NOT_FOUND',
    headers: {},
    config: {},
    data: {
      message: 'Something went wrong',
      detail: 'Something went wrong',
    },
  },
  isAxiosError: true,
  toJSON: () => ({}),
};

export const useAuthReturnDefaultValueMock: UseAuthReturn = {
  me: null,
  isAuthenticated: false,
  accountId: undefined,
  userRoleId: undefined,
  oidcConfig: null,
  accountName: '',
  organizationInitials: '',
  setTokens: () => {},
  setLoginDialog: () => {},
  hasAccess: () => false,
  loginDialog: LoginDialogState.Closed,
};

export const useAuthReturnAuthenticatedValueMock: UseAuthReturn = {
  ...useAuthReturnDefaultValueMock,
  me: meMock,
  isAuthenticated: true,
  accountId: meMock.organization?.accountId,
  userRoleId: meMock.organization?.userRoleId,
};

export const jwtTokenMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwiZXhwIjoxNTk2MDYwNjgyLCJuYmYiOjE1OTYwMzE4ODIsImlhdCI6MTU5NjAzMTg4Miwicm9sZXMiOltdLCJ1c2VyX3JvbGVzIjp7InJvbGVzIjpbInRlc3Qtcm9sZSJdfSwidXNlcl90ZW5hbnRzIjp7InRlbmFudHMiOlsidGVzdC10ZW5hbnQiLCJ6ZXRhLWFscGhhIl19fQ.IMnsJtaCIMaZGzqWSg1AsdyGYSInHHwMAzkrEk62ZnA';

export const jwtTokenValidMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwiZXhwIjoyNTk2MDYwNjgyLCJuYmYiOjI1OTYwMzE4ODIsImlhdCI6MjU5NjAzMTg4Miwicm9sZXMiOltdfQ.VnwXBpbM33gOZhNOHaU1KTVGsHRGOEV1lMEW7-w88TI';

export const refreshTokenMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwibmFtZSI6IlNoYW1pbCBNYW1tYWRvdiIsImV4cCI6MTU5NjA2MDY4MiwibmJmIjoxNTk2MDMxODgyLCJpYXQiOjE1OTYwMzE4ODJ9.AEgZjOGgB2jW786PHkfzmPn8f2Jjl4mCvkjIBgP2LfM';

export const validRefreshTokenMock =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5YjdjNzQwZC02ZDZkLTQ1ZTItODI4OC02YzA2ZDU1MDYwY2EiLCJlbWFpbCI6Im1hbW1hZG92QHpldGEtYWxwaGEuY29tIiwibmFtZSI6IlNoYW1pbCBNYW1tYWRvdiIsImV4cCI6MTYxMTY3NzA4NjU3NywibmJmIjoxNjExNjc3MDg2NTc3LCJpYXQiOjE2MTE2NzcwODY1Nzd9.qaf7nJOYV1chWmsTYcRpywWzMynIhci8u_TWbFNNtOg';

export const mockFiles = (files: File[]) => ({
  dataTransfer: {
    files,
    items: files.map((file) => ({
      kind: 'file',
      type: file.type,
      getAsFile: () => file,
    })),
    types: ['Files'],
  },
});
