import React, { useCallback, useState } from 'react';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import LinearProgress from '@mui/material/LinearProgress';
import { Nullable } from '../../../utils/assert';
import { StatusIcon } from './StatusIcon';

export type UploadedFileItemProps = {
  file: {
    size: number;
    path?: string;
    name: string;
    type: string;
  };
  status?: Nullable<'loading' | 'success' | 'error'>;
  onDelete: (fileName: string) => Promise<void>;
};

export const UploadedFileItem = ({
  file,
  status,
  onDelete,
}: UploadedFileItemProps) => {
  const { t } = useTranslation(['privateDocs']);
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(async () => {
    setOpen(false);
    await onDelete(file.name);
  }, [onDelete, file.name]);

  const isLoading = status === 'loading';

  return (
    <>
      <ListItem sx={{ position: 'relative' }}>
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', width: '60%', mt: -4, ml: 7 }}
            color="secondary"
          />
        )}

        <ListItemAvatar>
          <Avatar>
            <FileIcon />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={file.path ?? file.name}
          // File type is always empty. Also it's not so important to show the size. It would be better to show something like the number of private docs.
          // secondary={`${file.type}, ${Math.round(file.size / 1000)}KB`}
        />

        <ListItemSecondaryAction>
          <StatusIcon status={status ?? null}>
            <IconButton
              edge="end"
              aria-label="delete file"
              onClick={() => setOpen(true)}
              size="large"
              disabled={isLoading}
            >
              <CancelIcon />
            </IconButton>
          </StatusIcon>
        </ListItemSecondaryAction>
      </ListItem>

      <ExtendedConfirmationDialog
        open={open}
        setOpen={setOpen}
        onSubmit={handleSubmit}
        data-testid="removeFileConfirmation"
        aria-label="remove file confirmation"
      >
        {t('privateDocs:form.file.removeDialogConfirmation')}
      </ExtendedConfirmationDialog>
    </>
  );
};
