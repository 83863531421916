import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'app/state/store';
import { getOwnTags } from 'containers/Tags/ownTagsSlice/ownTags.slice';
import {
  getFollowingTags,
  selectFollowingTagsWithUpdates,
} from 'containers/Tags/followingTagsSlice/followingTags.slice';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useParsedHostname } from 'common/utils/useParsedHostname';

type TagUpdates = {
  id: string | number;
  name: string;
  newDocuments: number;
};

export interface UseTagsUpdatesReturn {
  hasTagsUpdates: boolean;
  tagsUpdatesCount: number;
  followingTags: TagUpdates[];
}

export const useTagsUpdates = (): UseTagsUpdatesReturn => {
  const dispatch = useDispatch<AppDispatch>();
  const { tenant } = useParsedHostname();
  const followingTagsWithUpdates = useSelector(selectFollowingTagsWithUpdates);
  const tagsUpdatesCount = useMemo(() => {
    return followingTagsWithUpdates.reduce((acc, cur) => {
      return acc + (cur.newDocuments ?? 0);
    }, 0);
  }, [followingTagsWithUpdates]);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) return;
    const getOwnTagsAction = dispatch(getOwnTags(tenant));
    return getOwnTagsAction.abort;
  }, [isAuthenticated, dispatch, tenant]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const getFollowingTagsAction = dispatch(getFollowingTags(tenant));

    const interval = setInterval(() => {
      dispatch(getFollowingTags(tenant));
    }, 3600000);

    return () => {
      getFollowingTagsAction.abort();
      clearInterval(interval);
    };
  }, [isAuthenticated, dispatch, tenant]);

  return {
    hasTagsUpdates: tagsUpdatesCount > 0,
    tagsUpdatesCount,
    followingTags: followingTagsWithUpdates,
  };
};
