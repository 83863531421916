import { AxiosResponse } from 'axios';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { omit } from 'lodash';

import {
  GroupedTaggedResourceItem,
  TaggedResourceItem,
} from '@zarn/vendor/dist/saved-results';
import {
  GroupedTaggedResources,
  TaggedResource,
  TaggedResourceData,
} from './TaggedResources.interface';
import { TaggedResourceType } from './TaggedResourceType.enum';

export const mapTaggedResourceData = (
  taggedResource: TaggedResourceItem
): TaggedResourceData => {
  return {
    id: taggedResource.id,
    tagId: taggedResource.tag_id,
  };
};

export const mapTaggedResource = (
  taggedResource: TaggedResourceItem
): TaggedResource => {
  return {
    resourceType: taggedResource.external_resource_type as TaggedResourceType,
    resourceId: taggedResource.external_resource_id,
    taggedData: [mapTaggedResourceData(taggedResource)],
  };
};

export const groupTaggedResources = (
  acc: GroupedTaggedResources,
  cur: TaggedResourceItem
): GroupedTaggedResources => {
  const resource = acc[cur.external_resource_id];

  acc[cur.external_resource_id] = resource
    ? {
        ...resource,
        taggedData: [...resource.taggedData, mapTaggedResourceData(cur)],
      }
    : mapTaggedResource(cur);

  return acc;
};

export const getFulfilledTaggedResources = (
  responses: PromiseSettledResult<AxiosResponse<TaggedResourceItem>>[]
): TaggedResourceItem[] => {
  return responses.reduce(
    (acc, cur) => (cur.status === 'fulfilled' ? [...acc, cur.value.data] : acc),
    [] as TaggedResourceItem[]
  );
};

export const mapGroupedTaggedResource = ({
  resources,
}: GroupedTaggedResourceItem): TaggedResource => {
  const res = resources[0];

  return {
    resourceType: res.external_resource_type as TaggedResourceType,
    resourceId: res.external_resource_id,
    taggedData: resources.map(mapTaggedResourceData),
  };
};

export const reduceGroupedTaggedResources = (
  acc: GroupedTaggedResources,
  cur: GroupedTaggedResourceItem
): GroupedTaggedResources => {
  const externalResouceId = cur.resources[0].external_resource_id;

  acc[externalResouceId] = mapGroupedTaggedResource(cur);

  return acc;
};

export const getFulfilledUntaggedResources = (
  responses: PromiseSettledResult<AxiosResponse<void>>[],
  ids: number[]
): number[] => {
  return responses.reduce(
    (acc, cur, i) => (cur.status === 'fulfilled' ? [...acc, ids[i]] : acc),
    [] as number[]
  );
};

export const filterUntaggedResources = (
  data: GroupedTaggedResources,
  resourceId: string,
  ids: number[]
): GroupedTaggedResources => {
  const resource = data[resourceId];

  if (!resource || ids.length <= 0) {
    return data;
  }

  const taggedData = resource.taggedData.filter(({ id }) => !ids.includes(id));

  return taggedData.length > 0
    ? {
        ...data,
        [resourceId]: {
          ...resource,
          taggedData,
        },
      }
    : omit(data, [resourceId]);
};

export const getTagsToAddToResource = (
  selectedTags: string[],
  initTags: TagDetails[],
  allTags: TagDetails[]
): number[] => {
  return selectedTags.reduce((acc, cur) => {
    const newTagId =
      !initTags.some(({ name }) => name === cur) &&
      allTags.find(({ name }) => name === cur)?.id;

    return newTagId ? [...acc, newTagId] : acc;
  }, [] as number[]);
};

export const getResourcesToUntag = (
  selectedTags: string[],
  initTags: TagDetails[],
  resources: TaggedResourceData[]
): number[] => {
  return initTags.reduce((acc, cur) => {
    const deletedResourceId =
      !selectedTags.includes(cur.name) &&
      resources.find(({ tagId }) => tagId === cur.id)?.id;

    return deletedResourceId ? [...acc, deletedResourceId] : acc;
  }, [] as number[]);
};
