import { WidgetLayoutSettings } from 'api/tenantSettingsApi/tenantSettingsApi.types';

export const DEFAULT_WIDGET_LAYOUT: WidgetLayoutSettings = {
  topFullWidthWidgets: [],
  columns: [
    {
      sizes: { xs: 12, sm: 6, md: 7, lg: 8, xl: 8, xxl: 8 },
      widgets: ['QA', 'searchResults'],
    },
    {
      sizes: { xs: 12, sm: 6, md: 5, lg: 4, xl: 4, xxl: 4 },
      widgets: [
        'findSimilarDocument',
        'findAuthoredBy',
        'vosViewer',
        'analytics',
        'expertSearch',
      ],
    },
  ],
};
