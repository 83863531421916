import { AxiosResponse } from 'axios';
import {
  Configuration,
  ExternalDocumentsApi,
  UserDocumentItem,
} from '@zarn/vendor/dist/user-documents';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import { CreateExternalDocPayload } from './externalDocApi.types';
import { serializeCreateExternalDocumentPayload } from './externalDocsApi.utils';

export const externalDocumentsApi = new ExternalDocumentsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const externalDocumentCreate = async (
  payload: CreateExternalDocPayload,
  tenant: string,
  indexId?: string,
  useOpenai: boolean = false
): Promise<AxiosResponse<UserDocumentItem>> =>
  await externalDocumentsApi.createExternalDocument({
    requesterUuid: '',
    userTenants: '',
    userRoles: '',
    tenant,
    indexId,
    useOpenai,
    userDocumentForm: serializeCreateExternalDocumentPayload(payload),
  });
