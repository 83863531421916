import React from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import { PrivateDocFormValues } from './PrivateDocForm.interface';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { FileUploadField } from 'common/components/Fields/FileUploadField/FileUploadField';
import { Box } from '@mui/material';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { MoreForm } from './MoreForm';
import { FileWithPath } from 'react-dropzone';
import { Nullable } from 'common/utils/assert';

type PrivateDocFormProps<T> = {
  loadingsStates?: Array<Nullable<'loading' | 'success' | 'error'>>;
} & FormikProps<PrivateDocFormValues & T>;

export const PrivateDocForm = <T,>({
  loadingsStates,
  ...props
}: PrivateDocFormProps<T>) => {
  const { t } = useTranslation(['common', 'privateDocs']);

  const isSingleDocument =
    !props?.values?.file?.length || props?.values?.file?.length === 1;

  const handleFileDrop = (files: FileWithPath[]) => {
    void props.setValues(
      (prevState) => ({
        ...prevState,
        uri: files.length > 1 ? '' : prevState.uri,
        title: '',
        authors: '',
        year: '',
        source: '',
        abstractText: '',
      }),
      false
    );
  };

  const acceptedDocTypes = {
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
    'text/html': ['.html'],
    'text/markdown': ['.md'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/vnd.visio': ['.vsd'],
    'application/vnd.oasis.opendocument.graphics': ['.odg'],
  };

  return (
    <Box>
      <Box>
        <FormTextField
          name="uri"
          margin="normal"
          label={t('privateDocs:form.uri.label')}
          placeholder={t('privateDocs:form.uri.placeholder')}
          disabled={!isSingleDocument}
        />

        <FileUploadField
          name="file"
          label={t('privateDocs:form.file.label')}
          accept={
            isFeatureEnabled('ff-new-private-doc-types')
              ? acceptedDocTypes
              : { 'application/pdf': ['.pdf'] }
          }
          loadingsStates={loadingsStates}
          onFileDrop={handleFileDrop}
        />
      </Box>

      {isSingleDocument && <MoreForm />}
    </Box>
  );
};
