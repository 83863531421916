import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TagsSortOption } from './TagsSort.interface';
import { TagsSortByEnum } from './TagsSortBy.enum';

export const useTagsSortOptions = (sharedTags?: boolean): TagsSortOption[] => {
  const { t } = useTranslation('tags');
  const sortOptions = useMemo(
    () => [
      ...(!sharedTags
        ? [
            {
              value: TagsSortByEnum.PriorityOrder,
              label: t('sort.options.priorityOrder.label'),
            },
          ]
        : []),
      {
        value: TagsSortByEnum.Alphabetically,
        label: t('sort.options.alphabetically.label'),
      },
      {
        value: TagsSortByEnum.CreationDate,
        label: t('sort.options.creationDate.label'),
      },
      {
        value: TagsSortByEnum.ModifiedDate,
        label: t('sort.options.modifiedDate.label'),
      },
    ],
    [t, sharedTags]
  );

  return sortOptions;
};
