import React, { FC, useCallback, useMemo } from 'react';
import { Chat } from '../../Chat';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import {
  buildChatContext,
  getPageDimensions,
  getTopCoordinate,
  mapChunk,
} from '../helpers';
import { useZoomLevel } from '@zarn/pdf-viewer';
import { AnnotationHighlight, NoteDetails } from 'api/notesApi/notesApi.types';
import { RetrievalUnitData } from '../../RetrievalUnit/RetrievalUnitData.interface';
import { SearchEngineEnum } from 'common/enums';
import { useSaveChat } from '../hooks/useSaveChat';
import {
  BotType,
  DefaultBotTypes,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useTranslation } from 'react-i18next';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import DocStatus from '../../RetrievalUnit/DocStatus/DocStatus';

interface Props {
  onAddHighlights: (highlights: AnnotationHighlight[]) => void;
  docId: string;
  docData: RetrievalUnitData;
  searchEngine?: SearchEngineEnum;
  scrollTo: (top: number) => void;
  defaultBotType: DefaultBotTypes;
  supportedBotTypes: BotType[];
}

export const ChatOfPdfViewer: FC<Props> = ({
  onAddHighlights,
  docId,
  docData,
  searchEngine,
  scrollTo,
  defaultBotType,
  supportedBotTypes,
}) => {
  const { t } = useTranslation('common');
  const { setParams } = useQuerySearchParams();
  const { zoomLevel } = useZoomLevel();

  const handleCreateNote = useCallback(
    async (newNote: NoteDetails) => {
      setParams({
        noteId: `${newNote.id}`,
      });
    },
    [setParams]
  );

  const { saveChat, isSaving } = useSaveChat({
    docId,
    onNoteCreated: handleCreateNote,
  });

  const handleEvidenceChunkClick = useCallback(
    (chunks: ChunkHighlight[]) => {
      const { height: pageHeight, width: pageWidth } = getPageDimensions();
      const temporaryHighlights = chunks.map(
        mapChunk(pageWidth, pageHeight, zoomLevel)
      );
      onAddHighlights(temporaryHighlights);

      const rect = chunks[0];
      if (!rect) {
        return;
      }
      scrollTo(
        getTopCoordinate({ ...rect, y1: rect.y1 }, getPageDimensions().height)
      );
    },
    [onAddHighlights, scrollTo, zoomLevel]
  );

  const context = useMemo(
    () =>
      buildChatContext(
        searchEngine ?? SearchEngineEnum.ZetaAlpha,
        docId,
        docData
      ),
    [docData, docId, searchEngine]
  );

  const greetings =
    docData.status && docData.status.title !== 'SUCCESS' ? (
      <DocStatus status={docData.status} sx={{ ml: 1, mr: 1 }} />
    ) : undefined;

  return (
    <Chat
      context={context}
      onSave={saveChat}
      isSaving={isSaving}
      onEvidenceChunkClick={handleEvidenceChunkClick}
      defaultBotType={defaultBotType}
      supportedBotTypes={supportedBotTypes}
      placeholder={t('chat.message.input.placeholderDocument')}
      disabled={docData.status && docData.status.title !== 'SUCCESS'}
      greetings={greetings}
    />
  );
};
