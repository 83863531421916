import { GRAPH_COLORS } from 'common/constants';
import {
  AnalyticsRequest,
  AnalyticsResponse,
  AnalyticsResponseDataInner,
  AnalyticsResponseDataInnerDataInner,
  Filters,
  FilterExists,
} from '@zarn/vendor/dist/search';
import { QueryEntity } from 'containers/QueryEntities/QueryEntities.interface';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import {
  AnalyticsGraph,
  AnalyticsGraphItem,
  AnalyticsGraphItemData,
  SpacyBasedNer,
} from './AnalyticsGraph.interface';

export const serializeAnalyticsNer = (
  entities: QueryEntity[]
): SpacyBasedNer[] => {
  return entities.map(({ offset, data }) => ({
    entity_class: data.entityClass,
    end_offset: offset.end,
    entity_type: data.type,
    link: data.id,
    mention: data.name,
    start_offset: offset.start,
    node_type: data.nodeType ?? '',
  }));
};

export const serializeAnalyticsGraphFiltersExists = ({
  withCode,
}: SearchPayload): FilterExists[] | undefined => {
  return withCode ? [{ name: 'with_code' }] : undefined;
};

export const serializeAnalyticsGraphFilters = (
  payload: SearchPayload
): Filters => {
  const {
    date,
    docSources,
    docTypes,
    term,
    locationCountries,
    organizations,
    tagIds,
  } = payload;

  return {
    term: term ? [{ name: term[0], value: term[1] }] : [],
    terms: [
      ...(docSources ? [{ name: 'source', values: docSources }] : []),
      ...(docTypes ? [{ name: 'document_type', values: docTypes }] : []),
      ...(locationCountries
        ? [{ name: 'location_countries', values: locationCountries }]
        : []),
      ...(organizations
        ? [{ name: 'organizations', values: organizations }]
        : []),
      ...(tagIds ? [{ name: 'tag_ids', values: tagIds }] : []),
    ],
    exists: serializeAnalyticsGraphFiltersExists(payload),
    range: date
      ? [
          {
            name: 'timestamp',
            lower_bound: date[0],
            upper_bound: date[1],
          },
        ]
      : [],
  };
};

export const serializeAnalyticsGraphPayload = (
  query: string,
  queryEntities: QueryEntity[],
  payload: SearchPayload
): AnalyticsRequest | null => {
  if (!query || queryEntities.length === 0) {
    return null;
  }

  return {
    query: { query },
    ner: serializeAnalyticsNer(queryEntities),
    filters: serializeAnalyticsGraphFilters(payload),
    tenant: payload.tenant,
    default_plot: false,
  };
};

export const mapAnalyticsGraphItemData = (
  { x, y }: AnalyticsResponseDataInnerDataInner,
  id: string,
  title: string
): AnalyticsGraphItemData => ({ y, id, title, x });

export const mapAnalyticsGraphItem = (
  item: AnalyticsResponseDataInner,
  i: number
): AnalyticsGraphItem => ({
  label: item.label,
  id: item.ID,
  color: GRAPH_COLORS[i],
  data: item.data.map((data) =>
    mapAnalyticsGraphItemData(data, item.ID, item.label)
  ),
});

export const getMinMaxXValues = (
  data: AnalyticsResponseDataInner[]
): [number, number] => {
  return data.reduce(
    (acc, cur) => {
      const [min, max] = acc;
      if (cur.data.length === 0) return acc;

      const first = new Date(cur.data[0].x).valueOf();
      const last = new Date(cur.data[cur.data.length - 1].x).valueOf();

      return [min < first ? min : first, max > last ? max : last];
    },
    [Date.now().valueOf(), 0]
  );
};

export const mapAnalyticsResponse = (
  res: AnalyticsResponse
): AnalyticsGraph | null => {
  if (res.data.length === 0 || res.data[0].data.length === 0) return null;

  return {
    title: res.name,
    xAxis: res.x_axis,
    yAxis: res.y_axis,
    xAxisMinMax: getMinMaxXValues(res.data),
    query: res.query,
    data: res.data.map(mapAnalyticsGraphItem),
  };
};

export const getTooltipXOffset = ({ x, flyoutWidth }: any) => {
  if (x < flyoutWidth) return 60;
  return -60;
};
