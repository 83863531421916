import React, { FC } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';

interface Props {
  onLogout: () => void;
}

export const NavbarProfileLogoutButtonsLayout: FC<Props> = ({ onLogout }) => {
  const { t } = useTranslation('common');

  return (
    <ListItem button onClick={onLogout}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>

      <ListItemText primary={t('navBar.logout')} />
    </ListItem>
  );
};
