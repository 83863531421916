import React, { FC } from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'common/components/Buttons/SubmitButton';

import saveDocument from 'containers/OnboardingWizard/images/save_document.gif';
import tagsPage from 'containers/OnboardingWizard/images/add_new_tag.gif';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export interface LastStepProps {
  onNext: () => void;
}

export const LastStep: FC<LastStepProps> = ({ onNext }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);

  return (
    <div aria-label={t('onboardingWizard:lastStep.label')}>
      <Box mt={2} mb={3}>
        <Typography variant="h5" gutterBottom>
          {t('onboardingWizard:lastStep.title')}
        </Typography>
        <Typography>{t('onboardingWizard:lastStep.text1')}</Typography>
        <Typography>{t('onboardingWizard:lastStep.text2')}</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography variant="body1">
                  {t('onboardingWizard:lastStep.documentCard.title')}
                </Typography>
              }
              subheader={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textSecondary">
                  {t('onboardingWizard:lastStep.documentCard.subheader')}
                </Typography>
              }
            />
            <Divider />
            <CardMedia
              image={saveDocument}
              title={t('onboardingWizard:lastStep.documentCard.title')}
              style={{ paddingTop: '50%' }}
            />
          </Card>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Typography variant="body1">
                  {t('onboardingWizard:lastStep.tagsCard.title')}
                </Typography>
              }
              subheader={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography variant="body2" color="textSecondary">
                  {t('onboardingWizard:lastStep.tagsCard.subheader')}
                </Typography>
              }
            />
            <Divider />
            <CardMedia
              image={tagsPage}
              title={t('onboardingWizard:lastStep.tagsCard.title')}
              style={{ paddingTop: '50%' }}
            />
          </Card>
        </Grid>
      </Grid>
      <Box mt={2} display="flex">
        <SubmitButton
          aria-label="submit"
          onClick={onNext}
          text={t('common:buttons.finish')}
          eventName={TrackEventName.WizardLastStepCompleted}
        />
      </Box>
    </div>
  );
};
