import React, { KeyboardEvent, useCallback } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { FormSwitchControl } from 'common/components/Fields/FormSwitchControl';
import SubmitButton from 'common/components/Buttons/SubmitButton';
import { ColorPaletteField } from 'common/components/Fields/ColorPaletteField/ColorPaletteField';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import TagDeleteButton from '../../TagDeleteButton/TagDeleteButton';
import { useTagsPalette } from '../hooks/useTagsPalette';
import { useTagSettingsInitialValues } from '../hooks/useTagSettingsInitialValues';
import { useTagSettingsValidation } from '../hooks/useTagSettingsValidation';
import { useTagSettingsForm } from '../hooks/useTagSettingsForm';
import ShareSelection from 'common/components/Share/ShareSettings/ShareSelection/ShareSelection';
import ShareSelectionSkeleton from 'common/components/Share/ShareSettings/ShareSelection/ShareSelectionSkeleton/ShareSelectionSkeleton';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { useTagShareTeam } from 'containers/TagShare/TagsShareSettings/hooks/useTagShareTeam';
import { FormSection } from 'common/components/FormSection/FormSection';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import TagShareLinkActions from 'containers/TagShare/TagShareLinkActions';
import { TextField } from 'common/components/TextField';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';

const useStyles = makeStyles((theme: Theme) => ({
  switchLabel: {
    marginLeft: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
  colorLabel: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
}));

export type TagSettingsDialogProps = {
  tag: TagDetails;
  setOpen: (state: boolean) => void;
} & DialogProps;

const TagSettingsDialog = ({
  tag,
  open,
  setOpen,
  ...dialogProps
}: TagSettingsDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['tags', 'common']);
  const tagsPalette = useTagsPalette();
  const { me, userRoleId, accountName, organizationInitials } = useAuth();
  const organization = me?.organization ?? null;

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const { data: teamMembers, isLoading } = useTagShareTeam({
    me,
    enabled: !!organization && open,
  });

  const initialValues = useTagSettingsInitialValues({
    tag,
    tagsPalette,
    organization,
    teamMembers: teamMembers?.items ?? [],
  });

  const validationSchema = useTagSettingsValidation();

  const { onSubmit } = useTagSettingsForm({ tag, onSubmitForm: handleClose });

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const { isShareToPublicInTag } = useTenantFeatures();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      {...dialogProps}
      aria-label={t('settings.dialog.ariaLabel')}
      data-testid="tagSettingsDialog"
    >
      <DialogActionTitle onClose={handleClose}>
        {t('tags:tagSettings')}
      </DialogActionTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, dirty, isSubmitting, handleSubmit, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(e);
              }
            }}
          >
            <DialogContent>
              <FormSection title={t('tags:settings.generalSettingsHeader')}>
                <Field name="tagName">
                  {({ field, meta }: FieldProps<string>) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoFocus
                      margin="normal"
                      label={t('tags:settings.tagName.label')}
                      multiline
                      onKeyPress={handleKeyPress}
                      inputProps={{ 'aria-label': 'Tag name input' }}
                      error={!!(meta.error && meta.touched)}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
                <FormControlLabel
                  classes={{ root: classes.switchLabel }}
                  labelPlacement="start"
                  label={t('tags:settings.enableRecommendations.label')}
                  control={<FormSwitchControl name="enableRecommendations" />}
                />
                <FormControl margin="normal">
                  <FormLabel className={classes.colorLabel}>
                    {t('tags:settings.tagColor.subHeader')}
                  </FormLabel>
                  <Field name="color">
                    {({ field }: FieldProps<string>) => (
                      <ColorPaletteField
                        {...field}
                        colorPalette={tagsPalette}
                      />
                    )}
                  </Field>
                </FormControl>
              </FormSection>
              {isShareToPublicInTag && (
                <FormSection
                  title={t('common:share.settings.publicAccessTitle')}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    sx={{ width: '100%' }}
                    label={t('common:share.settings.isPublicTag.label')}
                    control={
                      <FormSwitchControl name="shareSettings.isPublicTag" />
                    }
                  />
                  <FormHelperText>
                    {t('common:share.settings.isPublicTag.helperText')}
                  </FormHelperText>
                </FormSection>
              )}

              <FormSection title={t('tags:shareSettings.subHeader')}>
                {isLoading && <ShareSelectionSkeleton />}
                {userRoleId && teamMembers && (
                  <ShareSelection
                    fieldName="shareSettings.selection"
                    userRoleId={userRoleId}
                    accountName={accountName}
                    organizationInitials={organizationInitials}
                    users={teamMembers.items}
                  />
                )}
                {(!!values.shareSettings.selection.length ||
                  values.shareSettings.isPublicTag) && (
                  <Box mt={2}>
                    <TagShareLinkActions tag={tag} />
                  </Box>
                )}
              </FormSection>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2} justifyContent="space-between">
                {tag && (
                  <Grid item>
                    <TagDeleteButton tag={tag} />
                  </Grid>
                )}
                <Grid item container xs spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button onClick={handleClose}>
                      {t('common:buttons.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <SubmitButton
                      disabled={!isValid || !dirty}
                      isSubmitting={isSubmitting}
                      eventName={TrackEventName.TagSettingsUpdated}
                      eventProps={{ tagId: tag.id }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

TagSettingsDialog.displayName = 'TagSettingsDialog';

export default TagSettingsDialog;
