import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteInitialQuery, SavedQueryDetails } from 'api/savedQueriesApi';
import { BaseError } from 'common/models/Error.interface';
import { INITIAL_QUERY_KEY } from 'containers/InitialQuery/initialQuery.utils';
import { useParsedHostname } from 'common/utils/useParsedHostname';

export const useDeleteInitialQuery = (
  options?: UseMutationOptions<any, BaseError>
) => {
  const { t } = useTranslation('search');
  const parsedHostname = useParsedHostname();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<any, BaseError>(
    async () => {
      return (await deleteInitialQuery(parsedHostname.tenant)).data;
    },
    {
      onSuccess() {
        queryClient.setQueryData<SavedQueryDetails | null>(
          INITIAL_QUERY_KEY,
          () => null
        );
        enqueueSnackbar(t('initialQuery.reset.successMessage'));
      },
      ...options,
    }
  );
};
