import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { usePrivateDocFormValidation } from 'containers/PrivateDocs/PrivateDocForm/usePrivateDocFormValidation';

export const useAddPrivateDocsValidation = () => {
  const { t } = useTranslation(['privateDocs']);
  const { defaultShape } = usePrivateDocFormValidation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...defaultShape,
        selectedTags: Yup.array().when(
          'addToFavorites',
          ([addToFavorites], schema: any) => {
            return addToFavorites
              ? schema.min(0)
              : schema.min(1, t('privateDocs:form.selectedTags.error'));
          }
        ),
        addToFavorites: Yup.boolean(),
      }),
    [defaultShape, t]
  );

  return validationSchema;
};
