import {
  Configuration,
  TaggableResourcesApi,
} from '@zarn/vendor/dist/saved-results';
import { PaginatedResults } from 'api/models/PaginatedResults';
import { AxiosResponse } from 'axios';

import { BASE_HEADERS, SERVICE_URL } from '../apiConfig';
import axiosInstance from '../axiosInstance';
import {
  CreateSavedQueryPayload,
  SavedQueriesListPayload,
  SavedQueryDetails,
} from './savedQueriesApi.types';
import {
  deserializeSavedQueryDetails,
  serializeCreateSavedQueryPayload,
  serializeSavedQueriesListPayload,
} from './savedQueriesApi.utils';
import { DynamicSettings } from '../tenantSettingsApi/tenantSettingsApi.types';

const taggableResourcesApi = new TaggableResourcesApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const listSavedQueries = async (
  payload: SavedQueriesListPayload,
  searchFiltersDynamic?: DynamicSettings[] | null
): Promise<AxiosResponse<PaginatedResults<SavedQueryDetails>>> => {
  const { data, ...rest } = await taggableResourcesApi.queriesFilter(
    serializeSavedQueriesListPayload(payload)
  );

  return {
    ...rest,
    data: new PaginatedResults(
      data.count,
      data.results.map(deserializeSavedQueryDetails(searchFiltersDynamic)),
      data.next?.page,
      data.previous?.page,
      payload.page,
      payload.pageSize
    ),
  };
};

export const getSavedQuery = async (
  savedQueryId: number,
  tenant: string,
  searchFiltersDynamic?: DynamicSettings[] | null
): Promise<AxiosResponse<SavedQueryDetails>> => {
  const { data, ...rest } = await taggableResourcesApi.retrieveQuery({
    requesterUuid: '',
    userRoles: '',
    tenant,
    queryId: savedQueryId,
  });

  return {
    ...rest,
    data: deserializeSavedQueryDetails(searchFiltersDynamic)(data),
  };
};

export const createSavedQuery = async (
  payload: CreateSavedQueryPayload,
  searchFiltersDynamic?: DynamicSettings[] | null
): Promise<AxiosResponse<SavedQueryDetails>> => {
  const serializedPayload = serializeCreateSavedQueryPayload(payload);
  const { data, ...rest } = await taggableResourcesApi.createQuery(
    serializedPayload
  );

  return {
    ...rest,
    data: deserializeSavedQueryDetails(searchFiltersDynamic)(data),
  };
};

export const deleteSavedQuery = async (
  savedQueryId: number,
  tenant: string
): Promise<AxiosResponse<number>> => {
  const response = await taggableResourcesApi.deleteQuery({
    requesterUuid: '',
    userRoles: '',
    tenant,
    queryId: savedQueryId,
  });

  return {
    ...response,
    data: savedQueryId,
  };
};

export const createInitialQuery = async (
  savedQueryId: number,
  tenant: string,
  searchFiltersDynamic?: DynamicSettings[] | null
): Promise<AxiosResponse<SavedQueryDetails>> => {
  await taggableResourcesApi.replaceInitialQuery({
    requesterUuid: '',
    userRoles: '',
    tenant,
    body: {
      query_id: savedQueryId,
    },
  });

  return getSavedQuery(savedQueryId, tenant, searchFiltersDynamic);
};

export const getInitialQuery = async (
  tenant: string,
  searchFiltersDynamic?: DynamicSettings[] | null
): Promise<AxiosResponse<SavedQueryDetails>> => {
  const {
    data: { query_id: queryId },
  } = await taggableResourcesApi.retrieveInitialQuery({
    requesterUuid: '',
    userRoles: '',
    tenant,
  });

  return getSavedQuery(queryId, tenant, searchFiltersDynamic);
};

export const deleteInitialQuery = async (
  tenant: string
): Promise<AxiosResponse> => {
  return await taggableResourcesApi.deleteInitialQuery({
    requesterUuid: '',
    userRoles: '',
    tenant,
  });
};
