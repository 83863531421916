import { useMemo } from 'react';

import { SaveDocumentFormValues } from 'containers/SavedDocuments/SavedDocuments.interface';
import { TagDetails } from 'api/tagsApi/tagsApi.types';

export const useDocTagsInitialValues = (docTags: TagDetails[]) => {
  const initialValues = useMemo<SaveDocumentFormValues>(
    () => ({
      selectedTags: docTags.map((tag: TagDetails) => ({
        id: tag.id,
        name: tag.name,
        type: tag.type,
        ownerId: tag.owner?.id,
        permission: tag.permission,
        canDeleteDoc: tag.canDeleteDoc,
      })),
    }),
    [docTags]
  );

  return initialValues;
};
