import React from 'react';
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { FormTextField } from 'common/components/Fields/FormTextField';
import { FormCheckboxField } from 'common/components/Fields/FormCheckboxField';
import { useSharePermissionSelectOptions } from '../hooks/useSharePermissionSelectOptions';

export type ShareSelectedOptionProps = {
  primary: string;
  secondary: string;
  initials?: string;
  hideNotifyCheckbox?: boolean;
  notifyFieldName: string;
  permissionFieldName: string;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const ShareSelectedOption = React.memo(
  ({
    primary,
    initials,
    secondary,
    notifyFieldName,
    hideNotifyCheckbox,
    permissionFieldName,
    onDelete,
  }: ShareSelectedOptionProps) => {
    const permissionOptions = useSharePermissionSelectOptions();

    return (
      <ListItem dense disableGutters data-testid="shareSelectedOption">
        <Box visibility={hideNotifyCheckbox ? 'hidden' : 'visible'}>
          <FormCheckboxField
            name={notifyFieldName}
            aria-label="Notify selected group or user"
          />
        </Box>

        <ListItemAvatar>
          <StyledAvatar title={primary}>{initials ?? primary[0]}</StyledAvatar>
        </ListItemAvatar>

        <ListItemText primary={primary} secondary={secondary} />

        <Box
          sx={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 1,
            paddingLeft: 1,
          }}
        >
          <FormTextField
            select
            name={permissionFieldName}
            size="small"
            sx={{ minWidth: 120 }}
          >
            {permissionOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormTextField>

          <IconButton
            size="small"
            onClick={onDelete}
            aria-label="Delete selected user"
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </ListItem>
    );
  }
);

ShareSelectedOption.displayName = 'ShareSelectedOption';

export default ShareSelectedOption;
