import React from 'react';
import { Box, Divider } from '@mui/material';

import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import DocSource from 'containers/RetrievalUnit/DocSource/DocSource';
import DocTitle from 'common/components/Docs/DocTitle/DocTitle';
import DocMetadata from 'common/components/Docs/DocMetadata/DocMetadata';
import DocDate from 'common/components/Docs/DocDate/DocDate';
import DocCites from 'common/components/Docs/DocCites';
import DocRefs from 'common/components/Docs/DocRefs';
import { AuthorsList } from 'common/components/AuthorsList/AuthorsList';
import { RetrievalUnitTweets } from 'containers/RetrievalUnit/RetrievalUnitTweets/RetrievalUnitTweets';
import { RetrievalUnitGithub } from 'containers/RetrievalUnit/RetrievalUnitGithub/RetrievalUnitGithub';
import { DocInlineTags } from 'containers/RetrievalUnit/DocInlineTags';
import { HitType, SearchEngineEnum } from 'common/enums';
import DocSimilarToButton from 'containers/RetrievalUnit/DocSimilarToButton';
import DocTags from 'containers/SavedDocuments/DocTags';
import DocFavoriteButton from 'containers/RetrievalUnit/DocFavoriteButton';
import DocShare from 'containers/RetrievalUnit/DocShare';
import DocBibtexButton from 'containers/RetrievalUnit/DocBibtexButton';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useTenantFeatures } from 'common/hooks/useTenantFeatures';

export type DocPDFViewerInfoProps = {
  searchEngine?: SearchEngineEnum;
  docData: RetrievalUnitData;
};

const DocPDFViewerInfo = ({ searchEngine, docData }: DocPDFViewerInfoProps) => {
  const {
    title,
    source,
    uri,
    date,
    year,
    authors,
    duplicates,
    ontologyId,
    numberOfCitations,
    numberOfRefs,
    githubScore,
    tweets,
    numberOfTweets,
    githubRepos,
    getSimilarDocsId,
    getCitesId = null,
    getRefsId = null,
    getBibtexId,
    document: { id: docId, type: docType },
  } = docData;
  const isPrivateDoc = docType === HitType.PrivateDocument;
  const renderCites = typeof numberOfCitations === 'number';
  const renderRefs = typeof numberOfRefs === 'number';

  const { isExportBibtexInDoc } = useTenantFeatures();

  return (
    <TrackedActionContext.Provider value={{ actionContext: 'PDF viewer info' }}>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={0.25}
        data-testid="DocPDFViewerInfo-1"
      >
        <div>
          <DocSource
            uri={uri}
            id={docId}
            source={source}
            duplicates={duplicates}
          />
          <DocTitle uri={uri} title={title} id={docId} />
        </div>

        <DocMetadata>
          <DocDate date={date} year={year} />
          {authors && <AuthorsList authors={authors} />}
        </DocMetadata>

        <Box mt={1} display="flex" gap={0.25} flexWrap="wrap">
          {renderCites && numberOfCitations > 0 && (
            <DocCites score={numberOfCitations} getCitesId={getCitesId} />
          )}

          {renderRefs && numberOfRefs > 0 && (
            <DocRefs score={numberOfRefs} getRefsId={getRefsId} />
          )}

          <RetrievalUnitTweets
            tweets={tweets}
            numberOfTweets={numberOfTweets}
            documentId={docId}
          />

          {typeof githubScore === 'number' && (
            <RetrievalUnitGithub
              score={githubScore}
              repos={githubRepos}
              documentId={docId}
            />
          )}
        </Box>

        <DocInlineTags docData={docData} searchEngine={searchEngine} />
      </Box>

      <Divider />

      <Box
        py={0.5}
        px={2}
        display="flex"
        gap={0.25}
        data-testid="DocPDFViewerInfo-2"
      >
        {getSimilarDocsId && (
          <DocSimilarToButton
            getSimilarDocsId={getSimilarDocsId}
            eventName={TrackEventName.FindSimilarClicked}
            eventProps={{ ontologyId }}
          />
        )}

        <DocTags docData={docData} searchEngine={searchEngine} />

        <DocFavoriteButton docData={docData} searchEngine={searchEngine} />

        {!isPrivateDoc && (
          <DocShare
            docId={docId}
            docTitle={title}
            docAuthors={authors}
            eventName={TrackEventName.ShareDocumentClicked}
          />
        )}

        {getBibtexId && isExportBibtexInDoc && (
          <DocBibtexButton bibtexId={getBibtexId} />
        )}
      </Box>
    </TrackedActionContext.Provider>
  );
};

DocPDFViewerInfo.displayName = 'DocPDFViewerInfo';

export default DocPDFViewerInfo;
