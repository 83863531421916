import React, { createContext } from 'react';

import { OidcConfig } from './Auth.interface';
import { Me } from './models/Me.interface';

export enum LoginDialogState {
  Closed,
  Login,
  ResetPassword,
  SignUp,
}

export interface AuthContextValue {
  me: Me | null;
  oidcConfig: OidcConfig | null;
  setTokens: (jwt: string | null, refreshToken: string | null) => void;
  setLoginDialog: React.Dispatch<React.SetStateAction<LoginDialogState>>;
  loginDialog: LoginDialogState;
}

export const authContextInitialValue: AuthContextValue = {
  me: null,
  oidcConfig: null,
  setTokens: () => {},
  setLoginDialog: () => {},
  loginDialog: LoginDialogState.Closed,
};

export const AuthContext = createContext<AuthContextValue>(
  authContextInitialValue
);
