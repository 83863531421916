import {
  QueryItem,
  QuerySearchPayload,
  QuerySearchPayloadDocumentTypesEnum,
  TaggableResourcesApiCreateQueryRequest,
  TaggableResourcesApiQueriesFilterRequest,
} from '@zarn/vendor/dist/saved-results';

import {
  SearchPayload,
  SearchPayloadDocType,
} from 'containers/Search/SearchPayload.interface';
import {
  FederatedSearchValueEnum,
  SearchEngineEnum,
  SearchSortMethodEnum,
  SearchTermEnum,
  SearchUrlParamsEnum,
} from 'common/enums';
import {
  CreateSavedQueryPayload,
  SavedQueriesListPayload,
  SavedQueryDetails,
} from './savedQueriesApi.types';
import { normalizeResourcePermission } from 'api/taggedResourcesApi/taggedResourcesApi.utils';
import {
  deserializeSharingPolicy,
  serializeSharingPolicy,
} from 'containers/Sharing/sharing.utils';
import { SearchDateFilterValueEnum } from 'containers/Search/SearchFilters/SearchDateFilter/SearchDateFilterValue.enum';
import {
  deserializeFiltersConfigToUrl,
  serializeFilters,
} from 'containers/Search/dynamicSearch.utils';
import { DynamicSettings } from '../tenantSettingsApi/tenantSettingsApi.types';

export const docTypeToQuerySearchPayloadDocumentTypesEnum = (
  docType: SearchPayloadDocType
): QuerySearchPayloadDocumentTypesEnum => {
  switch (docType) {
    case 'citation':
      return QuerySearchPayloadDocumentTypesEnum.Citation;
    default:
      return QuerySearchPayloadDocumentTypesEnum.Document;
  }
};

export const serializeSavedQueriesListPayload = ({
  page,
  pageSize,
  queryIds,
  tenant,
}: SavedQueriesListPayload): TaggableResourcesApiQueriesFilterRequest => ({
  requesterUuid: '',
  userRoles: '',
  tenant,
  page,
  pageSize,
  queryIds,
});

export const normalizeQuerySearchPayloadSearchEngine = (
  searchEngine?: string
): FederatedSearchValueEnum | undefined => {
  if (!searchEngine) return undefined;

  switch (searchEngine) {
    case SearchEngineEnum.GoogleScholar:
      return FederatedSearchValueEnum.GoogleScholar;
    default:
      return FederatedSearchValueEnum.Default;
  }
};

export const deserializeQuerySearchPayload = (
  payload: QuerySearchPayload,
  searchFiltersDynamic?: DynamicSettings[] | null
): string => {
  const {
    query_string,
    retrieval_method,
    retrieval_unit,
    sort,
    cited_by,
    cites,
    authored_by,
    has_concept,
    doc_ids,
    tag_ids,
    sources,
    date,
    relative_date,
    search_engine,
    document_types,
    similar_to,
    with_code,
    visibility,
    location_countries,
    organizations,
    filters,
  } = payload;

  const searchParams = new URLSearchParams();
  const sortValue =
    sort &&
    Object.values(SearchSortMethodEnum).find((v) =>
      Object.keys(sort).includes(v)
    );
  const searchEngine = normalizeQuerySearchPayloadSearchEngine(search_engine);

  if (query_string) {
    searchParams.set(SearchUrlParamsEnum.Query, query_string);
  }

  if (retrieval_method) {
    searchParams.set(SearchUrlParamsEnum.RetrievalMethod, retrieval_method);
  }

  if (retrieval_unit) {
    searchParams.set(SearchUrlParamsEnum.RetrievalUnit, retrieval_unit);
  }

  if (
    relative_date &&
    Object.values(SearchDateFilterValueEnum).some((v) => v === relative_date)
  ) {
    searchParams.set(SearchUrlParamsEnum.Date, relative_date);
  }

  if (
    date?.lower_bound &&
    date?.upper_bound &&
    relative_date === SearchDateFilterValueEnum.Custom
  ) {
    searchParams.set(
      SearchUrlParamsEnum.DateRange,
      `${date.lower_bound},${date.upper_bound}`
    );
  }

  if (cited_by) {
    searchParams.set(
      SearchUrlParamsEnum.Term,
      `${SearchTermEnum.CitedBy},${cited_by}`
    );
  } else if (authored_by) {
    searchParams.set(
      SearchUrlParamsEnum.Term,
      `${SearchTermEnum.AuthoredBy},${authored_by}`
    );
  } else if (cites) {
    searchParams.set(
      SearchUrlParamsEnum.Term,
      `${SearchTermEnum.Cites},${cites}`
    );
  } else if (has_concept) {
    searchParams.set(
      SearchUrlParamsEnum.Term,
      `${SearchTermEnum.HasConcept},${has_concept}`
    );
  }

  if (sortValue) {
    searchParams.set(SearchUrlParamsEnum.SortBy, sortValue);
  }

  if (typeof with_code === 'boolean') {
    searchParams.set(SearchUrlParamsEnum.WithCode, String(with_code));
  }

  if (searchEngine) {
    searchParams.set(SearchUrlParamsEnum.FederatedSearch, searchEngine);
  }

  if (sources?.length) {
    searchParams.set(SearchUrlParamsEnum.DocSources, sources.toString());
  }

  if (doc_ids?.length) {
    searchParams.set(SearchUrlParamsEnum.DocIds, doc_ids.toString());
  }

  if (tag_ids?.length) {
    searchParams.set(SearchUrlParamsEnum.Tag, tag_ids.toString());
  }

  if (document_types?.length) {
    searchParams.set(SearchUrlParamsEnum.DocTypes, document_types.toString());
  }

  if (similar_to?.length) {
    searchParams.set(SearchUrlParamsEnum.SimilarTo, similar_to.toString());
  }

  if (visibility?.length) {
    searchParams.set(SearchUrlParamsEnum.Visibility, visibility.toString());
  }

  if (location_countries?.length) {
    searchParams.set(
      SearchUrlParamsEnum.Countries,
      location_countries.toString()
    );
  }

  if (organizations?.length) {
    searchParams.set(
      SearchUrlParamsEnum.Organizations,
      organizations.toString()
    );
  }

  if (filters && searchFiltersDynamic) {
    const result = deserializeFiltersConfigToUrl(filters, searchFiltersDynamic);
    result.forEach(({ key, value }) => searchParams.set(key, value));
  }

  return `?${searchParams.toString()}`;
};

export const serializeQuerySearchPayload = ({
  queryString,
  retrievalMethod,
  retrievalUnit,
  sort,
  term,
  docIds,
  tagIds,
  docSources,
  date,
  searchEngine,
  docTypes,
  similarTo,
  withCode,
  visibility,
  locationCountries,
  organizations,
  relativeDate,
  dynamicFilters,
}: SearchPayload): QuerySearchPayload => {
  const payload: QuerySearchPayload = {
    query_string: queryString,
    retrieval_method: retrievalMethod,
    retrieval_unit: retrievalUnit,
    doc_ids: docIds,
    tag_ids: tagIds,
    sources: docSources,
    search_engine: searchEngine,
    similar_to: similarTo,
    with_code: withCode,
    visibility,
    organizations,
    location_countries: locationCountries,
    document_types: docTypes?.map(docTypeToQuerySearchPayloadDocumentTypesEnum),
    ...(sort && { sort: { [sort[0]]: sort[1] } }),
    ...(term?.[0] === SearchTermEnum.CitedBy && { cited_by: term[1] }),
    ...(term?.[0] === SearchTermEnum.Cites && { cites: term[1] }),
    ...(term?.[0] === SearchTermEnum.AuthoredBy && { authored_by: term[1] }),
    ...(term?.[0] === SearchTermEnum.HasConcept && { has_concept: term[1] }),
    ...(date &&
      relativeDate === SearchDateFilterValueEnum.Custom && {
        date: { lower_bound: date[0], upper_bound: date[1] },
      }),
    ...(relativeDate && { relative_date: relativeDate }),
  };

  if (dynamicFilters && dynamicFilters.length) {
    payload.filters = serializeFilters(dynamicFilters);
  }

  return payload;
};

export const deserializeSavedQueryDetails =
  (searchFiltersDynamic: DynamicSettings[] | undefined | null) =>
  ({
    query_id,
    owner,
    date_created,
    date_modified,
    date_shared,
    permission,
    name,
    sharing,
    search_payload,
  }: QueryItem): SavedQueryDetails => {
    const searchParams = deserializeQuerySearchPayload(
      search_payload,
      searchFiltersDynamic
    );

    return {
      owner,
      searchParams,
      queryId: query_id,
      queryName: name,
      dateCreated: date_created,
      dateModified: date_modified,
      dateShared: date_shared,
      permission: normalizeResourcePermission(permission),
      sharing: deserializeSharingPolicy(sharing),
    };
  };

export const serializeCreateSavedQueryPayload = ({
  queryName,
  searchPayload,
  sharing,
  tenant,
}: CreateSavedQueryPayload): TaggableResourcesApiCreateQueryRequest => ({
  requesterUuid: '',
  userRoles: '',
  tenant,
  queryForm: {
    name: queryName,
    search_payload: serializeQuerySearchPayload(searchPayload),
    sharing: serializeSharingPolicy(sharing),
  },
});
