import { useMutation, UseMutationOptions } from 'react-query';

import { deleteTag } from 'api/tagsApi';
import { BaseError } from 'common/models/Error.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { useParsedHostname } from 'common/utils/useParsedHostname';
import { captureException } from '@sentry/react';

export const useDeleteTagDetails = (
  options?: UseMutationOptions<number, BaseError, number>
) => {
  const { tenant } = useParsedHostname();
  return useMutation<number, BaseError, number>(async (tagId: number) => {
    try {
      await deleteTag(tagId, tenant);
      return tagId;
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }, options);
};
