import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    ns: ['common', 'search', 'pages', 'saved', 'taggedDocs', 'tags'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
      format: (value, format: string | undefined) => {
        if (format === 'uppercase') {
          return value.toUpperCase();
        }
        return value;
      },
    },
    backend: {
      loadPath: `${
        window.env.LOCALES_URL
      }/{{lng}}/{{ns}}.json?${Math.random()}`,
    },
  });

export default i18n;
