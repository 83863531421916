import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  section: {
    margin: theme.spacing(2, 0, 4),
  },
  anchor: {
    visibility: 'hidden',
    position: 'relative',
    top: -90,
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
    '&:after': {
      content: '""',
      display: 'block',
      width: 60,
      height: 2,
      backgroundColor: theme.palette.divider,
      marginTop: theme.spacing(0.5),
    },
  },
}));

type Props = {
  anchor?: string;
  sectionTitle: string;
  children: React.ReactNode;
};

export const PageSection = ({ anchor = '', sectionTitle, children }: Props) => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      {anchor && <div className={classes.anchor} id={anchor} />}

      <Typography variant="h5" className={classes.sectionTitle}>
        {sectionTitle}
      </Typography>

      {children}
    </section>
  );
};
