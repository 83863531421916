import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DoneIcon from '@mui/icons-material/Done';

type Props = {
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  onCopy?: () => void;
};

export const FieldCopyButton = ({ inputRef, onCopy }: Props) => {
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = async () => {
    if (inputRef.current) {
      inputRef.current.select();
      await navigator.clipboard.writeText(inputRef.current.value);
    }

    if (onCopy) {
      onCopy();
    }
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 1000);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [copied, setCopied]);

  return (
    <Tooltip
      aria-label="copy"
      title={
        copied
          ? t<string>('copyButton.tooltip.copied')
          : t<string>('copyButton.tooltip.copy')
      }
    >
      <IconButton
        edge="end"
        aria-label="copy button"
        color="secondary"
        onClick={handleCopy}
        size="large"
      >
        {copied ? <DoneIcon /> : <FileCopyIcon />}
      </IconButton>
    </Tooltip>
  );
};
