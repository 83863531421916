import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';

import { deserializeAxiosError } from 'common/utils/error';
import { RootState } from 'app/state/store';
import { SavedNotes } from 'containers/SavedNotes/SavedNotes.interface';
import { TagType } from '@zarn/vendor/dist/saved-results';
import {
  createTagNote as createTagNoteApi,
  listTagNotes,
  updateNote,
} from 'api/notesApi/notesApi';
import {
  CreateTagNotePayload,
  NoteDetails,
  UpdateNotePayload,
} from 'api/notesApi/notesApi.types';
import { captureException } from '@sentry/react';

export type TagNoteListArgs = {
  tagId: number;
  tagType: TagType;
  tenant: string;
};

export const getTagNoteListThunk: AsyncThunkPayloadCreator<
  SavedNotes,
  TagNoteListArgs,
  { state: RootState }
> = async ({ tagId, tagType, tenant }: TagNoteListArgs) => {
  try {
    const { data } = await listTagNotes({
      tagId,
      tagType,
      page: 1,
      pageSize: 1000,
      tenant,
    });

    const mappedData: SavedNotes = {};

    if (data.count > 0) {
      mappedData[tagId] = {
        id: String(tagId),
        numberOfNotes: data.items.length,
        notesData: data.items,
      };
    }

    return mappedData;
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const createTagNoteThunk: AsyncThunkPayloadCreator<
  {
    id: string;
    data: NoteDetails;
  },
  CreateTagNotePayload,
  { state: RootState }
> = async (payload: CreateTagNotePayload) => {
  try {
    const { data } = await createTagNoteApi(payload);
    return {
      id: payload.tagId.toString(),
      data,
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};

export const updateTagNoteThunk: AsyncThunkPayloadCreator<
  { id: string; data: NoteDetails },
  UpdateNotePayload,
  { state: RootState }
> = async (payload, { getState }) => {
  try {
    await updateNote(payload);
    const note = getState().tagsNotes.data[
      String(payload.objectId)
    ].notesData.find(({ id }) => id === payload.noteId) as NoteDetails;

    return {
      id: String(payload.objectId),
      data: {
        ...note,
        content: payload.content,
        shareable: !!payload.sharing.orgs.length,
      },
    };
  } catch (error) {
    captureException(error);
    throw deserializeAxiosError(error);
  }
};
