import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemAvatar, ListItemText } from '@mui/material';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import { ShareSelectionOptionValue } from '../ShareSelection.interface';

export type ShareSelectionOptionContentProps = {} & ShareSelectionOptionValue;

const ShareSelectionOptionContent = React.memo(
  (option: ShareSelectionOptionContentProps) => {
    const { t } = useTranslation('common');
    const isUser = option.type === 'user';
    const secondary = isUser
      ? option.email
      : t('share.shareSettings.userSelection.options.team');

    return (
      <>
        <ListItemAvatar>
          <StyledAvatar>{option.initials}</StyledAvatar>
        </ListItemAvatar>

        <ListItemText primary={option.name} secondary={secondary} />
      </>
    );
  }
);

ShareSelectionOptionContent.displayName = 'ShareSelectionOptionContent';

export default ShareSelectionOptionContent;
