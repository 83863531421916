import React, { useMemo } from 'react';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleButtonProps, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = { iconStyle?: React.CSSProperties } & Omit<
  ToggleButtonProps,
  'value'
>;

export const SendFeedbackScoreToggle = (props: Props) => {
  const { t } = useTranslation('feedback');
  const [field, , helpers] = useField({ name: 'feedbackScore' });
  const { iconStyle, ...rest } = props;
  const feedbackButtons = useMemo(
    () => [
      {
        text: t('buttons.irrelevant'),
        Icon: MoodBadIcon,
        score: 0,
      },
      {
        text: t('buttons.relevant'),
        Icon: SentimentSatisfiedIcon,
        score: 1,
      },
      {
        text: t('buttons.highlyRelevant'),
        Icon: MoodIcon,
        score: 2,
      },
    ],
    []
  );

  const handleChange = async (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    if (Number.isInteger(value)) {
      helpers.setValue(value);
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      aria-label="send feedback"
      value={field.value}
      onChange={handleChange}
    >
      {feedbackButtons.map(({ Icon, text, score }) => (
        <ToggleButton key={text} aria-label={text} value={score} {...rest}>
          <Tooltip title={text}>
            <Icon sx={iconStyle} />
          </Tooltip>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
