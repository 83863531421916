import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  img: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: '100%',
  },
}));

type OnboardingWizardImgProps = {
  src: string;
  alt: string;
};

const OnboardingWizardImg = ({ src, alt }: OnboardingWizardImgProps) => {
  const classes = useStyles();

  return <img className={classes.img} alt={alt} src={src} />;
};

OnboardingWizardImg.displayName = 'OnboardingWizardImg';

export default OnboardingWizardImg;
