import React, { FC, PropsWithChildren } from 'react';
import { Nullable } from '../../../../utils/assert';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  status: Nullable<'loading' | 'success' | 'error'>;
}

export const StatusIcon: FC<PropsWithChildren<Props>> = ({
  status,
  children,
}) => {
  switch (status) {
    case 'success':
      return (
        <IconButton edge="end" size="large" disabled color="success">
          <CheckIcon />
        </IconButton>
      );
    case 'error':
      return (
        <IconButton edge="end" size="large" disabled color="error">
          <ErrorIcon />
        </IconButton>
      );
    case 'loading':
    default:
      return <>{children}</>;
  }
};

export default StatusIcon;
