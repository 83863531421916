import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';

import { selectUser } from 'containers/User/user.slice';
import { useSendRelevanceFeedback } from './hooks/useSendRelevanceFeedback';
import { ResultType } from '@zarn/vendor/dist/query-logging';
import { SendFeedbackFormValue } from '../Feedback.interface';
import { validationSchema } from '../sendFeedback.schema';
import { SendFeedbackForm } from './SendFeedbackForm/SendFeedbackForm';
import { FeedbackType } from '../enums';

const defaultInitialValues: SendFeedbackFormValue = {
  feedbackText: '',
  feedbackScore: -1,
};

type SendFeedbackProps = {
  searchId: string;
  resultId: string;
  resultType: ResultType;
  wrapperClassname?: string;
  withFeedbackMessage?: boolean;
  initialValues?: SendFeedbackFormValue;
  iconStyle?: React.CSSProperties;
};

const SendFeedback = React.memo(
  ({
    resultType,
    resultId,
    wrapperClassname,
    searchId,
    withFeedbackMessage,
    initialValues = defaultInitialValues,
    iconStyle,
  }: SendFeedbackProps) => {
    const user = useSelector(selectUser);
    const feedbackEnabled = !!user?.feedbackButtons;
    const mutation = useSendRelevanceFeedback();

    const handleSubmitForm = async (
      { feedbackText, feedbackScore }: SendFeedbackFormValue,
      { setSubmitting }: FormikHelpers<SendFeedbackFormValue>
    ) => {
      setSubmitting(true);

      await mutation.mutateAsync({
        searchId,
        resultId,
        resultType,
        feedbackType: FeedbackType.RelevanceJudgment,
        text: feedbackText,
        score: feedbackScore,
      });
    };

    if (!feedbackEnabled) {
      return null;
    }

    return (
      <div className={wrapperClassname}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
        >
          {() => (
            <Form>
              <SendFeedbackForm
                withFeedbackMessage={withFeedbackMessage}
                iconStyle={iconStyle}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

SendFeedback.displayName = 'SendFeedback';

export default SendFeedback;
