import { MutableRefObject, useCallback } from 'react';

export const useScrollTo = (
  documentRef: MutableRefObject<HTMLDivElement | null>
) => {
  const scrollTo = useCallback(
    (top: number) => {
      documentRef.current?.scrollTo({
        top,
        behavior: 'smooth',
      });
    },
    [documentRef]
  );

  return { scrollTo };
};
