import React, { FC, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppDispatch } from 'app/state/store';
import { ExtendedConfirmationDialog } from 'common/components/Dialogs/ExtendedConfirmationDialog';
import { updateUser } from 'containers/User/user.slice';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';

export const useStyles = makeStyles((theme: Theme) => ({
  skipButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export interface SkipWizardProps {
  onSkip: () => void;
}

export const SkipWizard: FC<SkipWizardProps> = ({ onSkip }) => {
  const { t } = useTranslation(['onboardingWizard', 'common']);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const handleOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const handleChange = () => {
    setChecked((value) => !value);
  };

  const handleSubmit = async () => {
    await dispatch(updateUser({ onboarded: checked }));
    setOpenConfirmationDialog(false);
    onSkip();
  };

  return (
    <div>
      <Button
        className={classes.skipButton}
        aria-label={t('onboardingWizard:skip.buttonAriaLabel')}
        onClick={handleOpen}
        size="small"
      >
        {t('common:buttons.skip')}
      </Button>
      <ExtendedConfirmationDialog
        aria-label={t('onboardingWizard:skip.dialogAriaLabel')}
        setOpen={setOpenConfirmationDialog}
        open={openConfirmationDialog}
        onSubmit={handleSubmit}
        eventName={TrackEventName.WizardSkipped}
      >
        <Typography>{t('onboardingWizard:skip.text')}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name="onboarded"
            />
          }
          label={t('onboardingWizard:skip.checkboxLabel')}
        />
      </ExtendedConfirmationDialog>
    </div>
  );
};
