import {
  GroupedSavedDocuments,
  SavedDocument,
  SavedFavouriteDocuments,
} from 'containers/SavedDocuments/SavedDocuments.interface';
import { RetrievalUnitDataDocument } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import {
  DocumentUserData,
  TaggedDocumentResponse,
} from 'api/documentsApi/DocumentsApi.types';

export const groupSavedDocuments = (
  documents: DocumentUserData[]
): GroupedSavedDocuments => {
  return documents.reduce(
    (acc, docUserData) => ({
      ...acc,
      [docUserData.docId]: [
        ...(docUserData.docId in acc ? acc[docUserData.docId] : []),
        ...docUserData.tags.map((tagData) => ({
          documentId: docUserData.docId,
          documentType: docUserData.docType,
          tag: tagData,
        })),
      ],
    }),
    {} as GroupedSavedDocuments
  );
};

export const mapFavouriteDocuments = (
  documents: DocumentUserData[]
): SavedFavouriteDocuments => {
  return documents.reduce((acc, docUserData) => {
    if (docUserData.favorite) {
      acc[docUserData.docId] = {
        documentId: docUserData.docId,
        documentType: docUserData.docType,
      };
    }
    return acc;
  }, {} as SavedFavouriteDocuments);
};

export const mapFavouriteDocumentsData = (
  docResponse: TaggedDocumentResponse
): SavedFavouriteDocuments => {
  return {
    [docResponse.docId]: {
      documentId: docResponse.docId,
      documentType: docResponse.docType,
    },
  } as SavedFavouriteDocuments;
};

export const getDocumentData = (
  savedDocument: SavedDocument[],
  document: RetrievalUnitDataDocument
): RetrievalUnitDataDocument => {
  return savedDocument?.length > 0
    ? {
        id: savedDocument[0].documentId,
        query: savedDocument[0].query,
        type: savedDocument[0].documentType,
      }
    : document;
};
