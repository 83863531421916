import React, { FC, useMemo } from 'react';
import { useTenantSettings } from 'common/hooks/useTenantSettings';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { buildTheme } from './theme';
import { PageLoader } from 'common/components/Loaders/PageLoader/PageLoader';
import { ErrorPageContent } from 'common/components/ErrorPageContent/ErrorPageContent';

export const AppTheme: FC = ({ children }) => {
  const { tenantSettings, error } = useTenantSettings();

  const theme = useMemo(
    () => buildTheme(tenantSettings ?? null),
    [tenantSettings]
  );

  if (error) {
    return (
      <ErrorPageContent
        title={`Error ${error.status}`}
        description={error.message}
      />
    );
  }

  if (!tenantSettings) {
    return <PageLoader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
