import { OrganizationMemberData } from 'api/organizationApi/OrganizationMembers.interface';
import { OrganizationMember } from '@zarn/vendor/dist/auth';

export const deserializeOrganizationMember = ({
  first_name,
  last_name,
  ...member
}: OrganizationMember): OrganizationMemberData => ({
  uuid: member.uuid,
  firstName: first_name,
  lastName: last_name,
  initials: `${first_name[0]}${last_name[0]}`.toUpperCase(),
  email: member.email,
  organization: member.organization ?? '',
  active: member.active,
  roles: member.roles,
});
