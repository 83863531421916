import { isCustomAccessToken } from '../Auth.utils';
import { AccessToken } from '../interfaces/AccessToken';
import { MeCustomModel } from './MeCustom.model';
import { MeOidcModel } from './MeOidc.model';
import { Me } from './Me.interface';

export const createMe = (token: AccessToken): Me =>
  isCustomAccessToken(token)
    ? new MeCustomModel(token)
    : new MeOidcModel(token);
