import {
  Configuration,
  SettingsApi,
  TagsSorting,
  UserSettings,
} from '@zarn/vendor/dist/saved-results';
import { SERVICE_URL, BASE_HEADERS } from './apiConfig';
import axiosInstance from './axiosInstance';

export const savedResultsSettingsApi = new SettingsApi(
  new Configuration({ basePath: SERVICE_URL, baseOptions: BASE_HEADERS }),
  SERVICE_URL,
  axiosInstance
);

export const savedResultsSettingsGet = () =>
  savedResultsSettingsApi.settingsRetrieve({ requesterUuid: '' });

export const savedResultsSettingsUpdate = (userSettings: UserSettings) =>
  savedResultsSettingsApi.settingsUpdate({
    requesterUuid: '',
    userSettings,
  });

export const getTagsSorting = () =>
  savedResultsSettingsApi.getTagsSorting({ requesterUuid: '' });

export const updateTagsSorting = (tagsSorting: TagsSorting) =>
  savedResultsSettingsApi.updateTagsSorting({ requesterUuid: '', tagsSorting });
