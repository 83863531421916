import React from 'react';

import { TrackedActionProps } from './withTrackedAction.interface';
import { useTrackedAction } from './hooks/useTrackedAction';

export type WithTrackedActionProps = TrackedActionProps;
export type WithTrackedActionWrappedProps<T> = {
  onAction: () => void;
} & T;

function withTrackedAction<T extends WithTrackedActionProps>(
  WrappedComponent: React.ComponentType<WithTrackedActionWrappedProps<T>>,
  defaultEventName?: string
) {
  const Component = React.forwardRef(
    (
      {
        eventName = defaultEventName,
        eventProps,
        ...props
      }: Omit<T, 'onAction'>,
      ref
    ) => {
      const { onAction } = useTrackedAction({
        eventName,
        eventProps,
      });

      return (
        <WrappedComponent {...(props as T)} onAction={onAction} ref={ref} />
      );
    }
  );

  Component.displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return Component;
}

export default withTrackedAction;
