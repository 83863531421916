import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SignInMobileButtonProps {
  onSignIn: () => void;
}
export const SignInMobileButton: FC<SignInMobileButtonProps> = ({
  onSignIn,
}) => {
  const { t } = useTranslation('common');

  return (
    <Button fullWidth variant="outlined" color="primary" onClick={onSignIn}>
      {t('common:buttons.signIn')}
    </Button>
  );
};
