import React, { FC, ReactNode } from 'react';
import ContentLoader from '../ContentLoader/ContentLoader';
import { CircularProgressProps } from '@mui/material';

interface Props extends CircularProgressProps {
  loading: boolean;
  children: ReactNode;
}

export const LoaderContainer: FC<Props> = ({ loading, children, ...props }) =>
  loading ? (
    <ContentLoader disableShrink size={18} {...props} />
  ) : (
    <>{children}</>
  );
