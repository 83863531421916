import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { format, isValid } from 'date-fns';

export type DocDateProps = {
  date: string | null;
  year: number | null;
  smalltext?: boolean;
};

const DocDate = ({ date, year, smalltext }: DocDateProps) => {
  const dateText = useMemo(() => {
    const d = new Date(date ?? '');

    return isValid(d) ? format(d, 'dd MMM yyyy') : year;
  }, [date]);

  if (!dateText) {
    return null;
  }

  const textVariant = smalltext ? 'caption' : 'body2';
  return (
    <Typography
      sx={{ flex: 'none' }}
      variant={textVariant}
      color="textSecondary"
      data-testid="retrievalUnitDate"
    >
      {dateText}
    </Typography>
  );
};

export default DocDate;
