import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export type TagMetadataItemProps = {
  translation: React.ReactElement;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

const TagMetadataItem = ({ translation, icon: Icon }: TagMetadataItemProps) => {
  return (
    <ListItem dense>
      <ListItemIcon>
        <Icon fontSize="small" color="action" />
      </ListItemIcon>

      <ListItemText primaryTypographyProps={{ color: 'textSecondary' }}>
        {translation}
      </ListItemText>
    </ListItem>
  );
};

TagMetadataItem.displayName = 'TagMetadataItem';

export default TagMetadataItem;
