import React, { FC, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import { FormTextField } from '../../../common/components/Fields/FormTextField';
import { useTranslation } from 'react-i18next';

export const MoreForm: FC = () => {
  const { t } = useTranslation(['common', 'privateDocs']);

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <Button onClick={() => setIsCollapsed((v) => !v)}>
        {isCollapsed
          ? t('privateDocs:hideMoreOption')
          : t('privateDocs:showMoreOption')}
      </Button>

      <Collapse in={isCollapsed}>
        <FormTextField
          name="title"
          autoFocus
          multiline
          margin="normal"
          label={t('privateDocs:form.title.label')}
          placeholder={t('privateDocs:form.title.placeholder')}
        />

        <FormTextField
          name="authors"
          multiline
          margin="normal"
          label={t('privateDocs:form.authors.label')}
          placeholder={t('privateDocs:form.authors.placeholder')}
          helperText={t('privateDocs:form.authors.helperText')}
        />

        <FormTextField
          name="year"
          type="number"
          margin="normal"
          label={t('privateDocs:form.year.label')}
          placeholder={t('privateDocs:form.year.placeholder')}
        />

        <FormTextField
          name="source"
          margin="normal"
          label={t('privateDocs:form.source.label')}
          placeholder={t('privateDocs:form.source.placeholder')}
        />

        <FormTextField
          name="abstractText"
          multiline
          minRows={4}
          margin="normal"
          label={t('privateDocs:form.abstract.label')}
          placeholder={t('privateDocs:form.abstract.placeholder')}
        />
      </Collapse>
    </>
  );
};
