import { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import {
  getApiServiceParams,
  getApiServiceParamsFromUrl,
} from 'common/utils/api-service-params';

const apiServiceParamsFormUrl = getApiServiceParamsFromUrl();
const { gwPrefix, serviceDepsHeaders } = getApiServiceParams(
  apiServiceParamsFormUrl
);
export const BASE_API_URL = window.env.API_URL.replace('{gwPrefix}', gwPrefix);
export const SERVICE_URL = `${BASE_API_URL}/service`;

const authToken = localStorage.getItem('token');

export const BASE_HEADERS: AxiosRequestHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  'Content-Type': 'application/json',
  Accept: 'application/json',
  ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
  ...(window.env.USE_SERVICE_HEADERS ? serviceDepsHeaders : {}),
};

export const API_CONFIG: AxiosRequestConfig = {
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  // returnRejectedPromiseOnError: true,
  timeout: 60 * 1000 * 5, // 5 min
  baseURL: BASE_API_URL,
  headers: BASE_HEADERS,
};

export const AXIOS_RETRY_CONFIG: axiosRetry.IAxiosRetryConfig = {
  retries: 3,
  retryCondition: (e: AxiosError<any, any>) => {
    // Axios can't detect the `e.response` on pre-flight errors, so we catch all "Network Error"
    return !e.response && e.message === 'Network Error';
  },
  retryDelay: (retryCount, error) => {
    if (error.response) {
      // We put the delai in `x-retry-in`, but if the error is in the pre-flight, we won't see it
      const retry_after: string = error.response.headers['x-retry-in'];
      if (retry_after) {
        return retry_after.includes('ms')
          ? parseFloat(retry_after.replace('ms', ''))
          : parseFloat(retry_after);
      }
    }
    // By default we do just exponential delay.
    return axiosRetry.exponentialDelay(retryCount);
  },
};
