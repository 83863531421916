import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { HIGHLIGHT, SEARCH_INPUT_BORDER_RADIUS } from '../../../app/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(6),
      flex: 1,
    },
  },
  searchIcon: {
    height: 40,
    width: 40,
    color: theme.palette.primary.main,
    borderRadius: `0 ${SEARCH_INPUT_BORDER_RADIUS}px ${SEARCH_INPUT_BORDER_RADIUS}px 0`,
    '&:hover': {
      borderColor: theme.palette.common.white,
      color: HIGHLIGHT,
    },
  },
  searchInputRoot: {
    borderRadius: SEARCH_INPUT_BORDER_RADIUS,
    width: '100%',
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 1.5),
    transition: theme.transitions.create('width'),
    width: '100%',
    height: theme.typography.fontSize * 1.7,
    color: theme.palette.primary.main,
    borderRadius: `${SEARCH_INPUT_BORDER_RADIUS}px 0 0 ${SEARCH_INPUT_BORDER_RADIUS}px`,
    '&::placeholder': {
      opacity: 0.8,
    },
    [theme.breakpoints.down('sm')]: {
      '&:focus,&:active': {
        width: 'calc(100vw - 240px)',
      },
    },
  },
  saveQuery: {
    marginLeft: 0,
  },
  resetButton: {
    height: 40,
    width: 40,
    borderRadius: 0,
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(0.5),
    '&:hover': {
      borderColor: theme.palette.common.white,
      color: HIGHLIGHT,
    },
  },
}));
