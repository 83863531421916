import { TagDetails } from 'api/tagsApi';

export function getTagNames<T extends { name: string }>(tags: T[]): string[] {
  return tags.map((tag) => tag.name);
}

export const tagIsFollowed = (
  followingTags: TagDetails[],
  tagId: number
): boolean => followingTags.some(({ id }) => id === tagId);

export const getTagsByIds = (
  ids: number[],
  data: TagDetails[]
): TagDetails[] => {
  return ids.reduce((acc: TagDetails[], id: number) => {
    const foundTag = data.find((item) => item.id === id);
    return foundTag ? [...acc, foundTag] : acc;
  }, []);
};

export const tagIsShared = (tag: TagDetails): boolean => {
  return !!tag.shareSettings.orgs.length || !!tag.shareSettings.users.length;
};

export const tagsQueryKeys = {
  all: ['tags'] as const,
  details: () => [...tagsQueryKeys.all, 'details'] as const,
  detail: (tagId: number) => [...tagsQueryKeys.details(), tagId] as const,
};
