import React, { FC, ReactNode } from 'react';
import { EnvOidcConfig } from './Auth.interface';
import { AuthProvider } from './AuthProvider';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import { WebStorageStateStore } from 'oidc-client-ts';

const url = window.location.origin;

const envOidcConfig: EnvOidcConfig = {
  redirectUri: url,
  postLogoutRedirectUri: url,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
};

export interface AuthProps {
  children: ReactNode;
}

export const Auth: FC<AuthProps> = ({ children }) => {
  const { tenantSettings } = useAssertTenantSettings();

  return (
    <AuthProvider oidcConfig={{ ...tenantSettings.oidc, ...envOidcConfig }}>
      {children}
    </AuthProvider>
  );
};
