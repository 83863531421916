import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  wrapper: {
    position: 'relative',
    minHeight: '100%',
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    width: '100%',
  },
  img: {
    display: 'block',
    width: '100%',
  },
  imgWrapper: {
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  imgWrapperNoImage: {
    overflow: 'hidden',
  },
  fallBackIcon: {
    width: '50%',
    marginLeft: 'auto',
  },
  lazyLoad: {
    opacity: 0,
    transition: 'all .5s ease-in-out',

    '&.is-visible': {
      opacity: 1,
    },
  },
}));
