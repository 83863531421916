import React, { FC, ReactNode } from 'react';
import { NoteContentUpdate } from '../../NoteContentUpdate';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
  visible: boolean;
  editMode: boolean;
  editable: boolean;
  validate: boolean;
  onModeToggle: () => void;
  onUpdate: (note: NoteDetails) => Promise<void>;
}

export const Content: FC<Props> = ({
  children,
  visible,
  editMode,
  editable,
  validate,
  onUpdate,
  onModeToggle,
}) => {
  if (editMode) {
    return <NoteContentUpdate onUpdate={onUpdate} validate={validate} />;
  }

  if (!visible) {
    return null;
  }

  if (editable) {
    return (
      <Box
        aria-label="click to edit content"
        sx={{ cursor: 'pointer', position: 'relative' }}
        data-testid="Content"
        onClick={onModeToggle}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative' }} data-testid="Content">
      {children}
    </Box>
  );
};
