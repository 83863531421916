import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'app/state/store';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { SearchResults } from './SearchResults.interface';
import { getDocIdsFromHits } from './searchResults.utils';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { deserializeAxiosError } from 'common/utils/error';
import { HitType, SearchEngineEnum } from 'common/enums';
import { parseHostname } from 'common/utils/useParsedHostname';
import { searchDocuments } from 'api/searchApi';
import { captureException } from '@sentry/react';

export interface SearchResultsState {
  fetchState: FiniteStatesType;
  data: {
    searchPayload: SearchPayload;
    results: SearchResults;
    firstPageResults?: SearchResults;
    searchId: string;
    scrollPosition: number;
  };
  error: string | null;
}

const searchPayloadFromURL = new SearchPayloadFromURL(
  window.location.search,
  parseHostname(),
  undefined
);

export const initialState: SearchResultsState = {
  fetchState: FiniteStates.Idle,
  data: {
    searchPayload: searchPayloadFromURL.getSearchPayload(),
    searchId: '',
    scrollPosition: 0,
    results: {
      pagesCount: 0,
      query: '',
      totalResults: 0,
      data: {
        items: [],
        count: 0,
        searchEngine: SearchEngineEnum.ZetaAlpha,
      },
    },
    firstPageResults: {
      pagesCount: 0,
      query: '',
      totalResults: 0,
      data: {
        items: [],
        count: 0,
        searchEngine: SearchEngineEnum.ZetaAlpha,
      },
    },
  },
  error: null,
};

export const makeSearch = createAsyncThunk(
  'searchResults/post',
  async (searchPayload: SearchPayload) => {
    try {
      const { data } = await searchDocuments(searchPayload);

      return {
        results: data,
        searchPayload,
        ...(searchPayload.page === 1 ? { firstPageResults: data } : {}),
      };
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
    updateSearchPayload: (state, action: PayloadAction<SearchPayload>) => {
      state.data.searchPayload = action.payload;
    },
    updateSearchQueryString: (state, action: PayloadAction<string>) => {
      state.data.searchPayload.queryString = action.payload;
    },
    setSearchResultsId: (state, action: PayloadAction<string>) => {
      state.data.searchId = action.payload;
    },
    updateSearchScrollPosition: (state, action: PayloadAction<number>) => {
      state.data.scrollPosition = action.payload;
    },
    deleteSearchResultByDocId: (state, action: PayloadAction<string>) => {
      state.data.results.data.items = state.data.results.data.items.filter(
        (item) => action.payload !== item.document.id
      );
    },
    updateSearchResultDocData: (
      state,
      action: PayloadAction<RetrievalUnitData>
    ) => {
      const docId = action.payload.document.id;
      state.data.results.data.items = state.data.results.data.items.map(
        (item) => {
          if (docId !== item.document.id) {
            return item;
          }

          return action.payload;
        }
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makeSearch.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(makeSearch.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error =
          action.error.message || 'Sorry, unexpected error happened';
      })
      .addCase(makeSearch.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.results = payload.results;
        if (payload.firstPageResults !== undefined) {
          state.data.firstPageResults = payload.firstPageResults;
        }
        state.data.searchPayload = payload.searchPayload;
        state.error = null;
      });
  },
});

const searchResultsStateSelector = (state: RootState): SearchResultsState =>
  state.searchResults;

const searchResultsDataSelector = (
  state: RootState
): SearchResultsState['data'] => state.searchResults.data;

const selectSearchResultsFetchStateSelector = (
  state: RootState
): FiniteStates => state.searchResults.fetchState;

const searchResultsSelector = (state: RootState): SearchResults | null =>
  state.searchResults.data.results;

const firstPageSearchResultsSelector = (
  state: RootState
): SearchResults | undefined => state.searchResults.data.firstPageResults;

export const selectSearchResults = createSelector(
  searchResultsDataSelector,
  (data) => data
);

export const selectSearchResultsScrollPosition = createSelector(
  searchResultsDataSelector,
  (data) => data.scrollPosition
);

export const selectSearchResultsPayload = createSelector(
  searchResultsDataSelector,
  ({ searchPayload }) => searchPayload
);

export const selectSearchQueryString = createSelector(
  searchResultsDataSelector,
  ({ searchPayload }) => searchPayload.queryString
);

export const selectSearchResultsId = createSelector(
  searchResultsDataSelector,
  ({ searchId }) => searchId
);

export const selectSearchResultsHits = createSelector(
  searchResultsSelector,
  (results) => results?.data.items ?? []
);

export const selectSearchResultsHitIDs = createSelector(
  searchResultsSelector,
  (results) => results?.data.items.map(({ document: { id } }) => id) ?? []
);

export const selectSearchResultsDocIdHitMap = createSelector(
  firstPageSearchResultsSelector,
  (firstPageResults) =>
    firstPageResults?.data.items.reduce(
      (acc: { [key: string]: RetrievalUnitData }, hit) => ({
        ...acc,
        [hit.document.id]: hit,
      }),
      {} as { [key: string]: RetrievalUnitData }
    ) ?? {}
);

export const selectSearchResultsHitDocumentIds = createSelector(
  searchResultsSelector,
  (results) => results?.data.items.map(({ documentId }) => documentId) ?? []
);

export const selectFirstPageSearchResultsHitDocIds = createSelector(
  firstPageSearchResultsSelector,
  (firstPageResults) =>
    firstPageResults?.data.items.map(({ document }) => document.id) ?? []
);

export const selectSearchResultsDocHitIDs = createSelector(
  searchResultsSelector,
  (results: SearchResults | null): string[] => {
    return getDocIdsFromHits(results?.data.items);
  }
);

export const selectSearchResultsOrganizeDocIds = createSelector(
  searchResultsSelector,
  (results: SearchResults | null): string[] => {
    return (results?.data.items ?? []).map(
      ({ organizeDocId }) => organizeDocId
    );
  }
);

export const selectSearchResultsNoteHitIDs = createSelector(
  searchResultsSelector,
  (results: SearchResults | null): number[] => {
    const noteIds: number[] = [];

    return (
      results?.data.items.reduce(
        (acc: number[], { document: { type }, identifier }) => {
          return type !== HitType.Note ? acc : [...acc, Number(identifier)];
        },
        noteIds
      ) ?? noteIds
    );
  }
);

export const selectSearchResultsLoading = createSelector(
  selectSearchResultsFetchStateSelector,
  (fetchState) => fetchState === FiniteStates.Loading
);

export const selectSearchResultsFailure = createSelector(
  selectSearchResultsFetchStateSelector,
  (fetchState) => fetchState === FiniteStates.Failure
);

export const selectSearchResultsError = createSelector(
  searchResultsStateSelector,
  ({ error }) => error
);

export const {
  setState,
  updateSearchPayload,
  updateSearchQueryString,
  setSearchResultsId,
  updateSearchScrollPosition,
  deleteSearchResultByDocId,
  updateSearchResultDocData,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
