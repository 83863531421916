import React from 'react';
import { DialogContent, DialogContentProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
}));

export type CollectionDialogContentProps = {} & DialogContentProps;

const CollectionDialogContent = ({
  children,
  ...props
}: CollectionDialogContentProps) => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.root} {...props}>
      {children}
    </DialogContent>
  );
};

CollectionDialogContent.displayName = 'CollectionDialogContent';

export default CollectionDialogContent;
