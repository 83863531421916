import React from 'react';
import { Box, CardHeader, Divider, Link, List } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { TagType } from '@zarn/vendor/dist/saved-results';
import { TagDetails } from 'api/tagsApi/tagsApi.types';
import { TagPreviewDocsData } from 'containers/Tags/TagPreviewTooltip/hooks/useTagPreviewDocs';
import TagMetadataPapers from '../TagMetadata/TagMetadataPapers/TagMetadataPapers';
import TagMetadataModified from '../TagMetadata/TagMetadataModified/TagMetadataModified';
import TagMetadataShared from '../TagMetadata/TagMetadataShared/TagMetadataShared';
import TagMetadataFollowers from '../TagMetadata/TagMetadataFollowers/TagMetadataFollowers';
import DocCardCompact from 'common/components/Docs/DocCardCompact/DocCardCompact';
import ContentLoader from 'common/components/Loaders/ContentLoader/ContentLoader';
import { useUserDetails } from 'containers/Users/hooks/useUserDetails';
import TagPermissionChip from '../TagPermissionChip';
import { TrackedActionContext } from 'common/components/TrackedActions/trackedAction.context';

export type TagPreviewProps = {
  tag: TagDetails;
  docsPreviewData?: TagPreviewDocsData;
  docsPreviewLoading?: boolean;
};

const TagPreview = ({
  tag,
  docsPreviewData,
  docsPreviewLoading,
}: TagPreviewProps) => {
  const { t } = useTranslation('tags');
  const ownTag = tag.type === TagType.Own;
  const { data: tagOwner } = useUserDetails({
    memberId: tag?.owner?.id ?? '',
    options: { enabled: !!tag && !!tag.owner },
  });
  const tagOwnerInfo = {
    fullName: tagOwner?.fullName,
    organizationName: tagOwner?.organization,
  };

  return (
    <TrackedActionContext.Provider
      value={{ actionContext: 'Tag preview', tagId: tag.id }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <CardHeader
          sx={{ py: 1, px: 2 }}
          subheaderTypographyProps={{
            variant: 'body2',
            sx: { overflowWrap: 'anywhere' },
          }}
          titleTypographyProps={{
            variant: 'body2',
            sx: { overflowWrap: 'anywhere' },
          }}
          subheader={
            tag.owner && (
              <Trans
                t={t}
                i18nKey="tagMetadata.owner.text"
                values={tagOwnerInfo}
              >
                <strong />
              </Trans>
            )
          }
          title={
            <>
              <Link
                component={RouterLink}
                to={`/tags/${tag.id}`}
                underline="hover"
              >
                <strong>{tag.name}</strong>
              </Link>

              {!ownTag && (
                <Box ml={1} component="span">
                  <TagPermissionChip permission={tag.permission} />
                </Box>
              )}
            </>
          }
        />

        <Divider />

        <List dense>
          <TagMetadataModified dateModified={tag.dateModified} />

          {ownTag && <TagMetadataShared shareSettings={tag.shareSettings} />}

          <TagMetadataFollowers
            numberOfFollowers={tag.numberOfFollowers ?? 0}
          />
        </List>

        <Divider />

        <List dense>
          <TagMetadataPapers numberOfPapers={docsPreviewData?.count ?? 0} />

          {docsPreviewLoading && <ContentLoader size={24} />}

          {!!docsPreviewData?.items?.length && (
            <Box display="flex" flexDirection="column" gap={1} px={2} py={1}>
              {docsPreviewData.items.map((item) => (
                <DocCardCompact key={item.document.id} data={item} />
              ))}
            </Box>
          )}
        </List>
      </div>
    </TrackedActionContext.Provider>
  );
};

TagPreview.displayName = 'TagPreview';

export default TagPreview;
