import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { AnnotationHighlight } from 'api/notesApi/notesApi.types';
import { HighlightRect } from '@zarn/pdf-viewer';
import { Dimensions } from 'common/interfaces/rectangle.interfaces';
import { matchRectangleSizes } from 'common/utils/rectangle';
import { RetrievalUnitEnum, SearchEngineEnum } from 'common/enums';
import { RetrievalUnitData } from '../RetrievalUnit/RetrievalUnitData.interface';
import { ChatContext } from 'api/chatApi/chatApi.types';

export const getTopCoordinate = (
  { pageNumber, y1 }: Pick<HighlightRect, 'pageNumber' | 'y1'>,
  pageHeight: number
) => Math.floor(pageHeight * (pageNumber - 1) + y1);

const getPage = () => window.document.querySelector('.react-pdf__Page');

export const getPageDimensions = (): Dimensions => {
  const page = getPage();
  return {
    height: page?.clientHeight ?? 0,
    width: page?.clientWidth ?? 0,
  };
};

export const mapChunk =
  (pageWidth: number, pageHeight: number, zoomLevel: number) =>
  (chunk: ChunkHighlight, index: number): AnnotationHighlight => ({
    id: index,
    color: 'rgba(142, 206, 220, 0.5)',
    content: {
      body: '',
      type: 'text',
    },
    rects: [
      {
        ...chunk,
        ...matchRectangleSizes(
          chunk,
          {
            width: pageWidth,
            height: pageHeight,
          },
          zoomLevel
        ),
      },
    ],
    position: 1,
    actions: [],
  });

export const buildChatContext = (
  searchEngine: SearchEngineEnum,
  docId?: string,
  docData?: RetrievalUnitData
): ChatContext => {
  if (searchEngine === SearchEngineEnum.ZetaAlpha) {
    return {
      fromDocIds: {
        docId: docId ? [docId] : [],
        retrievalUnit: RetrievalUnitEnum.Document,
        contextFields: [
          'metadata.dcmi.title',
          'metadata.dcmi.abstract',
          'metadata.dcmi.date',
          'representations.main.text',
        ],
      },
    };
  }

  return {
    fromDocContent: docId
      ? [
          {
            docId: docId,
            docContent: `${docData?.title}. ${docData?.abstractContent}`,
          },
        ]
      : [],
  };
};
