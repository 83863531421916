import React, { FC, useContext } from 'react';
import { AuthContext } from '../../Auth/Auth.context';
import { MainSidebarOidcSignInButton } from './MainSidebarOidcSignInButton';
import { MainSidebarCustomSignInButton } from './MainSidebarCustomSignInButton';
import { useAuth } from 'containers/Auth/hooks/useAuth';

export const MainSidebarSignInButton: FC = () => {
  const { oidcConfig } = useContext(AuthContext);
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return null;
  }

  return oidcConfig?.enabled ? (
    <MainSidebarOidcSignInButton />
  ) : (
    <MainSidebarCustomSignInButton />
  );
};
